import styled from '@emotion/styled'

const FieldContainer = styled.div`
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 7px;
  padding-left: 7px;
  width: min(100%, 400px);
`

const CircleText = styled.div`
  border: 1px solid ${props => `${props.theme.colors.dark}B0`};
  border-radius: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 7px;
`

const WhyNeedIDNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-left: 7px;
  color: ${props => `${props.theme.colors.dark}B0`};
  margin-top: 14px;
  margin-bottom:  ${props => props.shown ? "14px" : "14px"};
  text-align: left;
`

const ShowWhyNeedIDNumberContainer = styled.div`
  display: ${props => props.shown ? "block" : "none"};
  margin-bottom: 28px;
  margin-left: 7px;
  color: ${props => `${props.theme.colors.dark}B0`};
  text-align: left;
` 

export {
  FieldContainer,
  CircleText,
  WhyNeedIDNumberContainer,
  ShowWhyNeedIDNumberContainer
}
