import React from 'react'
import { ProfileMenuContainer, LogoutContainer, LogoutImage } from './ProfileMenu.style'
import { Text } from '../../generic'
import MenuItem from '../MenuItem'
import ProfileHeader from '../ProfileHeader'
import { logout } from '../../../images'
import { navigate } from 'gatsby'

const menuItems = [/*{
  title: "My Claims"
},*/ {
  title: "My Document Vault",
  path: "/documents"
},{
  title: "My Bank Account Details",
  path: "/bank-accounts"
},/* {
  title: "My Alerts"
},*/{
  title: "Profile Settings",
  path: "/profile/edit"
}]

function ProfileMenu() {
  return <ProfileMenuContainer>
    <ProfileHeader />
    {menuItems.map((item, i) => <MenuItem key={i} title={item.title} onClick={() => navigate(item.path)} />)}
    <LogoutContainer onClick={() => navigate('/logout')}><Text primary>Logout</Text> <LogoutImage src={logout} /></LogoutContainer>
  </ProfileMenuContainer>
}

export default ProfileMenu