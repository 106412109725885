import styled from '@emotion/styled'
import { device } from '../../../theme.style'

const SummaryItemCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  border-radius: 2px;
  padding: 8px;
  background-color: ${props => props.theme.colors.grey};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  align-items: start;
  margin: 2px;
  margin-left: 8px;
  margin-right: 8px;

  ${device.tabletS`
    flex-direction: column;
    margin-left: 2px;
    margin-right: 2px;
    box-shadow: none;
    border-radius: 0px;
  `}
`

const SummaryItemTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TitleContainer = styled.div`
  margin-top: 1px;
  margin-bottom: 1px;
  width 100%;
`

const DescriptionContainer = styled.div`
  width 100%;
  min-height: 20px;
`

const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 5% 4px;
`

const ValueContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: flex-end;
  min-width: 90px;
  justify-content: flex-end;

  ${device.tabletS`
    margin-top: 8px;
    align-self: flex-end;
  `}
`

const AlertNotification = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 3px;
  background-color: ${props => props.theme.colors[props.color || "primary"]};
  display: ${props => props.alert ? "block" : "none"}
`

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  //flex-grow: 1;
`

export {
  SummaryItemCardContainer,
  SummaryItemTitleContainer,
  TitleContainer,
  DescriptionContainer,
  AlertContainer,
  AlertNotification,
  ValueContainerRow,
  ValueContainer
}
