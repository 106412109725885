import styled from '@emotion/styled'
import { css } from '@emotion/core'

const FuneralCoverItemRowContainer = styled.div`
  background: ${props => props.theme.colors.grey};
  border-radius: 2px;
  height: 32px;
  ${props => props.color && css`border-left: 8px solid ${props.theme.colors[props.color]}`};
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
`

export {
  FuneralCoverItemRowContainer
}
