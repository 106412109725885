import React, { useState, useEffect } from 'react'
import { SavingsBucketContainer, BlobContainer, BlobImage, BucketImage, DatePickerContainer, ActionButtons } from './SavingsBucket.style'
import { Row, Col } from '../../generic/grid'
import { Text, ActivityIndicator, SelectableItem, Button, Select } from '../../generic'
import { MokuTipPanel } from '../../shared'
import { useQuery, useMutation, useApolloClient } from 'react-apollo'
import bucketImages from '../../../images/buckets'
import OldSavingsBucket from '../SavingsBucket'
import NewSavingsBucket from '../SavingsBucket3'
import gql from 'graphql-tag'
import moment from 'moment'
import WithdrawalModal from '../WithdrawalModal2'
import { printCurrency } from '../../../lib/utils'

const headingText = {
  YEAR_END: "Here’s your savings journey to your December"
}

const titleText = {
  YEAR_END: "December Savings Plan"
}

const GET_SAVINGS_ITEM_INFO = gql`
  query GetSavingsItemInfo($id: String) {
    myClient {
      id
      idNumber
      bankAccounts {
        id
        bankName
        accountNo
      }
      savings {
        id
        bucketInfo {
          id
          bucketType
          amount
          percentageFull
        }
        paymentBankAccountId
        bucketInfoById(id: $id) {
          id
          bucketType
          description
          name
          amount
          targetAmount
          targetDate
          payoutDate
          percentageFull
          desiredSavings
          totalContributionsAmount
          totalWithdrawalsAmount
        }
      }
    }
  }
`

const UPDATE_BUCKET = gql`
  mutation UpdateBucket($id: String!, $input: SavingsBucketInput!) {
    updateSavingsBucket(id: $id, input: $input) {
      id
      name
      bucketType
      targetAmount
      targetDate
      payoutDate
      desiredSavings
    }
  }
`

const ADD_SAVINGS_BUCKET = gql`
  mutation AddSavingsBucket($input: SavingsBucketInfoInput) {
    addSavingsBucket(bucketInfo: $input) {
      id
      buckets {
        id
        bucketType
      }
    }
  }
`

const TRANSFER_SAVINGS = gql`
  mutation TransferSavings($savingsId: String, $fromBucketId: String, $toBucketId: String, $amount: Float) {
    transferSavings(savingsId: $savingsId, fromBucketId: $fromBucketId, toBucketId: $toBucketId, amount: $amount) {
      id
      fromBucket: bucketInfoById(id: $fromBucketId) {
        id
        bucketType
        description
        name
        amount
        targetAmount
        targetDate
        payoutDate
        percentageFull
        desiredSavings
        totalContributionsAmount
        totalWithdrawalsAmount
      }
      toBucket: bucketInfoById(id: $toBucketId) {
        id
        bucketType
        description
        name
        amount
        targetAmount
        targetDate
        payoutDate
        percentageFull
        desiredSavings
        totalContributionsAmount
        totalWithdrawalsAmount
      }
    }
  }
`

function SavingsBucket(props) {
  const { id } = props

  const [withdrawalOpen, setWithdrawalOpen] = useState(false)
  const [payoutDay, setPayoutDay] = useState(1)
  const [payoutYear, setPayoutYear] = useState(2021)
  const [payoutDateSet, setPayoutDateSet] = useState(false)
  
  const apolloClient = useApolloClient()
  const { data, error, loading } = useQuery(GET_SAVINGS_ITEM_INFO, { variables: { id }})
  const [updateBucket] = useMutation(UPDATE_BUCKET)
  const [transferSavings] = useMutation(TRANSFER_SAVINGS)
  const [transferring, setTransferring] = useState(false)

  useEffect(() => {
    if (!loading && data && data.myClient && !payoutDateSet) {
      console.log(data.myClient.savings.bucketInfoById.payoutDate)

      setPayoutDay(parseInt(moment(data.myClient.savings.bucketInfoById.payoutDate || "2021-12-01").format('D')))
      setPayoutYear(parseInt(moment(data.myClient.savings.bucketInfoById.payoutDate || "2021-12-01").format('YYYY')))
      setPayoutDateSet(true)
    }
  }, [loading])

  useEffect(() => {
    if (payoutDateSet) {
      updateBucket({ variables: { id, input: { payoutDate: moment(`${payoutYear}-12-${payoutDay < 10 ? "0" : ""}${payoutDay}`).valueOf() }  } })
    }
  }, [payoutDay, payoutYear])

  if (loading || transferring) {
    return <ActivityIndicator />
  }

  if (error) {
    return "An error has occured. Please contact support"
  }

  const useData = data.myClient.savings.bucketInfoById
  const bucketId = useData.id
  const savingsId = data.myClient.savings.id
  const clientId = data.myClient.id
  const paymentBankAccountId = data.myClient.savings.paymentBankAccountId
  const bankAccounts = data.myClient.bankAccounts
  const buckets = data.myClient.savings.bucketInfo

  let emergencyBucketId
  buckets.forEach(({ bucketType, id }) => {
    if (!emergencyBucketId && bucketType === "EMERGENCY") {
      emergencyBucketId = id
    }
  })
  
  const transferToEmergencyFund = async (amount) => {
    setTransferring(true)
    if (!emergencyBucketId) {
      const addResult = await apolloClient.mutate({ mutation: ADD_SAVINGS_BUCKET, variables: { input: { bucketType: "EMERGENCY", targetAmount: 10000, targetDate: moment().add(1, 'year').startOf('day').valueOf() } } })
      addResult.data.addSavingsBucket.buckets.forEach(({ bucketType, id }) => {
        if (!emergencyBucketId && bucketType === "EMERGENCY") {
          emergencyBucketId = id
        }
      })
    }
    await transferSavings({ variables: { savingsId, fromBucketId: bucketId, toBucketId: emergencyBucketId, amount  } })
    setTransferring(false)
  }

  if (useData.bucketType === "EMERGENCY" || useData.bucketType === "OTHER" || useData.bucketType === "CAR" || useData.bucketType === "FUNERAL" || useData.bucketType === "HOLIDAY") {
    return <NewSavingsBucket {...props} />
  } else if (useData.bucketType !== "YEAR_END") {
    return <OldSavingsBucket {...props} />
  }

  return <SavingsBucketContainer>
    <Row>
      <Col size={2}><Text size={24} bold>{headingText[useData.bucketType]}</Text></Col>
      <BlobContainer>
        <BlobImage src={bucketImages.blob} alt={headingText[useData.bucketType]} />
        <BucketImage src={bucketImages[useData.bucketType]} alt={headingText[useData.bucketType]} />
      </BlobContainer>
    </Row>
    <p />
    <Text heading>{titleText[useData.bucketType]}</Text>
    <p />
    <SelectableItem title="Savings" value={printCurrency(useData.amount)} />
    <p />
    <Text heading>Payout Date</Text>
    <Text>Select the day and year you would like your December savings should payout. You can change this at any time in your journey.</Text>
    <p />
    <DatePickerContainer>
      <Row>
        <div style={{ width: "110px" }}><Select label="Day" options={[...Array(32).keys()].slice(1)} value={payoutDay} onChange={e => setPayoutDay(e.target.value)} /></div>
        <Col centerVertical centerHorizontal><Text bold>December</Text></Col>
        <div style={{ width: "110px" }}><Select label="Year" options={[2021, 2022, 2023, 2024, 2025]} value={payoutYear} onChange={e => setPayoutYear(e.target.value)} /></div>
      </Row>
    </DatePickerContainer>
    <p />
    <Text heading>Moku Tip</Text>
    <p />
    <MokuTipPanel face="sad" description="You don’t have any emergency savings add your December savings to your Emergency Fund." />
    <p />
    <WithdrawalModal
      open={withdrawalOpen}
      setOpen={setWithdrawalOpen}
      clientId={clientId}
      bucketId={bucketId}
      savingsId={savingsId}
      bankAccounts={bankAccounts}
      paymentBankAccountId={paymentBankAccountId}
      transferToEmergencyFund={transferToEmergencyFund}
      refetchQueries={[{ query: GET_SAVINGS_ITEM_INFO, variables: { id } }]}
      buckets={buckets}
    />
    {useData.amount > 0 && <ActionButtons>
      <Button inverted fullWidth style={{ marginBottom: "14px" }} onClick={() => transferToEmergencyFund(useData.amount)}>Transfer to My Emergency Fund</Button>
      <Button inverted fullWidth style={{ marginBottom: "14px" }} onClick={() => setWithdrawalOpen(true)}>Withdraw Now</Button>
    </ActionButtons>}
  </SavingsBucketContainer>
}

export default SavingsBucket
