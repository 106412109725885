import styled from '@emotion/styled'

const GoalOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: min(calc(100% - 16px), 360px);
  background: ${props => props.theme.colors.grey};
  border-radius: 4px;
  padding-left: 14px;
  padding-right: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 8px;
`

export {
  GoalOptionContainer
}
