import { home, homeSelected, myDebt, myDebtSelected, mySavings, mySavingsSelected, myBudget, myBudgetSelected, myProfile, myProfileSelected, alertNotification } from '../../images/menuBar'

const menuBarItems = [{
  image: home,
  text: "Home",
  selectedImage: homeSelected,
  path: "/"
}, {
  image: myDebt,
  selectedImage: myDebtSelected,
  text: "My Debt",
  path: "/debt"
}, {
  image: mySavings,
  selectedImage: mySavingsSelected,
  text: "My Savings",
  path: "/savings"
}, {
  image: myBudget,
  selectedImage: myBudgetSelected,
  text: "My Insurance",
  path: "/insurance"
}, {
  image: myProfile,
  selectedImage: myProfileSelected,
  text: "My Profile",
  path: "/profile"
}]

export default menuBarItems