import React, { useRef, useEffect, useState } from 'react'
import { VictoryChart, VictoryAxis, VictoryArea, VictoryLine, VictoryZoomContainer, LineSegment, VictoryScatter } from 'victory'
import { useTheme } from 'emotion-theming'
import moment from 'moment'
import CustomPoint from '../CustomPoint'
import { printCurrency } from '../../../lib/utils'

function CustomAxisLine(props) {
  const { desc, arrowStart, arrowEnd, ...rest } = props;
  const { stroke } = props;
  const x1 = Math.min(props.x1, props.x2)
  const x2 = Math.max(props.x1, props.x2)
  const y1 = Math.min(props.y1, props.y2)
  const y2 = Math.max(props.y1, props.y2)
  return desc ? (
    <g>
      {arrowStart && <polygon points={`${x1},${y1+2},${x1-2},${y1},${x1+2},${y1}`} style={{ fill: stroke }} />}
      {arrowEnd && <polygon points={`${x2},${y2},${x2-2},${y2+2},${x2+2},${y2+2}`} style={{ fill: stroke }} />}
      <line vectorEffect="non-scaling-stroke" {...rest}>
        <desc>{desc}</desc>
      </line>
    </g>
  ) : (
    <g>
      {arrowStart && <polygon points={`${x1},${y1+2},${x1-2},${y1},${x1+2},${y1}`} style={{ fill: stroke }} />}
      {arrowEnd && <polygon points={`${x2},${y2},${x2-2},${y2+2},${x2+2},${y2+2}`} style={{ fill: stroke }} />}
      <line vectorEffect="non-scaling-stroke" {...rest} />
    </g>
  );
}

function DebtGraph({ repayments, originalRepayments, income, incomeEstimated }) {
  const [width, setWidth] = useState(window.innerWidth)
  useEffect( () => {
    // The 'current' property contains info of the reference:
    // align, title, ... , width, height, etc.
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
  });

  const startDate = Math.min(...repayments.map(d => d.date), moment().valueOf())
  const debtRepayments = repayments.map(d => ({ x: d.date, y: d.payment  }))

  if (debtRepayments.length === 0) {
    debtRepayments.push({
      x: moment().valueOf(),
      y: 0
    })
  }

  const originalDebtRepayments = originalRepayments.map(d => ({ x: d.date, y: d.payment  }))

  const netIncome = [
    32000, 32000, 32000, 32000, 32000, 32000, 32000, 32000, 32000, 32000, 32000, 32000, 
    35000, 35000, 35000, 35000, 35000, 35000, 35000, 35000, 35000, 35000, 35000, 35000, 
    38000, 38000, 38000, 38000, 38000, 38000, 38000, 38000, 38000, 38000, 38000, 38000,
    42000, 42000, 42000, 42000, 42000, 42000, 42000, 42000, 42000, 42000, 42000, 42000,
    50000, 50000, 50000, 50000, 50000, 50000, 50000, 50000, 50000, 50000, 50000, 50000
  ].map((y, index) => ({ x: moment(startDate).add(index, 'month').valueOf(), y: income }))
  const affordability = netIncome.map(({ x, y }) => ({ x, y: y * 0.4 }))
  const theme = useTheme()

  const maxY = Math.max(
    Math.max(...originalDebtRepayments.map(d => d.y)),
    Math.max(...debtRepayments.map(d => d.y)),
    Math.max(...netIncome.map(d => d.y)),
    Math.max(...affordability.map(d => d.y)),
  )

  return <>
    <svg height={0}>
      <defs>
        <linearGradient id="debtGradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor={`${theme.colors.alertRed}80`} />
          <stop offset="74.5%" stopColor="rgba(255, 29, 66, 0.11)" />
        </linearGradient>
        <linearGradient id="greyDebtGradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor={`${theme.colors.grey5}80`} />
          <stop offset="74.5%" stopColor={`${theme.colors.grey}80`} />
        </linearGradient>
      </defs>
    </svg>
    <VictoryChart padding={{ bottom: 32, top: 0, left: 8, right: 16 }} height={250} width={width} maxDomain={{ y: maxY * 1.2 }}
    containerComponent={
      <VictoryZoomContainer allowZoom={false} allowPan={true} zoomDomain={{x: [debtRepayments[0].x, moment(debtRepayments[0].x).add(Math.floor((width / 400) * 24), 'month').valueOf()], y: [0, maxY * 1.2]}} />
    }
    animate={{
      duration: 2000
    }}    
    >
      <VictoryAxis
        tickFormat={(v) => moment(v).format("MMM 'YY")} 
        style={{ tickLabels: { fontFamily: theme.fontFamily, fontSize: "8px", padding: 8 }, axis: { stroke: theme.colors.grey3 }, grid: { stroke: theme.colors.grey } }} 
        gridComponent={<LineSegment lineComponent={<CustomAxisLine stroke={theme.colors.grey} arrowStart />} />}
      />
      <VictoryAxis dependentAxis 
        tickFormat={() => ""}
        axisComponent={<LineSegment lineComponent={<CustomAxisLine stroke={theme.colors.dark} arrowStart arrowEnd />} />}
      />
      <VictoryArea data={originalDebtRepayments} 
        interpolation="monotoneX"
        style={{
          data: {
            fill: "url(#greyDebtGradient)",
            /*stroke: theme.colors.alertRed,
            strokeDasharray: [2, 2],
            strokeLinecap: "round"*/
          }
        }} 
      />
      <VictoryArea data={debtRepayments} 
        interpolation="monotoneX"
        style={{
          data: {
            fill: "url(#debtGradient)",
            /*stroke: theme.colors.alertRed,
            strokeDasharray: [2, 2],
            strokeLinecap: "round"*/
          }
        }} 
      />
      <VictoryLine data={debtRepayments.map(d => d /*- (maxY / 100)*/)} style={{
        data: {
          stroke: theme.colors.alertRed,
          strokeWidth: "1px"
        }
      }} interpolation="monotoneX" />
      <VictoryLine data={affordability} style={{
        data: {
          stroke: theme.colors.alertYellow
        }
      }} interpolation="monotoneX" />
      {/*<VictoryLine data={affordability} style={{
        data: {
          stroke: theme.colors.alertRed,
          strokeWidth: "1px",
          strokeDasharray: [2, 2],
          strokeLinecap: "round"
        }
      }} />*/}
      <VictoryLine data={netIncome} style={{
        data: {
          stroke: theme.colors.primary
        }
      }} interpolation="monotoneX" />
      <VictoryScatter
        data={[{ x: moment(startDate).add(1, 'year'), y: income || 10000 }]}
        dataComponent={<CustomPoint label={`${printCurrency(income || 10000, 0)}${incomeEstimated ? "*" : ""} net income`} backgroundColor="primary" />}
      />
      {debtRepayments[3] && <VictoryScatter
        data={[debtRepayments[3]]}
        dataComponent={<CustomPoint label="Monthly Debt" backgroundColor="alertRed" />}
      />}
      <VictoryScatter
        data={[{ x: moment(startDate).add(1, 'year').valueOf(), y: income * 0.4 }]}
        dataComponent={<CustomPoint below label="Affordability Line" backgroundColor="alertYellow" textColor="text" />}
      />
    </VictoryChart>
  </>
}

export default DebtGraph

// gradient(180deg, #EB5757 0%, rgba(255, 29, 66, 0.22) 74.76%);