import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { isLoggedIn } from "../services/auth"
import { useQuery } from "react-apollo"
import { ActivityIndicator } from './generic'
import TermsConditions from './TermsConditions'
import { RegisterProfile } from './profile'
import gql from 'graphql-tag'
import firebase from 'gatsby-plugin-firebase'
import OTP from './OTP'
import { useQueryWithOptions } from "../lib/Utilities/react"

const GET_CLIENT_INFO = gql`{
  myClient {
    id
    acceptedTerms
    marketingConsent
    isRegistration
    otpVerification
  }
}`

const PrivateRoute = ({ component: Component, location, showSideBar, setShowSideBar, index, setSelectedIndex, ...rest }) => {
  const { data, loading, error, options } = useQueryWithOptions(GET_CLIENT_INFO);
  const { ErrorPage } =  options;

  useEffect(() => {
    setSelectedIndex && setSelectedIndex(index)
  }, [])

  if (typeof window === 'undefined') {
    return <div />
  }

  if (!isLoggedIn() && location.pathname !== `/sign-in`) {
    navigate(`/sign-in?redirect=${location.pathname}`)
    return null
  }

  if (loading) return <ActivityIndicator />

  if (error) return <ErrorPage /*message={error.message}*/ />

  if ((data.myClient.isRegistration || !data.myClient.acceptedTerms)) {
    if (!data.myClient.isRegistration && !data.myClient.acceptedTerms) {
      firebase
      .analytics()
      .logEvent("REGISTER_PROFILE_ACCEPT_TERMS", {})
    } else {
      firebase
        .analytics()
        .logEvent("REGISTER_PROFILE_FORM", {})
    }
    setShowSideBar && setShowSideBar(false)
    return <RegisterProfile />
  } else if (!data.myClient.otpVerification) { 
    if (showSideBar) {
      setShowSideBar && setShowSideBar(false)
      return <div>Opt invalid</div>
    }
    firebase
      .analytics()
      .logEvent("REGISTER_PROFILE_OTP", {})
    return <OTP nextRoute={typeof window !== 'undefined' && window ? window.location.pathname : null} refetchQuery={GET_CLIENT_INFO} />
  } else {
    setShowSideBar(true)
  }

  return <Component {...rest} />
}

export default PrivateRoute