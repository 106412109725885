import React, { useState } from 'react'
import { ProfileHeaderContainer, ProfileInfoContainer, ProfileImageContainer, ProfileAvatar } from './ProfileHeader.style'
import { Text } from '../../generic'
import { profile } from '../../../images'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import moment from 'moment'
import UpdateProfilePicture from '../UpdateProfilePicture'

const GET_CLIENT_INFO = gql`{
  myClient {
    id
    firstNames
    surname
    photo {
      url
    }
    createdAt
  }
}
`

function ProfileHeader() {
  const [open, setOpen] = useState(false)
  const { data, loading, error } = useQuery(GET_CLIENT_INFO)

  const { photo, firstNames = "", surname = "", createdAt } = (data && data.myClient) || {}
  console.log(data)

  return <ProfileHeaderContainer>
    <UpdateProfilePicture open={open} setOpen={setOpen} reloadQueries={[{ query: GET_CLIENT_INFO }]} />
    <ProfileInfoContainer>
      <Text bold size={26}>My Profile</Text>
      <Text bold size={16}>{firstNames} {surname}</Text>
      <Text size={13}>Member since {moment(createdAt).format("YYYY")}</Text>
    </ProfileInfoContainer>
    <ProfileImageContainer>
      <ProfileAvatar src={(photo && photo.url) || profile} loading alt="Profile Image" />
      <Text onClick={() => setOpen(true)}>Edit</Text>
    </ProfileImageContainer>
  </ProfileHeaderContainer>
}

export default ProfileHeader
