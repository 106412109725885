import React from 'react'
import { navigate } from 'gatsby'
import { SavingsCardContainer, TitleContainer, SmilyContainer } from './SavingsCard.style'
import { Text } from '../../generic'
import { Row, Col } from '../../generic/grid'
import { ProgressBarFull, ProgressBarDone } from '../../savings/ProgressBar/ProgressBar.style'
import { printCurrency } from '../../../lib/utils'
import { sad, meh, happy } from '../../../images/smily'
import { MokuTipPanel } from '../../shared'

const cardText = {
  HOUSE: "Housing",
  CAR: "Car",
  EDUCATION: "Education",
  HOLIDAY: "Holiday",
  EMERGENCY: "Emergency",
  YEAR_END: "December Fund",
  RETIREMENT: "Retirement",
  HEALTH: "Health",
  FUNERAL: "Funeral",
  OTHER: "Custom"
}

const getSmily = (percentage) => {
  if (percentage < 30) {
    return sad
  } 
  if (percentage < 70) {
    return meh
  }
  return happy
}

const getBarColor = (percentage) => {
  if (percentage < 30) {
    return "alertRed"
  } 
  if (percentage < 70) {
    return "alertAmber"
  }
  return "primary"
}

function SavingsCard({ type, percentage, to, targetAmount, targetDate, title, onClick }) {
  return <SavingsCardContainer onClick={onClick || (() => to && navigate(to))}>
    <TitleContainer>
      <Row>
        <Col size={3} centerVertical><Text size={16} bold>{title || cardText[type || "OTHER"]} Savings Goal</Text></Col>
        <Col size={1} style={{ alignItems: "flex-end" }}><img src={getSmily(percentage)} alt="Status" style={{ margin: 0 }} /></Col>
      </Row>
    </TitleContainer>
    <Text color="grey2" size={13}>Keep on contributing</Text>
    <div style={{ height: "40px", position: "relative" }}>
      <ProgressBarFull>
        <ProgressBarDone percentage={Math.min(100,percentage)} color={getBarColor(percentage)} />
      </ProgressBarFull>
      <div style={{ height: "24px" }} />
      <Row>
        <Col><Text color="grey2" size={13}>Estimated Goal: {printCurrency(targetAmount)}</Text></Col>
        <Col style={{ alignItems: "flex-end" }}><Text color="grey2" size={13}>{Math.min(100, Math.round(percentage))} % reached</Text></Col>
      </Row>
    </div>
  </SavingsCardContainer>
}

SavingsCard.cardText = cardText

export default SavingsCard
