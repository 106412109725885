import React, { useState, useEffect } from 'react'
import { SavingsBucketContainer, ActionButtons, ContentCard, BucketGraphContainer, BucketGraphDescriptionContainer } from './SavingsBucket.style'
import { Text, ActivityIndicator, Button, SpiralGraph } from '../../generic'
import { InformationRow, ImageHeader, MokuNotePanel } from '../../shared'
import { useQuery, useMutation, useApolloClient } from 'react-apollo'
import SavingsStatusCard from '../SavingsStatusCard'
import gql from 'graphql-tag'
import moment from 'moment'
import WithdrawalModal from '../WithdrawalModal2'
import { printCurrency } from '../../../lib/utils'
import savingsHeaders from '../../../images/savingsHeaders'
import { savingsGoalMetaData } from '../../../lib/savings'
import { FormProvider, FormField } from 'react-form-helper'
import renderFieldDisplay from '../../generic/ChatFlow/renderFieldDisplay'
import { navigate } from 'gatsby'
import firebase from 'gatsby-plugin-firebase';
import SavingsGoalWizard from '../SavingsGoalWizard'

const headingText = {
  YEAR_END: "Here’s your savings journey to your December"
}

const titleText = {
  EMERGENCY: "Emergency Fund",
  YEAR_END: "December Savings Plan",
  OTHER: "Custom Savings Goal",
  CAR: "Car Savings Plan",
  FUNERAL: "Funeral Savings Goal",
  HOLIDAY: "Holiday Goal"
}

const GET_SAVINGS_ITEM_INFO = gql`
  query GetSavingsItemInfo($id: String) {
    myClient {
      id
      idNumber
      bankAccounts {
        id
        bankName
        accountNo
      }
      income
      birthDate
      savings {
        id
        paymentBankAccountId
        bucketInfo {
          id
          bucketType
          amount
          percentageFull
        }
        bucketInfoById(id: $id) {
          id
          bucketType
          description
          name
          amount
          targetAmount
          targetDate
          payoutDate
          percentageFull
          desiredSavings
          existingAmount
          totalContributionsAmount
          totalWithdrawalsAmount
          metaData {
            key
            value
          }
        }
      }
    }
  }
`

const UPDATE_BUCKET = gql`
  mutation UpdateBucket($id: String!, $input: SavingsBucketInput!) {
    updateSavingsBucket(id: $id, input: $input) {
      id
      name
      bucketType
      targetAmount
      targetDate
      payoutDate
      desiredSavings
    }
  }
`

const ADD_SAVINGS_BUCKET = gql`
  mutation AddSavingsBucket($input: SavingsBucketInfoInput) {
    addSavingsBucket(bucketInfo: $input) {
      id
      buckets {
        id
        bucketType
      }
    }
  }
`

const TRANSFER_SAVINGS = gql`
  mutation TransferSavings($savingsId: String, $fromBucketId: String, $toBucketId: String, $amount: Float) {
    transferSavings(savingsId: $savingsId, fromBucketId: $fromBucketId, toBucketId: $toBucketId, amount: $amount) {
      id
      fromBucket: bucketInfoById(id: $fromBucketId) {
        id
        bucketType
        description
        name
        amount
        targetAmount
        targetDate
        payoutDate
        existingAmount
        percentageFull
        desiredSavings
        totalContributionsAmount
        totalWithdrawalsAmount
      }
      toBucket: bucketInfoById(id: $toBucketId) {
        id
        bucketType
        description
        name
        amount
        targetAmount
        targetDate
        payoutDate
        existingAmount
        percentageFull
        desiredSavings
        totalContributionsAmount
        totalWithdrawalsAmount
      }
    }
  }
`

const getBarColor = (percentage) => {
  if (percentage < 30) {
    return "alertRed"
  } 
  if (percentage < 70) {
    return "alertAmber"
  }
  return "primary"
}

function SavingsBucket(props) {
  const { id } = props

  const [withdrawalOpen, setWithdrawalOpen] = useState(false)
  const [payoutDay, setPayoutDay] = useState(1)
  const [payoutYear, setPayoutYear] = useState(2021)
  const [payoutDateSet, setPayoutDateSet] = useState(false)
  const [editBucketOpen, setEditBucketOpen] = useState(false)
  
  const apolloClient = useApolloClient()
  const { data, error, loading, refetch } = useQuery(GET_SAVINGS_ITEM_INFO, { variables: { id }})
  const [updateBucket, { loading: updatingBucket, error: errorUpdatingBucket }] = useMutation(UPDATE_BUCKET)
  const [transferSavings, { loading: transferringSavings, error: errorTransferringSavings }] = useMutation(TRANSFER_SAVINGS)
  const [transferring, setTransferring] = useState(false)

  useEffect(() => {
    if (!loading && data && data.myClient && !payoutDateSet) {
      console.log(data.myClient.savings.bucketInfoById.payoutDate)

      setPayoutDay(parseInt(moment(data.myClient.savings.bucketInfoById.payoutDate || "2021-12-01").format('D')))
      setPayoutYear(parseInt(moment(data.myClient.savings.bucketInfoById.payoutDate || "2021-12-01").format('YYYY')))
      setPayoutDateSet(true)
    }
  }, [loading])

  useEffect(() => {
    if (payoutDateSet) {
      updateBucket({ variables: { id, input: { payoutDate: moment(`${payoutYear}-12-${payoutDay < 10 ? "0" : ""}${payoutDay}`).valueOf() }  } })
    }
  }, [payoutDay, payoutYear])

  if (loading || transferring) {
    return <ActivityIndicator />
  }

  if (error) {
    return "An error has occured. Please contact support"
  }

  const useData = data.myClient.savings.bucketInfoById
  const bucketId = useData.id
  const savingsId = data.myClient.savings.id
  const clientId = data.myClient.id
  const paymentBankAccountId = data.myClient.savings.paymentBankAccountId
  const bankAccounts = data.myClient.bankAccounts
  const buckets = data.myClient.savings.bucketInfo
  const income = data.myClient.income
  const birthDate = data.myClient.birthDate

  console.log('BANK ACCOUNTS=', bankAccounts)

  let emergencyBucketId
  buckets.forEach(({ bucketType, id }) => {
    if (!emergencyBucketId && bucketType === "EMERGENCY") {
      emergencyBucketId = id
    }
  })
  
  const transferToEmergencyFund = async (amount) => {
    setTransferring(true)
    if (!emergencyBucketId) {
      const addResult = await apolloClient.mutate({ mutation: ADD_SAVINGS_BUCKET, variables: { input: { bucketType: "EMERGENCY", targetAmount: 10000, targetDate: moment().add(1, 'year').startOf('day').valueOf() } } })
      addResult.data.addSavingsBucket.buckets.forEach(({ bucketType, id }) => {
        if (!emergencyBucketId && bucketType === "EMERGENCY") {
          emergencyBucketId = id
        }
      })
    }
    await transferSavings({ variables: { savingsId, fromBucketId: bucketId, toBucketId: emergencyBucketId, amount  } })
    setTransferring(false)
  }

  const transferAllToEmergencyFund = async (amount) => {
    await transferToEmergencyFund(amount);
    firebase.analytics().logEvent("SAVINGS_TRANSFER_ALL_TO_EMERGENCY_DONE" , { bucketType: useData.bucketType });
  };

  return <SavingsBucketContainer>
    <ImageHeader title={titleText[useData.bucketType || "OTHER"]} image={savingsHeaders[useData.bucketType || "OTHER"]} onBack={() => navigate('/savings')}/>
    <div style={{ marginTop: "-22px", marginLeft: "auto", marginRight: "auto" }}>
      <center><SavingsStatusCard status={useData.amount === 0 ? "NO_SAVINGS" : useData.percentageFull < 70 ? "BELOW_TARGET" : "ON_TRACK"} /></center>
    </div>
    <p />
    <Text heading>{titleText[useData.bucketType]}</Text>
    <p />
    <Text size={18} bold>My Savings Plan</Text>
    <ContentCard>
      <BucketGraphContainer>
        <SpiralGraph width={150} height={150} series={[{ max: 100, value: useData.percentageFull, color: getBarColor(useData.percentageFull) }]} />
        <BucketGraphDescriptionContainer>
          <Text size={13}>{titleText[useData.bucketType]}</Text>
        </BucketGraphDescriptionContainer>
      </BucketGraphContainer>
    </ContentCard>
    <MokuNotePanel title="Get more with Meerkat" description="Transfer your existing capital into your Meerkat Savings to do more with your money" />
    <p />
    <Text size={18} bold>Plan Details</Text>
    <ContentCard>
      <InformationRow title={<Text size={16} bold>{titleText[useData.bucketType]}</Text>} value={<Text size={13} primary onClick={() => setEditBucketOpen(true)}>Edit</Text>} />
      {Object.keys(savingsGoalMetaData[useData.bucketType] || {}).length > 0 &&
      <FormProvider
        name={`savings-${id}`}
        mode="display"
        fields={{
          metaData: {
            type: "keyvalue",
            config: savingsGoalMetaData[useData.bucketType] || {}
          }
        }}
        data={useData}
        renderDisplay={renderFieldDisplay}
        initialValues={useData}
        renderField={({ mode, Value, Label }) => <InformationRow title={<Label />} value={<Value mode={mode} />} />}
      >
        {Object.keys(savingsGoalMetaData[useData.bucketType] || {}).map((field, i) => <FormField key={i} name={`metaData.${field}`} />)}
      </FormProvider>}
      <InformationRow title="Existing Capital" value={printCurrency(useData.existingAmount)} />
      <InformationRow title="Savings per month" value={printCurrency(useData.desiredSavings)} />
      <InformationRow title="Goal reached" value={useData.targetDate ? moment(useData.targetDate).format("MMM YYYY") : "-"} />  
    </ContentCard>
    <p />
    <WithdrawalModal
      open={withdrawalOpen}
      setOpen={setWithdrawalOpen}
      clientId={clientId}
      bucketId={bucketId}
      savingsId={savingsId}
      bankAccounts={bankAccounts}
      paymentBankAccountId={paymentBankAccountId}
      refetchQueries={[{ query: GET_SAVINGS_ITEM_INFO, variables: { id } }]}
      transferToEmergencyFund={useData.bucketType !== "EMERGENCY" && transferToEmergencyFund}
      buckets={buckets}
      refetchBankAccounts={refetch}
    />
    <SavingsGoalWizard open={editBucketOpen} setOpen={setEditBucketOpen} id={id}
      bucketData={useData}
      refetchQueries={[{ query: GET_SAVINGS_ITEM_INFO, variables: { id } }]}
      income={income}
      birthDate={birthDate}
    />
    <ActionButtons>
      <Button inverted style={{ marginBottom: "14px" }} onClick={() => setWithdrawalOpen(true)}>Withdraw Funds</Button>
      {useData.bucketType !== "EMERGENCY" && <Button inverted style={{ marginBottom: "14px" }} onClick={() => transferAllToEmergencyFund(useData.amount)}>Transfer to My Emergency Fund</Button>}
    </ActionButtons>
  </SavingsBucketContainer>
}

export default SavingsBucket
