import { useQuery } from '@apollo/react-hooks'
import { DocumentNode } from 'graphql'
import { QueryHookOptions } from 'react-apollo'
import ErrorPage from '../../components/pages/ErrorPage'

export const useQueryWithOptions = (query: DocumentNode, options?: QueryHookOptions) => {
  return { ...useQuery(query, options), options: { ErrorPage } }
  //     let result: any ={};
  //     // try{
  //         result = {...useQuery.apply(this, arguments), options: {ErrorPage}};
  //     // }catch(e){
  //     //     result = {data:null, loading:null, error:true, options: {ErrorPage}};
  //     // }

  //     return result;
}
