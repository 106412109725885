import React, { useState } from 'react'
import { Text, ActivityIndicator, Button, SpiralGraph  } from '../../generic'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import SavingsCard from '../SavingsCard2'
import { MySavingsContainer, SavingsGraphContainer } from './MySavings.style'
import BankDetails from "../BankDetails"
import SummaryItemCard from '../../dashboard/SummaryItemCard'
import TipItem from '../../dashboard/TipItem'
import { Row, Col } from '../../generic/grid'
import { printCurrency } from '../../../lib/utils'
import { GetInfoModal, MokuNotePanel, TitleImage } from '../../shared'
import { rollingMoney } from '../../../images'
import GoalOption from '../GoalOption'
import SavingsGoalWizard from '../SavingsGoalWizard'
import { update } from '../../../images/tips'
import { rightArrow } from '../../../images/itemRow'
import { BudgetSlideUpModal } from '../../budget'
import WithdrawalModal from '../WithdrawalModal2'
import { RowSpacer } from '../../generic/Layouts'
import UpdateForm from '../../shared/GetInfoModal/UpdateForm';
import { isRequired, isValidRsaTaxNumber } from '../../../lib/validators';
import appConfig from '../../../appConfig'
import { BlogPostCard } from '../../shared'
import { BlogCardsContainer } from '../../shared/BlogPostCard/BlogPostCard.style'
import improveCreditScoreImage from '../../../images/blogPosts/improveCreditScore.webp'
import consolidateDebtImage from '../../../images/blogPosts/consolidateDebt.webp'
import debtReviewImage from '../../../images/blogPosts/debtReview.webp'
import { BlogPostCards } from '../../sections'
import whiteWindow from '../../../images/blogPosts/whiteWindow.jpeg'
import emptyWallet from '../../../images/blogPosts/emptyWallet.jpeg'
import growMoney from '../../../images/blogPosts/growMoney.jpeg'

const MY_SAVINGS = gql`
  {
    myClient {
      id
      idNumber
      bankAccounts {
        id
        bankName
        accountNo
      }
      income
      birthDate
      taxNumber
      savings {
        id
        bucketInfo {
          id
          bucketType
          percentageFull
          amount
          desiredSavings
          targetDate
          targetAmount
        }
      }
    }
  }
`
const GET_TAX_NUMBER = gql`{
  myClient {
    id
    taxNumber
  }
}`;

const UPDATE_TAX_NUMBER = gql`
  mutation UpdateProfile($input: UpdateClientInput) {
    updateMyProfile(input: $input) {
      id
      taxNumber
    }
  }
`;

const getBarColor = (percentage) => {
  if (percentage < 30) {
    return "alertRed"
  } 
  if (percentage < 70) {
    return "alertAmber"
  }
  return "primary"
}

const allBlogs = [
  {
    imageSrc: whiteWindow,
    title: 'Saving for your very first home!',
    description: 'Are you thinking about saving for your first home? This blog will give you some tips and tricks to keep motivated and save for your very first home!',
    linkUrl: 'https://www.meerkat.co.za/blog/saving-for-your-very-first-home',
  },
  {
    imageSrc: emptyWallet,
    title: 'How can I start saving?',
    description: `It's not easy to start saving when finances are tight and you are struggling to get through each month. But, the best thing is to just start small.`,
    linkUrl: 'https://www.meerkat.co.za/blog/news/start-saving',
  },
  {
    imageSrc: growMoney,
    title: 'How saving can keep you debt free',
    description: 'Being debt free may seem like a dream rather than reality. Read our new blog post to find out how saving can keep you detb free.',
    linkUrl: 'https://www.meerkat.co.za/blog/news/savings-keeps-you-debt-free',
  },
]

function MySavings() {
  const { data, loading, error, refetch } = useQuery(MY_SAVINGS)
  const [goal, setGoal] = useState()
  const [addGoal, setAddGoal] = useState(false)
  const [budgetOpen, setBudgetOpen] = useState(false)
  const [withdrawalOpen, setWithdrawalOpen] = useState(false)
  const [getTaxNumberOpen, setGetTaxNumberOpen] = useState(false);

  if (loading) {
    return <ActivityIndicator />
  }

  if (error) {
    return error.message
  }

  

  const myClient = (data && data.myClient) || {}
  const savings = myClient.savings || {}
  const bucketInfo = savings.bucketInfo || []
  const validBuckets = bucketInfo.filter(bucket => bucket.percentageFull || bucket.percentageFull === 0)
  const totalSavings = validBuckets.reduce((total, bucket) => total + bucket.amount || 0, 0)
  // const totalTarget = validBuckets.reduce((total, bucket) => total + bucket.targetAmount || 0, 0)
  const totalMonthly = validBuckets.reduce((total, bucket) => total + bucket.desiredSavings || 0, 0)
  const savingsId = savings.id
  const clientId = myClient.id
  const bankAccounts = myClient.bankAccounts
  const income = myClient.income
  const birthDate = myClient.birthDate
  const paymentBankAccountId = savings.paymentBankAccountId

  const updateTaxNumberFields = {
    taxNumber:{
      label: "Tax Number",
      type: "text",
      validate: [isRequired, isValidRsaTaxNumber],
      placeholder: "Enter your Tax Number"
    },
  };

  const blogs = validBuckets.length > 0 ? [allBlogs[0], allBlogs[2]] : [allBlogs[0], allBlogs[1]]

  // TODO: income == 0 when null?
  // if((!myClient.taxNumber || myClient.income == 0) && !getIncomeOpen){
  //   setGetTaxNumberOpen(true);
  // }

  if (data.myClient.savings) {
  return <MySavingsContainer>
    <GetInfoModal open={getTaxNumberOpen}>
      <UpdateForm 
        fields={updateTaxNumberFields}
        afterSubmit={(data)=> {
          console.log("after submit:", data);
          setGetTaxNumberOpen(false);
        }}
        getQuery={GET_TAX_NUMBER}
        updateMutation={UPDATE_TAX_NUMBER}
        title={"My Tax Number"}
      />
    </GetInfoModal>
    

    <BudgetSlideUpModal open={budgetOpen} setOpen={setBudgetOpen} />
    <SavingsGoalWizard open={addGoal} setOpen={setAddGoal} refetchQueries={[{ query: MY_SAVINGS }]} income={income} birthDate={birthDate} />
    <Text size={26} bold>My Savings</Text>
    <p />
    <Row>
      <SummaryItemCard title="Monthly Savings" description="Contributions" value={printCurrency(totalMonthly)} color="text" />
      <SummaryItemCard title="Total Savings" description="To date" value={printCurrency(totalSavings)} color="text" />
      {/*<SummaryItemCard title="Savings Goal" description="Total savings goal" value={printCurrency(totalTarget)} color="text" />*/}
    </Row>
    <p />
    <Text size={18} bold>My Savings Goals</Text>
    <SavingsGraphContainer>
      <SpiralGraph width={280} series={validBuckets.map(bucket => ({ label: SavingsCard.cardText[bucket.bucketType || "OTHER"] || "Custom Goal", max: 100, value: Math.min(100, bucket.percentageFull), color: getBarColor(bucket.percentageFull) }))} />
    </SavingsGraphContainer>
    <p />
    { appConfig.coreFeatures.savings.getTaxNumber && !myClient.taxNumber && <MokuNotePanel title={"Moku Tip: Get your Tax Certificate"} description=
      {
        <div style={{ paddingRight: "28px" }}>
          <RowSpacer height={8}/>
          {/* TODO: Consolidate lineHeight */}
          <Text size={13} span style={{fontWeight:700, lineHeight:"20px !important"}}>We would like to send you your tax certificate. </Text>
          <Text size={13} span style={{fontWeight:400, lineHeight:"20px !important"}}>Please add your tax number to your profile. </Text>
          <RowSpacer height={22}/>
        </div>
      }
      footer={
        <>
          <center style={{ width: "100%" }}>
            <Button inverted onClick={() => setGetTaxNumberOpen(true)}>Add Tax Number</Button>
            
          </center>
          <RowSpacer height={8}/>
        </>
      }
    />}
    <p />
    { validBuckets.map(bucket => <SavingsCard key={bucket.id} to={`/savings/${bucket.id}`}type={bucket.bucketType} percentage={bucket.percentageFull} targetDate={bucket.targetDate} targetAmount={bucket.targetAmount} />)}    
    <p />
    <Text size={26} bold>{"Start Contributing"}</Text>
    <RowSpacer height={8}/>
    <Text size={13}>{"Add money  to your goal. EFT your contribution to:"}</Text>
    <RowSpacer height={16}/>
    <BankDetails />
    <p />
    <TitleImage title="Keeping you on-track" description="Here are some financial tips from us to help you reach your financial goals." image={rollingMoney} />
    <p />
    {/*<TipItem title="Set up my savings allocations" actionText={<img src={rightArrow} style={{ margin: 0 }} />} icon={overIndebted} onClick={() => {}}/>*/}
    <TipItem title="Set-up your Budget" actionText={<img src={rightArrow} style={{ margin: 0 }} />} icon={update} onClick={() => { setBudgetOpen(true) }}/>
    <p />
    <BlogPostCards blogs={blogs}/>
    <p />
    {/*<center><Button>Withdraw all Funds</Button></center>
    <p />*/}
    {/* <Text size={18} bold>Save now towards your goals</Text>
    <p />
    <MokuNotePanel title="To add to your savings, set up an EFT using the following details" description={<><br/><BankDetails /></>} />
    <p /> */}
    <center><Button onClick={() => { setAddGoal(true) }}>Add Savings Goal</Button></center>
    <p/>
    <center><Button inverted onClick={() => setWithdrawalOpen(true)}>Withdraw Funds</Button></center>
    <WithdrawalModal
      open={withdrawalOpen}
      setOpen={setWithdrawalOpen}
      clientId={clientId}
      savingsId={savingsId}
      bankAccounts={bankAccounts}
      paymentBankAccountId={paymentBankAccountId}
      refetchQueries={[{ query: MY_SAVINGS }]}
      refetchBankAccounts={refetch}
      buckets={bucketInfo}
    />
    </MySavingsContainer>
  }
  else {
    return <MySavingsContainer>
      <SavingsGoalWizard open={addGoal} setOpen={setAddGoal} bucketType={goal} income={income} birthDate={birthDate} />
      <TitleImage bottomText title="My Savings" title2="Add your goals" image={rollingMoney} />
      <Text bold size={13}>We want to see you save smart and reach your life goals. We recommend you start off with an Emergency Savings fund. Add and custom your savings goals as you go along. Remember you can withdraw funds at anytime, anywhere.</Text>
      <p />
      <Text size={16} bold>Savings Goals we Recommend</Text>
      <p />
      <GoalOption title="Emergency Savings Fund" description="We recommend you start here" onClick={() => { setGoal("EMERGENCY"); setAddGoal(true); }} />
      <GoalOption title="Education Savings Goal" description="Start saving for your or your family’s education" onClick={() => { setGoal("EDUCATION"); setAddGoal(true); }} />
      <GoalOption title="Car Savings Goal" description="Buying or maintaining your car, start saving" onClick={() => { setGoal("CAR"); setAddGoal(true); }} />
      <GoalOption title="Property Savings Goal" description="Buying or renting start saving" onClick={() => { setGoal("HOUSE"); setAddGoal(true); }} />
      <GoalOption title="Funeral Savings Goal" description="Save and stop paying premiums" onClick={() => { setGoal("FUNERAL"); setAddGoal(true); }} />
      <GoalOption title="Holiday Savings Goal" description="Start saving for your holidays" onClick={() => { setGoal("HOLIDAY"); setAddGoal(true); }} />
      <p />
      <center><Button inverted outlined onClick={() => { setGoal(null); setAddGoal(true); }}>Add a Goal</Button></center>
    </MySavingsContainer>
  }
}

// 63px betweeen

MySavings.MY_SAVINGS_QUERY = MY_SAVINGS

export default MySavings