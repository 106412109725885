import styled from '@emotion/styled'

const Block = styled.div`
  width: 217px;
  height: 217px;
  background-color: ${props => props.theme.colors.grey}
`

export {
  Block
}
