import React, { useState } from 'react'
import { SummaryItemsRow } from '../../dashboard/MyDashboard/MyDashboard.style'
import { ActivityIndicator, Text, Button } from '../../generic'
import { useQuery } from 'react-apollo'
import { printCurrency } from '../../../lib/utils'
import gql from 'graphql-tag'
import SummaryItemCard from '../../dashboard/SummaryItemCard'
import DetailedItemCard from '../../dashboard/DetailedItemCard'
import { BackActionHeader, TitleImage, BlogPostCard, MokuNotePanel } from '../../shared'
import { navigate } from 'gatsby'
import moment from 'moment'

const MY_GROUP_PRODUCT = gql`query ($id:String!) {
  groupproduct(id:$id) {
    id
    groupProductNumber
    state
    balance
    paymentDueDay
    processedUntil
    numFullPayments
    payments {
      id
      amount
    }
    group {
      id
      name
    }
    groupContributions {
      id
      isPaid
      period
      contributions {
        amount
      }
    }
  }
}`


function GroupContribution({ id }) {
  const { loading, data, error } = useQuery(MY_GROUP_PRODUCT, { variables: { id } })

  if (loading) {
    return <ActivityIndicator />
  }

  if (error) {
    return "Something has gone wrong. Please contact support"
  }

  const { groupContributions, numFullPayments, processedUntil, balance } = data && data.groupproduct || {}
  const sortedGroupContributions = (groupContributions || []).sort((a, b) => b.period.localeCompare(a.period))   

  return <>
    <BackActionHeader title="Home" onClick={() => navigate('/')} />
    <Text size={26} bold>Group Contribution</Text>

    <SummaryItemsRow>
      <SummaryItemCard title="Total Months" description="Contributing" value={numFullPayments} color="text" />
      <SummaryItemCard title="Next Payment" description={`Due: ${moment(processedUntil).format("MMM YYYY")}`} value={printCurrency((sortedGroupContributions.length > 0 && !sortedGroupContributions['0'].isPaid && sortedGroupContributions['0'].contributions || []).reduce((total, contribution) => total + contribution.amount || 0, 0))} color="text" /> 
      <SummaryItemCard title="Total Balance" description="" value={printCurrency(balance)} color="text" />
    </SummaryItemsRow>
    <p />
    {/* <MokuNotePanel title="Save with Meerkat" description="Get a personalised quote for your funeral cover today. We can also consolidate any existing policies into one more affordable premium.">
      <div style={{ display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center" }}><Button onClick={() => setOpen(true)}>Get a Quick Quote</Button></div>
    </MokuNotePanel>
    <p />
    <MokuNotePanel title="Add Existing Cover" description="Add your existing cover and we’ll compare to our funeral cover to see if we can save you money each month. We’ll also be able to provide a summary of all your funeral polices in one place.">
      <div style={{ display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center" }}><Button onClick={() => setAddExisting(true)}>Add Existing Policy</Button></div>
    </MokuNotePanel>
    <p /> */}

    <Text size={16} bold>Contributions</Text>
    <p />
    {(sortedGroupContributions || []).map(groupContribution => <DetailedItemCard key={groupContribution.id} description={`${groupContribution.isPaid ? `Paid` : `Due`} ${moment(groupContribution.period).format("MMM YYYY")}`} title={moment(groupContribution.period).format("MMM YYYY")} value={printCurrency((groupContribution.contributions || []).reduce((total, contribution) => total + contribution.amount || 0, 0))} />)}
    <p />
    <p />
    <p />
    {/* <center>
      <Button inverted onClick={() => setNewMember(true)}>Add New Member</Button>
    </center> */}
    <div style={{ height: "75px" }} />
  </>
}

export default GroupContribution
