import React from 'react'
import { RegisterProfileContainer, RegisterProfileContentContainer, LogoImageContainer } from './RegisterProfile.style'
import { Text, Button } from '../../generic'
import { useTranslation } from '../../../hooks'
import ProfileForm from '../ProfileForm'
import firebase from 'gatsby-plugin-firebase'
import { logo } from '../../../images'
import { navigate } from 'gatsby'

function RegisterProfile() {
  const { t } = useTranslation("RegisterProfile")

  return <RegisterProfileContainer>
    <LogoImageContainer>
      <img src={logo} alt="Meerkat" />
    </LogoImageContainer>
    <Text size={24} bold>{t("description")}</Text>
    <RegisterProfileContentContainer>
      <ProfileForm getIdNumber afterSubmit={() => { 
        firebase
          .analytics()
          .logEvent("REGISTER_PROFILE_SUBMIT", {});
        window.location.reload()
      }} />
    </RegisterProfileContentContainer>
    <Button inverted noBorder onClick={() => navigate('/logout')}>Sign In with a different account</Button>
  </RegisterProfileContainer>
}

export default RegisterProfile