import styled from '@emotion/styled'

const DocumentListItemContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: 46px;
  border-bottom: 1px solid #E0E0E0;
  align-items: center;
`

const DocumentListItemTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  aligh-items: flex-start;
  justify-content: center;
`

const DocumentListItemAlert = styled.div`
  margin-right: 10px;
  width: 8px;
  height: 8px;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 4px;
`

const DocumentListItemArrow = styled.img`
  position: absolute;
  right: 0px;
  top: 18px;
  margin: 0;  
`

export {
  DocumentListItemContainer,
  DocumentListItemTitleContainer,
  DocumentListItemAlert,
  DocumentListItemArrow
}
