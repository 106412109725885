/*
ql types?
--------
ClientCreditor
Creditor
BankAccount

Mr Debt: 6184d8b2a7b234001cb29466

? L is life insurance I think
["a", "b", "c"].reduce((acc,cur)=>acc+cur, "-")
*/

// const NORMAL_DEBT = ['C', 'D', 'E', 'G', 'I', 'M', 'N', 'O', 'P', 'R', 'T', 'V', 'Z']
// const HOUSING_DEBT = ['B', 'H']
// const CREDIT_CARD_DEBT = ['C', 'R']
// const VEHICLE_DEBT = ['Y']

//use string map
const debtCategorylookUp = {
  C: 'CREDIT_CARD_DEBT',
  D: 'NORMAL_DEBT',
  E: 'NORMAL_DEBT',
  G: 'NORMAL_DEBT',
  I: 'NORMAL_DEBT',
  M: 'NORMAL_DEBT',
  N: 'NORMAL_DEBT',
  O: 'NORMAL_DEBT',
  P: 'NORMAL_DEBT',
  R: 'CREDIT_CARD_DEBT',
  T: 'NORMAL_DEBT',
  V: 'NORMAL_DEBT',
  Z: 'NORMAL_DEBT',
  B: 'HOUSING_DEBT',
  H: 'HOUSING_DEBT',
  Y: 'VEHICLE_DEBT',
}
//clientMock.debtBreakdown.obligations

function classifyObligations(obligations: any) {
  const result = {
    NORMAL_DEBT: [],
    HOUSING_DEBT: [],
    CREDIT_CARD_DEBT: [],
    VEHICLE_DEBT: [],
    OTHER: [],
  }
  obligations.forEach((ob: any) => {
    const key = (debtCategorylookUp as any)[ob.accountType]
    console.log('key?', key, ob.accountType)
    if (key == null) (result['OTHER'] as any).push(ob)
    else (result as any)[key].push(ob)
  })
  return result
}

function capitalizeWords(string: string) {
  return string.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase()
  })
}

// type DebtCategory = //TODO: use this
//   'NORMAL_DEBT' | 'HOUSING_DEBT' | 'CREDIT_CARD_DEBT' | 'VEHICLE_DEBT' | 'OTHER'

// type StringMap<T> = {
//   [name: string]: T
// }

export interface IDebtProviderData {
  providerName: string
  agreementType: string
  currentBalance: number
  currentRepayment: number
  meerkatInsurancePremium?: number
  hasMeerkatInsurance: boolean
  currentRate: number //format?
}

export interface IDebtCategoryData {
  totalDebt: number
  categoryName: string //TODO: enum
  obligations: IDebtProviderData[]
}

export interface IDebtCategoriesData {
  home: IDebtCategoryData
  education?: IDebtCategoryData
  personal: IDebtCategoryData
  car: IDebtCategoryData
  credit: IDebtCategoryData
  //other?: IDebtCategoryData
}

export interface IClientDebtData {
  netIncome: number | null
  monthlyDebt: number | null
  incomeToSave: number | null
  categoryData: IDebtCategoriesData //StringMap<IDebtCategoryData>
}

//const categoryKeys = [ "NORMAL_DEBT", "HOUSING_DEBT", "CREDIT_CARD_DEBT", "VEHICLE_DEBT"];
const categoryKeys = [
  'NORMAL_DEBT',
  'HOUSING_DEBT',
  'CREDIT_CARD_DEBT',
  'VEHICLE_DEBT',
  //"OTHER"
]

const categoryNameLookup: { [key: string]: any } = {
  NORMAL_DEBT: 'Personal',
  HOUSING_DEBT: 'Property',
  CREDIT_CARD_DEBT: 'Credit Card',
  VEHICLE_DEBT: 'Car',
  OTHER: 'Other',
}
const categoryVarNameLookup: { [key: string]: any } = {
  NORMAL_DEBT: 'personal',
  HOUSING_DEBT: 'home',
  CREDIT_CARD_DEBT: 'credit',
  VEHICLE_DEBT: 'car',
  OTHER: 'other',
}

export function transformClientDebtData(clientData: any): IClientDebtData {
  const categorizedObligations = classifyObligations(clientData.debtBreakdown.obligations)

  const categoryData: any = {
    home: null,
    education: null,
    personal: null,
    car: null,
    credit: null,
    //other: null,

    // "NORMAL_DEBT":null,
    // "HOUSING_DEBT":null,
    // "CREDIT_CARD_DEBT":null,
    // "VEHICLE_DEBT":null,
  }

  categoryKeys.forEach((key) => {
    const valueArr = (categorizedObligations as any)[key]
    console.log('key?', key, valueArr)
    const tpObject = {
      totalDebt: 0,
      categoryName: categoryNameLookup[key],
      obligations: [] as any,
    }
    valueArr.forEach((ob: any) => {
      console.log('foreach', ob)
      tpObject.totalDebt += ob.currentBalance //ob.currentPayment.installment;
      const obligationTp: IDebtProviderData = {
        providerName: capitalizeWords(ob.clientCreditor.creditor.name),
        agreementType: categoryNameLookup[key], //TODO: qualify
        currentBalance: ob.currentBalance,
        currentRepayment: ob.currentPayment.installment,
        meerkatInsurancePremium: ob.linkedObligationInsurance && ob.linkedObligationInsurance.premiumAmount,
        currentRate: ob.currentPayment.rate,
        hasMeerkatInsurance: ob.linkedObligationInsurance && ob.linkedObligationInsurance.premiumAmount > 0,
        //currentBalance,
      }
      tpObject.obligations.push(obligationTp)
    })
    const varKey = categoryVarNameLookup[key]

    ;(categoryData as any)[varKey] = tpObject
  })

  const result = {
    netIncome: clientData.income,
    monthlyDebt: clientData.debtBreakdown.allDebt,
    incomeToSave: clientData.income - clientData.debtBreakdown.allDebt,
    categoryData,
  }
  return result
}

/*
const QUERY_MOCK_UP = gql`
  {
    myClient {
      id
      income
      firstNames
      debtBreakdown {
        allDebt
        housingDebt
        vehicleDebt
        normalDebt
        compuScore
        affordability
        estimatedIncome
        hasDebtCounselling
        budget {
          description
          amount
        }
        obligations {
          clientCreditor {
            description
            company {
              name
            }
          }
          accountCode
          accountType
          currentPayment {
            installment
            rate
          }
        }
      }
      savings {
        id
        bucketInfo {
          amount
          targetAmount
        }
      }
    }
  }
`

const QUERY_MOCK_UP2 = gql`
  {
    client(id: "6184d8b2a7b234001cb29466") {
      id
      income
      allDebt
      debtBreakdown {
        allDebt
        obligations {
          accountType
          claimPaymentReference

          currentBalance
          currentPayment {
            installment
            rate
          }
          clientCreditor {
            description
            creditor {
              name
            }
          }
        }
      }
    }
  }
`
*/
