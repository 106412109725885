import React from 'react'
import { InformationRowContainer, InformationTitleContainer, InformationValueContainer } from './InformationRow.style'
import { Text } from '../../generic'

function InformationRow({ title, value, valueColor }) {
  return <InformationRowContainer>
    <InformationTitleContainer>
      <Text>{title}</Text>
    </InformationTitleContainer>
    <InformationValueContainer>
      <Text bold color={valueColor || "text"}>{value}</Text>
    </InformationValueContainer>
  </InformationRowContainer>
}

export default InformationRow
