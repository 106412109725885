import React from 'react'
import { transformClientDebtData } from '../dataTransformer'
import CategoryDetails from '../CategoryDetails/CategoryDetails'
import DebtRepaymentSummary from '../DebtRepaymentSummary/DebtRepaymentSummary'
import { gql } from 'apollo-boost'
import { useQuery } from 'react-apollo'
import { ActivityIndicator } from '../../generic'
import appConfig from '../../../appConfig'

// THIS DOESN't BUILD
// const GET_CLIENT_DATA = (process.env.NODE_ENV === 'development') ? // use better example data in develop
//   gql`{
//     client(id:"6184d8b2a7b234001cb29466"){ # example client (real)
//       ${clientDataInnerQuery}
//     }
//   }`:
//   gql`{
//     myClient
//       ${clientDataInnerQuery}
//     }
//   }`
// ;

/* 
debt candidate ids:  
603982db3838ce002d6ac309
5bc5efe9e18e1a001ace2719 <- lots
603982ec3838ce002d6ade28 <- has insurance
603982ec3838ce002d6adddf <- has insurance (lots)
*/

const GET_CLIENT_DATA_DEV = gql`
  {
    client(id: "603982ec3838ce002d6adddf") {
      # example client (real)
      id
      income
      debtBreakdown {
        allDebt
        obligations {
          accountType
          claimPaymentReference
          currentBalance
          currentPayment {
            installment
            rate
          }
          clientCreditor {
            description
            creditor {
              name
            }
          }
          linkedObligationInsurance {
            hasCover
            amount
            premiumAmount
          }
        }
      }
    }
  }
`

const GET_CLIENT_DATA_PROD = gql`
  {
    myClient {
      # example client (real)
      id
      income
      debtBreakdown {
        allDebt
        obligations {
          accountType
          claimPaymentReference
          currentBalance
          currentPayment {
            installment
            rate
          }
          clientCreditor {
            description
            creditor {
              name
            }
          }
          linkedObligationInsurance {
            hasCover
            amount
            premiumAmount
          }
        }
      }
    }
  }
`

// FOR TESTING
//const GET_CLIENT_DATA = (process.env.NODE_ENV === 'development') ? GET_CLIENT_DATA_DEV : GET_CLIENT_DATA_PROD;
const GET_CLIENT_DATA = appConfig.development.useCandidateClient ? GET_CLIENT_DATA_DEV : GET_CLIENT_DATA_PROD
//const GET_CLIENT_DATA = GET_CLIENT_DATA_PROD;
//const GET_CLIENT_DATA = appConfig.development.useCandidateClient ? GET_CLIENT_DATA_DEV : GET_CLIENT_DATA_PROD;

interface IDebtRepaymentPageProps {
  category: string
}

function DebtRepaymentPage({ category }: IDebtRepaymentPageProps): any {
  const { data, loading, error } = useQuery(GET_CLIENT_DATA)

  if (error) {
    return 'An error occurred, please contact support'
  }
  if (loading) {
    return <ActivityIndicator />
  }
  //FOR TESTING
  //const clientData = (appConfig.development.useCandidateClient) ? data.client : data.myClient;
  const clientData = data.client || data.myClient || {}
  //const clientData = data.myClient;

  let currentPage = <>Not Found</>
  const clientDebtData = transformClientDebtData(clientData)

  switch (category) {
    case undefined:
      currentPage = <DebtRepaymentSummary clientDebtData={clientDebtData} />
      break
    case 'home':
      currentPage = (
        <CategoryDetails
          debtCategoryData={clientDebtData.categoryData.home}
          title={'Property Loans'}
          description={'Your total home & property debt'}
          listOf={'Property Loans'}
        />
      )
      break
    case 'education': //TODO: No category for education
      currentPage = (
        <CategoryDetails
          debtCategoryData={null as any}
          title={'Education Loans'}
          description={'Your total educational loan debt'}
          listOf={'Education Loans'}
        />
      )
      break
    case 'personal':
      currentPage = (
        <CategoryDetails
          debtCategoryData={clientDebtData.categoryData.personal}
          title={'Personal Loans'}
          description={'Your total personal loan debt'}
          listOf={'Personal Loans'}
        />
      )
      break
    case 'car':
      currentPage = (
        <CategoryDetails
          debtCategoryData={clientDebtData.categoryData.car}
          title={'Car Loans'}
          description={'Your total vehicle debt'}
          listOf={'Vehicle Loans'}
        />
      )
      break
    case 'credit':
      currentPage = (
        <CategoryDetails
          debtCategoryData={clientDebtData.categoryData.credit}
          title={'Store and Credit Cards'}
          description={'Your total credit & store card debt'}
          listOf={'Cards'}
        />
      )
      break
  }

  console.log('currentPage?', currentPage, category)

  if (clientDebtData === undefined) return <>Loading...</>

  return currentPage
}

export default DebtRepaymentPage
