import React from 'react'
import { FormField, FieldValue } from 'react-form-helper'
import { Slider } from '../../generic'
import { Row, Col } from '../../generic/grid'
import moment from 'moment'
import { dateFromAmount, amountFromDate } from '../../../lib/savings'

function SavingsSlider({ changeFieldValue, targetAmount, existingAmount, income }) {
  return (
    <Col>
      <Row>
        <Col style={{ marginRight: "4px" }}>
          <FormField name="desiredSavings" inputProps={{ onBlur: (e, v) => { changeFieldValue('desiredSavings', v); changeFieldValue('targetDate', dateFromAmount(targetAmount, existingAmount, v) ) }}} />
        </Col>
        <Col style={{ marginLeft: "4px" }}>
          <FormField name="targetDate" inputProps={{ onBlur: (e, v) => { changeFieldValue('targetDate', v); changeFieldValue('desiredSavings', amountFromDate(targetAmount, existingAmount, v) ) }}} />
        </Col>
      </Row>
      <Row>
        <FieldValue name="desiredSavings" renderField={({ input, value }) => <Slider noInput value={value || input.value} step={1} minValue={0} maxValue={Math.min(income || 10000, Math.max(0, targetAmount - existingAmount))} onChange={v => { input.onChange(v); changeFieldValue('desiredSavings', v); changeFieldValue('targetDate', dateFromAmount(targetAmount, existingAmount, v) ) }} /> } />
      </Row>  
    </Col>
  )
}

export { amountFromDate }

export default SavingsSlider
