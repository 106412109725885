import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { isLoggedIn } from "../services/auth"
import { useQuery } from "react-apollo"
import { ActivityIndicator } from './generic'
import TermsConditions from './TermsConditions'
import { RegisterProfile } from './profile'
import gql from 'graphql-tag'
import firebase from 'gatsby-plugin-firebase'
import OTP from './OTP'
import { useQueryWithOptions } from "../lib/Utilities/react"

const GET_EMPLOYEE_INFO = gql`{
  me {
    id
  }
}`

const PrivateEmployeeRoute = ({ component: Component, location, setShowSideBar, employee, ...rest }) => {
  const { data, loading, error, options } = useQueryWithOptions(GET_EMPLOYEE_INFO, { skip: employee });
  const { ErrorPage } =  options;

  useEffect(() => {
    setShowSideBar(false)
  }, [])

  if (typeof window === 'undefined') {
    return <div />
  }

  if (!isLoggedIn() && location.pathname !== `/sign-in`) {
    navigate(`/sign-in?redirect=${location.pathname}`)
    return null
  }

  if (loading) return <ActivityIndicator />

  if (error) return <ErrorPage /*message={error.message}*/ />

  if (!data.me) {
    return <ErrorPage message="You are not an employee" />
  }

  return <Component {...rest} />
}

export default PrivateEmployeeRoute