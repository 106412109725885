import { Text } from '../../generic'
import styled from '@emotion/styled'
import { IMeerkatWebColor } from '../../../theme.style'

interface ITypography {
  color?: IMeerkatWebColor
}

//Summary Item

const SummaryItemTitle = styled(Text)<ITypography>`
  font-weight: bold; //400;
  font-size: 16px !important;
`
const SummaryItemDescription = styled(Text)<ITypography>`
  font-weight: 400;
  font-size: 12px !important;
`
const SummaryItemValue = styled(Text)<ITypography>`
  font-weight: 700;
  font-size: 16px !important;
`

export { SummaryItemTitle, SummaryItemDescription, SummaryItemValue }
