import styled from '@emotion/styled'

const LoanItemCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: ${props => props.theme.colors.grey};
  box-shadow: inset 0px 0px 10px rgba(64, 67, 87, 0.08);
  border-radius: 4px;
  padding: 7px;
  margin-top: 8px;
`

export {
  LoanItemCardContainer
}
