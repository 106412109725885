import styled from '@emotion/styled'

const InfoBoxContainer = styled.div`
  text-align: center;

  width: 328px;
  min-height: 223px;
  height: auto;
  overflow: auto;

  /* Light Grey */

  background: #F9F8F8;
  /* InnerShadow_1 */

  box-shadow: inset 0px 0px 10px rgba(64, 67, 87, 0.08);
  border-radius: 4px;

  margin: auto;
  margin-top: 16px;
  margin-bottom: 16px;

  padding: 15px;

`
const InfoBoxImage = styled.img`
  width: 40px;
`


export {
  InfoBoxContainer,
  InfoBoxImage,
}