import React from 'react'
import { isRequired } from '../../../lib/validators'
import { MokuNotePanel, WizardModal } from '../../shared'
// import { printCurrency } from '../../../lib/utils'
// import {} from './UpdateLoanBalanceModal.style'

const buildSteps = (setOpen) => ([{
  name: "updateBalance",
  title: "Update Loan Balance",
  fieldNames: ["balance", "statementId"],
  nextSteps: ["done"],
  nextTitle: "Done",
  component: ({ chatState }) => {
    return <MokuNotePanel title="Moku Tip: Keep it honest" description="It’s fraud to enter an incorrect balance, we will need you to upload your latest bank statement not older than 30 days as proof." color="grey" />
  }
}, {
  name: "done",
  beforeStep: async ({ apolloClient }) => {
    setOpen(false)
  }
}])

const fields = {
  balance: {
    label: "Loan Balance",
    type: "currency"
  },
  statementId: {
    type: "fileupload",
    prompt: "Upload Bank Statement",
    validate: [isRequired]
  }
}

function UpdateLoanBalanceModal({ open, setOpen, id, balance }) {
  return <WizardModal
    name="updateBalance"
    open={open}
    setOpen={setOpen}
    steps={buildSteps(setOpen)}
    initialValues={{ balance }}
    fields={fields}
  />
}

export default UpdateLoanBalanceModal
