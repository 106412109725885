import HomeBubble from '../../images/svgs/bubbles/homeBubble.svg'
import EducationBubble from '../../images/svgs/bubbles/educationBubble.svg'
import PersonalBubble from '../../images/svgs/bubbles/personalBubble.svg'
import CarBubble from '../../images/svgs/bubbles/carBubble.svg'
import CreditBubble from '../../images/svgs/bubbles/creditBubble.svg'
import React, { useState } from 'react'
import { IMeerkatWebColor } from '../../theme.style'

export const svgIcons: { [key: string]: any } = {
  // TODO: break coupling
  home: HomeBubble,
  education: EducationBubble,
  personal: PersonalBubble,
  car: CarBubble,
  credit: CreditBubble,
}

export const debtColors: { [key: string]: IMeerkatWebColor } = {
  home: 'primary',
  education: 'alertAmber',
  personal: 'red',
  car: 'yellow',
  credit: 'purple',
  repayment: 'grey',
}
export const debtBreakdown: { [key: string]: any } = {
  home: 2100,
  education: 3100,
  personal: 3500,
  car: 2100,
  credit: 4800,
}
export const debtBreakdownAverages: { [key: string]: any } = {
  home: 1692,
  education: 1692,
  personal: 1692,
  car: 1692,
  credit: 1692,
}

export function useRangeState(defaultValue: number, inputProps: any, label: string) {
  const [val, setVal] = useState(defaultValue)
  const onChange = (event: any) => {
    const val = event.target.value
    setVal(Number(val))
  }
  const range = (
    <div>
      <label>{label}</label>
      <input type="range" {...inputProps} onChange={onChange} />
      <label>{val}</label>
    </div>
  )
  return [range, val]
}
