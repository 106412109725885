import React, { useState } from 'react'
// import {} from './OtherDocument.style'
import { ActivityIndicator, Text, Button, PdfViewer } from '../../../generic'
import { BackActionHeader, SendDocumentModal } from '../../../shared'
import { navigate } from 'gatsby'
import { useQuery, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import { isRequired, isValidEmail } from '../../../../lib/validators'

const GET_DOCUMENT = gql`query ($id: String!) {
  clientdocument(id: $id) {
    id
    description
    file {
      id
      url
      name
    }
  }
}`

const EMAIL_DOCUMENT = gql`mutation ($id: String, $email: String) {
  emailClientDocument(id: $id, email: $email)
}
`

function OtherDocument({ id, title }) {
  const [emailModal, setEmailModal] = useState(false)
  const { data, loading, error } = useQuery(GET_DOCUMENT, { variables: { id } })
  const [sendEmail] = useMutation(EMAIL_DOCUMENT)

  const isPdf = ((data && data.clientdocument && data.clientdocument.file.name) || "").indexOf('.pdf') > -1
  const isImage = (
    ((data && data.clientdocument && data.clientdocument.file.name) || "").indexOf('.jpg') > -1 ||
    ((data && data.clientdocument && data.clientdocument.file.name) || "").indexOf('.png') > -1 ||
    ((data && data.clientdocument && data.clientdocument.file.name) || "").indexOf('.jpeg') > -1
  )

  const pages = [
    // { link: 'clearance-certificate', name: 'Clearance Certificate', title: 'Clearance Certificates' },
    // { link: 'do', name: 'Debit Order Mandate', title: 'Debit Order Mandates' },
    { link: 'id', name: 'RSA Identity Document', title: 'All IDs' },
    { link: 'vaccine', name: 'Vaccination Certificate', title: 'All Certificates' },
    { link: 'payslip', name: 'My Payslip', title: 'All Payslips' },
    { link: 'hyphenStatement', name: 'Hyphen Statements', title: 'All Statements' },
  ]
  const current = pages.filter((page) => page.name === title)

  return (
    <>
      <SendDocumentModal
        title="Email Document"
        label="Enter your email"
        document={title}
        open={emailModal}
        setOpen={setEmailModal}
        validate={[isRequired, isValidEmail]}
        onSend={(email) => {
          sendEmail({ variables: { id, email } })
        }}
        zIndex={1001}
      />

      {current.length > 0 ? (
        <BackActionHeader title={current[0].title} onClick={() => navigate(`/documents/${current[0].link}`)} />
      ) : (
        <BackActionHeader title="Document Vault" onClick={() => navigate('/documents')} />
      )}

      {/* {title === 'My Payslip' ? (
        <BackActionHeader title="All Payslips" onClick={() => navigate('/documents/payslip')} />
      ) : (
        <BackActionHeader title="Document Vault" onClick={() => navigate('/documents')} />
      )} */}

      <Text bold size={26}>
        {title}
      </Text>
      <p />
      {loading || error ? (
        loading ? (
          <ActivityIndicator />
        ) : (
          error
        )
      ) : (
        <>
          {isPdf && <PdfViewer file={data.clientdocument.file.url} />}
          {isImage && <img src={data.clientdocument.file.url} />}
          <center>
            <Button onClick={() => window.open(data.clientdocument.file.url, '_blank')}>Download</Button>
            <p />
            <Button inverted onClick={() => setEmailModal(true)}>
              Email
            </Button>
          </center>
          <div style={{ height: '50px' }} />
        </>
      )}
    </>
  )
}

export default OtherDocument
