import styled from '@emotion/styled'

const ProfileHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-bottom: 28px;
`

const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const ProfileImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ProfileAvatar = styled.img`
  width: 66px;
  height: 66px;
  border-radius: 33px;
  box-shadow: 0px 4px 4px #E0E0E0;
  margin: 0;
  margin-bottom: 4px;
`

export {
  ProfileHeaderContainer,
  ProfileInfoContainer,
  ProfileImageContainer,
  ProfileAvatar
}
