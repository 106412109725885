import React from 'react'
import { ClearanceCertificateObligationSummaryContainer } from './ClearanceCertificateObligationSummary.style'
import Text from '../../generic/Text'
import Button from '../../generic/Button'
import { Row, Col } from '../../generic/grid'
import { printCurrency, printDate } from '../../../lib/utils'
import { greenTick, redCross } from '../../../images/svgs'

const statusText = (status = "") => {
  if (status.includes("PAID_UP")) {
    return "Paid Off:"
  }
  if (status.includes("PRESCRIBED")) {
    return "Prescribed:"
  }
  return "Balance At:"
}

const getStatusActionText = (status) => {
  if (status === "ACTIVE") {
    return <Row>
      <img src={redCross} style={{ width: "14px", height: "14px", margin: 0, marginRight: "4px" }} />
      <Text size={12} color="alertRed">Credit Bureaux indicates an outstanding balance. Click to update information.</Text>
    </Row>
  }
  if (status === "PRESCRIBED") {
    return <Row>
      <img src={greenTick} style={{  width: "14px", height: "14x", margin: 0, marginRight: "4px" }} />
      <Text size={12} color="primary">We will contact your CPs and confirm prescription</Text>
    </Row>
  }
  if (status === "PAID_UP_CREDIT_BUREAUX") {
    return <Row>
      <img src={greenTick} style={{  width: "14px", height: "14px", margin: 0, marginRight: "4px" }} />
      <Text size={12} color="primary">Credit Bureaux Indicates As Paid Up</Text>
    </Row>
  }
  if (status === "EXCLUDED") {
    return <Row>
      <img src={greenTick} style={{  width: "14px", height: "14px", margin: 0, marginRight: "4px" }} />
      <Text size={12} color="primary">This is not part of your plan</Text>
    </Row>
  }
  if (status === "PAID_UP_CLIENT_PROOF_PROVIDED") {
    return <Row>
      <img src={greenTick} style={{  width: "14px", height: "14px", margin: 0, marginRight: "4px" }} />
      <Text size={12} color="primary">Thanks for uploading your settlement letter</Text>
    </Row>
  }
  if (status === "PAID_UP_CLIENT_PROOF_ACCEPTED") {
    return <Row>
      <img src={greenTick} style={{  width: "14px", height: "14px", margin: 0, marginRight: "4px" }} />
      <Text size={12} color="primary">Your settlement letter has been verified</Text>
    </Row>
  }
  if (status === "PAID_UP_REQUEST_PROOF") {
    return <Row>
      <img src={greenTick} style={{  width: "14px", height: "14px", margin: 0, marginRight: "4px" }} />
      <Text size={12} color="primary">We will request your settlement letter on your behalf</Text>
    </Row>
  }
  if (status === "PAID_UP_PROOF_REQUESTED") {
    return <Row>
      <img src={greenTick} style={{  width: "14px", height: "14px", margin: 0, marginRight: "4px" }} />
      <Text size={12} color="primary">Settlement letter requested from Credit Provider</Text>
    </Row>
  }
  if (status === "PAID_UP_CREDITOR_LETTER") {
    return <Row>
      <img src={greenTick} style={{  width: "14px", height: "14px", margin: 0, marginRight: "4px" }} />
      <Text size={12} color="primary">Settlement letter received from Credit Provider</Text>
    </Row>
  }
  if (status === "PAID_UP_CLIENT_PROVIDE_PROOF") {
    return <Row>
      <img src={redCross} style={{  width: "14px", height: "14px", margin: 0, marginRight: "4px" }} />
      <Text size={12} color="primary">Please upload your settlement letter</Text>
    </Row>
  }
}

function ClearanceCertificateObligationSummary({ description, status, statusAt, currentBalance, onClick }) {
  const editable = status === "ACTIVE" || status === "PAID_UP_CLIENT_PROVIDE_PROOF"

  return <>
    <ClearanceCertificateObligationSummaryContainer onClick={editable && onClick}>
    <Row>
      <Col grow><Text bold size={14} padBottom="3px">{description}</Text></Col>
      <Col size={0}>{editable && <Text primary bold size={16} padRight="8px">Edit</Text>}</Col>
    </Row>
    <Text color="grey4" size={12} padBottom="1px">Balance {printCurrency(currentBalance)}</Text>
    <Text color="grey4" size={12} padBottom="4px">{statusText(status)} {printDate(statusAt)}</Text>
    {getStatusActionText(status, currentBalance)}
  </ClearanceCertificateObligationSummaryContainer>
  {status === "PAID_UP_CLIENT_PROVIDE_PROOF" && <center><Button onClick={onClick} padTop="8px" padBottom="8px">Upload Settlement Letter</Button></center>}</>
}

export default ClearanceCertificateObligationSummary
