import React, { useState, useRef } from 'react'
import gql from 'graphql-tag'
import { FormField } from 'react-form-helper'
import ActivityIndicator from '../../generic/ActivityIndicator'
import { printCurrency } from '../../../lib/utils'
import MokuHoldingCard from '../../shared/MokuHoldingCard'
import InformationRow from '../../shared/InformationRow'
import { ContentCard } from '../SavingsBucket3/SavingsBucket.style'
import QuickQuotePrice from '../../funeral/QuickQuotePrice/QuickQuotePrice'
import SavingsSlider from '../SavingsSlider'
import Text from '../../generic/Text'
import { savingsGoalMetaData } from '../../../lib/savings'

const DEFAULT_AMOUNT = 500

const GET_QUOTE = gql`
  query GetQuote($idNumber: String,$age : Int!, $ageSpouse: Int, $gender: Gender!, $spouse: Boolean, $children: Boolean, $coverAmount: Int!, $parents: [ParentQuoteInput], $extended: [ParentQuoteInput],  $income: Float)
  {
    quote: getPolicyQuote(idNumber: $idNumber, age: $age, ageSpouse: $ageSpouse, gender: $gender, spouse: $spouse, children: $children, coverAmount: $coverAmount, parents: $parents, extended: $extended, income: $income) {
      premium
      totalCover
    }
  }
`

const bucketTypeText = {
  EMERGENCY: "Emergency Fund",
  HOLIDAY: "Holiday Savings",
  FUNERAL: "Funeral Savings",
  OTHER: "Custom Savings",
  CAR: "Car Savings" 
}

//TODO: Share this function
const quoteVariablesFromChatState = () => ({
})

function SavingsQuote({ step, chatState, setChatState, changeFieldValue, gotoNext }) {
  console.log("We Quoting Now .....")

  console.log('chatstate ', chatState)
  const [showNext, setShowNext] = useState(true)
  const quoteCard = useRef(null)

  const autoScroll = () => {
    setTimeout(() => quoteCard.current && quoteCard.current.scrollIntoView(true), 100)
  }

  const variables = quoteVariablesFromChatState(chatState)
  console.log('Savings quote ', variables, chatState)

  let loading
  autoScroll()


  return (
    <div>{/*<Query query={GET_QUOTE} variables={variables}>
      {
        ({ loading, error, data }) => {
          if (error) {
            console.log(error)
            return "An error has occured"
          }

          console.log(data)

          const quoteAddOns = {
            parents: (data && data.quote && data.quote.premium) - (data && data.noParents && data.noParents.premium) || 0,
            extended: (data && data.quote && data.quote.premium) - (data && data.noExtended && data.noExtended.premium) || 0
          }

          console.log(quoteAddOns)*/}
                <div ref={quoteCard}>
                  <center>
                    <MokuHoldingCard>
                      {loading ? <ActivityIndicator large="true"/> :
                      <QuickQuotePrice title={`Meerkat Estimated ${bucketTypeText[chatState.bucketType]} Contribution`} disclaimer="This is an estimated amount and is subject to change." price={<FormField mode="display" name="desiredSavings" />} color="white" />}
                    </MokuHoldingCard>
                  </center>
                  <p />
                  <p />
                  <Text size={18} bold>Reach goal sooner</Text>
                  <p />
                  <SavingsSlider changeFieldValue={changeFieldValue} targetAmount={chatState.targetAmount} existingAmount={chatState.existingAmount} income={chatState.income || 10000} />
                  <p />
                  <ContentCard>
                    <InformationRow title={<Text size={16} bold>{bucketTypeText[chatState.bucketType]}</Text>} value={<Text primary onClick={() => gotoNext([chatState.bucketType])}>Edit</Text>} />
                    {Object.keys(savingsGoalMetaData[chatState.bucketType] || {}).filter(field => !savingsGoalMetaData[chatState.bucketType][field].excludeFromSummary).map((field, i) => <FormField key={i} mode="display" name={`${chatState.bucketType}.${field}`} renderLabel={({ field }) => field.title || field.label || ""} renderField={({ mode, Label, Value }) => <InformationRow title={<Label />} value={<Value mode={mode} />} />} />)}
                    <InformationRow title="Existing Capital" value={printCurrency(chatState.existingAmount)} />
                    <InformationRow title="Savings per month" value={<FormField mode="display" name="desiredSavings" />} />
                    <InformationRow title="Goal reached" value={<FormField mode="display" name="targetDate" />} />  
                  </ContentCard>
                </div>
                {/*})
             }
    }
    {/*</div></Query>*/}</div>
  )
}

export default SavingsQuote
