import React from 'react'
import { WizardModal } from '../../shared'
// import { printCurrency } from '../../../lib/utils'
// import {} from './NewThirdPartyFuneral.style'
import gql from 'graphql-tag'
import { isRequired } from '../../../lib/validators'
import { isNumberRange } from '../FuneralQuickQuote/FuneralQuickQuote'

const ADD_NEW_THIRD_PARTY_FUNERAL = gql`
  mutation($clientId: ObjID, $input: ThirdPartyFuneralInput) {
    createClientObligation(input: {
      clientId: $clientId,
      funeralPolicyInfo: $input,
      source: "CLIENT_MANUAL_ENTRY"
    }) {
      id
    }
  }
`

const UPDATE_THIRD_PARTY_FUNERAL = gql`
  mutation($id: String!, $input: ThirdPartyFuneralInput) {
    updateClientObligation(id: $id, input: {
      funeralPolicyInfo: $input,
      source: "CLIENT_MANUAL_ENTRY"
    }) {
      id
      clientId
      funeralPolicyInfo {
        companyName
        coverAmount
        livesCovered {
          memberType
        }
        startDate
        premiumAmount
      }
    }
  }
`

const GET_THIRD_PARTY_FUNERAL = gql`
  query($id: ObjID!) {
    clientobligation(id: $id) {
      id
      clientId
      funeralPolicyInfo {
        companyName
        coverAmount
        premiumAmount
        livesCovered {
          memberType
        }
        startDate
        premiumAmount
      }
    }
  }
`

const buildSteps = (setOpen, edit) => ([{
  name: "addMethod",
  //title: "Add existing",
  //title2: "Let's get to know your Funeral Policies",
  //skipStep: ({ chatState }) => chatState.id,
  nextAfterDelay: 10,
  nextSteps: ["addManual"]
}, {
  name: "addManual",
  title2: edit ? "Edit Funeral Policy" : "Add Funeral Policy",
  fieldNames: ["companyName", "coverAmount", "premiumAmount", "coveredMemberTypes", "startDate"],
  nextSteps: ["done"],
  nextTitle: "Submit"
}, {
  name: "done",
  submitBeforeStep: true,
  noNext: true
}, {
  name: "addStitch",
}])

const fields = {
  companyName: {
    label: "Service Provider",
    type: "autocomplete",
    options: ["Capitec Funeral", "Old Mutual", "Clientelle", "Hollard", "1Life"],
    validate: [isRequired]
  },
  coverAmount: {
    label: "Covered Amount",
    type: "slider",
    min: 0,
    max: 100000,
    step: 500,
    endText: null,
    startText: "R",
    validate: [isRequired, isNumberRange(0, 100000)]
  },
  premiumAmount: {
    label: "Monthly Contribution",
    type: "slider",
    max: 1000,
    validate: [isRequired]
  },
  coveredMemberTypes: {
    label: "Who's covered?",
    type: "radiogroup",
    options: [{ label: "Me", value: "POLICY_HOLDER" }, { label: "My Spouse", value: "SPOUSE" }, { label: "My Children", value: "CHILD" }, { label: "My Parents", value: "PARENT" }, { label: "My Extended Family", value: "EXTENDED" }],
    multiple: true,
    validate: value => value && value.length > 0 ? undefined : "Please select who is covered"
  },
  startDate: {
    label: "Start Date",
    type: "date"
  },
  paymentMethod: {
    label: "Payment Method",
    type: "radiogroup",
    options: [{ label: "Personal Debit Order", value: "DO" }, { label: "Paid by My Employer", value: "EMPLOYER" }, { label: "EFT", value: "EFT" }]
  }
}

function ThirdPartyFuneralWizard({ id, clientId, open, setOpen, refetchQueries, afterSubmit }) {
  return <WizardModal
    name="thirdPartyFuneral"
    mode={id ? "edit" : "add"}
    addMutation={ADD_NEW_THIRD_PARTY_FUNERAL}
    updateMutation={UPDATE_THIRD_PARTY_FUNERAL}
    query={GET_THIRD_PARTY_FUNERAL}
    initialValuesFromData={data => ({ ...data.clientobligation.funeralPolicyInfo, coveredMemberTypes: data.clientobligation.funeralPolicyInfo.livesCovered.map(({ memberType }) => memberType) })}
    id={id}
    open={open}
    setOpen={setOpen}
    steps={buildSteps(setOpen, !!id)}
    initialValues={{ coveredMemberTypes: ["POLICY_HOLDER"], id }}
    mapInputVariables={input => ({ clientId, input: {
      companyName: input.companyName,
      coverAmount: input.coverAmount,
      startDate: input.startDate,
      premiumAmount: input.premiumAmount,
      paymentMethod: input.paymentMethod,
      livesCovered: input.coveredMemberTypes.map(memberType => ({ memberType }))
    } })}
    keepInitialValues
    afterSubmit={data => { setOpen(false); afterSubmit && afterSubmit(data) }}
    refetchQueries={refetchQueries}
    fields={fields}
  />
}

export default ThirdPartyFuneralWizard
