import React from 'react'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { Text, ActivityIndicator, Button } from '../../generic'
import SavingsCard from '../../savings/SavingsCard'
import { PORTAL_URL } from '../../../config'
import { getAccessToken } from '../../../services/auth'
import { navigate } from 'gatsby'

const loggedInPortal = path => `${PORTAL_URL}/login/callback?token=${getAccessToken()}&redirect=${path}`

const GET_EMPLOYERS = gql`{
  myEmployers {
    id
    companyName
  }
}`

function MyEmployers() {
  const { loading, error, data } = useQuery(GET_EMPLOYERS)

  if (loading) { return <ActivityIndicator /> }

  const employers = (data.myEmployers || []).filter(e => e && e.id)

  if (employers.length === 0) {
    navigate('/employer/add')
  }
  

  return <>
    <Text heading>My Companies with Employee Benefits</Text>
    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      {employers.map(e => <SavingsCard key={e.id} onClick={() => window.location = loggedInPortal(`/employer/${e.id}`) } type="HEALTH" title={e.companyName} />)}
    </div>
    <p />
    <Button title="Add a new company" onClick={() => navigate('/employer/add')} />
    <p />
  </>

}

export default MyEmployers
