import React from 'react'
import gql from 'graphql-tag'
import { isRequired } from '../../../lib/validators'
import { InfoBoxContainer, InfoBoxImage } from './UploadMembersFile.style'
import { redCrossFaded, greenTickFaded } from '../../../images'
import { Text } from '../../generic'
import { WizardModal } from '../../shared'

const UPLOAD_FUNERAL_DOCUMENT = gql`
  mutation ($fileId: ObjID!, $groupProductNumber: String!) {
    validateAndProcessBulkUploadFile(fileId: $fileId, groupProductNumber: $groupProductNumber) 
  }
`

export const BeneficiaryInfoBox = ({ reason, src }) => {  
  return <InfoBoxContainer >
    <InfoBoxImage src={src}/>
    {reason.map((description, index) => <Text key={index} size={16} padding-bottom={10} >{description}</Text> )}
  </InfoBoxContainer>
}

const buildSteps = (setOpen) => ([{
  name: "step1",
  // title: "Upload Funeral File",
  title2:  "Upload Members File",
  fieldNames: ["fileId"],
  nextSteps: ["validation"],
  nextTitle: "Upload to Validate"
},
{
  name: "validation",
  title2: "Validation Result",
  beforeStep: async ({ apolloClient, chatState, changeFieldValue }) => {
    const { fileId, groupProductNumber } = chatState
    changeFieldValue('validationResult', null)
    if (fileId) {
      const result = await apolloClient.mutate({ mutation: UPLOAD_FUNERAL_DOCUMENT, variables: {fileId, groupProductNumber } })
      changeFieldValue('validationResult', result.data.validateAndProcessBulkUploadFile)
      
    }
  },
  component: ({ chatState }) => <>
    {chatState.validationResult.length > 0 ? (chatState.validationResult).map((reason, index) => <BeneficiaryInfoBox reason={reason} src={redCrossFaded} key={index} /> ) : <BeneficiaryInfoBox reason={['Upload Successful']} src={greenTickFaded} />}
    </>,
    nextButtonsInColumn: true,
    nextButtons: ({ validationResult }) => {      
      let buttons = []
      if (validationResult.length > 0) {
        buttons.push(
          { label: "Retry", nextSteps: ["step1"] }
        )
      }
      buttons.push(
        { label: "Done", nextSteps: ["done"] }
      )
      return buttons
    }
  },
{
  name: "done",
  beforeStep: () => setOpen(false)
}])

const fields = {
  fileId: {
    type: 'fileupload',
    prompt: 'Choose File',
    validate: [isRequired]
  },
  groupProductNumber: {
     type: 'text',
     label: 'Group Product Number',
     validate: [isRequired]
    }
}

function UploadMembersFile({ open, setOpen, groupProductNumber }) {  
  return <WizardModal
    name="funeralFile"
    open={open}
    setOpen={setOpen}
    steps={buildSteps(setOpen)}
    initialValues={{ groupProductNumber }}
    fields={fields}
  />
}

export default UploadMembersFile
