import React from 'react'
import { Link, navigate } from 'gatsby'
import PrivateMenuItem from '../PrivateMenuItem'
import { MenuBarContainer, MenuBarItemsContainer, SelectedMenuItem } from './PrivateMenuBar.style'
import menuBarItems from './menuBarItems'

function PrivateMenuBar({ alerts, selectedIndex }) {
  return <MenuBarContainer>
    <MenuBarItemsContainer selectedIndex={selectedIndex}>
      {menuBarItems.map((item, index) => <PrivateMenuItem key={index} {...item} alerts={alerts} selected={index === selectedIndex} onClick={() => {
        if (selectedIndex !== index && item.path)  {
          navigate(item.path)
        }
      }} />)}
    </MenuBarItemsContainer>
    <SelectedMenuItem selectedIndex={selectedIndex} />
  </MenuBarContainer>
}

export default PrivateMenuBar