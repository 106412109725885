import React, { useState } from 'react'
// import {} from './BankAccounts.style'
import { BackActionHeader, TitleImage } from '../../shared'
import { sittingOnMoney } from '../../../images'
import { useQuery } from 'react-apollo'
import { ActivityIndicator, Text, Button } from '../../generic'
import gql from 'graphql-tag'
import MenuItem from '../MenuItem'
import { navigate } from 'gatsby'
import AddBankAccount from '../AddBankAccount'

const MY_BANK_ACCOUNTS = gql`{
  myClient {
    id
    bankAccounts {
      id
      bankName
      accountNo
    }
  }
}`

function BankAccounts() {
  const { data, loading, error, refetch } = useQuery(MY_BANK_ACCOUNTS)
  const [addBankAccount, setAddBankAccount] = useState(false)

  if (loading) return <ActivityIndicator />

  const { bankAccounts, id } = (data && data.myClient) || {}

  return <>
    <BackActionHeader title="My Profile" onClick={() => navigate('/profile')} />
    <TitleImage title="My Bank Accounts" description="Edit or add any bank accounts easily" image={sittingOnMoney} />
    <AddBankAccount open={addBankAccount} setOpen={setAddBankAccount} clientId={id} afterSubmit={() => refetch()} />
    {(bankAccounts || []).map((bankAccount, i) => <MenuItem key={i} description={<>
      <Text>{bankAccount.bankName}</Text>
      <Text>{bankAccount.accountNo}</Text>
    </>} onClick={() => navigate(`/bank-account/${bankAccount.id}`)} />)}
    <p />
    <center><Button primary onClick={() => { setAddBankAccount(true) }}>Request to add New Account</Button></center>
  </>
}

export default BankAccounts
