import styled from '@emotion/styled'

const ProfilePictureContainer = styled.div`
  margin: auto;
  width: 260px;
  height: 260px;
  max-width: calc(100vw - 100px);
  max-height: calc(100vw - 100px);
  background-color: ${props => props.theme.colors.grey};
  border-radius: 130px;
`

const ProfileImage = styled.img`
  border-radius: 130px;
`

export {
  ProfilePictureContainer,
  ProfileImage
}
