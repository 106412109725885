import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import { IMeerkatWebTheme } from '../../../theme.style'

const MyDashboardContainer = styled.div`
  margin-bottom: 150px;
`

interface IContainerProps {
  spacing?: number
}

interface ISummaryItemsRowProps extends IContainerProps {
  children?: ReactNode[]
}

export const SummaryItemsRow = ({ children, ...containerProps }: ISummaryItemsRowProps) => {
  const Container = styled.div<IContainerProps>`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    & > * {
      width: 100%;
      padding-right: ${(props) => (props.spacing ? props.spacing : 0)}px;
      box-sizing: border-box;
    }
    & > *:last-child {
      padding-right: 0px;
    }
  `
  return <Container {...containerProps}>{children && children.map((child, i) => <div key={i}>{child}</div>)}</Container>
}

export const TitleContainer = styled.div`
  padding: 7px 1px 15px 1px;
`

//-------

const DashboardCard = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(64, 67, 87, 0.12);
  border-radius: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 3px;
  margin-right: 3px;
  flex: 1;
  padding: 8px;
`

const TitleTextContainer = styled.div`
  margin-top: 18px;
  margin-bottom: 7px;
`

const DashboardCardContentsContainer = styled.div`
  height: 100px;
  margin: 8px;
`

interface IOpenOptionContainerProps {
  theme: IMeerkatWebTheme
}

const OpenOptionContainer = styled.div<IOpenOptionContainerProps>`
  diplay: flex;
  flex-direction: column;
  border-top: 1px solid ${(props) => props.theme.colors.grey6};
  //padding: 13px 16px 0px 19px;
  padding: 8px 17px;
  background-color: ${(props) => props.theme.colors.grey};
`

const debtColors = {
  home: 'primary',
  education: 'alertAmber',
  personal: 'red',
  car: 'yellow',
  credit: 'purple',
}

const ToggleProductOptionsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export {
  MyDashboardContainer,
  DashboardCard,
  TitleTextContainer,
  DashboardCardContentsContainer,
  OpenOptionContainer,
  debtColors,
  ToggleProductOptionsRow,
}
