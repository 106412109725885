import styled from '@emotion/styled'

const SavingsBucketContainer = styled.div`
 .Mui
`

const BlobContainer = styled.div`
  position: relative;
`

const BlobImage = styled.img`
  position: relative;
  margin: 0;
`

const BucketImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0;
`

const DatePickerContainer = styled.div`
  width: 100%;
  max-width: 360px;

  .MuiFormControl-root {
    min-width: 0 !important
  }
`

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

export {
  SavingsBucketContainer,
  BlobContainer,
  BlobImage,
  BucketImage,
  DatePickerContainer,
  ActionButtons
}