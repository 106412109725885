import styled from '@emotion/styled'

const OTPInputContainer = styled.div`
  :focus {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
  }
`

const OTPInputDigit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: min(20vw, 76px);
  height: min(19vw, 72px);
  background: ${props => `${props.theme.colors.primary}${props.active ? "30" : "19"}`};
  border-radius: 4px;
  margin-left: 4px;
  margin-right: 4px;
  cursor: text;
`

const HiddenInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

export {
  OTPInputContainer,
  OTPInputDigit,
  HiddenInput
}
