import React, { useState } from 'react'
import { ChatFlow, Text, ActivityIndicator, Select } from '../../generic'
import gql from 'graphql-tag'
import { banks, payrollSystems, payrollDate } from '../../../constants'
import { navigate } from 'gatsby'
import { isBrowser } from '../../../services/auth'
import { isRequiredForPayrolls } from '../../../lib/validators'

const COMPLETE_REGISTRATION = gql`
  mutation RegisterEmployer($input: RegisterEmployerInput) {
    registerEmployer(input: $input) {
      id
      companyName
    }
  }
`

const GET_SIMPLE_PAY_CLIENTS = gql`
  query GetSimplePayClients($token: String) {
    getSimplePayClients(token: $token) {
      id
      name
    }
  }
`

function SelectSimplePayCompany({ chatState, changeFieldValue }) {
  const { simplePayClients, simplePayId } = chatState
  const [id, setId] = useState(simplePayId)

  console.log(simplePayClients)

  const handleChange = (v) => {
    console.log(v)
    setId(v)
    changeFieldValue('simplePayId', v)
  }

  return <Select onChange={e => handleChange(e.target.value)} value={id} options={simplePayClients.map(c => ({ label: c.name, value: c.id }))} />
}


const setSimplePayToken = ({ chatState, apolloClient, changeFieldValue, gotoNext }) => {
  const { simplePayToken } = chatState
  
  apolloClient.query({ query: GET_SIMPLE_PAY_CLIENTS, variables: { token: simplePayToken } })
    .then(({ data }) => {
      if (data.getSimplePayClients.length > 1) {
        changeFieldValue('simplePayClients', data.getSimplePayClients)
        gotoNext(["simplePayCompany"])
      } else if (data.getSimplePayClients.length == 1) {
        changeFieldValue('simplePayId', data.getSimplePayClients[0].id)
      } else {
        gotoNext(["incorrectToken"])
      }

      gotoNext()
    })
}

const steps = [{
  name: "payroll",
  text: "What payroll system do you use (we can automatically synchronise with the deductions you have specified in SimplePay)",
  fieldNames: ["payrollSystem"],
  nextSteps: ({ payrollSystem }) => [payrollSystem]
}, {
  name: "SIMPLE_PAY",
  text: <div>Please enter your API token for Simple Pay. We will store this securely and use it to synchorinise your data after your pay day. Click <a style={{ textDecoration: "underline" }} target="_blank" rel="noreferrer" href="https://www.simplepay.co.za/api-docs/#authentication">here</a> for instructions on how to retrieve the token</div>,
  fieldNames: ["simplePayToken"],
  handleNext: setSimplePayToken,
  nextSteps: ["payDate"]
}, {
  name: "simplePayCompany",
  text: "We see you have multiple companies set up on Simple Pay - which one would you like to add for employee benefits?",
  component: SelectSimplePayCompany,
  nextSteps: ["payDate"]
  // Needs a custom component
}, {
  name: "incorrectToken",
  text: "The token you provided us was not correct",
  noNext: true,
  nextAfterDelay: 1000,
  nextSteps: ["SIMPLE_PAY"]
}, {
  name: "SAGE",
  text: "We currently do not have an integration to Sage. We will contact you once we have an integration. You will be able to proceed to provide your employee benefits by sending us a file",
  nextAfterDelay: 1000,
  noNext: true,
  nextSteps: ["FILE"]
}, {
  name: "VIP",
  text: "We currently do not have an integration to VIP. We will contact you once we have an integration. You will be able to proceed to provide your employee benefits by sending us a file",
  nextAfterDelay: 1000,
  noNext: true,
  nextSteps: ["FILE"]
},
{
  name: "ORACLE",
  text: "We currently do not have an integration to Oracle. We will contact you once we have an integration. You will be able to proceed to provide your employee benefits by sending us a file",
  nextAfterDelay: 1000,
  noNext: true,
  nextSteps: ["FILE"]
},
{
  name: "OTHER",
  text: "We currently do not have an integration to your payroll system. You will be able to proceed to provide your employee benefits by sending us a file",
  nextAfterDelay: 1000,
  noNext: true,
  nextSteps: ["FILE"]
},
{
  name: "EXCEL",
  text: "We have the ability to accept excel sheets which are of a specific format. We will provide you with details once the signup is complete",
  nextAfterDelay: 1000,
  noNext: true,
  nextSteps: ["FILE"]
},
{
  name: "FILE",
  text: "Please complete the following information to complete signup",
  fieldNames: ["companyName"],
  nextSteps: ["payDate"],
}, {
  name: "payDate",
  text: "When is your current pay day? (We use this to ensure we stay in sync)",
  fieldNames: ["payDate", "payDay"],
  nextSteps: ["getInfo"]
}, {
  name: "getInfo",
  text: ({ payrollSystem }) => payrollSystem === "SIMPLE_PAY" ? "Fetching information from payroll system" : "Completing setup of your employee benefits scheme",
  noNext: true,
  nextSteps: ["submit"],
  nextAfterDelay: 100
}, {
  name: "submit",
  submitBeforeStep: true,
  text: "Congratulations your employee benefits scheme has been setup and you have been emailed intructions for your chosen method to provide us with data on your contributions",
  nextTitle: "Done",
  handleNext: () => navigate('/employer')
}]

const fields = {
  companyName: {
    label: "Company Name",
    type: "text",
    validate: [isRequiredForPayrolls(["SAGE", "ORACLE", "VIP", "EXCEL", "OTHER"])]
  },
  payrollSystem: {
    type: "select",
    options: payrollSystems
  },
  payDate: {
    label: "Payroll Date",
    type: "select",
    options: payrollDate
  },
  payDay: {
    label: "Pay Day",
    type: "select",
    options: [...Array(31).keys()].map(i => i + 1)
  },
  simplePayToken: {
    type: "secret"
  },
  simplePayId: {
    type: "text"
  },
  /*"bankAccount.bankName": {
    label: "Bank",
    type: "select",
    options: banks
  },
  "bankAccount.accountNo": {
    label: "Account Number",
    type: "text",
    validate: value => value && value.length > 0 ? null : "We need an account number"
  },*/
}

function NewEmployer() {
  return (
    <>
    <Text xlarge bold>Welcome</Text>
    <Text>Please provide the details below to start giving your employees extra benefits</Text>
    <p />
    <ChatFlow
      name="newEmployer"
      addMutation={COMPLETE_REGISTRATION}
      steps={steps}
      fields={fields}
      initialValues={{ 
        payrollSystem: "SIMPLE_PAY",
        payDate: "SET_DATE_MOVE_BACK",
        payDay: "25",
      }}
      startWithCleanState
    />
    </>
  )
}

export default NewEmployer
