import styled from '@emotion/styled'

const cardColor = {
  "NO_SAVINGS": "alertRed",
  "ON_TRACK": "primary",
  "BELOW_TARGET": "alertAmber"
}

const SavingsStatusCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-left: 16px;
  margin-right: 16px;
  max-width: 360px;
  padding: 8px;
  height: 44px;

  background: ${props => props.theme.colors[cardColor[props.status]]};
  
  box-shadow: 0px 0px 10px rgba(64, 67, 87, 0.12);
  border-radius: 2px;
`

const SmilyImage = styled.img`
  margin: 0;
  margin-left: 12px;
  width: 20px;
  height: 20px;
`

export {
  SavingsStatusCardContainer,
  SmilyImage
}
