import styled from '@emotion/styled'

const MySavingsContainer = styled.div`
  margin-bottom: 100px
`

const SavingsGraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 350px;
  flex: 1;
  align-items: center;
  padding: 32px;
  background: #FFFFFF;
  box-shadow: inset 0px 0px 10px rgba(64, 67, 87, 0.08);
  margin-top: 8px;
`

export {
  MySavingsContainer,
  SavingsGraphContainer
}
