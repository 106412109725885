import React, { useState, useRef } from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { FormField } from 'react-form-helper'
import ActivityIndicator from '../../generic/ActivityIndicator'
import Button from '../../generic/Button'
import Text from '../../generic/Text'
import { Row, Col } from '../../generic/grid'
import { Quote } from './CreditLifeQuote.style'
import { printCurrency } from '../../../lib/utils'
import LoansToCover from '../LoansToCover'
// import { navigate } from 'gatsby'
import MokuHoldingCard from '../../shared/MokuHoldingCard'
import QuickQuotePrice from '../../funeral/QuickQuotePrice/QuickQuotePrice'
import MokuNotePanel from '../../shared/MokuNotePanel'
import moment from 'moment'

const GET_LOANS = gql`query ($idNumber: String!, $accessCode: String) {
  getCreditRecord(idNumber: $idNumber, accessCode: $accessCode) {
    obligations {
      id
      externalId
      description
      loanType
      reference
      accountCode
      inceptionDate
      currentBalance
      balanceDate
      insuranceAmount
      creditLifeInsurance
      removedAt
      statusCode
      source
      creditLifePremiumEstimate
    }
    accessCode
  }
}`

export const GET_QUOTE = gql`
  query CreditLifeQuote($productType: CreditLifeProductType, $termsVersion: CreditLifeTermsVersion, $pricingVersion: String $obligations: [ObligationInsuranceInput], $age: Int, $matchDiscount: Float) {
    getCreditLifeQuote(productType: $productType, termsVersion: $termsVersion, pricingVersion: $pricingVersion, obligations: $obligations, matchDiscount: $matchDiscount, age: $age) {
      priceBreakdown {
        id
        amount
        calcPremium
        premiumAmount
        matchPremium
        matchPremiumDiscount
      }
      price
      minPrice
    }
  }
`
const quoteVariablesFromChatState = ({ termsVersion, productType, loans }) => {
  console.log(termsVersion, productType, loans)

  return {
    termsVersion,
    productType: productType || "OPEN_MARKET",
    obligations: loans && loans.filter(loan => loan.isCovered).map(loan => ({
      amount: loan.currentBalance,
      package: setLoanPackage(loan, false, termsVersion, productType).package,
      rejectReason: setLoanPackage(loan, false, termsVersion, productType).reason,
      status: (productType === 'OPEN_MARKET' || productType === "RMA") ? "COVERED" : null,
      id: loan.externalId || loan.id,
      obligationId: loan.id,
      previousPremium: loan.creditLifeInsurance && loan.creditLifeInsurance > 0 ? loan.creditLifeInsurance : loan.shortTermInsurance === 0 ? loan.insuranceAmount : loan.creditLifePremiumEstimate && loan.creditLifePremiumEstimate > 0 ? loan.creditLifePremiumEstimate : 0,
    })),
    matchDiscount: productType === "RMA" ? 0.1 : 0
  }
}

// FROM ADMIN - Needs refactoring / server side
function setLoanPackage (loan, basicListed, termsVersion, productType) {
  if (productType === "OPEN_MARKET") {
    let loanPackage = "PLAN_C"
    let reason

    if (loan.loanType === 'PERSONAL_LOAN') {
      loanPackage = "PLAN_A"
      reason = "PERSONAL LOANS"
      if (loan && loan.description) {
        switch (loan.description.toUpperCase()) {
          case "ABSA" ||
            "1ST FOR WOMEN" ||
            "AFRICAN BANK" ||
            "BAYPORT" ||
            "BUDGET INSURANCE" ||
            "CLIENTELE" ||
            "DIRECTAXIS" ||
            "FNB" ||
            "IZWE LOANS" ||
            "MAKRO" ||
            "OLD MUTUAL FINANCE" ||
            "RCS" ||
            "SANLAM" ||
            "UBANK" ||
            "WESBANK" ||
            "WOOLWORTHS":
            loanPackage = "PLAN_A"
            break
          case 'CAPITEC' || 'NEDBANK':
            loanPackage = "PLAN_B"
            break
          case 'STANDARD BANK':
            loanPackage = "PLAN_B"
            break
          default:
            break
        }
      }
    }
    else if (loan.loanType === 'STUDENT_LOAN') {
      reason = "STUDENT LOANS"
      if (loan && loan.description) {
        switch (loan.description.toUpperCase()) {
          case "ABSA" ||
            "1ST FOR WOMEN" ||
            "FNB" ||
            "NEDBANK" ||
            "FUNDI CAPITAL" ||
            "NATIONAL STUDENT FINANCIAL AID SCHEME":
            loanPackage = "PLAN_A"
            break
          case 'STANDARD BANK':
            loanPackage = "PLAN_B"
            break
          default:
            loanPackage = "PLAN_A"
            break
        }
      }
    }
    else if (loan.loanType === 'REVOLVING_LOAN') {
      reason = "REVOLVING_LOAN"
      if (loan && loan.description) {
        switch (loan.description.toUpperCase()) {
          case "ABSA" ||
            "1ST FOR WOMEN" ||
            "AFRICAN BANK" ||
            "BAYPORT" ||
            "BUDGET INSURANCE" ||
            "CLIENTELE" ||
            "DIRECTAXIS" ||
            "FNB" ||
            "IZWE LOANS" ||
            "MAKRO" ||
            "NEDBANK" ||
            "OLD MUTUAL FINANCE" ||
            "RCS" ||
            "SANLAM" ||
            "UBANK" ||
            "WESBANK" ||
            "WOOLWORTHS":
            loanPackage = "PLAN_C"
            break
          case 'CAPITEC':
            loanPackage = "PLAN_D"
            break
          case 'STANDARD BANK':
            loanPackage = "PLAN_D"
            break
          default:
            loanPackage = "PLAN_C"
            break
        }
      }
    }

    else if (loan.loanType === 'CREDIT_CARD') {
      reason = "CREDIT_CARD"
      if (loan && loan.description) {
        switch (loan.description.toUpperCase()) {
          case "ABSA" ||
            "AFRICAN BANK" ||
            "FNB" ||
            "MAKRO" ||
            "NEDBANK" ||
            "RCS" ||
            "UBANK" ||
            "WESBANK" ||
            "WOOLWORTHS":
            loanPackage = "PLAN_C"
            break
          case "CAPITEC":
            loanPackage = "PLAN_D"
            break
          default:
            loanPackage = "PLAN_C"
            break
        }
      }
    }

    // loan type 

    return { package: loanPackage, reason }
  }
  else if (productType === "RMA") {
    return { package: loan.loanType }
  } else {

    if (loan.package === "REJECTED") {
      let reason = loan.reason
      if (!reason) { reason = "admin rejected" }
      return { package: "REJECTED", reason }
    }

    if (loan.accountCode && loan.accountCode === 'SM') {
      return {package: "REJECTED", reason:"Home Loan"}
    }

    /* Reject African Bank with previous insurance prior to March 2018
    if (loan.description.toLowerCase().indexOf('african bank') > -1 && previousPremium != 0 && loan.inceptionDate < moment("2018/04/01").valueOf()) {
      console.log('before 2018/04/01')
      return {package: "REJECTED", reason:"previous < 2018/04/01"}
    }*/

    if (loan.description.toLowerCase().indexOf('african bank') > -1) {
      return {package: "EXTRA", reason:"African Bank"}
    }

    // Check for previous insurance
    let previousPremium = 0
    if (loan.creditLifeInsurance && loan.creditLifeInsurance > 0) {
      previousPremium = loan.creditLifeInsurance
    }
      else if (loan.shortTermInsurance === 0) { previousPremium = loan.insuranceAmount }

    // Reject Lewis stores with previuos insurance
    if (loan.description.toLowerCase().indexOf('lewis') > -1 && previousPremium != 0 && loan.inceptionDate > moment("2018/07/31").valueOf()) {
      //console.log('before 2018/07/31')
      return {package: "REJECTED", reason:"previous > 2018/07/31"}
    }

    // Reject Capitec with start date before 2016/05/06 moment(loan.inceptionDate).format('YYYY/MM/DD') < "2016/05/06"
    if (loan.description.toLowerCase().indexOf('capitec') > -1 && loan.inceptionDate < moment("2016/05/06").valueOf() && previousPremium != 0) {
      return {package: "REJECTED", reason:"previous < 2016/05/06"}
    }

    // Reject Bayport with start date before 2016/02/01
    if (loan.description.toLowerCase().indexOf('bayport') > -1 && loan.inceptionDate > moment("2016/02/01").valueOf() && previousPremium != 0) {
      return {package: "REJECTED", reason:"previous > 2016/02/01"}
    }

    // Reject direct axis with start date before 2011/10/01
    if (loan.description.toLowerCase().indexOf('direct axis') > -1 && loan.inceptionDate > moment("2011/10/01").valueOf() && previousPremium != 0) {
      //console.log('Rejected Direct Axis')
      return {package: "REJECTED", reason:"previous > 2011/10/01"}
    }

    // Reject loans with previous insurance which are on Basic for excluded industried / employers

    if (previousPremium != 0 && basicListed && termsVersion == 'BASIC') {
      //console.log(' Basic listed: this should be rejected')
      return {package: "REJECTED", reason:"basic/hazardous"}
    }

    return {package: "NORMAL", reason:""}
  }
}

const brandName = {
  OPEN_MARKET: "Meerkat",
  RMA: "RMA"
}

function FiveYearSavings({ loans, priceBreakdown }) {
  console.log("FIVE", priceBreakdown, loans)

  const total = loans.reduce((total, l) => {
    console.log(l)
    const price = (priceBreakdown || []).find(p => p.id === l.externalId)
    if (price) {
      return total + ((l.creditLifePremiumEstimate - price.calcPremium) * 60)
    } else {
      return total
    }
  }, 0)

  return <center>
    <Text size={10}>We will save you approximately</Text>
    <Text bold size={24}>{printCurrency(total)}</Text>
    <Text size={10}>on insurance over the next 5 years*</Text>
  </center>
}

function CreditLifeQuote({ step, chatState, setChatState, gotoNext, fullQuote, changeFieldValue }) {
  console.log("We Quoting Now .....")

  console.log('chatstate ', chatState)
  const [showNext, setShowNext] = useState(true)
  const quoteCard = useRef(null)

  const autoScroll = () => {
    setTimeout(() => quoteCard.current && quoteCard.current.scrollIntoView(true), 100)
  }

  console.log('Credit Life quote ', chatState)

  // Choose Terms Version
  const termsVersion =  chatState.employmentStatus && chatState.employmentStatus === "PERMANENT_EMPLOYMENT" ? "CORE" : "BASIC"

  if (!fullQuote) {
    const variables = {
      termsVersion,
      productType: chatState.productType || "OPEN_MARKET",
      obligations: [{
        amount: chatState.coverAmount || 100000,
        package: chatState.productType === "RMA" ? "PERSONAL_LOAN" : "PLAN_C",
        id: 1
      }]
    }

    autoScroll()
    return (
        <div ref={quoteCard}>
          <center>
            <MokuHoldingCard noMoku={chatState.productType === "RMA"}>
              <Query query={GET_QUOTE} variables={variables}>
              {
                ({ loading: quoteLoading, error, data: quoteData }) => { const price = quoteData && quoteData.getCreditLifeQuote.price; return (
                    quoteLoading 
                      ? <ActivityIndicator large="true"/> 
                      : <QuickQuotePrice title={`${brandName[chatState.productType]} Credit Life Insurance Quote`} disclaimer="This is an estimated premium based on your total debt below" price={`${printCurrency(price)}pm`} color="white" />
              )}}
              </Query>
            </MokuHoldingCard>
          </center>
          <p />
          <FormField name="coverAmount" inputProps={{ onChange: (e, v) => setChatState({ coverAmount: v }) }} />
          <MokuNotePanel noMoku={chatState.productType === "RMA"} title={`${chatState.productType === "RMA" ? "" : "Moku "}Tip: Customize your cover`} description="To increase cover, slide to the left. This is the total amount for loans you can cover in the event of Death, Disability or Retrenchment. On average we save our clients 33%" color="grey" />
          <p />
          <center>
          <div style={{ width: "95%" }}>
          {showNext && (
            <Col>
              <Row>
                <Col centerHorizontal><Button action="true"onClick={() => { setShowNext(false); gotoNext(["idNumber"]) }}>Get me covered!</Button></Col>
              </Row>
              <Row padTop="16px">
                <Col centerHorizontal><Button inverted action="true"onClick={() => { setShowNext(false); gotoNext(["callback"]) }}>Request a Callback</Button></Col>
              </Row>
            </Col>
          )}
          </div>
          </center>     
        </div>
        )
     }
 
    console.log("USING chatState=", chatState)

  return (
    <Query query={GET_LOANS} variables={{ idNumber: chatState.idNumber, accessCode: chatState.accessCode }}>
      {
        ({ loading, error, data }) => {
          if (loading) {
            return <ActivityIndicator large="true"/> 
          }

          if (error) {
            console.log(error)
            return "An error has occured"
          }

          const loansFilterByProductType = {
            RMA: ['CREDIT_CARD', 'PERSONAL_LOAN', 'REVOLVING_LOAN', 'HOME_LOAN', 'STUDENT_LOAN', 'VEHICLE_LOAN', 'OVERDRAFT'],
            OPEN_MARKET: ['CREDIT_CARD', 'PERSONAL_LOAN', 'REVOLVING_LOAN', 'STUDENT_LOAN']
          }

          const loans = data && data.getCreditRecord && data.getCreditRecord.obligations.filter(o => o.loanType && loansFilterByProductType[chatState.productType || "OPEN_MARKET"].includes(o.loanType) && o.currentBalance > 0 && !o.statusCode)
          
          if (!chatState.loans) {
            setChatState({
              termsVersion,
              productType: chatState.productType || "OPEN_MARKET",
              loans: (loans || []).map(l => ({
                ...l,
                isCovered: l.currentBalance < (chatState.productType === "RMA" ? 1000000 : 400000)
              }))
            })
            return <div />
          }

          const variables = quoteVariablesFromChatState(chatState)

          const noLoans = (chatState.loans || []).filter(l => l.isCovered).length === 0
          autoScroll()
            return (
                <div ref={quoteCard}>
                  <Query query={GET_QUOTE} variables={variables}>
                      {
                        ({ loading: quoteLoading, error, data: quoteData }) => { 
                          const price = quoteData && quoteData.getCreditLifeQuote.price;
                          const priceBreakdown = quoteData && quoteData.getCreditLifeQuote.priceBreakdown;
                          return <>
                            <center>
                              <MokuHoldingCard  noMoku={chatState.productType === "RMA"}>
                                {loading ? <ActivityIndicator large="true"/> :
                                      quoteLoading 
                                        ? <ActivityIndicator large="true"/> 
                                        : <QuickQuotePrice title={`${brandName[chatState.productType]} Credit Life Insurance Quote`} disclaimer="This is an estimated premium based on your selected loans and the credit report data" price={`${printCurrency(price)}pm`} color="white" />
                                  }
                              </MokuHoldingCard>
                            </center>
                            <p />
                            {!noLoans && loans.length > 0 && <FiveYearSavings loans={loans} priceBreakdown={priceBreakdown} />}
                            <p />
                            <MokuNotePanel noMoku={chatState.productType === "RMA"} title={`${chatState.productType === "RMA" ? "" : "Moku "}Tip: Customize your cover`} description={`Based on your credit report, these are the loans you can cover in the event of Death${variables.termsVersion === "BASIC" ? "": ", Disability or Retrenchment"}. Select to add or remove from your policy`} color="grey" />
                            <p />
                            <center>
                              <div style={{ width: "95%" }}>
                                <LoansToCover chatState={chatState} setChatState={setChatState} isQuote changeFieldValue={changeFieldValue} priceBreakdown={priceBreakdown} />
                                {noLoans && loans.length > 0 && <>Select at least 1 loan to continue<p /></>}
                                {loans.length === 0 && <>You have no loans for credit life cover<p /></>}
                                {!noLoans && loans.length > 0 && <>
                                  <Text size={10}>* This based on assumed premium rates for the credit providers. The current premiums and actual savings will be confirmed with your credit providers.</Text>
                                  <p />
                                  <p />
                                </>}
                                {showNext && (
                                  <Col>
                                    <Row>
                                      {loans.length === 0 ?
                                      <Col centerHorizontal><Button action="true"onClick={() => { setShowNext(false); gotoNext(["done"]) }}>Done!</Button></Col> :
                                      <Col centerHorizontal><Button disabled={noLoans} action="true"onClick={() => { setShowNext(false); gotoNext(["mainMember"]) }}>Get me covered!</Button></Col>}
                                    </Row>
                                    {/*<Row padTop="16px">
                                      <Col centerHorizontal><Button inverted action="true"onClick={() => { setShowNext(false); gotoNext(["callback"]) }}>Request a Callback</Button></Col>
                                      </Row>*/}
                                  </Col>
                                )}
                              </div>
                            </center>
                          </>}      
                    }
                  </Query>
                </div>
              )
             }
    }
    </Query>
  )
}

CreditLifeQuote.quoteVariablesFromChatState = quoteVariablesFromChatState
CreditLifeQuote.GET_QUOTE = GET_QUOTE

export default CreditLifeQuote