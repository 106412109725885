import React from 'react'
import { DocumentGridItemContainer } from './DocumentGridItem.style'
import { Text } from '../../generic'

function DocumentGridItem({ title, onClick }) {
  return <DocumentGridItemContainer onClick={onClick}>
    <Text size={12} bold>{title}</Text>
  </DocumentGridItemContainer>
}

export default DocumentGridItem
