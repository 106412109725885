import React from 'react'
// import {} from './TrackStatusModal.style'
import SlideUpModal from '../../generic/SlideUpModal'
import Timeline from '../../shared/Timeline'

const buildStatusItems = (forObligations, outstandingRequirements) => ([
  {
    completed: {
      title: "Application Made",
      description: "Thank you for your application."
    },
    inProgress: {
      title: "Application Made",
      description: `Thank you for your application. To process your application we need the following outstanding items: ${outstandingRequirements.join(", ")}.`
    },
    currentStates: ["CLIENT_INFO_REQUIRED", "APPLICATION"],
    include: true
  }, {
    completed: {
      title: "Settlement Letters Uploaded",
      description: "Thank you for uploading your settlement letters. We will review each one."
    },
    inProgress: {
      title: "Upload Settlement Letters",
      description: "We need a copy of your Settlement Letters in order to proceed with your Clearance Certificate application"
    },
    todo: {
      title: "Upload Settlement Letters",
      description: "We need a copy of your Settlement Letters in order to proceed with your Clearance Certificate application"
    },
    currentStates: ["CLIENT_SETTLEMENT_LETTERS_REQUIRED"],
    include: forObligations.filter(o => ["PAID_UP_CLIENT_PROVIDE_PROOF", "PAID_UP_CLIENT_PROOF_PROVIDED", "PAID_UP_CLIENT_PROOF_ACCEPTED"].includes(o.status)).length > 0
  }, {
    completed: {
      title: "Settlement Letters Verified",
      description: "We have verified your settlement letters and will proceed to transfer you across to our Debt Counsellor code."
    },
    inProgress: {
      title: "Verifying Settlement Letters",
      description: "We are currently reviewing your settlement letters and will then proceed to transfer you across to our Debt Counsellor code."
    },
    todo: {
      title: "Verify Settlement Letters",
      description: "We will verify your Settlement Letters on receipt."
    },
    currentStates: ["AWAITING_DOCUMENT_VERIFICATION"],
    include: forObligations.filter(o => ["PAID_UP_CLIENT_PROVIDE_PROOF", "PAID_UP_CLIENT_PROOF_PROVIDED", "PAID_UP_CLIENT_PROOF_ACCEPTED"].includes(o.status)).length > 0
  }, {
    completed: {
      title: "DC Transfer Complete",
      description: "You are now registered with NCRDC2613."
    },
    inProgress: {
      title: "DC Transfer in Progress",
      description: "We are now transferring you across to our Debt Counsellor code NCRDC2613."
    },
    todo: {
      title: "Transfer DC",
      description: "We will transfer you across to our Debt Counsellor code NCRDC2613."
    },
    currentStates: ["DC_TRANSFER_REJECTED", "DC_TRANSFER_FAILED", "DC_TRANSFER_IN_PROGRESS"],
    include: true
  }, {
    completed: {
      title: "Settlement Letters Received",
      description: "We have received your settlement letters from the various Credit Providers."
    },
    inProgress: {
      title: "Meerkat requested Settlement Letters",
      description: "We are now requesting your Settlement Letters from the various Credit Providers."
    },
    todo: {
      title: "Meerkat to Request Settlement Letters",
      description: ""
    },
    currentStates: ["AWAITING_CREDITORS"],
    include: forObligations.filter(o => ["PAID_UP_REQUEST_PROOF", "PAID_UP_PROOF_REQUESTED", "PAID_UP_CREDITOR_LETTER"].includes(o.status)).length > 0
  }, {
    completed: {
      title: "Payment Received",
      description: "Thank you for your payment."
    },
    inProgress: {
      title: "Make Payment",
      description: "You will need to make payment before we can issue you with the Clearance Certificate."
    },
    todo: {
      title: "Make Payment",
      description: "You will need to make payment before we can issue you with the Clearance Certificate."
    },
    currentStates: ["AWAITING_FINAL_PAYMENT"],
    include: true
  }, {
    completed: {
      title: "Clearance Certificate Issued",
      description: "Great news! Your Clearance Certificate has been issued. We will send a copy to your email."
    },
    todo: {
      title: "Issue Clearance Certificate",
      description: "Once your Clearance Certificate is issued, we will send a copy to your email."
    },
    currentStates: [],
    include: true
  }, {
    inProgress: {
      title: "Credit Providers & Bureaux notified of Clearance",
      description: "Your Credit Providers and the Credit Bureau have been notified of your Clearance Certificate being issued. Please note it can take up to 1 month for your debt free status to reflect on your credit record."
    },
    todo: {
      title: "Notify Credit Providers & Bureaux of Clearance.",
      description: "We will notify Your Credit Providers and the Credit Bureaux of your Clearance Certificate being issued. Please note it can take up to 1 month for your debt free status to reflect on your credit record."
    },
    currentStates: ["CERTIFICATE_ISSUED"],
    include: true
  }
])

const getCurrentIndex = (items, state) => {
  return items.filter(item => item.include).findIndex(item => item.currentStates.includes(state))
}

export const getCurrentItem  = (forObligations, outstandingRequirements, state) => {
  const items = buildStatusItems(forObligations || [], outstandingRequirements || [])
  const index = getCurrentIndex(items, state)

  console.log(items, index)

  return items.filter(item => item.include)[index].inProgress
}


function TrackStatusModal({ open, setOpen, state, outstandingRequirements, forObligations }) {
  const items = buildStatusItems(forObligations || [], outstandingRequirements || [])
  const currentIndex = getCurrentIndex(items, state)

  return <SlideUpModal open={open} setOpen={setOpen} title="Track My Status">
    <Timeline items={items.filter(item => item.include).map((item, index) => currentIndex=== index ? item.inProgress : currentIndex > index ? item.completed : item.todo)} currentIndex={currentIndex} />    
  </SlideUpModal>
}

export default TrackStatusModal
