import React, { useEffect, useState } from 'react'
// import {} from './ClearanceCertificateObligationDetails.style'
import { useLazyQuery } from 'react-apollo'
import gql from 'graphql-tag'
import ClearanceCertificateObligationSummary from '../ClearanceCertificateObligationSummary'
import ActivityIndicator from '../../generic/ActivityIndicator'
import UpdateClearanceCertificateObligationModal from '../UpdateClearanceCertificateObligationModal'

const GET_CLEARANCE_CERTIFICATE_LOANS = gql`query ($accessCode: String, $clearanceCertificateId: String) {
  getClearanceCertificate(clearanceCertificateId: $clearanceCertificateId, accessCode: $accessCode) {
    forObligations {
      id
      currentBalance
      description
      status
      statusAt
    }
    accessCode
  }
}`

function ClearanceCertificateObligationDetails({ accessCode, setAccessCode, chatState }) {
  console.log("CC Details CHAT STATE", chatState)

  const { clearanceCertificateId } = chatState
  const [editOpen, setEditOpen] = useState(false)
  const [obligation, setObligation] = useState(null)
  const [getClearanceCertificateLoans, { data, error, loading }] = useLazyQuery(GET_CLEARANCE_CERTIFICATE_LOANS)
  const [useAccessCode, setUseAccessCode] = useState(accessCode)


  useEffect(() => {
    getClearanceCertificateLoans({
      variables: {
        clearanceCertificateId,
        accessCode
      }
    })
  }, [])

  const handleAccessCodeChange = accessCode => {
    setAccessCode && setAccessCode(accessCode)
    setUseAccessCode(accessCode)
  }

  useEffect(() => {
    if (!loading && data && data.getClearanceCertificate) {
      handleAccessCodeChange(data.getClearanceCertificate.accessCode)
    }
  }, [loading])

  useEffect(() => {
    console.log("Setting access code from prop")
    setUseAccessCode(accessCode)
  }, [accessCode])

  if (!data || loading || error) return <ActivityIndicator />

  const { forObligations } = data.getClearanceCertificate

  return <>
    <UpdateClearanceCertificateObligationModal accessCode={useAccessCode} setAccessCode={handleAccessCodeChange} open={editOpen} setOpen={setEditOpen} obligation={obligation} clearanceCertificateId={clearanceCertificateId} refetchQueries={[{ query: GET_CLEARANCE_CERTIFICATE_LOANS, variables: { clearanceCertificateId, accessCode: useAccessCode } }]} />
    {forObligations.map(o => <ClearanceCertificateObligationSummary key={o.id} status={o.status} description={o.description} currentBalance={o.currentBalance} statusAt={o.statusAt} onClick={() => {
      setObligation(o)
      setEditOpen(true)
    }} />)}
  </>
}

export default ClearanceCertificateObligationDetails
