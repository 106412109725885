import React from 'react'
import SelectableItem from '../../generic/SelectableItem'
import { printCurrency } from '../../../lib/utils'

const normalizeLoanType = loanType => {
  return loanType.split("_").map(d => d[0]+d.slice(1).toLowerCase()).join(" ")
}

function LoansToCoverSubText({ loan, price, limit }) {
  return <>
    {`${normalizeLoanType(loan.loanType)} - Balance: ${printCurrency(loan.currentBalance)}`}<br/>
    {!limit || (loan.currentBalance < limit) ? <>
      Current Premium Estimate: {printCurrency(loan.creditLifePremiumEstimate)}<br />
      {price && <>Our Premium: {printCurrency(price.calcPremium)}<br /></>}
      {price && <>You <b>SAVE</b> {printCurrency(loan.creditLifePremiumEstimate - price.calcPremium)} per month</>}
    </> :
    <>
      This loan exceeds the product maximum and cannot be covered
    </>}
  </>
}

function LoansToCover({ chatState, setChatState, priceBreakdown }) {
  console.log(priceBreakdown)

  const { loans, productType } = chatState
  return <div style={{ width: "100%"}}>
    {loans && loans.map(loan => <SelectableItem key={loan.id} control="radio" selected={loan.isCovered} title={loan.description} onChange={() => { setChatState({ loans: loans.map(l => {
      console.log(loan)
      if (l.id === loan.id && loan.currentBalance < (productType === "RMA" ? 1000000 : 400000)) {
        return {
          ...l,
          isCovered: !l.isCovered
        }
      } else {
        return l
      } 
    }) })
    }} subtext={<LoansToCoverSubText limit={productType === "RMA" ? 1000000 : 400000} loan={loan} price={(priceBreakdown || []).find(p => loan.externalId === p.id)} />} />)}

    <p />
  </div>
}


export default LoansToCover
