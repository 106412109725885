import React from 'react'
import { isRequired } from '../../../lib/validators'
import { WizardModal } from '../../shared'
import gql from 'graphql-tag'
import { GET_MY_DOCUMENTS } from '../DocumentVault/DocumentVault'

const UPLOAD_CLIENT_DOCUMENT = gql`
  mutation ($input: CreateClientDocumentInput!) {
    createClientDocument(input: $input) {
      id
    }
  }
`

const documentNameByType = {
  "RSA_ID": "RSA Identity Document",
  "COVID19_VACCINATION_CERTIFICATE": "Vaccination Certificate",
  "PAYSLIP": "Payslip",
  "SELFIE": "Selfie"
}

const buildSteps = (setOpen, type) => ([{
  name: "step1",
  title: "Upload to Document Vault",
  title2: `Upload My ${documentNameByType[type] || "Document"}`,
  fieldNames: ["name", "fileId"],
  nextSteps: ["submit"],
  nextTitle: "Upload to Vault"
}, {
  name: "submit",
  submitBeforeStep: true,
  nextAfterDelay: 1000,
  noNext: true,
  nextSteps: ["done"]
}, {
  name: "done",
  beforeStep: () => setOpen(false)
}])

const fields = {
  fileId: {
    type: 'fileupload',
    prompt: 'Choose File',
    validate: [isRequired],
  },
  name: {
    type: 'text',
    validate: [isRequired],
    label: "Document Name",
  },
}

function UploadDocument({ open, setOpen, type, clientId, forClientId, refetchQueries, afterSubmit, source, description }) {
  return <WizardModal
    name="uploadDocument"
    addMutation={UPLOAD_CLIENT_DOCUMENT}
    mode="add"
    open={open}
    setOpen={setOpen}
    steps={buildSteps(setOpen, type)}
    afterSubmit={afterSubmit}
    initialValues={{ type, clientId, forClientId, fromSimplicity: false, name: documentNameByType[type], source: source || "DOCUMENT_VAULT_UPLOAD", description: description || "" }}
    fields={fields}
    refetchQueries={[{ query: GET_MY_DOCUMENTS }, ...(refetchQueries || [])]}
  />
}

export default UploadDocument
