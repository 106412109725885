import React from 'react'
import firebase from 'gatsby-plugin-firebase'
import { isRequired } from '../../../lib/validators'
import { WizardModal } from '../../shared'
import gql from 'graphql-tag'


export const ROLE_HOLDER_UPLOAD_SIGNATURE = gql`
  mutation($groupId: String!, $clientId: String!, $signature: String!) {
    updateUserRoleHolderSignature(groupId: $groupId, clientId: $clientId, signature: $signature) {
      id
    }
  }
`

const buildSteps = (setOpen, refetchQueries) => ([{
    name: "init",
    noNext: true,
    nextAfterDelay: 10,
    nextSteps: ["getSignature"]
  },
  {
    name: "getSignature",
    beforeStep: async () => {
      firebase.analytics().logEvent("GROUP_GET_SIGNATURE");
    },
    title: "Group Details",
    title2: "Add your signature to make it official",
    title3: <>
      You will be signing the following documents that we use to complete your Group policy.
      <p />
      <ul style={{ textAlign: "left", fontSize: "12px", margin: 0 }}>
        <li>Group Funeral Constitution</li>
        <li>Group Addendum To Constitution</li>
        <li>Group Waiting Period Acceptance</li>
        <li>Debit Order Mandate</li>
      </ul>
    </>,
    fieldNames: ["signature"],
    nextSteps: ["doSign"]
  },
  {
    beforeStep: async ({ apolloClient, chatState }) => {
      const { signature, groupId, clientId } = chatState
      const result = await apolloClient.mutate({ mutation: ROLE_HOLDER_UPLOAD_SIGNATURE, variables: { signature, groupId, clientId }, refetchQueries })
      console.log(result)
    },
    name: "doSign",
    noNext: true,
    nextAfterDelay: 1000,
    nextSteps: ["done"]
  },
  {
    name: "done",
    beforeStep: () => setOpen(false)
  }
])

const fields = {
  signature: {
    type: "signature",
    validate: [isRequired]
  }
}

function GroupSignature({ open, setOpen, groupId, clientId, refetchQueries}) {
  return <WizardModal
    name={`GroupSignature-${groupId}`}
    open={open}
    setOpen={setOpen}
    steps={buildSteps(setOpen, refetchQueries)}
    initialValues={{ groupId, clientId }}
    fields={fields}
  />
}

export default GroupSignature
