import React, { useState } from 'react'
import { VictoryScatter } from 'victory'
import theme from '../../../theme.style'
import { BudgetSlideUpModal } from '../../budget'
import CustomPoint from '../../dashboard/CustomPoint'
import { CardRow, Card, GraphContainer } from '../../financialHealth/MyFinancialHealth/MyFinancialHealth.style'
import { BarGraph, StackedBarGraph, Text } from '../../generic'
import { printCurrency } from '../../../lib/utils'
import { navigate } from 'gatsby'
import { debtColors } from '../../debtRepayments/shared'
import { ColumnSpacer, RowSpacer } from '../../generic/Layouts'

interface IFinancialHealthGraphsProps {
  financialHealthData: any
  income: number
  title?: string
  hideTitle?: boolean
  hideBudget?: boolean
  hideDebtRepayments?: boolean
}

function FinancialHealthGraphs({
  financialHealthData,
  income,
  title,
  hideTitle,
  hideBudget,
  hideDebtRepayments,
}: IFinancialHealthGraphsProps) {
  const [budgetOpen, setBudgetOpen] = useState(false)
  const useData = financialHealthData
  const maxY = useData && useData.budget ? Math.max(...useData.budget.map((d: any) => d.amount)) : 0
  const budget = income - useData.affordability

  return (
    <>
      <BudgetSlideUpModal open={budgetOpen} setOpen={setBudgetOpen} />
      {!hideTitle && (
        <Text size={16} bold>
          {title || 'My Expenses'}
        </Text>
      )}
      <RowSpacer height={16} />
      <CardRow>
        {!hideBudget && (
          <>
            <Card
              onClick={() => {
                setBudgetOpen(true)
              }}
              style={{ margin: '0px' }}
            >
              <Text bold small>
                My Budget
              </Text>
              <GraphContainer small>
                <StackedBarGraph
                  data={
                    useData.budget
                      ? [
                          useData.budget.map((d: any, index: any) => ({
                            x: index + 1,
                            y: d.amount,
                            fill: theme.colors.primary,
                          })),
                        ]
                      : []
                  }
                >
                  <VictoryScatter
                    data={[{ x: ((useData.budget || []).length + 1) / 2, y: maxY * 1.1 }]}
                    //@ts-ignore
                    dataComponent={<CustomPoint label={`${printCurrency(budget)} pm`} backgroundColor="primary" />}
                  />
                </StackedBarGraph>
              </GraphContainer>
              <center>
                <Text small>Set-up Budget</Text>
              </center>
            </Card>
            <ColumnSpacer width={8} />
          </>
        )}
        {!hideDebtRepayments && (
          <Card onClick={() => navigate('/debt-repayments')} style={{ margin: '0px' }}>
            <Text bold small>
              My Debt Repayments
            </Text>
            <GraphContainer small>
              <BarGraph
                data={Object.keys(useData.debtBreakdown).map((category, index) => ({
                  x: index + 1,
                  y: useData.debtBreakdown[category],
                  fill: theme.colors[debtColors[category]],
                }))}
              >
                <VictoryScatter
                  data={[
                    {
                      x: 3,
                      y:
                        Math.max(
                          ...Object.keys(useData.debtBreakdown).map((category) => useData.debtBreakdown[category])
                        ) * 1.05,
                    },
                  ]}
                  //@ts-ignore
                  dataComponent={<CustomPoint label={printCurrency(useData.allDebt)} backgroundColor="alertRed" />}
                />
              </BarGraph>
            </GraphContainer>
            <center>
              <Text small>View Debt Details</Text>
            </center>
          </Card>
        )}
      </CardRow>
    </>
  )
}

export default FinancialHealthGraphs
