import styled from '@emotion/styled'
import { device } from '../../theme.style'

const PrivateContentPane = styled.div`
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 100px;
`

const PrivateContentContainer = styled.div`
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 50px;/* needs fixing (flexbox) */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 160px;
  margin-top: 150px;

  ${device.tabletS`
    margin-left: 0;
    margin-top: 0;
  `}
`

const PrivateSidebarContainer = styled.div`
  display: table-cell;
  width: 31%;
  height: 100vh;
  max-width: 595px;

  ${() => device.tablet`
    display: none;
  `}
`

const PrivateHeaderContainer = styled.div`
  # display: none;

  ${device.tablet`
    display: block;
  `}
`

export  {
  PrivateContentPane,
  PrivateContentContainer,
  PrivateSidebarContainer,
  PrivateHeaderContainer,
}