import React from 'react'
import { SlideUpModal } from '../../generic'
import BudgetForm from '../BudgetForm'

function BudgetSlideUpModal({ open, setOpen }) {
  return <SlideUpModal open={open} setOpen={setOpen} title="Budget">
    <BudgetForm setOpen={setOpen} />
  </SlideUpModal>
}

export default BudgetSlideUpModal
