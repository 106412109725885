import React, { useState } from 'react'
// import {} from './MyCreditLife.style'
import { ActivityIndicator, Text, Button } from '../../generic'
import { BackActionHeader, MokuNotePanel, TitleImage, BlogPostCard } from '../../shared'
import { Row } from '../../generic/grid'
import SummaryItemCard from '../../dashboard/SummaryItemCard'
import LoanItemCard from '../LoanItemCard'
import { navigate } from 'gatsby'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { printCurrency, prettyText } from '../../../lib/utils'
import CreditLifeQuickQuote from '../CreditLifeQuickQuote'
import AddNextOfKinModal from '../AddNextOfKinModal'
import { ColumnSpacer, RowSpacer } from '../../generic/Layouts'
import { rollingMoney } from '../../../images'
import { BlogCardsContainer } from '../../shared/BlogPostCard/BlogPostCard.style'
import piggyBankOnCalculator from '../../../images/blogPosts/piggyBankOnCalculator.webp'
import coverYourHome from '../../../images/blogPosts/coverYourHome.webp'
import { BlogPostCards } from '../../sections'
import CreditLifeSignature from '../CreditLifeSignature'

export const CREDIT_LIFE_INFO = gql`{
  myClient {
    id
    creditLife {
      id
      policyStatus
      payment
      nextPaymentDate
      signatureId
      obligationInsurances {
        id
        package
        cancelledAt
        obligation {
          description
          remainingTerm
          currentBalance
          originalBalance
        }
        amount
        status
        premiumAmount
      }
      beneficiaries{
        isNextOfKin
      }
    }
  }
}`

const blogs = [
  {
    imageSrc: piggyBankOnCalculator,
    title: 'What is Credit Life Cover used for?',
    description: 'Credit Life Cover is used to protect individuals from missing their debt repayments should something completely unexpected happen. Find out more here... ',
    linkUrl: 'https://www.meerkat.co.za/blog/what-is-credit-life-used-for',
  },
  {
    imageSrc: coverYourHome,
    title: 'Insure Your Debt For When Life Happens',
    description: 'Be prepared for when life happens to you. Meerkat is looking out for you, make sure you have the insurance that you need for your debt.',
    linkUrl: 'https://www.meerkat.co.za/blog/news/insurance',
  }
]

function MyCreditLife() {
  const [showTip, setShowTip] = useState(true)
  const [signCreditLifeOpen, setSignCreditLifeOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const [nextOfKinIsOpen, setNextOfKinOpen] = useState(false)
  const { data, loading, error } = useQuery(CREDIT_LIFE_INFO)

  if (loading) {
    return <ActivityIndicator />
  }
  
  const { obligationInsurances: loans, payment } = (data && data.myClient && data.myClient.creditLife) || { obligationInsurances: [] }


  const creditLife = data && data.myClient && data.myClient.creditLife
  const productId = creditLife && creditLife.id
  const beneficiaries = creditLife && creditLife.beneficiaries
  let hasNextOfKin = false
  beneficiaries && beneficiaries.length > 0 && beneficiaries.forEach(beneficiary => {
    if(beneficiary.isNextOfKin) hasNextOfKin = true
  })

  return <>
    <CreditLifeQuickQuote open={open} setOpen={setOpen} />
    <CreditLifeSignature open={signCreditLifeOpen} setOpen={setSignCreditLifeOpen} refetchQueries={[{ query: CREDIT_LIFE_INFO }]} id={productId} />
    <AddNextOfKinModal open={nextOfKinIsOpen} setOpen={setNextOfKinOpen} productId={productId} refetchQueries={[{ query: CREDIT_LIFE_INFO }]}/>
    <BackActionHeader title="My Insurance" onClick={() => navigate('/insurance')} />
    <Text bold size={26} style={{padding: "2px"}}>
      My Credit Life Insurance
    </Text>
    <RowSpacer height={14}/>
    {creditLife && <><Row>
      {/* TODO: Consolidate  SummaryItemCard title font-size in Figma*/}
      <SummaryItemCard title="Estimated Next Premium" value={printCurrency(payment)} />
      <ColumnSpacer width={8}/>
      <SummaryItemCard title="Total Due" value={printCurrency(payment)} alert alertColor="primary" />
    </Row>
    <RowSpacer height={16}/>
    <MokuNotePanel title="Moku Tip: Update Timings" description="We update our data at 14:00 everyday. Should you not see a change in your loan statuses please be patient." visible={showTip} showClose onClose={() => { setShowTip(false) }} />
    </>}
    <p />
    { productId && !hasNextOfKin &&
      <>
        {/* <RowSpacer height={11}/> */}
        <MokuNotePanel 
          description={<Text><Text span bold>Moku Alert: </Text>It is important to provide details of your next of kin so they may claim on your behalf in the event of death.</Text>}
          showMokuBubble={false}
        >
          <center style={{width: "100%"}}><Button inverted onClick={()=>setNextOfKinOpen(true)}>Add</Button></center>
        </MokuNotePanel>
        <p />
      </>
      // TODO: refetch after next of kin modal complete
    }
    {creditLife && ["POLICY_ISSUED", "QUOTE"].includes(creditLife.policyStatus) && !creditLife.signatureId && <>
      <MokuNotePanel
      description={<Text><Text span bold>Moku Alert: </Text>We need to make it official to proceed. Please add your signature</Text>}
        footer={
          <>
            <RowSpacer height={8} />
            <center style={{ width: '100%' }}>
              <RowSpacer height={8} />
              <Button inverted onClick={() => setSignCreditLifeOpen(true)}>
                Sign Now
              </Button>
            </center>
            <RowSpacer height={8} />
          </>
        }
      />
      <p/>
    </>}
    {creditLife && <><RowSpacer height={14}/>
    <Text bold size={16}>My Loans</Text>
    <RowSpacer height={18}/>
    {loans && loans.filter(loan => loan.package !==  "REJECTED" && !loan.cancelledAt).map((loan, i) => <LoanItemCard key={i} title={prettyText(loan.obligation.description)} status={loan.status} premiumAmount={loan.premiumAmount} onClick={() => navigate(`/loan/${loan.id}`)} />)}
    <p/></>}
    {!creditLife ?
    <>
      <MokuNotePanel title="Credit Life Cover" description="We are able to provide cover on your loans should something unexpected happen such as death, critical illness or retrenchment. We pay creditors directly">
        <Button onClick={() => setOpen(true)}>Get a Quick Quote</Button>
      </MokuNotePanel>
      <p />
      <MokuNotePanel title="What is Credit Life Cover?" description="Credit life insurance provides cover on your unsecured loans in the event of death, temporary and permanent disability, critical illness, and retrenchment. This is generally included by default and we are able to save you money if you sign up with Meerkat." />
    </>
    :
    <center>
      <Button onClick={() => setOpen(true)}>Get a Quick Quote</Button>
    </center>}
    <p/>
    <TitleImage title="Keeping you on-track" description="Here are some financial tips from us to help you reach your financial goals." image={rollingMoney} />
    <p />
    <BlogPostCards blogs={blogs}/>
    <p />
    <p />
  </>
}

export default MyCreditLife
