import styled from '@emotion/styled'

const InformationRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
`

const InformationTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const InformationValueContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export {
  InformationRowContainer,
  InformationTitleContainer,
  InformationValueContainer
}
