import React from 'react'
// import {} from './TermsAndConditions.style'
import { ActivityIndicator, Text } from '../../../generic'
import { BackActionHeader } from '../../../shared'
import { navigate } from 'gatsby'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'

const GET_TERMS_AND_CONDITIONS = gql`{
  getTemplate(name: "CONST_TERMS")
}`

function TermsAndConditions() {
  const { data, loading, error } = useQuery(GET_TERMS_AND_CONDITIONS)

  return <>
    <BackActionHeader title="Document Vault" onClick={() => navigate('/documents')} />
    <Text bold size={26}>Terms and Conditions</Text>
    <p />
    {loading || error 
      ? loading ? <ActivityIndicator /> : error 
      : <div dangerouslySetInnerHTML={{__html: data.getTemplate  }} />
    }
  </>
}

export default TermsAndConditions
