import EMERGENCY from './emergency.png'
import CAR from './car.png'
import OTHER from './other.png'
import HOLIDAY from './holiday.png'
import FUNERAL from './funeral.png'

export default {
  EMERGENCY,
  CAR,
  OTHER,
  HOLIDAY,
  FUNERAL
}