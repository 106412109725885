import React, { useState } from 'react'
// import {} from './BankAccount.style'
import { useQuery } from 'react-apollo'
import { BackActionHeader, AnnouncementModal, TitleImage } from '../../shared'
import { Text, Button, SelectableItem } from '../../generic'
import BankAccountForm from '../BankAccountForm'
import gql from 'graphql-tag'
import { sittingOnMoney } from '../../../images'
import { SubmitButton } from 'react-form-helper'
import { navigate } from 'gatsby'

const BANK_ACCOUNT_DETAILS = gql`query ($id: String!) {
  bankaccount(id: $id) {
    id
    bankName
    branchCode
    accountNo
    associatedWith {
      id
      type
      reference
    }
  }
}`

function BankAccount({ id }) {
  const { data, loading, error } = useQuery(BANK_ACCOUNT_DETAILS, { variables: { id } })
  const [showAnnouncement, setShowAnnouncement] = useState(false)
  const { associatedWith } = (data && data.bankaccount) || {}
  const linkedProducts = (associatedWith || []).filter(a => a.type !== "DebitOrder")

  return <>
    <BackActionHeader title="My Bank Accounts" onClick={() => navigate('/bank-accounts')} />
    <AnnouncementModal open={showAnnouncement} setOpen={setShowAnnouncement} title="Your bank account has been updated" closeTitle="OK" />
    <TitleImage title="My Bank Account Details" image={sittingOnMoney} />
    <Text bold size={16}>Bank Account</Text>
    <BankAccountForm id={id} afterSubmit={data => {
      setShowAnnouncement(true)
    }} />
    {linkedProducts.length > 0 && <Text bold size={16}>Meerkat Products Linked to Account</Text>}
    {linkedProducts.map(({ type, reference }, i) => <SelectableItem key={i} title={`${type} ${type !== "Savings" ? reference : ""}`}/>)}
    <p />
    <center><SubmitButton name={`bankAccount:${id}`} renderSubmitButton={({ submit, submitting, invalid }) => (
      <Button submitting={submitting} disabled={invalid} onClick={submit}>Request to change account</Button>
    )} /></center>
  </>
}

export default BankAccount
