import React, { useState, useEffect } from 'react'
import { ContactFormContainer, CloseImage, ContactFormHeaderContainer, MokuContainer, ContactFormHeaderText, ContactFormFormContainer, ContactFormFieldContainer, ContactFormButtonContainer, MobileCloseImage } from './ContactForm.style'
import PropTypes from 'prop-types'
import { Text, Button } from '../generic'
import { close } from '../../images'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { GraphQLFormProvider, SubmitButton } from 'react-form-helper'
import { renderInput, renderLabel, ColumnLayout, Mandatory } from '../generic/form' 
import gql from 'graphql-tag'
import { isValidEmail, isValidCellphoneNumber, isRequired } from '../../lib/validators'
import { ADD_LEAD, buildAddLeadMutationInput } from '../../lib/contactForm'

const fields = {
  firstName: {
    label: "Name",
    required: true,
    validate: [isRequired]
  },
  surname: {
    label: "Surname"
  },
  cellNumber: {
    label: "Cell Number",
    required: true,
    validate: [isRequired, isValidCellphoneNumber]
  },
  email: {
    label: "Email",
    validate: [isValidEmail]
  },
  talkAbout: {
    label: "I'd like to talk about",
    required: true,
    type: "select",
    options: ["Debt Management", "Credit Life Cover", "Funeral Cover", "Savings", "Other"],
    validate: [isRequired]
  },
  message: {
    label: "Leave a message (optional)"
  },
}

function buildInitialValues(routing) {
  if (routing === "sav") {
    return {
      talkAbout: "Savings",
      routing
    }
  }
  if (routing === "fun") {
    return {
      talkAbout: "Funeral Cover",
      routing
    }
  }
  if (routing === "cli") {
    return {
      talkAbout: "Credit Life Cover",
      routing
    }
  }
  if (routing === "debt" || routing === "af") {
    return {
      talkAbout: "Debt Management",
      routing
    }
  }
  return {
    routing
  }
}

function ContactForm({ inline, open, setOpen, routing }) {
  const data = useStaticQuery(graphql`{
    header: file(relativePath: { eq: "ModalTop.png" }) {
      childImageSharp {
        fluid(maxWidth: 652) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    moku: file(relativePath: { eq: "MokuModal.png" }) {
      childImageSharp {
        fluid(maxWidth: 146) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }`)

  
  const [willContact, setWillContact] = useState(false)

  if (willContact) {
    navigate('/thank-you')
    setWillContact(false)
  }

  const closeModal = () => {
    setOpen && setOpen(false);
    setTimeout(() => setWillContact(false), 1000)
  }

  return <ContactFormContainer willContact={willContact} inline={inline}>
      {!inline && <MobileCloseImage src={close} alt="Close" onClick={() => closeModal()} />}
      <ContactFormHeaderContainer>
        {!inline && <CloseImage src={close} alt="Close" onClick={() => closeModal()} />}
        {/*<Img fluid={data.header.childImageSharp.fluid} alt="X" />
        <MokuContainer>
          <Img fluid={data.moku.childImageSharp.fluid} alt="Moku" />
        </MokuContainer>
        <ContactFormHeaderText>
          <Text bold>Hi, there</Text>
          <ContactFormFieldContainer><Text>I'm Moku your financial coach</Text></ContactFormFieldContainer>
        </ContactFormHeaderText>*/}
      </ContactFormHeaderContainer>
      {willContact
        ? <>
            <Text heading><p>Thank You!</p></Text>
            <Text><p>We will be in touch shortly.</p></Text>
            {!inline && <Button onClick={() => closeModal()}>Close</Button>}
          </>
        : <ContactFormFormContainer>
            <Text bold>Let us contact you</Text>
            <ContactFormFieldContainer><Text>Enter your details below and we will contact you shortly</Text></ContactFormFieldContainer>
            <GraphQLFormProvider
              addMutation={ADD_LEAD}
              name="contact"
              mode="add"
              fields={fields}
              renderInput={renderInput}
              renderLabel={renderLabel}
              initialValues={buildInitialValues(routing)}
              mapInputVariables={buildAddLeadMutationInput}
              afterSubmit={() => setWillContact(true)}
            >
              <ColumnLayout fields={fields} />
              <Mandatory />
              <ContactFormButtonContainer>
                <SubmitButton renderSubmitButton={({ submit, submitting, invalid }) => (
                  <Button submitting={submitting} disabled={invalid} onClick={submit}>Send</Button>
                )} />
              </ContactFormButtonContainer>
            </GraphQLFormProvider>
          </ContactFormFormContainer>}
    </ContactFormContainer>
}

ContactForm.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  routing: PropTypes.string,
  inline: PropTypes.bool
}

ContactForm.buildAddLeadMutationInput = buildAddLeadMutationInput
ContactForm.ADD_LEAD = ADD_LEAD

export default ContactForm
