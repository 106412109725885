import React from 'react'
import { Text, Label } from '../../generic'
import { Row, Col } from '../../generic/grid'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { BankingDetailsContainer, BankingDetailsRow, ReferenceLabel } from './BankDetails.style'
import { RowSpacer } from '../../generic/Layouts'
import { InformationRow } from '../../shared'

const GET_ID_NUMBER = gql`
{
  myClient {
    idNumber
  }
}
`

function BankDetails({ reference }) {
  const { data } = useQuery(GET_ID_NUMBER)
  const useReference = reference || (data && data.myClient && data.myClient.idNumber) || ""
  return (
    <BankingDetailsContainer>
      <BankingDetailsRow>
      <RowSpacer height={12}/>
        <Text size={16} bold>Nedbank</Text>
        <RowSpacer height={6}/>
        <InformationRow title={'Account holder'} value={'Meerkat'}/>
        <InformationRow title={'Account no.'} value={'1186772700'}/>
        {/* <InformationRow title={'Account type'} value={'TODO: add value'}/> */}
        {/* <InformationRow title={'Branch'} value={'TODO: add value'}/> */}
        <InformationRow title={'Branch code'} value={'198765'}/>
      </BankingDetailsRow>
      <BankingDetailsRow>
        <InformationRow title={'Reference'} value={<ReferenceLabel title={useReference} color="primary" textColor="primary"/>}/>
      </BankingDetailsRow>
    </BankingDetailsContainer>
  );
}

export default BankDetails
