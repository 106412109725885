import React from 'react'
import { MenuItemContainer, RightContainer, LeftContainer } from './MenuItem.style'
import { Text } from '../../generic'
import { chevronRight } from '../../../images'

function MenuItem({ title, description, onClick }) {
  return <MenuItemContainer onClick={onClick}>
    <LeftContainer><Text bold>{title}</Text><Text>{description}</Text></LeftContainer>
    <RightContainer><img src={chevronRight} alt=">" /></RightContainer>
  </MenuItemContainer>
}

export default MenuItem
