import React, { useState } from 'react'
import { printCurrency, toDecimalPlaces } from '../../../lib/utils'
import Text from '../../generic/Text'
import { InformationRow } from '../../shared'
import { OpenOptionContainer } from '../DebtRepaymentSummary/DebtRepaymentSummary.style'
import { InformationRowLayout } from '../../shared/InformationRow'
import Button from '../../generic/Button'
import { downArrow } from '../../../images'
import { TextInputCurrency } from '../../generic/TextInput'
import { IDebtProviderData } from '../dataTransformer'
import { rightArrow } from '../../../images/itemRow'
import theme from '../../../theme.style'
import { Switch } from '../../generic'
import appConfig from '../../../appConfig'
import { ComponentPadder, RowSpacer } from '../../generic/Layouts'

// const credits = {
//   truworths: 5400.00,
//   capitec: 8000.00,
//   creditProvider1: 4400.00,
//   creditProvider2: 5400.00,
//   creditProvider3: 4600.00,
// };

//CLI CTA
const showCLICTA = appConfig.coreFeatures.creditLifeQuoting
const showSnowball = appConfig.coreFeatures.snowballActive
const showMeerkatInsurance = true

export interface ICategoryDetailAccordianProps {
  expanded?: boolean
  title: string
  //creditValue: number
  hasAddedSnowball?: boolean
  hasInsurance?: boolean
  categoryData: IDebtProviderData
}

function CategoryDetailAccordian({ expanded, title, hasAddedSnowball, categoryData }: ICategoryDetailAccordianProps) {
  const data: IDebtProviderData = categoryData

  const [isExpanded, setIsExpanded] = useState(expanded ? expanded : false)
  const [useSnowball, setUseSnowball] = useState(hasAddedSnowball ? hasAddedSnowball : false)

  console.log('cat deets:', data)

  const onSnowballChange = (event: any) => {
    const isChecked = event.target.checked
    setUseSnowball(isChecked)
  }

  const getInsurance = () => {
    //TODO: Add CTA...
    //setHasInsuranceState(true);
  }

  const addSnowball = () => {
    setUseSnowball(true)
  }

  const expandedContent = (
    <>
      <RowSpacer height={3} />
      <div>
        {!showMeerkatInsurance ? (
          <></>
        ) : (
          <>
            <OpenOptionContainer>
              <InformationRowLayout
                title={<Text>Meerkat Credit Life Insurance</Text>}
                value={
                  data.hasMeerkatInsurance ? (
                    <Text bold size={13}>{`${printCurrency(data.meerkatInsurancePremium)} pm`}</Text>
                  ) : showCLICTA ? (
                    <Button small onClick={getInsurance}>
                      Get
                    </Button>
                  ) : (
                    <></>
                  )
                }
              />
            </OpenOptionContainer>
            <RowSpacer height={3} />
          </>
        )}
        <OpenOptionContainer>
          <InformationRow title="Credit Provider" value={data.providerName} />
          <InformationRow title="Credit Agreement Type" value={data.agreementType} />
          <InformationRow title="Current Balance" value={`${printCurrency(data.currentBalance)}`} />
          <InformationRow title="Current Repayment" value={`${printCurrency(data.currentRepayment)}`} />
          {!data.meerkatInsurancePremium ? (
            <></>
          ) : (
            <InformationRow
              title="Meerkat Insurance Premium"
              value={`${printCurrency(data.meerkatInsurancePremium)}`}
            />
          )}
          <InformationRow title="Rate %" value={`${toDecimalPlaces(data.currentRate, 2)}%`} />
        </OpenOptionContainer>
        <RowSpacer height={3} />
        {!showSnowball ? (
          <></>
        ) : (
          <>
            <OpenOptionContainer>
              <InformationRowLayout
                title={
                  <Text bold size={14}>
                    Add Snowball
                  </Text>
                }
                value={<Switch onChange={onSnowballChange} checked={useSnowball} />}
              />
              <InformationRowLayout
                title={<Text size={13}>Settle your debt quicker with an additional monthly amount.</Text>}
                value={null}
              />
              <InformationRowLayout title={<Text size={13}>Recommended snowball amount</Text>} value={null} />

              <TextInputCurrency
                label={null}
                style={{ width: '100%' }}
                disabled={!useSnowball}
                InputProps={{
                  startAdornment: (
                    <Text size={16} style={{ opacity: !useSnowball ? '50%' : 'unset' }}>
                      R
                    </Text>
                  ),
                  endAdornment: (
                    <Text size={16} style={{ opacity: '50%' }}>
                      pm
                    </Text>
                  ),
                }}
                inputProps={{
                  style: { textAlign: 'right', fontSize: '16px' },
                  inputMode: 'numeric',
                }}
              />
            </OpenOptionContainer>
            <RowSpacer height={3} />
          </>
        )}
        {!showSnowball && !(showMeerkatInsurance && !data.hasMeerkatInsurance) ? (
          <></>
        ) : (
          <>
            <OpenOptionContainer style={{ alignItems: 'center' }}>
              {!showSnowball ? (
                <></>
              ) : (
                <>
                  <Button textSize={14} inverted style={{ width: '100%' }} onClick={addSnowball}>
                    Add Snowball
                  </Button>
                  <RowSpacer height={8} />
                </>
              )}
              {!(showMeerkatInsurance && !data.hasMeerkatInsurance && showCLICTA) ? (
                <></>
              ) : (
                <Button textSize={14} style={{ width: '100%' }} onClick={getInsurance}>
                  Get Meerkat Credit Life Insurance
                </Button>
              )}
            </OpenOptionContainer>
          </>
        )}
      </div>
    </>
  )

  const content = !isExpanded ? (
    <ComponentPadder padding="4px 0px">
      <ComponentPadder
        padding="7px 17px"
        backgroundColor={theme.colors.grey}
        borderRadius={4}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <InformationRowLayout
          title={<Text size={14}>{title}</Text>}
          value={
            <div>
              <Text bold span size={16}>
                {printCurrency(data.currentBalance)}
              </Text>
              <img src={rightArrow} style={{ paddingLeft: '14px', margin: 0 }} />
            </div>
          }
        />
      </ComponentPadder>
    </ComponentPadder>
  ) : (
    <ComponentPadder padding="4px 0px">
      <div style={{ borderRadius: '4px', overflow: 'hidden' }}>
        <OpenOptionContainer onClick={() => setIsExpanded(!isExpanded)}>
          <InformationRowLayout
            title={
              <Text bold size={14}>
                {title}
              </Text>
            }
            value={<img src={downArrow} style={{ paddingLeft: '14px', margin: 0 }} />}
          />
          <InformationRowLayout
            title={<Text size={10}>Credit Provider</Text>}
            value={
              <Text bold color="primary" size={18}>
                {printCurrency(data.currentBalance, true)}
              </Text>
            }
          />
        </OpenOptionContainer>
        {expandedContent}
      </div>
    </ComponentPadder>
  )

  return <>{content}</>
}
export default CategoryDetailAccordian
