import React from 'react'
import Layout from '../Layout'
import Sidebar from '../Sidebar'
import PropTypes from 'prop-types'
import { PrivateContentPane, PrivateContentContainer, PrivateSidebarContainer, PrivateHeaderContainer, PrivateLayoutContainer } from './PrivateLayout.style'
import PrivateHeader from '../PrivateHeader'
import PrivateMenuBar from '../PrivateMenuBar'
import PrivateSideMenuBar from '../PrivateSideMenuBar'

function PrivateLayout({ children, showSideBar, selectedIndex = 0 }) {
  return <Layout noHeader noFooter>
      {showSideBar ?<div style={{ position: "fixed", top: "0", left: "0" }}><PrivateSideMenuBar selectedIndex={selectedIndex} alerts /></div> : <div />}
      <PrivateContentContainer>
        {children}
      </PrivateContentContainer>
      {showSideBar ? <div style={{ position: "fixed", bottom: "0", left: "0", width: "100vw"}}>
        <PrivateMenuBar selectedIndex={selectedIndex} alerts />
      </div> : <div />}
  </Layout>
}

PrivateLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PrivateLayout
