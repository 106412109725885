import moment from 'moment'
import { isInteger, isRequired, isNumber } from './validators'

const savingsGoalMetaData = {
  EMERGENCY: {
    income: {
      type: "currency",
      label: "Net-Salary",
      validate: [isRequired, isNumber]
    }, 
    goalMonthsInFund: {
      type: "radiogroup",
      label: "Emergency Fund Goal",
      options: [
        { value: 1, label: "1 Months Salary" },
        { value: 2, label: "2 Months Salary" },
        { value: 3, label: "3 Months Salary" }
      ],
      validate: [isRequired]
    }
  },
  HOLIDAY: {
    destination: {
      type: "text",
      label: "Where are you going?",
      title: "Holiday Title",
      validate: [isRequired]
    },
    tripDate: {
      type: "date",
      label: "When would you like to go?",
      title: "Date",
      validate: [isRequired]
    }
  },
  OTHER: {
  },
  FUNERAL: {
    currentPremiums: {
      type: "currency",
      label: "How much are you paying today for funeral cover?",
      excludeFromSummary: true
    },
    coverTypes: {
      type: "number",
      label: "How many funeral cover types do you have today?",
      excludeFromSummary: true,
      validate: [isInteger]
    },
    sumAssured: {
      type: "currency",
      label: "What is your total sum assured for?",
      excludeFromSummary: true,
      validate: [isNumber, isRequired]
    },
    stopPayingAge: {
      type: "radiogroup",
      label: "When would you like to stop paying for your funeral premiums?",
      title: "Premium Holiday At",
      options: [{
        label: "55 years of age",
        value: 55      
      }, {
        label: "60 years of age",
        value: 60     
      }, {
        label: "65 years of age",
        value: 65      
      }],
      validate: [isRequired]
    }
  },
  CAR: {
    savingFor: {
      label: "I am saving up for a",
      title: "Saving For",
      type: "radiogroup",
      options: ["New Car", "Car Deposit", "Maintainance"]
    } 
  }
}

function dateFromAmount(targetAmount, existingAmount, amount) {
  if ((targetAmount - (existingAmount || 0)) <= 0) {
    return moment().valueOf()
  }
  const months = Math.max(1, Math.ceil(Math.max(1, (targetAmount - (existingAmount || 0))) / amount))
  return moment().add(months, 'month').valueOf()
}

function amountFromDate(targetAmount, existingAmount, targetDate) {
  const months = -moment().diff(targetDate, 'month') + 1
  console.log(months)
  return Math.round(Math.max(0, (targetAmount - (existingAmount || 0)) / Math.max(1, months)) * 100) / 100
}

const targetFromMetaData = ({ targetAmount, bucketType, ...chatState }) => {
  if (bucketType === "EMERGENCY") {
    const { income, goalMonthsInFund } = chatState.EMERGENCY
    return income * goalMonthsInFund
  }
  if (bucketType === "CAR") {
    return targetAmount
  }
  if (bucketType === "FUNERAL") {
    return chatState.FUNERAL.sumAssured
  }
  if (bucketType === "HOLIDAY") {
    return targetAmount
  }
  if (bucketType === "OTHER") {
    return targetAmount
  }
}

const targetDateFromMetaData = ({ targetDate, bucketType, ...chatState }) => {
  if (bucketType === "HOLIDAY") {
    return chatState.HOLIDAY.tripDate
  }
  if (bucketType === "FUNERAL") {
    return moment(chatState.birthDate).add(chatState.FUNERAL.stopPayingAge, 'years').valueOf()
  }
  return targetDate
}

export {
  savingsGoalMetaData,
  targetFromMetaData,
  targetDateFromMetaData,
  dateFromAmount,
  amountFromDate
}