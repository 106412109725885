import { navigate } from 'gatsby-link'
import React from 'react'
import { printCurrency } from '../../../lib/utils'
import Text from '../../generic/Text'
import { BackActionHeader, MokuNotePanel } from '../../shared'
import { TitleContainer } from '../DebtRepaymentSummary/DebtRepaymentSummary.style'
import { IDebtCategoryData, IDebtProviderData } from '../dataTransformer'
import theme from '../../../theme.style'
import CategoryDetailAccordian from '../CategoryDetailAccordian/CategoryDetailAccordian'
import { ComponentPadder, RowSpacer } from '../../generic/Layouts'

export interface ICategoryDetailsProps {
  debtCategoryData: IDebtCategoryData
  title: string
  description: string
  listOf: string
}

function CategoryDetails({ debtCategoryData, title, description, listOf }: ICategoryDetailsProps) {
  const data = debtCategoryData
  //const theme = useTheme();

  return (
    <>
      <ComponentPadder padding="0px 9px">
        <BackActionHeader title="My Debt Repayments" onClick={() => navigate('/debt-repayments')} />
      </ComponentPadder>
      <TitleContainer>
        <Text bold size={26}>
          {title}
        </Text>
      </TitleContainer>
      {
        data && data.obligations && data.obligations.length > 0 ? (
          <>
            <Text style={{ padding: '6px 4px' }} size={12}>
              {description}
            </Text>
            <ComponentPadder padding="12px 8px" backgroundColor={theme.colors.alertRedBackground} borderRadius={4}>
              <Text bold size={16} style={{ textAlign: 'right' }}>
                {printCurrency(data.totalDebt, true)}
              </Text>
            </ComponentPadder>
            <Text size={10} style={{ textAlign: 'right', padding: '8px 0px' }}>
              Estimated*
            </Text>
            <ComponentPadder padding="8px 0px 0px 0px">
              <Text bold size={16}>
                My List of {listOf}
              </Text>
            </ComponentPadder>

            <RowSpacer height={16} />

            <ComponentPadder padding="0px 0px 16px 0px">
              {Object.keys(data.obligations).map((key: string) => {
                const ob: IDebtProviderData = data.obligations[key as any]
                console.log('obligation?', ob)
                return <CategoryDetailAccordian key={key} title={ob.providerName} categoryData={ob} />
              })}
            </ComponentPadder>
          </>
        ) : (
          <MokuNotePanel title={'No Debt'} description={'You have no debt in this category'}></MokuNotePanel>
        )
        // <>no data</>
      }
    </>
  )
}

export default CategoryDetails
