import React from 'react'
import { ProgressBarFull, ProgressBarDone, ProgressBarContainer, ProgressBarTextContainer, ProgressTextContainer, ProgressPercent, ProgressRemaining } from './ProgressBar.style'
import { Text } from '../../generic'
import moment from 'moment'

function ProgressBar({ percentage = 20, targetDate }) {
  const monthsLeft = targetDate ? moment(targetDate).diff(moment(), 'months') : null
  
  return (  
    <ProgressBarContainer>
      {/*<ProgressBarTextContainer percentage={Math.min(100, percentage)}>
        <Text bold style={{ lineHeight: "20px", fontSize: "15px" }}>{Math.round(percentage)}%</Text>
      </ProgressBarTextContainer>*/}
      <ProgressBarFull>
        <ProgressBarDone percentage={Math.min(100, percentage)} color="primary" />
      </ProgressBarFull>
      <ProgressTextContainer>
        <ProgressPercent><Text small>{Math.round(Math.min(100, percentage), 0)} %</Text></ProgressPercent>
        <ProgressRemaining>{(monthsLeft || monthsLeft === 0) && <Text small>{monthsLeft} months {monthsLeft > 0 ? "left" : "over"}</Text>}</ProgressRemaining>
      </ProgressTextContainer>
    </ProgressBarContainer>
  )
}

export default ProgressBar