import React, { useState } from 'react'
import { LoanInfoContainer, Separator } from '../../insurance/MyLoan/MyLoan.style'
import { InfoRow } from '../../insurance/MyLoan/MyLoan'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { ActivityIndicator, Text } from '../../generic'
import { printCurrency, printDate } from '../../../lib/utils'
import { BackActionHeader  } from '../../shared'
import { navigate } from 'gatsby'
import MemberDetailsCard from '../MemberDetailsCard'
import ThirdPartyFuneralWizard from '../ThirdPartyFuneralWizard'

const FUNERAL_POLICY_INFO = gql`
  query ($id: String!) {
    clientobligation(id: $id) {
      id
      clientId
      funeralPolicyInfo {
        companyName
        coverAmount
        livesCovered {
          memberType
        }
        startDate
        premiumAmount
      }
    }
  }
`

const memberTypeText = {
  POLICY_HOLDER: 'Me',
  SPOUSE: 'My Spouse',
  CHILD: 'My Children',
  PARENT: 'My Parents',
  EXTENDED: 'My Extended Family'
}

const deriveCoverType = (livesCovered) => {
  const covered = {}
  livesCovered.forEach(({ memberType}) => covered[memberType] = true)
  if (covered.EXTENDED) {
    if (covered.SPOUSE || covered.CHILD) {
      return "My Family and Extended Family"
    } else if (covered.PARENT) {
      return "Me and My Parents"
    }
  } else {
    if (covered.SPOUSE) {
      if (covered.PARENT) {
        return "My Family and My Parents"
      } else {
        return "My Family"
      }
    } else {
      if (covered.CHILD) {
        if (covered.PARENT) {
          return "Me, My Parents and My Children"
        } else {
          return "Me and My Children"
        }
      } else {
        if (covered.PARENT) {
          return "Me and My Parents"
        } else {
          return "Myself"
        }
      }
    }
  }
}

function MyThirdPartyFuneralPolicy({ id }) {
  const { data, loading, error } = useQuery(FUNERAL_POLICY_INFO, { variables: { id }})  
  const [editOpen, setEditOpen] = useState(false)

  const [memberTypeExpanded, setMemberTypeExpanded] = useState({
    POLICY_HOLDER: false,
    SPOUSE: false,
    CHILD: false,
    PARENT: false,
    EXTENDED: false
  })
  
  if (loading) { return <ActivityIndicator /> }

  if (error) {
    return error
  }

  const { companyName, coverAmount, livesCovered, startDate, premiumAmount, policyNumber } = data.clientobligation.funeralPolicyInfo

  const livesCoveredByType = {}

  livesCovered.filter(member => member.memberType !== "BENEFICIARY").forEach(member => {
    if (!livesCoveredByType[member.memberType]) {
      livesCoveredByType[member.memberType] = []
    }
    livesCoveredByType[member.memberType].push(member)
  })

  return <>
    <BackActionHeader title="My Funeral Policies" onClick={() => navigate('/funeral')} />
    <Text size="26" bold>{companyName} Policy</Text>
    <p />
    <ThirdPartyFuneralWizard id={id} clientId={data && data.clientobligation && data.clientobligation.clientId}  open={editOpen} setOpen={setEditOpen} refetchQueries={[{ query: FUNERAL_POLICY_INFO, variables: { id } }]} />
    <LoanInfoContainer>
      <InfoRow title={`${companyName} Policy`} titleColor="primary" value={<Text primary clickable onClick={() => setEditOpen(true)}>Edit</Text>} />
      <Separator />
      <InfoRow title="Cover Amount" value={printCurrency(coverAmount)} />
      <Separator />
      <InfoRow title="Start Date" value={printDate(startDate)} />
      <InfoRow title="Cover Type" value={deriveCoverType(livesCovered)} />
      <InfoRow title="Monthly Contribution" value={printCurrency(premiumAmount, 2)} />
    </LoanInfoContainer>
    <p />
    {/*<Text size="14" bold>Who's covered?</Text>
    <p />
    {Object.keys(livesCoveredByType).map(memberType => <ToggleProductOption title={memberTypeText[memberType]} expanded={memberTypeExpanded[memberType]} setExpanded={value => setMemberTypeExpanded({ ...memberTypeExpanded, [memberType]: value })} noIndicator>
        {livesCoveredByType[memberType].map(member => <MemberDetailsCard member={member} />)}
      </ToggleProductOption>
    )}
    <p />*/}
    <div style={{ height: 70 }} />
  </>
}

export default MyThirdPartyFuneralPolicy
