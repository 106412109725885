import React, { useState } from 'react'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { ActivityIndicator, Text, PercentagePie, Link, Button } from '../../generic'
import { Row, Col } from '../../generic/grid'
import { printCurrency } from '../../../lib/utils'
import SavingsCard from '../SavingsCard'
import moment from 'moment'
import { useStaticQuery, graphql } from 'gatsby'
import EditBucketModal from '../EditBucketModal'
import WithdrawalModal from '../WithdrawalModal2'
import Img from 'gatsby-image'

const cardText = SavingsCard.cardText

const GET_SAVINGS_ITEM_INFO = gql`
  query GetSavingsItemInfo($id: String) {
    myClient {
      id
      idNumber
      bankAccounts {
        id
        bankName
        accountNo
      }
      savings {
        id
        bucketInfo {
          id
          bucketType
          amount
          percentageFull
        }
        bucketInfoById(id: $id) {
          id
          bucketType
          description
          name
          amount
          targetAmount
          targetDate
          percentageFull
          desiredSavings
          totalContributionsAmount
          totalWithdrawalsAmount
        }
        paymentBankAccountId
      }
    }
  }
`

function InformationRow({ title, value }) {
  return <Row>
    <Col style={{ flex: 2 }}><Text bold>{title}</Text></Col>
    <Col><Text>{value}</Text></Col>
  </Row>     
}

function SavingsBucket({ id }) {
  const imageData = useStaticQuery(graphql`{
    blob: file(relativePath: { eq: "Blob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    EMERGENCY: file(relativePath: { eq: "EmergencyBlob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HOLIDAY: file(relativePath: { eq: "HolidayBlob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    EDUCATION: file(relativePath: { eq: "EducationBlob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    CAR: file(relativePath: { eq: "CarBlob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HOUSE: file(relativePath: { eq: "HousingBlob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HEALTH: file(relativePath: { eq: "HealthBlob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    RETIREMENT: file(relativePath: { eq: "RetirementBlob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    OTHER: file(relativePath: { eq: "OtherBlob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    YEAR_END: file(relativePath: { eq: "DecemberBlob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    FUNERAL: file(relativePath: { eq: "FuneralBlob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
  `)

  const [withdrawalOpen, setWithdrawalOpen] = useState(false)
  const [editBucketOpen, setEditBucketOpen] = useState(false)

  const { data, error, loading } = useQuery(GET_SAVINGS_ITEM_INFO, { variables: { id } })

  if (loading) {
    return <ActivityIndicator large="true"/>;
  }

  if (error) {
    return <div>Error! {error.message}</div>;
  }
  
  const bucketInfo = data.myClient.savings.bucketInfoById
  const bucketId = bucketInfo.id
  const savingsId = data.myClient.savings.id
  const clientId = data.myClient.id
  const amountLeft = bucketInfo.targetAmount - bucketInfo.amount
  const estMonthsLeft = bucketInfo.desiredSavings && Math.ceil(amountLeft / bucketInfo.desiredSavings)
  const buckets = data.myClient.savings.bucketInfo
  const bankAccounts = data.myClient.bankAccounts
  const paymentBankAccountId = data.myClient.savings.paymentBankAccountId

  return <>
    <Row style={{ alignItems: "center" }}><div style={{ width: "5vw", height: "5vw", marginRight: "14px", marginTop: "7px" }}><Img fluid={imageData[bucketInfo.bucketType || "OTHER"].childImageSharp.fluid} alt={bucketInfo.bucketType} /></div><Text heading>{cardText[bucketInfo.bucketType || "OTHER"] || cardText["OTHER"]}</Text>
    </Row>
    {bucketInfo.description && <Text>{bucketInfo.description}</Text>}
    <p />
    <Text medium bold primary>Goal</Text>
    <Text primary>{printCurrency(bucketInfo.targetAmount)}</Text>
    <Row style={{ flexWrap: "wrap" }}>
      <Col style={{ minWidth: "300px" }}>
        <PercentagePie width={300} height={300} percentage={bucketInfo.percentageFull} innerText={`${printCurrency(amountLeft, 0)} to go`} />
        <Text medium bold>You&apos;ve saved {printCurrency(bucketInfo.amount)} so far</Text>
        <p />
      </Col>
      <Col style={{ minWidth: "300px" }}>
        <InformationRow title="Contribution per month" value={bucketInfo.desiredSavings ?printCurrency(bucketInfo.desiredSavings) : '-'} />
        <InformationRow title="Estimated Months left" value={estMonthsLeft ? `${estMonthsLeft} months` : '-'} />
        <InformationRow title="Estimated date reaching target" value={estMonthsLeft ? moment().add(estMonthsLeft, 'month').format('YYYY-MM-DD') : '-'} />
        <InformationRow title="Target date" value={bucketInfo.targetDate ? moment(bucketInfo.targetDate).format('YYYY-MM-DD') : '-'} />
        <InformationRow title="Time until target date" value={bucketInfo.targetDate ? `${moment(bucketInfo.targetDate).diff(moment(), 'month')} months` : '-'} />
        <InformationRow title="Total Contributions" value={printCurrency(bucketInfo.totalContributionsAmount)} />
        <InformationRow title="Total Withdrawals" value={printCurrency(bucketInfo.totalWithdrawalsAmount)} />
        <p />
      </Col>
    </Row>
    <Row>
      <Button onClick={() => setEditBucketOpen(true)}>Edit Goal</Button>
      <div style={{ width: "28px" }} />
      <Button disabled={bucketInfo.amount === 0} onClick={() => setWithdrawalOpen(true)}>Withdraw</Button>
    </Row>
    <p />
    <Text heading>Save now towards your goal</Text>
    <p />
    <Text>To add to your savings, set up an EFT using the following details</Text><br />
    <Col style={{ maxWidth: "350px" }}>
      <Row>
        <Col><Text bold>Bank Name</Text></Col>
        <Col><Text>Nedbank</Text></Col>
      </Row>
      <Row>
        <Col><Text bold>Account Number</Text></Col>
        <Col><Text>1186772700</Text></Col>
      </Row>
      <Row>
        <Col><Text bold>Reference</Text></Col>
        <Col><Text>{data.myClient.idNumber}</Text></Col>
      </Row>
    </Col>
    <p />
    <Link to="/savings"><Text bold primary underline>Back</Text></Link>
    <WithdrawalModal
      open={withdrawalOpen}
      setOpen={setWithdrawalOpen}
      clientId={clientId}
      bucketId={bucketId}
      savingsId={savingsId}
      bankAccounts={bankAccounts}
      paymentBankAccountId={paymentBankAccountId}
      refetchQueries={[{ query: GET_SAVINGS_ITEM_INFO, variables: { id } }]}
      buckets={buckets}
    />
    <EditBucketModal open={editBucketOpen} setOpen={setEditBucketOpen} bucketId={bucketId} savingsId={savingsId} refetchQueries={[{ query: GET_SAVINGS_ITEM_INFO, variables: { id } }]} />
  </>
}

export default SavingsBucket
