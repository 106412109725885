import React, { Fragment, useState } from 'react'
import gql from 'graphql-tag'
import { GraphQLFormProvider, FormField, SubmitFormButton, FieldValue } from 'react-form-helper'
import { renderLabel, renderDisplay, renderInput } from '../../generic/form'
import { Button, Text } from '../../generic'
import { Row, Col } from '../../generic/grid'
import { isRequired, isNumber } from '../../../lib/validators'
import { EditBucketFormContainer } from './EditBucketForm.style'

import SavingsCard from '../SavingsCard'
const cardText = SavingsCard.cardText


const UPDATE_BUCKET = gql`
  mutation UpdateBucket($id: String!, $input: SavingsBucketInput!) {
    updateSavingsBucket(id: $id, input: $input) {
      id
      name
      bucketType
      targetAmount
      targetDate
      desiredSavings
    }
  }
`

const GET_BUCKET = gql`
  query GetSavingsBucket($id: String!) {
    getSavingsBucket(id:$id) {
      id
      name
      bucketType
      targetAmount
      targetDate
      desiredSavings
    }
  }
`

function Layout({ closeModal }) {
  return (
    <EditBucketFormContainer>
      <center>
        <FieldValue name="bucketType" renderField={({ input }) => <Text heading>{cardText[input.value]}</Text>} />
      </center>
      <center><Text>Edit Goal</Text></center>
      <p />
      <FormField name={"targetAmount"} /><br />
      <FormField name={"targetDate"} /><br />
      <FormField name={"desiredSavings"} /><br />
      <Row>
        <Button inverted onClick={() => closeModal(true) }>Cancel</Button>
        <div style={{ width: "14px" }} />
        <SubmitFormButton renderSubmitButton={({ submit, submitting }) => <Button submitting={submitting} onClick={ () => submit()  }>Save</Button>} />
      </Row>
    </EditBucketFormContainer>
  )
}


const fields = {
  "targetAmount": {
    label: "Target Amount",
    type: "currency",
    validate: [isNumber]
  },
  "targetDate": {
    label: "Target Date",
    type: "date",
    validate: [isRequired]
  },
  "desiredSavings": {
    label: "Monthly Contribution",
    type: "text",
    validate: [isNumber]
  },
  "bucketType": {
    strip: true
  }
}

function EditBucketForm({ id, refetchQueries, closeModal }) {
  return (
    <GraphQLFormProvider
      query={GET_BUCKET}
      updateMutation={UPDATE_BUCKET}
      initialValuesFromData={data => data.getSavingsBucket}
      name="savings"
      id={id}
      fields={fields}
      mode="edit"
      InputFormLayout={props => <Layout {...props} closeModal={closeModal}  />}
      renderLabel={renderLabel}
      renderDisplay={renderDisplay}
      renderInput={renderInput}
      refetchQueries={refetchQueries}
      afterSubmit={() => closeModal()}
    />
  )
}

export default EditBucketForm
