import React from 'react'
import BlogPostCard, { IBlogPostCardProps } from '../../shared/BlogPostCard/BlogPostCard'
import { BlogCardsContainer } from '../../shared/BlogPostCard/BlogPostCard.style'

interface IBlogPostCardsProps {
  blogs: IBlogPostCardProps[]
}

function BlogPostCards({ blogs }: IBlogPostCardsProps) {
  return <BlogCardsContainer>{blogs && blogs.map((blog, i) => <BlogPostCard key={i} {...blog} />)}</BlogCardsContainer>
}

export default BlogPostCards
