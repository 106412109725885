import React from 'react'
import { useTheme } from 'emotion-theming'

function CustomPoint({ x, y, label, backgroundColor, textColor, below }) {
  const theme = useTheme()

  return <svg>
    <defs>
      <filter id="boxShadow">
        <feGaussianBlur in="SourceAlpha" stdDeviation="5"/>
        <feOffset dx="0" dy="4" result="offsetblur"/> 
        <feComponentTransfer>
          <feFuncA type="linear" slope="0.05"/>
        </feComponentTransfer>
        <feMerge> 
          <feMergeNode/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
    </defs>
    {below ? (
    <g filter="url(#boxShadow)">
      <rect x={x - 45} y={y + 6.31} width={90} height={24} rx={2} ry={2} style={{ fill: theme.colors[backgroundColor], stroke: theme.colors.white, strokeWidth: 1, boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)" }} /> 
      <rect x={x - 5.31} y={y + 4} width={8} height={8} rx={2} transform={`rotate(45 ${x} ${y + 9.31})`} style={{ fill: theme.colors[backgroundColor], stroke: theme.colors.white, strokeWidth: 1, boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)"  }} /> 
      <rect x={x- 6.31} y={y + 6.81} width={11.62} height={7.31} fill={theme.colors[backgroundColor]} />
    </g> ) : (
    <g filter="url(#boxShadow)">
      <rect x={x - 45} y={y - 30} width={90} height={24} rx={2} ry={2} style={{ fill: theme.colors[backgroundColor], stroke: theme.colors.white, strokeWidth: 1, boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)" }} /> 
      <rect x={x - 5.31} y={y - 10.62} width={8} height={8} rx={2} transform={`rotate(45 ${x} ${y - 5.31})`} style={{ fill: theme.colors[backgroundColor], stroke: theme.colors.white, strokeWidth: 1, boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)"  }} /> 
      <rect x={x-6.31} y={y-13.82} width={11.62} height={7.31} fill={theme.colors[backgroundColor]} />
    </g>  
    )}
    <text textAnchor="middle" x={x} y={below ? y + 21.5 : y - 15} fontSize={8} fontFamily={theme.fontFamily} fill={textColor ? theme.colors[textColor] : theme.colors.white}>{label}</text>
  </svg>
}

export default CustomPoint
