import React, { useState } from 'react'
import { ToggleProductOptionContainer, ToggleProductOptionTitleContainer, AlertNotification, ExpanderContainer, TitleTextContainer, ContentContainer } from './ToggleProductOption.style'
import { Switch, Text } from '../../generic'
import { downArrow } from '../../../images'
import { rightArrow } from '../../../images/itemRow'

function ToggleProductOption({ title, showSwitch, expanded, alert, setExpanded, children, color, noIndicator }) {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const arrow = isExpanded ? downArrow : rightArrow;

  const setExpandedFunc = setExpanded ? setExpanded : ()=>{};

  return <ToggleProductOptionContainer>
    <ToggleProductOptionTitleContainer color={color} noIndicator={noIndicator} onClick={() => {
        setIsExpanded(!isExpanded);
        setExpandedFunc(!isExpanded);
      }}>
      <AlertNotification alert={alert} />
      <TitleTextContainer><Text size={14}>{title}</Text></TitleTextContainer>
      <ExpanderContainer>
        {showSwitch ? <Switch checked={expanded} onChange={e => setExpandedFunc(e.target.checked)} /> : <img src={arrow} style={{ margin: 0 }} />}
      </ExpanderContainer>
    </ToggleProductOptionTitleContainer>
    
    <ContentContainer expanded={isExpanded}>
      {isExpanded && children}
    </ContentContainer>
    
  </ToggleProductOptionContainer>
}

export default ToggleProductOption
