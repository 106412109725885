import React, { useState } from 'react'
import { LoanInfoContainer, LoanInfoRow, Separator, RowLabel, RowValue } from './MyLoan.style'
import { ActivityIndicator, Text, ValueItem, Label, Button } from '../../generic'
import { Row, Col } from '../../generic/grid'
import { useQuery } from 'react-apollo'
import { CREDIT_LIFE_INFO } from '../MyCreditLife/MyCreditLife'
import { prettyText, printCurrency } from '../../../lib/utils'
import { MokuNotePanel } from '../../shared'
import UpdateLoanBalanceModal from '../UpdateLoanBalanceModal/UpdateLoanBalanceModal'
import moment from 'moment'
import CreditLifeQuickQuote from '../CreditLifeQuickQuote'

export function InfoRow({ title, titleColor, value }) {
  return <LoanInfoRow>
    <RowLabel>{title instanceof String || typeof title === 'string' ? <Text size={13} color={titleColor}>{title}</Text> : title}</RowLabel>
    {value && <RowValue><Text size={13} bold>{value}</Text></RowValue>}
  </LoanInfoRow>
}

function MyLoan({ id }) {
  const { data, loading, error } = useQuery(CREDIT_LIFE_INFO)
  const [updateBalance, setUpdateBalance] = useState(false)
  const [open, setOpen] = useState(false)

  if (loading) {
    return <ActivityIndicator />
  }

  const insurance = data.myClient.creditLife.obligationInsurances.find(o => o.id === id)

  return <>
    <CreditLifeQuickQuote open={open} setOpen={setOpen} />
    <UpdateLoanBalanceModal open={updateBalance} setOpen={setUpdateBalance} id={id} balance={insurance.amount} />
    <Text bold size={26}>{prettyText(insurance.obligation.description)}</Text>
    <Row style={{ marginLeft: "-4px", marginRight: "-4px" }}>
      <ValueItem label="Loan Balance" value={printCurrency(insurance.obligation.currentBalance || insurance.amount)} actionText={/*"Update Balance" onAction={() => setUpdateBalance(true)}*/""} large color="primary" />
    </Row>
    <Row style={{ marginLeft: "-4px", marginRight: "-4px" }}>
      <ValueItem label="Next Premium" value={printCurrency(insurance.premiumAmount)} />
      <ValueItem label="Remaining Terms" value={`${insurance.obligation.remainingTerm} months`} />
    </Row>
    <LoanInfoContainer>
      <InfoRow title="Status" value={<Label title="Active" color="primary" textColor="primary" />} />
      <Separator />
      <InfoRow title="Original Balance" value={printCurrency(insurance.obligation.originalBalance)} />
      <InfoRow title="Est. Remaining Cover Amount" value={printCurrency(insurance.obligation.currentBalance || insurance.amount)} />
      {/*<InfoRow title="Est. Premium Back" value="R 967.20" />*/}
      <Separator />
      <InfoRow title="Your next premium" value={printCurrency(insurance.premiumAmount)} />
      <InfoRow title="Next Premium Date" value={moment(data && data.myClient.creditLife.nextPaymentDate).format('D MMMM YYYY')} />
      <Separator />
      <InfoRow title="Cover Includes" />
      <LoanInfoRow>
        <Col>
          <Row style={{ marginBottom: "8px" }}>
            <Col style={{ marginRight: "4px" }}><Label inverted color="dark" title="Death" textColor="primary" /></Col>
            <Col style={{ marginLeft: "4px" }}><Label inverted color="dark" title="Permanent Disability" textColor="primary" /></Col>
          </Row>
          <Row>
            <Col style={{ marginRight: "4px" }}><Label inverted color="dark" title="Temporary Disability" textColor="primary" /></Col>
            <Col style={{ marginLeft: "4px" }}><Label inverted color="dark" title="Retrenchment" textColor="primary" /></Col>
          </Row>
        </Col>
      </LoanInfoRow>
      <Separator />
      {/*<LoanInfoRow>
        <MokuNotePanel title="Moku Tip: Keep your debts up to date" description=" Edit your balance and upload your bank statement as proof. You can pay-off your debt quicker by adding Snowball. If you already settled your debt you can click on ‘I have settled the debt." color="grey" />
      </LoanInfoRow>*/}
    </LoanInfoContainer>
    <p />
    <center>
      <Button inverted title="Get a Quick Quote" onClick={() => setOpen(true)} />
      <p />
      {/*<Button title="I have settled this Debt" onClick={() => setUpdateBalance(true) }/>*/}
    </center>
  </>
}

export default MyLoan
