import React, { useState } from 'react'
import { LoanInfoContainer, Separator } from '../../insurance/MyLoan/MyLoan.style'
import { InfoRow } from '../../insurance/MyLoan/MyLoan'
import { Text, Button, ActivityIndicator } from '../../generic'
import { MokuNotePanel } from '../../shared'
import ClearanceCertificateObligationDetails from '../ClearanceCertificateObligationDetails'
import { useQuery, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import { printCurrency } from '../../../lib/utils'
import TrackStatusModal from '../TrackStatusModal'
import { RowSpacer } from '../../generic/Layouts'
import UploadDocument from '../../profile/UploadDocument'
// import {} from './MyClearanceCertificate.style'
import { getCurrentItem } from '../TrackStatusModal/TrackStatusModal'
import ClearanceCertificateSignature from '../ClearanceCertificateSignature'
import { navigate } from 'gatsby'
import ClearanceCertificatePayment from '../ClearanceCertificatePayment'
import OzowStatus from '../../Ozow'


const UPDATE_IDENTITY_DOCUMENT_ID = gql`
  mutation ($id: String!, $clientIdDocumentId: ObjID, $secondaryApplicantIdDocumentId: ObjID) {
    updateClearanceCertificate(id: $id, input: { clientIdDocumentId: $clientIdDocumentId, secondaryApplicantIdDocumentId: $secondaryApplicantIdDocumentId }) {
      id
      forObligations {
        id
        status
      }
      previousDebtCounsellor {
        fullName
      }
      amountPaid
      amount
      settlementLettersRequested
      outstandingRequirements
      state
      clientId
    }
  }
`

const GET_CLEARANCE_CERTIFICATE = gql`query ($id: String!) {
  clearancecertificate(id: $id) {
    id
    forObligations {
      id
      status
    }
    previousDebtCounsellor {
      fullName
    }
    certificateId
    amountPaid
    amount
    settlementLettersRequested
    outstandingRequirements
    state
    clientId
    secondaryApplicantId
    debitOrderId
  }
}`

export const friendlyState = {
  APPLICATION: "Application Made",
  CLIENT_INFO_REQUIRED: "Information Required",
  CLIENT_SETTLEMENT_LETTERS_REQUIRED: "Upload Settlement Letters",
  AWAITING_DOCUMENT_VERIFICATION: "Documents Being Verified",
  DC_TRANSFER_IN_PROGRESS: "DC Transfer Requested",
  DC_TRANSFER_REJECTED: "DC Transfer Failed", 
  DC_TRANSFER_FAILED: "DC Transfer Failed",
  AWAITING_CREDITORS: "Waiting for Creditor Response",
  AWAITING_FINAL_PAYMENT: "Awaiting Final Payment",
  CERTIFICATE_ISSUED: "Certificate Issued",
  CANCELLED: "Cancelled",
  PROCESSING: "Application being Processed"
}


function MyClearanceCertificate({ id }) {
  const [signClearanceCertificateOpen, setSignClearanceCertificateOpen] = useState(false)
  const [spouseSignClearanceCertificateOpen, setSpouseSignClearanceCertificateOpen] = useState(false)
  const [idDocOpen, setIdDocOpen] = useState(false);
  const [spouseIdDocOpen, setSpouseIdDocOpen] = useState(false);
  const [ozowOpen, setOzowOpen] = useState(false);
  const [trackStatusOpen, setTrackStatusOpen] = useState(false)
  const [paymentOpen, setPaymentOpen] = useState(false)
  const { loading, data, error } = useQuery(GET_CLEARANCE_CERTIFICATE, { variables: { id } })
  const [updateIdentityDocumentId] = useMutation(UPDATE_IDENTITY_DOCUMENT_ID)

  if (loading) { return <ActivityIndicator /> }

  if (error) return error

  if (data && !data.clearancecertificate) {
    return <Text>Not Found</Text>
  }

  console.log("DATA=", data)

  const { clearancecertificate: clearanceCertificate } = data

  let lettersAllReceived = true

  clearanceCertificate.forObligations.forEach(o => {
    if (!["EXCLUDED", "PRESCRIBED", "PAID_UP_CLIENT_PROOF_PROVIDED", "PAID_UP_CLIENT_PROOF_ACCEPTED", "PAID_UP_CREDITOR_LETTER", "PAID_UP_CREDIT_BUREAUX"].includes(o.status)) {
      lettersAllReceived = false
    }
  })

  if (clearanceCertificate.state === "CANCELLED") {
    return <>
      <Text size="26" bold>My Clearance Certificate</Text>
      <p />
      <MokuNotePanel title="Invalid Clearance Certificate Link" description="This link is no longer valid or has changed. Please access My Clearance Certificate from your dashboard to view your progress.">
        <Button onClick={() => navigate('/')}>My Dashboard</Button>
      </MokuNotePanel>
      <p />
    </>
  }

  const item = getCurrentItem(clearanceCertificate.forObligations || [], clearanceCertificate.outstandingRequirements || [], clearanceCertificate.state)

  


  return <>
    <ClearanceCertificateSignature open={signClearanceCertificateOpen} setOpen={setSignClearanceCertificateOpen} refetchQueries={[{ query: GET_CLEARANCE_CERTIFICATE, variables: { id } }]} id={id} />
    <ClearanceCertificateSignature secondaryApplicant open={spouseSignClearanceCertificateOpen} setOpen={setSpouseSignClearanceCertificateOpen} refetchQueries={[{ query: GET_CLEARANCE_CERTIFICATE, variables: { id } }]} id={id} />
    <UploadDocument open={idDocOpen} setOpen={setIdDocOpen} description={"Identity Document"} type={"RSA_ID"} clientId={clearanceCertificate.clientId} source={"CLEARANCE_CERTIFICATE_NOTIFICATION"} afterSubmit={({ data }) => updateIdentityDocumentId({ variables: { id, clientIdDocumentId: data.createClientDocument.id } })} refetchQueries={[{query: GET_CLEARANCE_CERTIFICATE, variables: { id }}]}/>
    <UploadDocument open={spouseIdDocOpen} setOpen={setSpouseIdDocOpen} description={"Spouse Identity Document"} type={"RSA_ID"} clientId={clearanceCertificate.clientId} forClientId={clearanceCertificate.secondaryApplicantId} source={"CLEARANCE_CERTIFICATE_NOTIFICATION"} afterSubmit={({ data }) => updateIdentityDocumentId({ variables: { id, secondaryApplicantIdDocumentId: data.createClientDocument.id } })} refetchQueries={[{query: GET_CLEARANCE_CERTIFICATE, variables: { id }}]}/>
    <TrackStatusModal open={trackStatusOpen} setOpen={setTrackStatusOpen} state={clearanceCertificate.state} outstandingRequirements={clearanceCertificate.outstandingRequirements} forObligations={clearanceCertificate.forObligations} />
    <ClearanceCertificatePayment open={paymentOpen} setOpen={setPaymentOpen} id={clearanceCertificate.id} />
   <OzowStatus open={ozowOpen} setOpen={setOzowOpen} url={window.location.href} id={id} productTitle={'Meerkat Clearance Certificate'} retryPaymentModal={setPaymentOpen} successMessage={'Thanks for your payment. We will proceed with your application for your clearance certificate.'} failMessage={'We have been unable to process your payment. Please try again.'}   />
    <Text size="26" bold>My Clearance Certificate</Text>
    {clearanceCertificate.state !== "AWAITING_FINAL_PAYMENT" && <>
      <p />
      <MokuNotePanel title={item.title} description={item.description} />
    </>}
    <p />
    {clearanceCertificate.outstandingRequirements.includes("Client ID Document Required") && <>
      <MokuNotePanel
        title={'Moku Tip: Upload ID'}
        description={"You have not yet uploaded your ID which we need to complete your clearance certificate application. Upload now."}
        footer={
          <>
            <RowSpacer height={8} />
            <center style={{ width: '100%' }}>
              <RowSpacer height={8} />
              <Button inverted onClick={() => setIdDocOpen(true)}>
                Upload ID
              </Button>
            </center>
            <RowSpacer height={8} />
          </>
        }
      />
      <p/>
    </>}
    {clearanceCertificate.outstandingRequirements.includes("Secondary Applicant's ID Document Required") && <>
      <MokuNotePanel
        title={'Moku Tip: Upload ID'}
        description={"You have not yet uploaded your spouse's ID which we need to complete your clearance certificate application. Upload now."}
        footer={
          <>
            <RowSpacer height={8} />
            <center style={{ width: '100%' }}>
              <RowSpacer height={8} />
              <Button inverted onClick={() => setSpouseIdDocOpen(true)}>
                Upload Spouse ID
              </Button>
            </center>
            <RowSpacer height={8} />
          </>
        }
      />
      <p/>
    </>}

    {clearanceCertificate.outstandingRequirements.includes("Applicant Signature Required") && <>
      <MokuNotePanel
        title={'Moku Tip: Sign your Application'}
        description={"You have not yet signed your application. We need this need to complete your clearance certificate application"}
        footer={
          <>
            <RowSpacer height={8} />
            <center style={{ width: '100%' }}>
              <RowSpacer height={8} />
              <Button inverted onClick={() => setSignClearanceCertificateOpen(true)}>
                Sign Now
              </Button>
            </center>
            <RowSpacer height={8} />
          </>
        }
      />
      <p/>
    </>}

    {clearanceCertificate.outstandingRequirements.includes("Secondary Applicant's Signature Required") && <>
      <MokuNotePanel
        title={'Moku Tip: Your spouse needs to sign your Application'}
        description={"Your spouse has not yet signed your application. We need this need to complete your clearance certificate application"}
        footer={
          <>
            <RowSpacer height={8} />
            <center style={{ width: '100%' }}>
              <RowSpacer height={8} />
              <Button inverted onClick={() => setSpouseSignClearanceCertificateOpen(true)}>
                Sign Now
              </Button>
            </center>
            <RowSpacer height={8} />
          </>
        }
      />
      <p/>
    </>}
    {clearanceCertificate.state === "AWAITING_FINAL_PAYMENT" && !clearanceCertificate.debitOrderId && <>
      <MokuNotePanel
        title={'Moku Tip: Make your payment'}
        description={"We are ready to issue your clearance certificate. Please make a payment to complete the process."}
        footer={
          <>
            <RowSpacer height={8} />
            <center style={{ width: '100%' }}>
              <RowSpacer height={8} />
              <Button inverted onClick={() => setPaymentOpen(true)}>
                Pay Now
              </Button>
            </center>
            <RowSpacer height={8} />
          </>
        }
      />
      <p/>
    </>}
    <LoanInfoContainer>
      <InfoRow title={<Text>Status: <Text span bold>{friendlyState[clearanceCertificate.state]}</Text></Text>} value={<Button onClick={() => setTrackStatusOpen(true)} small inverted>Track</Button>}/>
      <Separator />
      <InfoRow title="Number of Loans" value={clearanceCertificate.forObligations.filter(o => o.status !== "EXCLUDED").length || "0"} />
      <InfoRow title="Debt Counsellor" value={clearanceCertificate.previousDebtCounsellor.fullName} />
      <InfoRow title="Amount Paid" value={printCurrency(clearanceCertificate.amountPaid)} />
      <InfoRow title="Expected Cost" value={printCurrency(clearanceCertificate.amount)} />
      <InfoRow title="Clearance Letters Meerkat Requested" value={clearanceCertificate.settlementLettersRequested || "0"} />
      <InfoRow title="All Clearance Letters Received" value={lettersAllReceived ? "Yes" : "No"} />
    </LoanInfoContainer>
    <p />
    {clearanceCertificate.forObligations.length > 0 &&
    <> 
      <Text size="16" bold>My Debt Review Loans</Text>
      <p />
    </>}
    <ClearanceCertificateObligationDetails chatState={{ clearanceCertificateId: id }} />
    <p />
    <center>
     {clearanceCertificate.state == "CERTIFICATE_ISSUED" && <Button style={{"margin": "15px"}} onClick={() =>  navigate(`/documents/clearance-certificate/${clearanceCertificate.certificateId}`)}>Download Certificate </Button>}
    </center>
    <center>
      <Button onClick={() => setTrackStatusOpen(true)}>Track My Status</Button>
    </center>
  </>
}

export default MyClearanceCertificate
