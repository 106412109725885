import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { device } from '../../theme.style'

const ContactFormContainer = styled.div`
  width: 652px;
  height: ${props => props.willContact && !props.inline ? "350px" : "760px"};
  background-color: ${props => props.theme.colors.grey};
  position: relative;

  ${props => props.willContact && css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}

  ${device.tablet`
    max-width: 450px;
    max-height: 700px;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: white;
  `}
`

const ContactFormHeaderContainer = styled.div`
  height: 80px;

  ${device.tablet`
    display: none; 
  `}
`

const CloseImage = styled.img`
  position: absolute;
  right: 30px;
  top: 20px;
  z-index: 1002;

  ${device.tablet`
    display: none;
  `}
`

const MobileCloseImage = styled.img`
  display: none;

  ${device.tablet`
    display: block;
    position: absolute;
    left: 29px;
    top: 28px;
    z-index: 1002;
  `}
`

const MokuContainer = styled.div`
  width: 146px;
  position: absolute;
  right: 91px;
  top: 0px;
`

const ContactFormHeaderText = styled.div`
  position: absolute;
  left: 45px;
  top: 42px;
`

const ContactFormFormContainer = styled.div`
  padding: 56px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  
  ${device.tablet`
    padding: 40px;
    padding-top: 60px;
  `}
`

const ContactFormFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  margin-bottom: 7px;
`

const ContactFormButtonContainer = styled.div`
  padding-top:21px;
`

export {
  ContactFormContainer,
  ContactFormHeaderContainer,
  CloseImage,
  MobileCloseImage,
  MokuContainer,
  ContactFormHeaderText,
  ContactFormFormContainer,
  ContactFormFieldContainer,
  ContactFormButtonContainer
}
