import React, { useState } from 'react'
import { ProfilePictureContainer, ProfileImage } from './ProfilePicture.style'
import { ActivityIndicator, Text, Button } from '../../../generic'
import { BackActionHeader } from '../../../shared'
import gql from 'graphql-tag'
import {  useQuery } from 'react-apollo'
import { navigate } from 'gatsby'
import UpdateProfilePicture from '../../UpdateProfilePicture'

const GET_PROFILE_PICTURE = gql`{
  myClient {
    id
    photo {
      url
    }
  }
}`

function ProfilePicture() {
  const [updateProfilePicture, setUpdateProfilePicture] = useState(false)
  const { data, loading, error } = useQuery(GET_PROFILE_PICTURE)

  const { photo } = (data && data.myClient) || {}

  return <>
    <UpdateProfilePicture open={updateProfilePicture} setOpen={setUpdateProfilePicture} />
    <BackActionHeader title="Document Vault" onClick={() => navigate('/documents')} />
    <Text bold size={26}>My Selfie</Text>
    <p />
    <center>
      <ProfilePictureContainer>
        {loading && <ActivityIndicator />}
        {photo && <ProfileImage src={photo.url} />}
      </ProfilePictureContainer>
      <p />
      <Button onClick={() => setUpdateProfilePicture(true)}>Update</Button>
    </center>
    <p />
  </>
}

export default ProfilePicture
