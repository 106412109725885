import React from 'react'
import { WizardModal } from '../../shared'
// import { printCurrency } from '../../../lib/utils'
// import {} from './ClearanceCertificateSignature.style'
import firebase from 'gatsby-plugin-firebase'
import { SIGN_CLEARANCE_CERTIFICATE } from '../ClearanceCertificateQuickQuote/ClearanceCertificateQuickQuote'
import { isRequired } from '../../../lib/validators'

const buildSteps = (setOpen, secondaryApplicant, refetchQueries, accessCode) => ([{
    name: "init",
    noNext: true,
    nextAfterDelay: 10,
    nextSteps: ["getSignature"]
  },
  {
    name: "getSignature",
    beforeStep: async () => {
      firebase.analytics().logEvent(secondaryApplicant ? "CLEARANCE_CERTIFICATE_GET_SECONDARY_SIGNATURE" : "CLEARANCE_CERTIFICATE_GET_SIGNATURE");
    },
    title: "Clear My Name",
    title2: "Add your signature to make it official",
    title3: <>
      You will be signing the following documents that we use to clear your name. We will fill them in for you.
      <p />
      <ul style={{ textAlign: "left", fontSize: "12px", margin: 0 }}>
        <li>Application Form</li>
        <li>Consent to Transfer of Debt Counsellor so we can issue a certificate</li>
        <li>Power of Attorney to act on your behalf</li>
      </ul>
    </>,
    fieldNames: ["signature"],
    nextSteps: ["doSign"]
  },
  {
    beforeStep: async ({ apolloClient, chatState }) => {
      const { signature, clearanceCertificateId } = chatState
      const result = await apolloClient.mutate({ mutation: SIGN_CLEARANCE_CERTIFICATE, variables: { signature, clearanceCertificateId, accessCode, secondaryApplicant }, refetchQueries })
      console.log(result)
    },
    name: "doSign",
    noNext: true,
    nextAfterDelay: 1000,
    nextSteps: ["done"]
  },
  {
    name: "done",
    beforeStep: () => setOpen(false)
  }
])

const fields = {
  signature: {
    type: "signature",
    validate: [isRequired]
  }
}

function ClearanceCertificateSignature({ open, setOpen, id, refetchQueries, secondaryApplicant }) {
  return <WizardModal
    name={`clearanceCertificateSignature-${id}`}
    open={open}
    setOpen={setOpen}
    steps={buildSteps(setOpen, secondaryApplicant, refetchQueries)}
    initialValues={{ clearanceCertificateId: id }}
    fields={fields}
  />
}

export default ClearanceCertificateSignature
