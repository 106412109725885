import React from 'react'
import { MokuNotePanel, WizardModal } from '../../shared'
import { isRequired, isValidCellphoneNumber, isValidEmail, isValidIdNumber } from '../../../lib/validators'
import { Text } from '../../generic'
import gql from 'graphql-tag'
import { useApolloClient } from '@apollo/react-hooks'
import { relationshipTypes } from '../../../constants'
import ApolloClient from 'apollo-client'

type ILookup<T> = {
  [name: string]: T
}

const familyRelationships = relationshipTypes.filter((relation) => relation.value != 'OTHER')
const familyRelationshipLookup: ILookup<string> = {}
familyRelationships.forEach((relation) => {
  familyRelationshipLookup[relation.label] = relation.value
})

enum RelationshipType { // <- consolidate with /\ relationshipTypes
  SPOUSE = 'SPOUSE',
  //FAMILY = "FAMILY",
  FAMILY_FRIEND = 'FAMILY_FRIEND',
  FRIEND = 'FRIEND',
  OTHER = 'OTHER',
}

const relationshipTypeLookup: ILookup<RelationshipType | string> = {
  ...familyRelationshipLookup,
  Spouse: RelationshipType.SPOUSE,
  //"Family": RelationshipType.FAMILY,
  'Family Friend': RelationshipType.FAMILY_FRIEND,
  'Friend ': RelationshipType.FRIEND,
  Other: RelationshipType.OTHER,
}

interface IBeneficiary {
  firstNames: string
  surname: string
  idType?: string
  idNumber?: string
  relationship: RelationshipType | string
  cellNumbers: string[]
  email: string
}

interface IField extends ILookup<any> {
  label: string
  type: string
  placeholder?: string
  validate?: ((value: any) => string | undefined)[]
}
type IFields = ILookup<IField>

const ADD_BENEFICIARY = gql`
  mutation AddBeneficiary($productId: ObjID!, $beneficiary: AddBeneficiaryInput) {
    addBeneficiary(productId: $productId, beneficiary: $beneficiary) {
      id
    }
  }
`

function mapInputValues(input: any, productId: string) {
  console.log('mapInputVariables', input)
  const cellNumbers = [input.mobilePrimary]
  if (input.mobileSecondary) cellNumbers.push(input.mobileSecondary)

  const beneficiary: IBeneficiary = {
    firstNames: input.firstNames,
    surname: input.surname,
    email: input.email,
    relationship: input.relationship,
    cellNumbers,
  }
  if (input.idType) beneficiary.idType = input.idType
  if (input.idNumber) beneficiary.idNumber = input.idNumber
  const variables = {
    productId,
    beneficiary,
  }
  console.log('mapInputVariables out', variables, input)
  return variables
}

const buildSteps = (
  setOpen: (opem: boolean) => void,
  fields: IFields,
  productId: string,
  apolloClient: ApolloClient<any>,
  onSubmit: (data: any) => void,
  refetchQueries: [any],
  nextOfKinTitle: string
) => [
  {
    name: 'addNextOfKin',
    title2: `Add ${nextOfKinTitle || 'Next of Kin'}`,
    title3: (
      <MokuNotePanel
        color="lightgrey"
        title={`Moku Tip: Your ${nextOfKinTitle || 'Next of Kin'}`}
        description={`It is important to provide details of your ${
          (nextOfKinTitle && nextOfKinTitle.toLowerCase()) || 'next of kin'
        } so they may claim on your behalf in the event of death.`}
      />
    ),
    fieldNames: [
      'firstNames',
      'surname',
      'idType',
      'idNumber',
      'relationship',
      'mobilePrimary',
      'mobileSecondary',
      'email',
    ],
    nextSteps: ['done'],
    nextTitle: 'Add new',
    handleNext: async ({ chatState, gotoNext }: any) => {
      const variables = mapInputValues(chatState, productId)
      const { data } = await apolloClient.mutate({ mutation: ADD_BENEFICIARY, variables, refetchQueries })
      if (data.error) {
        //TODO: handle request/network error
        onSubmit && onSubmit(false)
      }
      if (data) {
        console.log('success', data)
        gotoNext()
        onSubmit && onSubmit(true)
      }
    },
  },
  {
    name: 'done',
    beforeStep: () => {
      setOpen(false)
    },
  },
]

const fields: IFields = {
  firstNames: {
    type: 'text',
    label: 'First Names',
    placeholder: 'First Name',
    validate: [isRequired],
  },
  surname: {
    type: 'text',
    label: 'Last Name',
    placeholder: 'Last Name',
    validate: [isRequired],
  },
  idType: {
    type: 'selector',
    label: 'ID Type',
    options: [
      { label: 'SA ID', value: 'rsa_id' },
      { label: 'Non SA ID', value: 'oth_id' },
    ],
    validate: [],
  },
  idNumber: {
    type: 'text',
    label: 'ID Number',
    placeholder: 'ID Number',
    validate: [isValidIdNumber],
  },
  relationship: {
    type: 'select',
    label: 'Relationship',
    placeholder: 'Select',
    options: Object.keys(relationshipTypeLookup).map((label) => ({ label, value: relationshipTypeLookup[label] })), //["Spouse", "Family", "Family Friend", "Friend ", "Other"],
    validate: [isRequired],
  },
  mobilePrimary: {
    type: 'text',
    label: 'Mobile (primary)',
    placeholder: '+27 0 000 0000',
    validate: [isRequired, isValidCellphoneNumber],
  },
  mobileSecondary: {
    type: 'text',
    label: 'Mobile (secondary)',
    placeholder: '+27 0 000 0000',
    validate: [isValidCellphoneNumber],
  },
  email: {
    type: 'text',
    label: 'Email',
    placeholder: 'name@email.com',
    validate: [isRequired, isValidEmail],
  },
}

export interface IAddNextOfKinModalProps {
  title?: string
  productId: string
  open: boolean
  setOpen: (open: boolean) => void
  onSubmit?: (success: boolean) => void
  refetchQueries: [any]
}

function AddNextOfKinModal({ title, productId, open, setOpen, onSubmit, refetchQueries }: IAddNextOfKinModalProps) {
  const apolloClient = useApolloClient()

  const closeComponent = (
    <div style={{ position: 'relative', width: '100%' }}>
      <div
        style={{
          position: 'absolute',
          right: '20px',
          top: '20px',
          zIndex: 3,
        }}
      >
        <Text size={12} color="primary" onClick={() => setOpen(false)}>
          Back
        </Text>
      </div>
    </div>
  )

  return (
    // @ts-ignore
    <WizardModal
      closeComponent={closeComponent}
      name="addNextOfKin"
      open={open}
      setOpen={setOpen}
      // @ts-ignore
      steps={buildSteps(setOpen, fields, productId, apolloClient, onSubmit, refetchQueries, title)}
      initialValues={{ idType: 'rsa_id' }}
      fields={fields}
    />
  )
}

export default AddNextOfKinModal
