import React from 'react'
import { WizardModal } from '../../shared'
import { ActivityIndicator, Text } from '../../generic'
// import { printCurrency } from '../../../lib/utils'
import { Block } from './UpdateProfilePicture.style'
import { FieldValue } from 'react-form-helper'
import { ProfileImage, ProfilePictureContainer } from '../documents/ProfilePicture/ProfilePicture.style'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

const GET_FILE_URL = gql`
  query($id: String!) {
    file(id: $id) {
      url
    }
  }
`

const UPDATE_PROFILE_PICTURE = gql`
  mutation($photoId: ObjID) {
    updateMyProfile(input: { photoId: $photoId }) {
      id
      photo {
        url
      }
    }
  }
`

const buildSteps = (setOpen) => ([{
  name: "step1",
  beforeStep: ({ changeFieldValue }) => {
    changeFieldValue('selfie', null)
  },
  title2: "My Selfie",
  title3: <center>
    <Text size={13} bold>Taking the perfect selfie</Text>
    <p />
    <Block />
    <p />
    <Text size={13}>Make sure your face is well lit.</Text>
    <Text size={13}>Don’t stand infront of a window.</Text>
    <p />
  </center>,
  nextSteps: ["step2"],
  fieldNames: ["selfie"],
  noNext: true,
  nextOnValue: true,
}, {
  name: "step2",
  title2: "My Selfie",
  title3: "Looks good",
  component: () => <FieldValue name="selfie" renderField={({ input }) => (
    <Query query={GET_FILE_URL} variables={{ id: input.value }}>
      {({ loading, data }) => <ProfilePictureContainer>
          {loading && <ActivityIndicator />}
          {data && data.file && data.file.url && <ProfileImage src={data.file.url} />}
        </ProfilePictureContainer>}
    </Query>
  )} />,
  nextButtons: [{ label: "Done", nextSteps: ["update"] }, { label: "Retake", nextSteps: ["step1"] }],
  nextButtonsInColumn: true
}, {
  name: "update",
  submitBeforeStep: true,
  nextAfterDelay: 1000,
  noNext: true,
  nextSteps: ["done"]
}, {
  name: "done",
  beforeStep: () => setOpen(false)
}])

const fields = {
  selfie: {
    type: "fileupload",
    prompt: "Take my selfie",
    fileTypes: "image/png, image/jpeg",
    capture: "user"
  }
}

function UpdateProfilePicture({ open, setOpen, refetchQueries, source }) {
  return <WizardModal
    name="updateProfilePic"
    mode="add"
    addMutation={UPDATE_PROFILE_PICTURE}
    open={open}
    setOpen={setOpen}
    steps={buildSteps(setOpen)}
    mapInputVariables={({ selfie }) => ({ photoId: selfie, source: source })}
    fields={fields}
    refetchQueries={refetchQueries}
  />
}

export default UpdateProfilePicture
