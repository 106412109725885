import styled from '@emotion/styled'

const ClearanceCertificateObligationSummaryContainer = styled.div`
  background: ${props => props.theme.colors.white};
  box-shadow: inset 0px 0px 10px rgba(64, 67, 87, 0.08);
  border-radius: 4px;
  padding: 12px;
  margin: 4px;


  img {
    margin: 0
  }
`

export {
  ClearanceCertificateObligationSummaryContainer
}
