import React from 'react'
import { ChatFlow, ActivityIndicator } from '../../generic'
import {getDOBFromID, forMemberTypes, isValidIdNumber, isValidEmail, isValidCellphoneNumber, isValidAge, isValidDay, doesDOBMatchIdNumber, doesAgeAtSignupMatchDOB, doesAgeAtSignupMatchId, isRequired, isRequiredForForeignID} from '../../../lib/validators'
import schema from './schema'
import FuneralQuote from '../FuneralQuote'
import gql from 'graphql-tag'
import { TermsAndConditions } from '../../shared'
// import { Mandate } from '../../payments'
import  { banks, idTypes, memberTypes } from '../../../constants'
import moment from 'moment'
import { Text } from '../../generic'
import firebase from 'gatsby-plugin-firebase'
import { useQuery } from 'react-apollo'
import { printCurrency } from '../../../lib/utils'

const MAX_CHILDREN = 5

const CREATE_POLICY = gql`
  mutation CreateSalesInterationPolicyWithMembers($funeral: CreateFuneralInput!, $bankAccount: CreateBankAccountInput, $beneficiary: AddBeneficiaryInput, $members: [CreateMemberInput!], $salesInteraction: CreateSalesInteractionInput!) {
    createSalesInteractionPolicyWithMembers(funeral: $funeral, bankAccount: $bankAccount, members: $members, salesInteraction: $salesInteraction, beneficiary: $beneficiary) {
      payAtNumber
      policyNumber
      paymentBankAccountId
      id
    }
  }
`

const MY_DETAILS = gql`{
  myClient {
    id
    firstNames
    surname
    idNumber
    idType
    passportExpiresDate
    birthDate
    cellNumbers
    gender
    email
  }
}`

const sanitizeMembers = (members) => {
  console.log('sanitizeMembers ', members)
  const coveredMembers = members.filter(d => d.memberType).map(member => member.idNumber && member.idType === "rsa_id" ? 
      ({ ...member, DOB: moment(getDOBFromID(member.idNumber, member.idType)).valueOf() }) : 
      ({ ...member, DOB: member.DOB && moment(member.DOB).valueOf() }))
  
  console.log('sanitizeMembers after ', coveredMembers)

  coveredMembers.forEach(member => {
   
    if (member.relationship) {
      if (member.relationship.includes('MOTHER')) {
        member.gender = "FEMALE"
      }
      else {
        member.gender = "MALE"
      }
    }

    if (member.hasOwnProperty('isCovered')) {
      delete member.isCovered
    }
    if (member.hasOwnProperty('isActive')) {
      delete member.isActive
    }
  })

  console.log('sanitizeMembers after ', coveredMembers)
  return coveredMembers
}

// TODO: Put this in a separate folder
/*function SignDebitOrderMandate({ gotoNext, chatState }) {
  const [showModal, setShowModal] = useState(true)

  const saleCompleted = () => {
    setShowModal(false);
    gotoNext(["congratulations"])
  }

  if (chatState.bankAccount.ownAccount === "N" && showModal) {
    saleCompleted()
    return <div />
  }

  return (
    <Modal
      centered
      width='98%'
      backgroundColor= 'rgb(242, 246, 252)'
      visible={showModal}
      hideOk
      extraButtonsTop={true}
      hideCancel
    >
      <Mandate.ShowMandate id={chatState.bankAccountId} onMandateSign={saleCompleted} />
    </Modal>
  )
}*/

function stepsFromSchema() {
  const quizSchema = schema.quizSchema()
  console.log(quizSchema)
  const steps = [{
    name: "start",
    nextAfterDelay: 10,
    noNext: true,
    nextSteps: ["logStart"]
    }, {
    name: "logStart",
    nextAfterDelay: 10,
    beforeStep: () => firebase.analytics().logEvent("NEW_FUNERAL_START", {}),
    noNext: true,
    nextSteps: ["step1"]
  }].concat(quizSchema.map((step, n) => ({
    name: `step${n+1}`,
    text: step.question,
    fieldNames: step.elements.map(e => e.name),
    nextSteps: n+1 <= quizSchema.length && [`step${n+2}`],
    nextOnValue: step.elements[0].type === "yesno" || step.elements[0].type === "options",
    skipStep: (data) => {
      console.log(step.question, data, step.showCondition && !(step.showCondition(data)))
      return step.showCondition && !(step.showCondition(data))
    },
    noNext: step.elements[0].type === "yesno" || step.elements[0].type === "options"
  }))).concat([
    {
      name: `step${quizSchema.length+1}`,
      fieldNames:["income"],
      nextSteps: ["doQuote"],
      skipStep: ({ income }) => income,
    },
    {
    name: "doQuote",
    beforeStep: ({ changeFieldValue, chatState }) => {
      changeFieldValue("members[0].gender", chatState.gender)
      firebase.analytics().logEvent("NEW_FUNERAL_QUOTE", {})
    },
    component: FuneralQuote,
    noNext: true,
    },
    {
    name: "noThanks",
    beforeStep: () => firebase.analytics().logEvent("NEW_FUNERAL_QUOTE_REJECT", {}),
    text: "Thanks for talking to us about funeral insurance. Visit Moku anytime if you would like to take us up on this product",
    noNext: true
  },
  {
    name: "acceptTerms",
    component: TermsAndConditions,
    nextSteps: ["getIncome"],
    handleNext: ({ apolloClient, setSubmitting, gotoNext }) => {
      setSubmitting(true);
      //apolloClient.mutate({ })
      console.log('Handling Next in Terms');
      setTimeout(() => { setSubmitting(false); gotoNext() }, 2000)
    },
    skipStep: ({ hasAcceptedTerms,acceptedTerms }) => hasAcceptedTerms || acceptedTerms,
    noNext: true,
    noCard: true
  },
  {
    name: "getIncome",
    fieldNames:["income"],
    nextSteps: ["doIdCard"],
    skipStep: ({ income }) => income,
  },
  {
    name: "mainMember",
    beforeStep: ({ changeFieldValue, chatState }) => {
      firebase.analytics().logEvent("NEW_FUNERAL_QUOTE_ACCEPT", {})
      changeFieldValue("members[0]", {
        ...chatState.members[0],
        memberType: "POLICY_HOLDER",
        idNumber: chatState.idNumber,
        idType: chatState.idType,
        ageAtSignup: chatState.age,
        firstNames: chatState.firstName,
        cellNumbers: chatState.cellNumber,
        gender: chatState.gender
      })
    },
    text: "Please can we get your details",
    fieldNames: ['members[0].firstNames', 'members[0].surname', 'members[0].ageAtSignup', 'members[0].cellNumbers', 'members[0].email', 'members[0].DOB', 'members[0].idType', 'members[0].idNumber', 'members[0].passportExpiresDate'],
    labels: true,
    //skipStep: ({ loggedIn }) => loggedIn,
    nextSteps: ["doIdCard"]
  }, {
    name: "spouse",
    beforeStep: ({ changeFieldValue, chatState }) => {
      const {spouse} = chatState
    console.log('spouse is ',spouse)
      changeFieldValue("members[1]", {
        ageAtSignup: chatState.ageSpouse,
        ...chatState.members[1],
        memberType: "SPOUSE"
      })
    },
    text: "Please can we get your spouse's details",
    fieldNames: ['members[1].firstNames', 'members[1].surname', 'members[1].ageAtSignup', 'members[1].cellNumbers', 'members[1].email', 'members[1].DOB','members[1].idType', 'members[1].idNumber', 'members[1].passportExpiresDate'],
    skipStep: ({ spouse }) => spouse === "N",
    labels: true,
    nextSteps: ["addChild1"]
  },
  // Add in the steps for 5 children
  ...Array.from(Array(MAX_CHILDREN), (_, index) => ({
    name: `addChild${index + 1}`,
    beforeStep: ({ changeFieldValue, chatState }) => {
      changeFieldValue(`members[${index + 2}]`, {
        ...chatState.members[index + 2],
        memberType: "CHILD"
      })
    },
    labels: true,
    skipStep: ({ children }) => children === "N",
    text: index === 0 ? `Your policy provides cover for up to 5 children. Please enter the details of child ${index + 1}` : `Please enter the details of child ${index + 1}`,
    fieldNames: [`members[${2+index}].firstNames`, `members[${2+index}].surname`, `members[${2+index}].ageAtSignup`, `members[${2+index}].DOB`, `members[${2+index}].idType`, `members[${2+index}].idNumber`,  `members[${2+index}].passportExpiresDate`],
    nextButtons: [{ label: "Add Another", nextSteps: [`addChild${index + 2}`] }, { label: "Done", nextSteps: ["beneficiarySpouse"] }],
    nextSteps: ["beneficiarySpouse"]
  })),
  {
    name: `addChild${MAX_CHILDREN + 1}`,
    text: "You have now added 5 children",
    noNext: true,
    nextAfterDelay: 100,
    nextSteps: ["beneficiarySpouse"]
  },
  // TODO: Check this ...
  {
    name: "beneficiarySpouse",
    skipStep: ({ spouse }) => spouse === "N",
    text: "Is your spouse the beneficiary?",
    fieldNames: ["beneficiary.isSpouse"],
    labels: false,
    noNext: true,
    nextOnValue: true,
    nextSteps: ({ beneficiary }) => beneficiary && beneficiary.isSpouse === "Y" ? ["ownAccount"] : ["beneficiaryInfo"],
  },
  {
    name: "beneficiaryInfo",
    text: "Who is the beneficiary?",
    fieldNames: ["beneficiary.firstNames", "beneficiary.surname", "beneficiary.idNumber", "beneficiary.idType", "beneficiary.cellNumbers", "beneficiary.email", "beneficiary.bankName", "beneficiary.accountNo"],
    labels: true,
    nextSteps: ["ownAccount"]
  },
  {
    name: "ownAccount",
    text: "Will you be paying the account?",
    noNext: true,
    fieldNames: ["bankAccount.ownAccount"],
    nextOnValue: true,
    nextSteps: ({ bankAccount }) => bankAccount.ownAccount === "Y" ? ["bankAccountInfo"] : ["accountHolderInfo"]
  },
  {
    name: "accountHolderInfo",
    text: "Please can we have the information for the account holder",
    fieldNames: ["bankAccount.accountHolderName", "bankAccount.accountHolderContactNumber", "bankAccount.accountHolderEmail", "bankAccount.accountHolderIdNumber"],
    labels: true,
    nextSteps: ["bankAccountInfo"]
  },
  {
    name: "bankAccountInfo",
    text: ({ bankAccount }) => bankAccount.ownAccount === "Y" ? "Your bank account info" : "The Bank Account of the Premium Payer",
    fieldNames: ["bankAccount.bankName", "bankAccount.accountNo", "funeral.paymentDueDay"],
    labels: true,
    nextSteps: ({ bankAccount }) => bankAccount.ownAccount === "Y" ? ["completeSale"] : ["doMandateSent"],
  },
  {
    name: "completeSale",
    beforeStep: async ({ apolloClient, chatState, setChatState }) => {
      console.log('Completing sale ...', chatState.bankAccount )
      console.log('Completing sale members...', chatState.members )
      const { data } = await apolloClient.mutate({ mutation: CREATE_POLICY, variables: {
        funeral: { paymentMethod:"NAEDO", coverAmount: chatState.coverAmount, ...chatState.funeral },
        bankAccount: chatState.bankAccount ? { ...chatState.bankAccount, bankName: chatState.bankAccount.bankName ?chatState.bankAccount.bankName :"Capitec Bank", ownAccount: chatState.bankAccount.ownAccount === "Y" } : null,
        members: chatState.members && sanitizeMembers(chatState.members),
        beneficiary: chatState.beneficiary ? { ...chatState.beneficiary, isSpouse: chatState.beneficiary.isSpouse === "Y" } : { isSpouse: true },
        salesInteraction: {}
      } });
      console.log(data)
      if (data) {
        firebase.analytics().logEvent("NEW_FUNERAL_PURCHASE_COMPLETE", { policyNumber: data.createSalesInteractionPolicyWithMembers.policyNumber })
        setChatState({ policyNumber: data.createSalesInteractionPolicyWithMembers.policyNumber, bankAccountId: data.createSalesInteractionPolicyWithMembers.paymentBankAccountId })
      }
    },
    // component: SignDebitOrderMandate,
    text: ({ policyNumber, bankAccount }) => bankAccount.ownAccount === "Y" ? `Congratulations. You have now purchased your funeral cover. Your cover will be active as soon as the first payment is received. We have sent you an SMS with the debit order mandate to sign. Your policy number is ${policyNumber}.` :
    `Congratulations. You have now purchased your funeral cover. Your cover will be active as soon as the first payment is received. Your policy number is ${policyNumber}.`,
    noNext: true
    // DO Mandate
  }, {
    name: "doMandateSent",
    text: "We have sent the debit order mandate to the account holder to sign. Once that has been signed, your policy will be issued and your cover will be active once the first payment has been received",
    nextSteps: ["completeSale"]
  },
  {
    name: "doIdCard",
    text: "Please can you upload a copy of your ID?",
    fieldNames: ["idCardId"],
    nextOnValue:true,
    nextTitle:"Later",
    skipStep: ({ idCardId }) => idCardId,
    nextSteps: ["spouse"]
  },
  {
    name: "doSelfie",
    text: 'Please can you upload a "selfie" of yourself',
    fieldNames: ["selfieId"],
    nextTitle:"Later",
    nextOnValue:true,
    skipStep: ({ selfieId }) => selfieId,
    nextSteps: ["mainMember"]
  },
  // In completeSale ...
   {
    name: "congratulations",
    text: ({ policyNumber }) => `Congratulations. You have now purchased your funeral cover. Your cover will be active as soon as the first payment is received. We have sent you an SMS with the debit order mandate to sign. Your policy number is ${policyNumber}.`,
    noNext: true
  }])
  console.log(steps)
  return steps
}


function fieldsFromSchema() {
  const quizSchema = schema.quizSchema()

  const fields = {
    coverAmount: {
      type: 'optionselector',
      options: FuneralQuote.QUOTE_AMOUNTS
    },
    members: {
      type: 'fieldarray',
      config:
      {
        firstNames: { type: 'text', label: "First Name",
          validate: [isRequired] },
        surname: { type: 'text', label: "Last Name",
          validate: [isRequired] },
        ageAtSignup: { type: 'text', label: "Age",
          validate: [ forMemberTypes(["PARENT"],isRequired), isValidAge, doesAgeAtSignupMatchId, doesAgeAtSignupMatchDOB] },
        email: { type: 'email', label: 'Email', validate: [forMemberTypes(["POLICY_HOLDER","SPOUSE"],isRequired), isValidEmail] },
        DOB: { type: 'date', label: 'Birth Date',
          validate: [forMemberTypes(["CHILD"],isRequired), doesDOBMatchIdNumber, isValidAge] },
        idNumber: { type: 'number',label: "ID Number", maxLength: 13,
          validate:[forMemberTypes(["POLICY_HOLDER"],isRequired), isValidIdNumber] },
        idType: { type: "select",options: idTypes,label: "Identity Type",
          validate:[forMemberTypes(["POLICY_HOLDER"],isRequired)] },
        passportExpiresDate: { type: "date", label: "Passport Expiry Date",
          validate:[isRequiredForForeignID] },
        memberType: { type: "select",options: memberTypes,label: "Member Type"},
        cellNumbers: { type: 'number', label: "Cell Number", maxLength: 10,
          validate: [forMemberTypes(["POLICY_HOLDER","SPOUSE"],isRequired), isValidCellphoneNumber] },
      }
    },
    beneficiary: {
      isSpouse:{type: 'yesno', label: "Is your spouse the beneficiary" },
      firstNames: { type: 'text', label: "Beneficiary First Name ", validate: [isRequired] },
      surname: { type: 'text', label: "Beneficiary Last Name *", validate: [isRequired] },
      cellNumbers: { type: 'number', label: "Beneficiary Cell Number *", maxLength: 10, validate: [isRequired, isValidCellphoneNumber] },
      email: { type: 'email', label: 'Beneficiary Email', validate: [isValidEmail] },
      idNumber: { type: 'text',label: "Beneficiary ID Number", maxLength: 13,validate:[isValidIdNumber] },
      idType: { type: "select",options: idTypes,label: "Beneficiary Identity Type"},
      passportExpiresDate: { type: "select",options: idTypes,label: "Passport Expiry Date"},
      bankName: { type: 'select', options: banks, label: 'Beneficiary Bank' },
      accountNo: { type: 'text', label: 'Beneficiary Account Number' }
    },
    bankAccount: {
      bankName: { type: 'select', options: banks.map(b => b.label), label: 'Bank' },
      accountNo: { type: 'text', label: 'Account Number' },
      ownAccount:{ type: 'yesno', label: "Policy holder's account" },
      accountHolderName: { type: 'text', label: 'Premium Payer Name' },
      accountHolderContactNumber: { type: 'text', label: 'Contact Number'},
      accountHolderEmail: { type: 'text', label: 'Contact Email' },
      accountHolderIdNumber: { type: 'text', label: 'Id Number' }
    },
    funeral: {
      paymentDueDay: {
        type: 'number',
        keyboard: 'numeric',
        maxLength: 2, 
        label: 'Debit Order Day'
      },
    },
    idCardId: {
      type: "fileupload"
    },
    selfieId: {
      type: "fileupload"
    }
  }

  quizSchema.forEach((step) => {
    step.elements.forEach(e => {
      const { name, ...fieldConfig } = e
      fields[name] = fieldConfig
    })
  })
  return fields
}

const newFuneralSteps = stepsFromSchema(schema)
const fields = fieldsFromSchema(schema)

const getMemberInfoFromClient = (client) => {
  return {
    firstNames: client.firstNames,
    surname: client.surname,
    cellNumbers: client.cellNumbers,
    gender: client.gender,
    idNumber: client.idNumber,
    idType: client.idType,
    DOB: client.birthDate,
    ageAtSignup: client.birthDate ? moment().diff(moment(client.birthDate), "years") : null,
    passportExpiresDate: client.passportExpiresDate,
    email: client.email
  }
}

function NewFuneral() {
  // if logged in then get the client details
  console.log('new funeral')

  const { data, loading, error } = useQuery(MY_DETAILS)
  const { myClient } = data || {}

  console.log(myClient, myClient && moment().diff(moment(myClient.birthDate), "years") )
  return (
    <>
    <Text xlarge bold>Welcome</Text>
    <Text>Select the cover you need to get started</Text>
    <p />
    {loading ? <ActivityIndicator /> : myClient &&
    <ChatFlow
      name="newFuneral"
      steps={newFuneralSteps}
      fields={fields}
      initialValues={chatState => ({ firstName: myClient.firstNames, idNumber: myClient.idNumber, age: myClient.birthDate ? moment().diff(moment(myClient.birthDate), "years") : null, idType: myClient.idType, cellNumber: myClient.cellNumbers[0], gender: myClient.gender, bankAccount: { bankName:"Capitec Bank" }, coverAmount: 30000, members: [getMemberInfoFromClient(myClient)], funeral: { paymentDueDay: 1 }, beneficiary: { idType: "rsa_id"} })}
      startWithCleanState
    />}
    </>
  )
}

export default NewFuneral