import React from 'react'
import {
  SummaryItemCardContainer,
  TitleContainer,
  DescriptionContainer,
  AlertContainer,
  AlertNotification,
  ValueContainer,
  ValueContainerRow,
  SummaryItemTitleContainer,
} from './SummaryItemCard.style'
import { SummaryItemDescription, SummaryItemTitle, SummaryItemValue } from '../../shared/Typography'

interface ISummaryItemCardProps {
  title: string
  description: string
  value: number
  alert?: boolean
  color?: string
  backgroundColor?: string
  onClick?: () => void
}

function SummaryItemCard({ title, description, value, alert, color, backgroundColor, onClick }: ISummaryItemCardProps) {
  return (
    // @ts-ignore
    <SummaryItemCardContainer backgroundColor={backgroundColor} onClick={onClick}>
      <SummaryItemTitleContainer>
        <TitleContainer>
          <SummaryItemTitle>{title}</SummaryItemTitle>
        </TitleContainer>
        <DescriptionContainer>
          <SummaryItemDescription color="grey2a">{description}</SummaryItemDescription>
        </DescriptionContainer>
      </SummaryItemTitleContainer>
      <ValueContainerRow>
        <AlertContainer>
          {/** @ts-ignore */}
          <AlertNotification color={color} alert={alert} />
        </AlertContainer>
        <ValueContainer>
          <SummaryItemValue color={color || 'primary'}>{value}</SummaryItemValue>
        </ValueContainer>
      </ValueContainerRow>
    </SummaryItemCardContainer>
  )
}

export default SummaryItemCard
