import React from 'react'
import { GoalOptionContainer } from './GoalOption.style'
import { Text } from '../../generic'
import { ArrowContainer } from '../../financialHealth/MyFinancialHealth/MyFinancialHealth.style'
import { rightArrow } from '../../../images/itemRow'
import { Row, Col } from '../../generic/grid'

function GoalOption({ title, description, onClick }) {
  return <GoalOptionContainer onClick={onClick}>
    <Row>
      <Col padRight="4px">
        <Text size={16} bold padBottom="8px">{title}</Text>
        <Text size={13} color="grey2">{description}</Text>
      </Col>
      <ArrowContainer>
        <img src={rightArrow} alt=">" />
      </ArrowContainer>
    </Row>
  </GoalOptionContainer>
}

export default GoalOption
