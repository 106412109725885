import React from 'react'
// import {} from './DocumentFolder.style'
import { GET_MY_DOCUMENTS, documentParamsByType } from '../../DocumentVault/DocumentVault'
import DocumentListItem from '../../DocumentListItem'
import { useQuery } from 'react-apollo'
import { TitleImage, BackActionHeader } from '../../../shared'
import { sittingOnMoney } from '../../../../images'
import { ActivityIndicator } from '../../../generic'
import { navigate } from 'gatsby'
import Button from '../../../generic/Button'


function DocumentFolder({ type, title }) {
  const { loading, error, data } = useQuery(GET_MY_DOCUMENTS)

  if (loading || error) {
    return <>
      <BackActionHeader title="Document Vault" onClick={() => navigate('/documents')} />
      <TitleImage title={title} image={sittingOnMoney} />
      {loading && <ActivityIndicator />}
      {!loading && error}
    </>
  }

  const { documents } = data.myClient
  const { link } = documentParamsByType[type]

  return (
    <>
      <BackActionHeader title="Document Vault" onClick={() => navigate('/documents')} />
      <TitleImage title={title} image={sittingOnMoney} />
      <Button onClick={() => navigate(`${link}/add`)}>{`Add another ${type.toLowerCase()}`}</Button>
      {documents.length > 0
        ? documents
            .filter((d) => d.type === type)
            .map(({ id, name }, i) => (
              <DocumentListItem key={i} title={`${name}` || title} onClick={() => navigate(`${link}/${id}`)} />
            ))
        : 'None'}
    </>
  )
}

export default DocumentFolder
