// Allow static features/settings for the app to
// be turned on and off

interface ICoreFeatures {
  snowballActive: boolean
  creditLifeQuoting: boolean
  savings: ISavingsFeatures
}

interface IDevelopment {
  useCandidateClient: boolean
  candidateClient?: string
}

interface IAppConfig {
  coreFeatures: ICoreFeatures
  development: IDevelopment
}

interface ISavingsFeatures {
  getTaxNumber: boolean
}

let appConfig: IAppConfig = {
  coreFeatures: {
    snowballActive: false,
    creditLifeQuoting: false,
    savings: {
      getTaxNumber: false,
    },
  },
  development: {
    useCandidateClient: false,
  },
}

const isDev = process.env.NODE_ENV === 'development'

if (process.env.NODE_ENV === 'development') {
  //for development
  appConfig = {
    coreFeatures: {
      snowballActive: false,
      creditLifeQuoting: true,
      savings: {
        getTaxNumber: false,
      },
    },
    development: {
      useCandidateClient: false,
    },
  }
} else {
  //PRODUCTION OR OTHER
  appConfig = {
    coreFeatures: {
      snowballActive: false,
      creditLifeQuoting: true,
      savings: {
        getTaxNumber: false,
      },
    },
    development: {
      useCandidateClient: false,
      candidateClient: '603982ec3838ce002d6adddf',
      //TODO:not implemented
    },
  }
}
//debug
if (isDev) {
  window && ((window as any).appConfig = appConfig)
}
export default appConfig
