import React, { useState } from 'react'
import { idTypes } from '../../../constants'
// import {} from './MemberDetailsCard.style'
import { Text } from '../../generic'
import { InfoRow } from '../../insurance/MyLoan/MyLoan'
import MemberDetailsModal from '../MemberDetailsModal'

const labelFromValue = (instances, value) => {
  let result
  instances.forEach(instance => {
    console.log(instance.value, value, value === instance.value)
    if (value === instance.value) {
      console.log(instance.label)
      result = instance.label
    }
  }) 
  return result
}

const genderText = {
  MALE: "Male",
  FEMALE: "Female"
}

function MemberDetailsCard({ member, coverAmount }) {
  const [open, setOpen] = useState(false)
  const { id, firstNames, surname, memberType, gender, idNumber, idType, age } = member

  return <>
    <MemberDetailsModal open={open} setOpen={setOpen} id={id} />
    <InfoRow title={<Text size={16} bold>{firstNames} {surname}</Text>} value={<Text clickable primary onClick={() => setOpen(true)}>Edit</Text>} />
    <InfoRow title="ID Number" value={idNumber} />
    <InfoRow title="ID Type" value={labelFromValue(idTypes, idType)} />
    <InfoRow title="Age" value={`${age} years`} />
    <InfoRow title="Gender" value={genderText[gender]} />
  </>
}

export default MemberDetailsCard
