import React, { useState } from 'react'
// import {} from './MyFuneral.style'
import { SummaryItemsRow } from '../../dashboard/MyDashboard/MyDashboard.style'
import { ActivityIndicator, Text, Button } from '../../generic'
import { useQuery } from 'react-apollo'
import { printCurrency } from '../../../lib/utils'
import gql from 'graphql-tag'
import SummaryItemCard from '../../dashboard/SummaryItemCard'
import FuneralItemCard from '../../funeral/FuneralItemCard'
import FuneralCoverItemRow from '../../funeral/FuneralCoverItemRow'
import { BackActionHeader, TitleImage, MokuNotePanel } from '../../shared'
import { navigate } from 'gatsby'
import { rollingMoney } from '../../../images'
import parkBench from '../../../images/blogPosts/parkBench.webp'
import dirtCoffin from '../../../images/blogPosts/dirtCoffin.jpeg'
import { BlogPostCards } from '../../sections'
import UploadMembersFile from '../UploadMembersFile'
import { LoanInfoContainer, Separator } from '../../insurance/MyLoan/MyLoan.style'
import { InfoRow } from '../../insurance/MyLoan/MyLoan'



const GROUP_FUNERAL = gql`query ($id:String!) {
  groupproduct(id:$id) {
    id
    groupProductNumber
    state
    group {
      name
    }
    funerals {
      id
      client {
        firstNames
        surname
			}
      state
      currentPremiumTotal
      policyType
      livesCovered {
        memberType
      }
		}
  }
}`

const memberTypeDescription = {
  POLICY_HOLDER: "Me",
  SPOUSE: "My Spouse",
  CHILD: "My Children",
  PARENT: "My Parents",
  EXTENDED: "My Extended Family"
}

function GroupFuneral({ id }) {
  const [open, setOpen] = useState(false)
  const [addExisting, setAddExisting] = useState(false)
  const { data, loading, error } = useQuery(GROUP_FUNERAL, { variables: { id }})

  if (loading) {
    return <ActivityIndicator />
  }

  if (error) {
    return "Something has gone wrong. Please contact support"
  }

  const funerals = (data && data.groupproduct && data.groupproduct.funerals) || []
  const thirdPartyFunerals = [] // (data && data.myClient && data.myClient.thirdPartyFuneralPolicies && data.myClient.thirdPartyFuneralPolicies.map(d => ({ ...d.funeralPolicyInfo, id: d.id }))) || []
  /*const funerals = [{
    currentPremiumTotal: 275,
    coverAmount: 20000,
    livesCovered: [{
      memberType: "POLICY_HOLDER"
    }, {
      memberType: "CHILD"
    }, {
      memberType: "EXTENDED"
    }]
  }]*/

  /*const thirdPartyFunerals = [{
    companyName: "Old Mutual",
    premiumAmount: 275,
    coverAmount: 10000,
    livesCovered: [{
      memberType: "POLICY_HOLDER"
    }, {
      memberType: "SPOUSE"
    }, {
      memberType: "EXTENDED"
    }]
  }, {
    companyName: "Clientele",
    premiumAmount: 375,
    coverAmount: 5000,
    livesCovered: [{
      memberType: "EXTENDED"
    }]
  }, {
    companyName: "Hollard",
    premiumAmount: 375,
    coverAmount: 30000,
    livesCovered: [{
      memberType: "PARENT"
    }]
  }]*/

  const coverByType = {}

  const descriptionByState = {
    "CANCELLED": "Cancelled Policy",
    "ON_RISK_GRACE": "Missed Payment - Monthly Contribution",
    "LAPSED": "Lapsed - Monthly Contribution"
  }

  const policyDescription = (funeral) => {
    let description = ""
    if (funeral.policyType.includes("COUPLE")) {
      description = "Family"
    } else {
      description = "Single Member"
    }
    if (funeral.livesCovered.filter(l => l.memberType === "EXTENDED").length > 0) {
      description += " and " + (funeral.livesCovered.filter(l => l.memberType === "EXTENDED").length) + " adult dependants"
    }
    return description
  }

  const nextPaymentDate = "30 Oct 2024"

  /*var funerals = [{
    id: 1,
    state: "ACTIVE",
    client: {
      firstNames: "Fred",
      surname: "Otten"
    },
    currentPremiumTotal: 100,
    policyType: "COUPLE",
    livesCovered: [{
      memberType: "POLICY_HOLDER"
    }, {
      memberType: "EXTENDED"
    }, {
      memberType: "EXTENDED"
    }]
  }]*/

  var claims = []

  const groupProduct = data && data.groupproduct
  const { group } = groupProduct || {}

  return <>
    <UploadMembersFile open={open} setOpen={setOpen} groupProductNumber={data && data.groupproduct && data.groupproduct.groupProductNumber} />
    <BackActionHeader title="Group Sales" onClick={() => navigate(`/group-sales`)} />
    {/*<BackActionHeader title="Group" onClick={() => navigate(`/group/${data.groupproduct.groupId}`)} />*/}
    <Text size={16} bold>My Group Funeral Policy</Text>
    <SummaryItemsRow>
      <SummaryItemCard title="Total Members" description="Active" value={funerals.filter(f => f.state === "ACTIVE" || f.state === "DORMANT" || f.state === "INFO_MISSING").length} color="text" />
      <SummaryItemCard title="Total Claims" description="To Date" value={printCurrency(claims.reduce((total, c) => total + c.amountClaimed, 0))} color="text"/>
      <SummaryItemCard title="Next Payment" description={nextPaymentDate} value={printCurrency(funerals.filter(f => f.state === "ACTIVE" || f.state === "DORMANT" || f.state === "INFO_MISSING").reduce((total, f) => total + f.currentPremiumTotal, 0))} color="text"/>
    </SummaryItemsRow>
    <p />
    <LoanInfoContainer>
      <InfoRow title={<Text>Status: <Text span bold>{groupProduct.state}</Text></Text>} />
      <Separator />
      <InfoRow title="Group Name" value={group.name} />
      <InfoRow title="Policy Number" value={groupProduct.groupProductNumber} />
    </LoanInfoContainer>
    <p/>
    <Text size={16} bold>My Group Members</Text>
    {funerals.map(funeral => <FuneralItemCard key={funeral.id} title={`${funeral.client.firstNames} ${funeral.client.surname}`} description={policyDescription(funeral)} premiumAmount={funeral.currentPremiumTotal} onClick={() => navigate(`/funeral/${funeral.id}`)}  />)}
    <p />
    <p />
    <center>
      {/*<Button inverted onClick={() => setAddExisting(true)}>Add A New Member</Button>*/}
      <p/>
      <Button onClick={() => setOpen(true)}>Upload Members Sheet</Button>
    </center>
    <div style={{ height: "75px" }} />
  </>
}

export default GroupFuneral
