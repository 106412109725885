import styled from '@emotion/styled'
import { device } from '../../theme.style'

const MenuBarContainer = styled.div`
  display: none;

  ${device.tabletS`
    display: block;
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
  `}
`

const MenuBarItemsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
  background: #FFFFFF;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  height: 48px;
  overflow-y: hidden;
  box-shadow: 8px 0 8px 0px rgba(203, 203, 203, 0.5);
    &:before {
      transition-duration: 0.5s;
      border-radius: 0px 0px 0px 8px;
      transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 0);
      content: '';
      width: 16.93px;
      height: 16.93px;
      background-color:white;
      position:absolute;
      top: -12px;
      left: calc((((100% - 24px) / 5) * ${props => props.selectedIndex || 0}) + 12px + ((100% - 24px) / 10) - 8.465px);
      overflow-y: hidden;
      box-shadow: inset 0px 0 8px 0px rgba(203, 203, 203, 0.5);
    }
  }

`

const SelectedMenuItem = styled.div`
  transition-duration: 0.5s;
  position: absolute;
  left: calc((((100% - 24px) / 5) * ${props => props.selectedIndex || 0}) + 10.465px + ((100% - 24px) / 10));
  top: -1px;
  width: 4px;
  height: 4px;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 2px;
`

export {
  MenuBarContainer,
  MenuBarItemsContainer,
  SelectedMenuItem
}