import React, { useState, useEffect } from 'react'
import { isRequired, isValidRsaIdNumber, isValidCellphoneNumber, isValidEmail, isValidAge, doesDOBMatchIdNumber, isValidIdNumber, isRequiredForThirdPartyAccount, isNumber, idNumberAgeCheck, getAgeFromID } from '../../../lib/validators'
import WizardModal from '../../shared/WizardModal'
import CompleteBlob from '../../shared/CompleteBlob'
import CreditLifeQuote from '../CreditLifeQuote'
import ActivityIndicator from '../../generic/ActivityIndicator'
import Text from '../../generic/Text'
// import { printCurrency } from '../../../lib/utils'
// import {} from './CreditLifeQuickQuote.style'
import firebase from 'gatsby-plugin-firebase'
import gql from 'graphql-tag'
import { ADD_LEAD, buildAddLeadMutationInput } from '../../../lib/contactForm'
import { FuneralQuickQuoteHeader, FuneralQuickQuoteProgress, isValidAgeRange, QuickQuoteModalContainer, BeneficiaryInfoBox, MokuTitle, PencilText } from '../../funeral/FuneralQuickQuote/FuneralQuickQuote'
import sittingOnMoney from '../../../images/sittingOnMoney.svg'
import { memorial, will } from '../../../images/funeral'
import { printCurrency } from '../../../lib/utils'
import DebitOrderMandate from '../../shared/DebitOrderMandate'
import { gotoFlowComponent } from '../../generic/ChatFlow'
import  { banks, idTypes, memberTypes, relationshipTypes } from '../../../constants'
import { Query, useQuery } from 'react-apollo'
import moment from 'moment'

const GET_CREDIT_REPORT = gql`query($idNumber:String!, $income: Float) {
  getChallenge(idNumber: $idNumber, income: $income) {
    error
    telephones
    employers
    accounts
  }
}`

const GET_CREDIT_REPORT_ACCESS = gql`query($idNumber: String!, $telephone: String, $employer: String, $account: String) {
  doChallenge(idNumber: $idNumber, telephone: $telephone, employer: $employer, account: $account)   {
    passedChallenge
    accessCode
  }
}`

const CREATE_POLICY = gql`mutation($paymentDay: Int, $client: CreateClientInput, $bankAccount: CreateBankAccountInput, $beneficiary: AddBeneficiaryInput, $salesInteraction: CreateSalesInteractionInput!, $productType: CreditLifeProductType, $termsVersion: CreditLifeTermsVersion) {
  createSalesInteractionCreditLifePolicy(paymentDay: $paymentDay, client: $client, bankAccount: $bankAccount, beneficiary: $beneficiary, salesInteraction: $salesInteraction, productType: $productType, termsVersion: $termsVersion, useCreditReport: true, digitalWebSale: true) {
    policyNumber
    debitOrderId
    paymentBankAccountId
    id
    clientId
  }
}`

export const UPDATE_POLICY = gql`mutation($id: ObjID, $paymentDay: Int, $client: CreateClientInput, $bankAccount: CreateBankAccountInput, $beneficiary: AddBeneficiaryInput, $salesInteraction: CreateSalesInteractionInput, $productType: CreditLifeProductType, $termsVersion: CreditLifeTermsVersion, $obligations: [ObligationInsuranceInput], $signature: String, $quoteAccepted: Boolean) {
  updateSalesInteractionCreditLifePolicy(id: $id, paymentDay: $paymentDay, client: $client, bankAccount: $bankAccount, beneficiary: $beneficiary, salesInteraction: $salesInteraction, productType: $productType, termsVersion: $termsVersion, obligations: $obligations, signature: $signature, quoteAccepted: $quoteAccepted) {
    policyNumber
    debitOrderId
    paymentBankAccountId
    id
    clientId
  }
}`

const SALES_DETAILS = gql`{
  me {
    id
  }
}`

const MY_DETAILS = gql`{
  myClient {
    id
    firstNames
    surname
    idNumber
    idType
    passportExpiresDate
    birthDate
    cellNumbers
    gender
    email
    income
    estimatedIncome
  }
  me {
    id
  }
}`

const CREATE_CLIENT_DOCUMENT = gql`
  mutation CreateClientDocument($input: CreateClientDocumentInput!) {
    createClientDocument(input: $input) {
      id
    }
  }
`

const isGreaterThan = (min = 1) => value => (
  !value || value > min
    ? undefined
    : `Must be greater than ${min}`
)


function PricePerMonth({ chatState }) {
  const variables = CreditLifeQuote.quoteVariablesFromChatState(chatState)

  return <Query query={CreditLifeQuote.GET_QUOTE} variables={variables}>
    {({ loading, error, data }) => <center>
        {loading ? <ActivityIndicator /> : <>
          <Text size={32} primary bold>{data && data.getCreditLifeQuote && printCurrency(data.getCreditLifeQuote.price)}</Text>
          <Text>per month</Text>
          </>}
        </center>}
  </Query>
}

function Disclosures({ chatState }) {
  if (["UNEMPLOYED", "PENSIONER"].includes(chatState.employmentStatus)) {
    return <div>As you are not earning an income from employment you are <b>NOT</b> covered for retrenchment and your temporary and permanent disability benefits will be based on your ability to perform activities of daily living.</div>
  } 
  if (["OTHER"].includes(chatState.employmentStatus)) {
    return <div>As you are not full time permanently employed, please note that you are <b>NOT</b> covered for retrenchment</div>
  } 
}

// TODO: Field Names based on chatstate
const buildSteps = (setOpen, setQuestionData, accessCode, setAccessCode) => ([/*{
  name: "step1",
  title: "Quick Quote Credit Life Insurance",
  title2: "Let's get to know your loans",
  nextButtonsInColumn: true,
  nextButtons: [{ label: "Manually Input your Loans", nextSteps: ["manualLoanEntry"] }, { label: "Do it for me. We just need your ID Number", nextSteps: ["idNumber"] }]
}, {
}, {
  name: "manualLoanEntry",
  title: "Quick Quote Credit Life Insurance",
  title2: "Let's get to know your loans",
  nextSteps: ["done"]
},*/{
  name: "start",
  nextAfterDelay: 10,
  noNext: true,
  nextSteps: ({ salesPersonId }) => salesPersonId ? ["idNumber"] : ["policyHolderInfo"]
},
{
  name: "policyHolderInfo",
  beforeStep: async ({ changeFieldValue }) => {
    changeFieldValue('wizardSection', "QQ")
    changeFieldValue('currentStep', 0)
    firebase.analytics().logEvent("CLI_QUICK_QUOTE_START", {});
  },
  fieldNames: ["firstName", "surname", "email"/*, "age", "gender", "income"*/],
  title: "Quick Quote Credit Life Insurance",
  title2: "Let's get to know you better",
  nextTitle: "Get Quote",
  nextSteps: ["doQuote"]
}, {
  name: "verificationStep1",
  beforeStep: async ({ apolloClient, chatState }) => {
    firebase.analytics().logEvent("NEW_CLI_VERIFICATION_START");
    const { idNumber, income } = chatState
    const result = await apolloClient.query({ query: GET_CREDIT_REPORT, variables: { idNumber, income } })
    setQuestionData(result.data.getChallenge)
  },
  skipStep: chatState => !chatState.salesPersonId && chatState.signedIn,
  title: "Credit Report - Challenge Questions",
  title2: "Please answer the following questions to validate your identity",
  fieldNames: ["telephone"],
  nextSteps: ["verificationStep2"]
}, {
  name: "verificationStep2",
  skipStep: chatState =>  !chatState.salesPersonId && chatState.signedIn,
  title: "Credit Report - Challenge Questions",
  title2: "Please answer the following questions to validate your identity",
  fieldNames: ["employer"],
  nextSteps: ["verificationStep3"]
},
{
  name: "verificationStep3",
  skipStep: chatState =>  !chatState.salesPersonId && chatState.signedIn,
  title: "Credit Report - Challenge Questions",
  title2: "Please answer the following questions to validate your identity",
  fieldNames: ["account"],
  nextSteps: ["performVerification"]
},
{
  name: "performVerification",
  beforeStep: async ({ apolloClient, chatState, changeFieldValue }) => {
    const { idNumber, telephone, employer, account } = chatState
    const result = await apolloClient.query({ query: GET_CREDIT_REPORT_ACCESS, variables: { idNumber, telephone, employer, account } })

    console.log(result)

    if (result.data.doChallenge && result.data.doChallenge.passedChallenge) {
      accessCode = result.data.doChallenge.accessCode
    } else {
      accessCode = null
    }
    if (accessCode) {
      firebase.analytics().logEvent("NEW_CLI_VERIFICATION_SUCCESS");
    } else {
      firebase.analytics().logEvent("NEW_CLI_VERIFICATION_ERROR");
    }
    changeFieldValue('accessCode', accessCode)
    setAccessCode(accessCode)
  },
  skipStep: chatState =>  !chatState.salesPersonId && chatState.signedIn,
  component: ActivityIndicator,
  nextAfterDelay: 1000,
  noNext: true,
  nextSteps: chatState => accessCode || chatState.signedIn ? ["fullQuote"] : chatState.salesPersonId ? ["rmaError"] : ["error"]
},
{
  name: "fullQuote",
  beforeStep: async ({ changeFieldValue, apolloClient, chatState, setChatState }) => {
    const { data } = await apolloClient.mutate({ mutation: CREATE_POLICY, variables: {
      client: {
        firstNames: chatState.firstName,
        surname: chatState.surname,
        email: chatState.email,
        idType: chatState.idType,
        idNumber: chatState.idNumber,
        employmentStatus: chatState.employmentStatus
      },
      ...CreditLifeQuote.quoteVariablesFromChatState(chatState),
      salesInteraction: chatState.salesPersonId ? {
        salesPersonId: chatState.salesPersonId,
      } : {}
    } });
    setChatState({ policyNumber: data.createSalesInteractionCreditLifePolicy.policyNumber, clientId: data.createSalesInteractionCreditLifePolicy.clientId, creditLifeId: data.createSalesInteractionCreditLifePolicy.id })
    changeFieldValue("currentStep", 1)
  },
  component: ({ ...props }) => <CreditLifeQuote {...props} fullQuote />,
  noNext: true
},
{
  name: "error",
  title: "Credit Report",
  title2: <CompleteBlob image={sittingOnMoney} title="Oops!" description="Something went wrong." />,
  nextButtonsInColumn: true,
  nextButtons: [{ label: "Let's try again", nextSteps: ["step1"] }, { label: "Nevermind, I'm out", onClick: () => setOpen(false), noNextStep: true }]
},
{
  name: "rmaError",
  title: "Credit Report",
  title2: <CompleteBlob image={sittingOnMoney} title="Oops!" description="The Customer has failed the credit report challenge questions. Please send an email to matlasupport@meerkat.co.za with the customer’s name and their cellphone number and the back office will contact them to complete the sale." />,
  nextButtonsInColumn: true,
  nextButtons: [{ label: "OK", onClick: () => setOpen(false), noNextStep: true }]
},
{
  name: "doQuote",
  component: CreditLifeQuote,
  noNext: true
}, {
  name: "idNumber",
  beforeStep: ({ changeFieldValue}) => {
    changeFieldValue("steps", [
      { text: "Identity Verification" },
      { text: "Choose Cover" },
      { text: "Personal Details" },
      // { text: "Next of Kin" },
      { text: "Payment Details" },
      { text: "Signature" },
      { text: "Required Documents" }
    ])
    changeFieldValue("wizardSection", "Buy")
    changeFieldValue("currentStep", 0)
  },
  title: "Quick Quote Credit Life Insurance",
  title2: "We need these details to finalise your Quote",
  fieldNames: ["idNumber", "employmentStatus", "acceptTerms"],
  nextTitle: "Choose Cover",
  nextSteps: ["setAge"]
},
{
  name: "setAge",
  beforeStep: ({ chatState, changeFieldValue }) => {
    const { idNumber } = chatState
    const dob = moment(`${parseInt(idNumber.slice(0,2)) > 25 ? "19" : "20"}${idNumber.slice(0, 6)}`, 'YYYY MM DD');
    const age = moment().diff(moment(dob), 'years');
    changeFieldValue("age", age)
  },
  nextAfterDelay: 10,
  noNext: true,
  nextSteps: ({ age, employmentStatus }) => age >= 60 ? ["notEligibleAge"] : employmentStatus === "PERMANENT_EMPLOYMENT" ? ["disabilityCheck"] : ["disclosures"]
},
{
  name: "disclosures",
  title: "Quick Quote Credit Life Insurance",
  title2: "Important Disclosures",
  component: Disclosures,
  nextTitle: "Continue",
  nextSteps: ["verificationStep1"]
},
{
  name: "disabilityCheck",
  title: "Quick Quote Credit Life Insurance",
  title2: "In the last 12 months, have you been so sick that you needed more than a week off work ?",
  fieldNames: ["disability"],
  noNext: true,
  nextOnValue: true,
  nextSteps: ["verificationStep1"]
},
{
  name: "notEligibleAge",
  title: "Quick Quote Credit Life Insurance",
  title2: "Unfortunately we are not able to help",
  text: "Our policy's entrance age is below 60",
  nextTitle: "OK",
  nextStep: ["done"]
},
{
  name: "mainMember",
  beforeStep: ({ changeFieldValue, chatState }) => {
    firebase.analytics().logEvent("NEW_CLI_QUOTE_ACCEPT", {})
    changeFieldValue("currentStep", 2)
  },
  title: "Lets complete your profile",
  fieldNames: ['firstName', 'surname', 'cellNumber', 'email'/*, 'DOB', 'idType', 'idNumber', 'income'*/],
  requiredFields: ['firstName', 'surname', 'cellNumber', 'email'/*, 'DOB', 'idType', 'idNumber'/*, 'income'*/],
  // Smoker? Product Question
  labels: true,
  nextSteps: ["ownAccount"],
  showBack: true
},
{
  name: "beneficiary",
  beforeStep: ({ changeFieldValue }) => {
    changeFieldValue("currentStep", 3)
  },
  title: "Buy Credit Life Cover",
  title2: "Why we need you to add a next of kin",
  component: ({ gotoNext }) => <>
    <BeneficiaryInfoBox description="If you are unable to make a claim yourself we will contact your next of kin to do so on your behalf" image={will} onClick={gotoNext} />
    <BeneficiaryInfoBox description="We will pay your creditors directly in the event of a successful claim" image={memorial} onClick={gotoNext} />
  </>,
  showBack: true,
  nextSteps: ["beneficiaryInfo"]
},
{
  beforeStep: ({ changeFieldValue }) => {
    changeFieldValue("currentStep", 3)
  },
  name: "beneficiaryInfo",
  title2: "Next of Kin Details",
  fieldNames: ["beneficiary.firstNames", "beneficiary.surname", "beneficiary.cellNumbers", "beneficiary.email", "beneficiary.idType", "beneficiary.idNumber", /* "beneficiary.bankName", "beneficiary.accountNo" */],
  requiredFields: ["beneficiary.firstNames", "beneficiary.surname", "beneficiary.cellNumbers"],
  labels: true,
  showBack: true,
  nextSteps: ["ownAccount"]
},
{
  beforeStep: ({ changeFieldValue }) => {
    changeFieldValue("currentStep", 3)
  },
  name: "ownAccount",
  text: chatState => <>
    <MokuTitle title2="Will you be paying for this Credit Life Insurance Policy?" />
    <p />
    <PricePerMonth chatState={chatState} />
    <p />
  </>,
  noNext: true,
  fieldNames: ["bankAccount.ownAccount"],
  nextOnValue: true,
  showBack: true,
  nextSteps: ({ bankAccount }) => bankAccount.ownAccount === "Y" ? ["bankAccountInfo"] : ["accountHolderInfo"]
},
{
  name: "accountHolderInfo",
  title: "Account Holder Details",
  beforeStep: ({ changeFieldValue }) => {
    firebase.analytics().logEvent("NEW_CLI_ACCOUNT_HOLDER_INFO", {});
    changeFieldValue("currentStep", 3)
  },
  fieldNames: ["bankAccount.accountHolderName", "bankAccount.accountHolderContactNumber", "bankAccount.accountHolderEmail", "bankAccount.accountHolderIdNumber"],
  requiredFields: ["bankAccount.accountHolderName", "bankAccount.accountHolderContactNumber", "bankAccount.accountHolderIdNumber"],
  labels: true,
  nextSteps: ["bankAccountInfo"],
  showBack: true
},
{
  name: "bankAccountInfo",
  beforeStep: ({ changeFieldValue }) => {
    firebase.analytics().logEvent("NEW_CLI_BANK_ACCOUNT_INFO", {});
    changeFieldValue("currentStep", 3)
  },
  text: ({ bankAccount }) => bankAccount.ownAccount === "Y" ? <MokuTitle title="Please provide your bank account details to collect premiums from" /> : <MokuTitle title="Account Holder Info" title2="Bank Account" />,
  fieldNames: ["bankAccount.bankName", "bankAccount.accountNo", "paymentDay"],
  requiredFields: ["bankAccount.bankName", "bankAccount.accountNo", "paymentDay"],
  labels: true,
  nextSteps: ({ bankAccount, signedIn }) => bankAccount.ownAccount === "Y" ? ["completeSale"] : ["doMandateSent"],
  showBack: true
},
{
  name: "completeSale",
  beforeStep: async ({ apolloClient, chatState, setChatState }) => {
    const { data } = await apolloClient.mutate({ mutation: UPDATE_POLICY, variables: {
      id: chatState.creditLifeId,
      paymentDay: chatState.paymentDay,
      client: {
        firstNames: chatState.firstName,
        surname: chatState.surname,
        email: chatState.email,
        cellNumbers: [chatState.cellNumber],
        income: chatState.income,
        gender: chatState.gender,
        birthDate: moment(chatState.DOB).valueOf(),
        idType: chatState.idType,
        idNumber: chatState.idNumber
      },
      bankAccount: chatState.bankAccount ? { ...chatState.bankAccount, bankName: chatState.bankAccount.bankName ?chatState.bankAccount.bankName :"Capitec Bank", ownAccount: chatState.bankAccount.ownAccount === "Y" } : null,
      // beneficiary: chatState.beneficiary ? { ...chatState.beneficiary, isSpouse: chatState.beneficiary.isSpouse === "Y" } : { isSpouse: true },
      ...CreditLifeQuote.quoteVariablesFromChatState(chatState),
      salesInteraction: chatState.salesPersonId ? {
        salesPersonId: chatState.salesPersonId,
      } : {},
      signature: chatState.signature,
      quoteAccepted: true
    } });
    console.log(data)
    if (data) {
      //fbTrack('track', 'Lead')
      //firebase.analytics().logEvent("NEW_FUNERAL_PURCHASE_COMPLETE", { policyNumber: data.createSalesInteractionPolicyWithMembers.policyNumber })
      setChatState({ policyNumber: data.updateSalesInteractionCreditLifePolicy.policyNumber, bankAccountId: data.updateSalesInteractionCreditLifePolicy.paymentBankAccountId, clientId: data.updateSalesInteractionCreditLifePolicy.clientId, debitOrderId: data.updateSalesInteractionCreditLifePolicy.debitOrderId })
    }
  },
  nextAfterDelay: 1000,
  noNext: true,
  nextSteps: ["signature"]
},
{
  name: "signature",
  beforeStep: ({ changeFieldValue }) => {
    firebase.analytics().logEvent("NEW_CLI_GET_SIGNATURE", {});
    changeFieldValue("currentStep", 4)
  },
  title: "Credit Life Quick Quote",
  title2: "Add your signature to make it official",
  title3: <>
    You will be signing the following documents that we use to clear your name. We will fill them in for you.
    <p />
    <ul style={{ textAlign: "left", fontSize: "12px", margin: 0 }}>
      <li>Application Form</li>
      <li>Power of Attorney to act on your behalf to cancel credit life insurance</li>
      <li>Form 23s to each of your Credit Providers</li>
      <li>Replacement Advice Record for you and your current insurers</li>
    </ul>
  </>,
  fieldNames: ["signature"],
  nextSteps: ["doSign"]
},
{
  name: "doSign",
  beforeStep: async ({ apolloClient, chatState, setChatState }) => {
    const {  data } = await apolloClient.mutate({ mutation: UPDATE_POLICY, variables: {
      id: chatState.creditLifeId,
      signature: chatState.signature,
      quoteAccepted: true
    } });

    setChatState({ policyNumber: data.updateSalesInteractionCreditLifePolicy.policyNumber })
  },  
  nextAfterDelay: 1000,
  noNext: true,
  nextSteps: ["doMandate"],
},
{
  name: "doMandate",
  component: gotoFlowComponent(DebitOrderMandate, ({ chatState, ...args }) => { console.log(chatState, args); return {  id: chatState.debitOrderId, startWithCleanState: false, noCloseButton: true } }),
  noNext: true,
  skipStep: ({ debitOrderId }) => !debitOrderId,
  nextSteps: ["policyInfo"]
},
{
  name: "policyInfo",
  title: "Credit Life Quick Quote",
  title2: "Client Disclosures",
  component: ({ chatState }) => <>
  Thanks for your information. Please note the following important information about your new policy.
  <ul>
    <li>The Plan commences on the payment of the first premium.</li>
    <li>The cover on the Plan is always equal to the outstanding balance on the credit facilities at the date of the claim event.</li>
    <li>Cover ceases for disability and retrenchment at age 65, and for death at age 70.</li>
    <li>The maximum age for any cover increases is age 60.</li>
    <li>Your premium rate can change with one months notice.</li>
    <li>The minimum premium is R25 (Twenty five Rands) per month</li>
    <li>The maximum Sum Assured per Loan is {chatState.productType === "RMA" ? "R1,000,000 (One million Rand)" : "R400,000 (Four hundred thousand Rand)"}.</li>
    <li>The benefit payable for Retrenchment is 12 months of repayments with a maximum of two claims allowed. Premiums must resume after a retrenchment event for cover to continue.</li>
  </ul>
  </>,
  nextTitle: "Continue",
  nextSteps: ["requiredDocuments"]
},
{
  name: "requiredDocuments",
  beforeStep: ({ changeFieldValue }) => {
    firebase.analytics().logEvent("NEW_CLI_REQUIRED_DOCUMENTS", {});
    changeFieldValue("currentStep", 5)
  },
  title:  "Credit Life Quick Quote",
  title2: "Upload Required Documents",
  fieldNames: [ "idDocumentId"],
  nextSteps: ({ signedIn }) => [`completedSale${signedIn ? "SignedIn" : ""}`],
  component: () => <div style={{ height: "90px"}} />,
  nextButtonsInColumn: true,
  nextButtons: [{ label: "Done", nextSteps: ["uploadDocs"] }, { label: "Skip for now", buttonProps: { inverted: true, outlined: false } }]
},
{
  name: "uploadDocs",
  beforeStep: async ({ apolloClient, chatState }) => {
    const { selfieId, idDocumentId, clientId } = chatState
    if (selfieId) {
      await apolloClient.mutate({ mutation: CREATE_CLIENT_DOCUMENT, variables: { input: { clientId, name: "Selfie", source: "FUNERAL_QUICK_QUOTE", type: "SELFIE", fileId: selfieId } } })
    }
    if (idDocumentId) {
      await apolloClient.mutate({ mutation: CREATE_CLIENT_DOCUMENT, variables: { input: { clientId, name: "ID Document", source: "CLI_QUICK_QUOTE", type: "RSA_ID", fileId: idDocumentId } } })
    }
  },
  nextSteps: ({ signedIn }) => [`completedSale${signedIn ? "SignedIn" : ""}`],
  nextAfterDelay: 1000,
  noNext: true
},
{
  name: "completedSale",
  beforeStep: ({ changeFieldValue }) => {
    changeFieldValue("wizardSection", "Done")
  },
  // component: SignDebitOrderMandate,
  text: ({ policyNumber, bankAccount }) => <>
    <div style={{ position: "absolute", left: "calc(79px + calc((100% - 360px) / 2)" }}>
      <PencilText primary size={34} style={{ marginBottom: "20px", height: "30px", lineHeight: "30px", transform: "rotate(-7.69deg)" }}>Nearly!</PencilText>
      <div style={{ marginLeft: "40px" }}><PencilText primary size={28} style={{ width: "13px", height: "30px", transform: "rotate(90deg)", lineHeight: "30px" }}>{"<"}</PencilText></div>
    </div>
    <div style={{ height: "18px"}} />
    <MokuTitle title2="We've &nbsp; got you covered!" />
    <center>
    <Text size={16} style={{ padding: "14px" }}>{bankAccount && bankAccount.ownAccount === "Y" ? "Your cover will be active as soon as the first payment is received." : "Your cover will be active as soon as the first payment is received."}</Text>
    <p /> 
    <Text bold size={16} style={{ padding: "14px" }}>Your policy number is {policyNumber}</Text>
    <p />
    </center>
  </>,
  nextButtonsInColumn: true,
  nextButtons: [{ label: "Finish, take me home", nextSteps: ["done"], buttonProps: { inverted: true } }, { label: "Register to view policy", nextSteps: ["signup"] }],
  // DO Mandate
}, {
  name: "completedSaleSignedIn",
  beforeStep: ({ changeFieldValue }) => {
    changeFieldValue("wizardSection", "Done")
  },
  /*beforeStep: async ({ apolloClient, chatState, setChatState }) => {
    console.log('Completing sale ...', chatState.bankAccount )
    console.log('Completing sale members...', chatState.members )
    const { data } = await apolloClient.mutate({ mutation: CREATE_POLICY, variables: {
      funeral: { paymentMethod:"DEBI_CHECK", coverAmount: chatState.coverAmount, ...chatState.funeral },
      bankAccount: chatState.bankAccount ? { ...chatState.bankAccount, bankName: chatState.bankAccount.bankName ?chatState.bankAccount.bankName :"Capitec Bank", ownAccount: chatState.bankAccount.ownAccount === "Y" } : null,
      members: chatState.members && sanitizeMembers(chatState.members),
      beneficiary: chatState.beneficiary ? { ...chatState.beneficiary, isSpouse: chatState.beneficiary.isSpouse === "Y" } : { isSpouse: true },
      salesInteraction: {}
    } });
    console.log(data)
    if (data) {
      fbTrack('track', 'Lead')
      firebase.analytics().logEvent("NEW_FUNERAL_PURCHASE_COMPLETE", { policyNumber: data.createSalesInteractionPolicyWithMembers.policyNumber })
      setChatState({ policyNumber: data.createSalesInteractionPolicyWithMembers.policyNumber })
    }
  },*/
  // component: SignDebitOrderMandate,
  text: ({ policyNumber, bankAccount }) => <>
    <div style={{ position: "absolute", left: "calc(79px + calc((100% - 360px) / 2)" }}>
      <PencilText primary size={34} style={{ marginBottom: "20px", height: "30px", lineHeight: "30px", transform: "rotate(-7.69deg)" }}>Nearly!</PencilText>
      <div style={{ marginLeft: "40px" }}><PencilText primary size={28} style={{ width: "13px", height: "30px", transform: "rotate(90deg)", lineHeight: "30px" }}>{"<"}</PencilText></div>
    </div>
    <div style={{ height: "18px"}} />
    <MokuTitle title2="We've &nbsp; got you covered!" />
    <center>
    <Text size={16} style={{ padding: "14px" }}>{bankAccount && bankAccount.ownAccount === "Y" ? "Your cover will be active as soon as the first payment is received." : "Your cover will be active as soon as the first payment is received."}</Text>
    <p /> 
    <Text bold size={16} style={{ padding: "14px" }}>Your policy number is {policyNumber}</Text>
    <p />
    </center>
  </>,
  nextTitle: "Finish",
  nextSteps: ["done"]
  // DO Mandate
}, {
  name: "doMandateSent",
  text: <><MokuTitle title="We will send a debit order mandate to the account holder to sign." />
  <center><Text size={16} style={{ padding: "14px" }}>Once that has been signed, your policy will be issued and your cover will be active once the first payment has been received.</Text></center>
  <p />
  </>,
  nextTitle: "Send now",
  nextSteps: ({ signedIn }) => ["completeSale"]
},
{ // TODO: Consoldate these steps
  name: "callback",
  title: "Quick Quote Credit Life Insurance",
  title2: "Call Back Request",
  fieldNames: ["firstName", "surname", "cellNumber", "email"],
  beforeStep: async () => {
    firebase.analytics().logEvent("CLI_QUICK_QUOTE_CALLBACK_REQUEST", {})
  },
  nextSteps: ["sendCallback"],
  showBack: true
}, {
  name: "sendCallback",
  beforeStep: async ({ apolloClient, chatState }) => {
    firebase.analytics().logEvent("CLI_QUICK_QUOTE_CALLBACK_REQUEST_SENT", {})
    apolloClient.mutate({ mutation: ADD_LEAD, variables: buildAddLeadMutationInput({
      firstName: chatState.firstName,
      surname: chatState.surname,
      cellNumber: chatState.cellNumber,
      talkAbout: "Credit Life Insurance",
      message: "Quick Quote Callback Request",
      routing: "cli"
    }) })
    setOpen(false)
  }
}, {
  name: "done",
  beforeStep: () => setOpen(false)
}])

const fields = {
  paymentDay: {
    label: "When do you get paid?",
    type: 'radiogroup',
    options: [
      { label: "1st of the Month", value: 1 },
      { label: "15th of the Month", value: 15 },
      { label: "20th of the Month", value: 20 },
      { label: "25th of the Month", value: 25 },
      { label: "Last Day of the Month", value: 31 },
    ],
    includeOther: true,
    otherField: {
      type: 'select',
      options: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
      label: "Select Payment Day"
    },
    keyboard: 'numeric',
    maxLength: 2
  },
  firstName: { type: 'text', label: "First Name", validate: [isRequired] },
  surname: { type: 'text', label: "Last Name", validate: [isRequired] },
  email: { type: 'email', label: 'Email', validate: [isRequired, isValidEmail] },
  cellNumber: { type: 'number', label: 'Mobile Number', validate: [isRequired, isValidCellphoneNumber] },
  coverAmount: {
    label: "My Total Debt (excluding Home Loans and Vehicle Finance)",
    type: 'slider',
    endText: null,
    startText: "R",
    min: 10000,
    step: 1,
    max: 1000000,
    validate: value => value > 0 ? undefined : 'Cover Amount must be a number'
  },
  idNumber: {
    label: "ID number",
    type: "text",
    validate: [isRequired, isValidRsaIdNumber, idNumberAgeCheck(18, 60)]
  },
  beneficiary: {
    isSpouse:{type: 'yesno', label: "Is your spouse the beneficiary" },
    firstNames: { type: 'text', label: "First Name ", validate: [isRequired] },
    surname: { type: 'text', label: "Last Name", validate: [isRequired] },
    cellNumbers: { type: 'number', label: "Cell Number", maxLength: 10, validate: [isRequired, isValidCellphoneNumber] },
    email: { type: 'email', label: 'Email', validate: [isValidEmail] },
    idNumber: { type: 'text',label: "ID Number", maxLength: 13,validate:[isValidIdNumber] },
    idType: { type: "select",options: idTypes,label: "Identity Type"},
    passportExpiresDate: { type: "select",options: idTypes,label: "Passport Expiry Date"},
    bankName: { type: 'select', options: banks, label: 'Beneficiary Bank' },
    accountNo: { type: 'text', label: 'Beneficiary Account Number' }
  },
  disability: {
    type: 'yesno', label: "In the last 12 months, have you been so sick that you needed more than a week off work?", column: true
  },
  employmentStatus: {
    type: "select",
    label: "Employment Status",
    options: [
      { label: "Permanently Employed", value: "PERMANENT_EMPLOYMENT" },
      { label: "Self Employed", value: "OTHER" },
      { label: "Unemployed", value: "UNEMPLOYED" },
      { label: "Pensioner", value: "PENSIONER" },
      { label: "Other", value: "OTHER" },
    ]
  },
  obligationInsurances: {
    type: "fieldarray",
    config: {
      amount: {
        label: "Loan balance",
        type: "currency",
        validate: [isRequired]
      },
      loanType: {
        label: "Loan type",
        type: "select",
        options: [
          { label: "Personal Loan", value: "PERSONAL_LOAN" },
          { label: "Student Loan", value: "STUDENT_LOAN" },
          { label: "Revolving Loan", value: "REVOLVING_LOAN" },
          { label: "Credit Card", value: "CREDIT_CARD" }
        ],
        validate: [isRequired]
      }
    }
  },
  age: {
    label: "Your Age",
    type: "slider",
    min: 18,
    max: 60,
    step: 1,
    endText: "years",
    startText: null,
    validate: [isRequired, isValidAgeRange()]
  },
  income: {
    label: "Your Gross Income",
    type: "slider",
    min: 0,
    max: 50000,
    step: 100,
    validate: [isRequired, isNumber, isGreaterThan(1)]
  },
  gender: {
    label: "Your Gender",
    type: "selector",
    options: [{ value: "MALE", label: "Male" }, { value: "FEMALE", label: "Female" }],
    validate: [isRequired]
  },
  telephone: {
    label: "What is your telephone number?",
    type: "radiogroup",
    options: [],
    validate: [isRequired]
  },
  employer: {
    label: "Who is your employer?",
    type: "radiogroup",
    options: [],
    validate: [isRequired]
  },
  account: {
    label: "Who do you have an account with?",
    type: "radiogroup",
    options: [],
    validate: [isRequired]
  },
  acceptTerms: {
    type: "checkbox",
    label: "Accept our Terms of Services and Privacy Policy",
    validate: value => value === true ? undefined : "You need to accept terms and conditions to continue"
  },
  DOB: { type: 'date', label: 'Birth Date', validate: [isRequired, doesDOBMatchIdNumber, isValidAge] },
  idType: { type: "select",options: idTypes,label: "Identity Type", validate: [isRequired] },
  bankAccount: {
    bankName: { type: 'select', options: banks.map(b => b.label), label: 'Bank', validate: [isRequired] },
    accountNo: { type: 'text', label: 'Account Number', validate: [isRequired] },
    ownAccount:{ type: 'yesno', column: true, label: "Policy holder's account" },
    accountHolderName: { type: 'text', label: 'Premium Payer Name', validate: [isRequiredForThirdPartyAccount] },
    accountHolderContactNumber: { type: 'text', label: 'Contact Number', validate: [isRequiredForThirdPartyAccount, isValidCellphoneNumber]},
    accountHolderEmail: { type: 'text', label: 'Contact Email', validate: [isValidEmail] },
    accountHolderIdNumber: { type: 'text', label: 'Id Number', validate: [isRequiredForThirdPartyAccount] }
  },
  signature: {
    type: "signature",
    validate: [isRequired]
  },
  idDocumentId: {
    type: "fileupload",
    label: "ID Document / Drivers Licence",
    prompt: "Upload your Identity Document / Drivers Licence",
  }
}

function CreditLifeQuickQuote({ open, setOpen, sales, setShowSideBar, productType = "OPEN_MARKET", showSideBar }) {
  const { data, loading, error } = useQuery(sales ? SALES_DETAILS : MY_DETAILS)
  useEffect(() => {
    setShowSideBar && setShowSideBar(false)
  }, [open])
  let { myClient, me } = data || {}
  if (!myClient || sales) {
    myClient = {}
  }
  console.log("CLIQQ", data, loading, error, myClient, me)

  const [accessCode, setAccessCode] = useState(null)

  if (loading) { return <div /> }

  const setQuestionData = ({ telephones, accounts, employers }) => {
    fields.telephone.options = telephones
    fields.account.options = accounts
    fields.employer.options = employers
  }

  return <WizardModal
    name="creditLifeQQ"
    open={open}
    setOpen={setOpen}
    steps={buildSteps(setOpen, setQuestionData, accessCode, setAccessCode)}
    initialValues={chatState => ({ currentStep: 0, wizardSection: "QQ", acceptTerms: true, signedIn: !!(myClient && myClient.idNumber), DOB: myClient && myClient.birthDate, age: myClient && myClient.birthDate ? moment().diff(moment(myClient.birthDate), "years") : null, gender: (myClient && myClient.gender) || "MALE", coverAmount: 100000, firstName: myClient.firstNames, surname: myClient.surname, income: myClient.income || myClient.estimatedIncome, idNumber: myClient.idNumber, email: myClient.email, idType: myClient.idType, cellNumber: myClient.cellNumbers && myClient.cellNumbers[0], bankAccount: { bankName:"Capitec Bank", verifyAccountInfo: true  }, paymentDay: 25, beneficiary: { idType: "rsa_id"}, numLoans: 0, salesPersonId: sales && me && me.id, productType })}
    fields={fields}
    renderBodyHeader={FuneralQuickQuoteHeader}
    renderContentContainer={QuickQuoteModalContainer}
    renderBodyFooter={FuneralQuickQuoteProgress}
  />
}

export default CreditLifeQuickQuote
