import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { OTPContainer, LogoImageContainer, OTPContentContainer, ButtonsContainer } from './OTP.style'
import { Button, Text, ActivityIndicator } from '../generic'
import { Row, Col } from '../generic/grid'
import { useMutation, useApolloClient } from 'react-apollo'
import gql from 'graphql-tag'
import OTPInput from '../OTPInput'
import { logo } from '../../images'
import ContactModal from '../ContactModal'
import moment from 'moment'

const GET_CLIENT_INFO = gql`{
  myClient {
    id
    acceptedTerms
    marketingConsent
    isRegistration
    otpVerification
  }
}`

const SEND_OTP = gql`mutation ($communicationType: CommunicationType) {
  sendOTP(communicationType: $communicationType)
}`

const CONFIRM_OTP = gql`mutation ($otp: String) {
  confirmOTP(OTP: $otp) 
}`

const MAX_ATTEMPTS = 3

const formatCellNumber = number => {
  return number && `+27 ${number.substring(1,3)} xxx  ${number.substring(6,10)}`
}

const formatEmail = email => {
  if (!email) { return undefined }
  let beforeAt = email.substring(0,email.indexOf('@'))
  let afterLastDot = email.substring(email.length - Array.from(email).reverse().join('').indexOf('.'))
  return `${beforeAt}@xxx.${afterLastDot}`
}

const formatSentTo = (communicationType, to) => {
  if (communicationType === "SMS") {
    return formatCellNumber(to)
  } else {
    return formatEmail(to)
  }
}

// TODO: Rename used cell number

function OTP({ nextRoute, defaultCommunicationType = "SMS" }) {
  const apolloClient = useApolloClient()
  const [otp, setOtp] = useState("")
  const [attempts, setAttempts] = useState(0) 
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [sentTo, setSentTo] = useState("")
  const [communicationType, setCommunicationType] = useState(defaultCommunicationType)
  const [disableResend, setDisableResend] = useState(false)
  const [contactModalOpen, setContactModalOpen] = useState(false)
  const [expiryTime, setExpiryTime] = useState(30)

  const [sendOTP] = useMutation(SEND_OTP)
  const [confirmOTP] = useMutation(CONFIRM_OTP)

  const resendOTP = async () => {
    setLoading(true)
    setDisableResend(true)
    setAttempts(0)
    const { data } = await sendOTP({ variables: { communicationType  } })
    setTimeout(() => setDisableResend(false), 30000)
    setLoading(false)
    setSentTo(data.sendOTP)
    // TODO: put up an error
  }

  const submitOTP = async () => {
    setSubmitting(true)
    const result = await confirmOTP({ variables: { otp } })
    if (result.data.confirmOTP) {
      setLoading(true)
      const res = await apolloClient.query({ query: GET_CLIENT_INFO, fetchPolicy: "network-only" })
      setLoading(false)
      navigate(nextRoute || '/welcome')
    } else {
      setAttempts(attempts + 1)
      setSubmitting(false)
    }
  }

  const changeCommunicationType = () => {
    setLoading(true)
    setCommunicationType(communicationType === "SMS" ? "EMAIL" : "SMS")
  }

  useEffect(() => {
    setTimeout(() => {
      if (expiryTime > 0) {
        setExpiryTime(expiryTime - 1)
      }
    }, 60000)
  }, [expiryTime])

  useEffect(() => {
    resendOTP()
    setExpiryTime(30)
  }, [communicationType])

  return (
    <OTPContainer>
      <ContactModal open={contactModalOpen} setOpen={setContactModalOpen} routing="sav" />
      <LogoImageContainer>
        <img src={logo} alt="Meerkat" />
      </LogoImageContainer>
      {loading ? 
        <ActivityIndicator /> : 
        <>
          <Text size={24} bold>{attempts === 0 ? "We've sent your OTP" : attempts >= MAX_ATTEMPTS ? "Let's take a moment" : "Oops, incorrect OTP"}</Text>
          <p />
          <>
            <Text size={16}>Your OTP was sent to</Text>
            <Text size={16} bold>{formatSentTo(communicationType, sentTo)}</Text>
            <Text size={16}>It will expire in {expiryTime} minutes</Text>
          </> 
          {attempts === 0 
            ? <div />
            : attempts >= MAX_ATTEMPTS 
              ? <>
                  <Text size={16}>You have exceed the maximum number of OTP attempts.</Text>
                  <p />
                  <Text size={16}>Request a call back and our team will help you get back on track!</Text>
                </>
              : expiryTime === 0 ?
                <>
                  <Text>Your OTP has expired - Resend to get a new one. You have {MAX_ATTEMPTS - attempts} attempts left.</Text>
                </>
                :
                <>
                  <Text>The OTP you’ve entered was incorrect. Please Try again. You have {MAX_ATTEMPTS - attempts} attempts left.</Text>
                </>
          }
          <p />
          <OTPContentContainer>
            {attempts < MAX_ATTEMPTS && <>
              <Text size={16}>Enter OTP</Text>
              <p />
              <OTPInput value={otp} onChange={v => setOtp(v)} />
              <Row padTop="24px">
                <Col padLeft="4px" alignHorizontal="flex-start">
                  <Text size={16} color={disableResend ? "grey4" : "primary"} clickable onClick={!disableResend && resendOTP}>Resend {communicationType === "SMS" ? "SMS" : "Email"}</Text>
                </Col>
                <Col padRight="4px" alignHorizontal="flex-end">
                  <Text size={16} clickable onClick={changeCommunicationType}>Send via {communicationType === "SMS" ? "Email" : "SMS"}</Text>
                </Col>
              </Row>
              <p />
            </>}
            <ButtonsContainer>
              {attempts < MAX_ATTEMPTS 
                ? <Button submitting={submitting} disabled={otp.length < 4} onClick={submitOTP} title={"Verify Registration"} />  
                : <Button title={"Request a callback"} onClick={() => setContactModalOpen(true)} />
              }
            </ButtonsContainer>
          </OTPContentContainer>
        </>}
    </OTPContainer>
    
  )
}

export default OTP
    