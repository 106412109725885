import React, { useState } from 'react'
import { GraphQLFormProvider, FormField, FieldValue, SubmitButton } from 'react-form-helper'
import { renderInput, renderDisplay, renderLabel, Mandatory } from '../../generic/form'
import gql from 'graphql-tag'
import { Row } from '../../generic/grid'
import { Button, Text } from '../../generic'
import { FieldContainer } from './ProfileForm.style'
import { isValidEmail, isValidIdNumber, isValidCellphoneNumber, isRequired, isRequiredForForeignID } from '../../../lib/validators'
import { idTypes } from '../../../constants'
import { touch } from 'redux-form'
import { connect } from 'react-redux'
import { prettyText } from '../../../lib/utils'
import { TermsAndConditionsModal } from '../../shared'


const GET_MY_PROFILE = gql`{
  myClient {
    id
    title
    firstNames
    surname
    idNumber
    idType
    nickName
    gender
    birthDate
    passportExpiresDate
    acceptedTerms
    photo {
      id
      url
    }
    selfieId
    selfie {
      id
      url
    }
    email
    cellNumbers
  }
}`

const UPDATE_MY_PROFILE = gql`
  mutation UpdateProfile($input: UpdateClientInput) {
    updateMyProfile(input: $input) {
      id
      title
      firstNames
      surname
      idNumber
      idType
      nickName
      gender
      birthDate
      passportExpiresDate
      photo {
        id
        url
      }
      selfieId
      selfie {
        id
        url
      }
      email
      cellNumbers
    }
  }
`

function TermsAndConditionsLink({ children }) {
  const [open, setOpen] = useState(false)

  return <>
    <TermsAndConditionsModal open={open} setOpen={setOpen} />
    <span onClick={() => setOpen(true)}>{children}</span>
  </>
}

const fields = {
  selfie: {
    strip: true
  },
  photo: {
    strip: true
  },
  selfieId: {
    label: "Selfie",
    type: "fileupload"
  },
  firstNames: {
    label: "First Name",
    type: "text",
    required: true,
    validate: [isRequired]
  },
  surname: {
    label: "Last Name",
    type: "text"
  },
  nickName: {
    label: "Nick Name",
    type: "text"
  },
  gender: {
    label: "Gender",
    type: "select",
    options: [{ value: "MALE", label: "Male" }, { value: "FEMALE", label: "Female" }]
  },
  idNumber: {
    label: "ID Number",
    type: "text",
    required: true,
    validate: [isRequired, isValidIdNumber]
  },
  idType: {
    label: "ID Type",
    type: "select",
    options: idTypes
  },
  passportExpiresDate: {
    label: "Passport Expiry Date",
    type: "date",
    validate: [isRequiredForForeignID]
  },
  "cellNumbers[0]": {
    label: "Mobile",
    type: "text",
    required: true,
    validate: [isRequired, isValidCellphoneNumber]
  },
  birthDate: {
    label: "Date of Birth",
    type: "date"
  },
  email: {
    label: "Email",
    type: "text",
    validate: [isValidEmail]
  },
  acceptedTerms: {
    label: <Text>Accept our <TermsAndConditionsLink>T&Cs</TermsAndConditionsLink></Text>,
    type: "checkbox",
    validate: value => value === true ? undefined : "You need to accept our Ts & Cs to Continue"
  }
}

function Layout({ getIdNumber, setTouched }) {
  return (
    <>
      <Row wrap>
        <FieldContainer><FormField name="firstNames" /></FieldContainer>
        <FieldContainer><FormField name="surname" /></FieldContainer>
      </Row>
      <Row wrap>
        <FieldContainer><FormField name="birthDate" /></FieldContainer>
        <FieldContainer><FormField name="gender" /></FieldContainer>
      </Row>
      <Row wrap>
        <FieldContainer><FormField name="idType" displayOnly={!getIdNumber} /></FieldContainer>
        <FieldContainer><FormField name="idNumber" displayOnly={!getIdNumber} /></FieldContainer>
      </Row>
      <Row wrap>
        <FieldValue name="idType" renderField={({ input }) => 
          input.value !== 'rsa_id' ? <FieldContainer><FormField name="passportExpiresDate" /></FieldContainer> : <div />
        } />
      </Row>
      <Row wrap>
        <FieldContainer><FormField name="cellNumbers[0]" /></FieldContainer>
      </Row>
      <Row wrap>
        <FieldContainer><FormField name="email" /></FieldContainer>
      </Row>
      <FieldContainer><Mandatory /></FieldContainer>
      <p />
      {getIdNumber && <center><FormField name="acceptedTerms" /><p /></center>}
      <center><SubmitButton renderSubmitButton={({ submitting, invalid, submit, asyncValidating }) => <Button title={getIdNumber ? "Send OTP" : "Save"} onClick={submit} disabled={asyncValidating || invalid} onDisabledClick={() => setTouched(Object.keys(fields))} submitting={submitting} />} /></center>
      <div style={{ height: "50px"}} />
    </>
  )
}

const mapDispatchToProps = (dispatch, { formName }) => ({
  setTouched: fieldNames => dispatch(touch(formName, ...fieldNames))
})

const MyLayout = connect(() => ({}), mapDispatchToProps)(Layout)

const HAS_EXISTING_USER = gql`query ($idNumber: String!, $idType: String) {
  hasExistingUser(idNumber: $idNumber, idType: $idType)
}`

const asyncValidate = (values, dispatch, { client }) => {
  const { idNumber } = values

  if (!idNumber) { return null }

  return client.query({ query: HAS_EXISTING_USER, variables: { idNumber } }).then(({ data }) => {
    if (data.hasExistingUser) {
      throw { idNumber: `You have already signed up using this ID Number with another ${prettyText(data.hasExistingUser)} account` }
    }
  })
}

function ProfileForm({ afterSubmit, getIdNumber }) {
  return <GraphQLFormProvider
    name="profile"
    query={GET_MY_PROFILE}
    updateMutation={UPDATE_MY_PROFILE}
    mode="edit"
    fields={fields}
    renderInput={renderInput}
    renderDisplay={renderDisplay}
    renderLabel={renderLabel}
    InputFormLayout={props => <MyLayout formName="profile" getIdNumber={getIdNumber} {...props} />}
    afterSubmit={afterSubmit}
    initialValuesFromData={data => data.myClient ? ({ ...data.myClient, idType: data.myClient.idType || "rsa_id" }) : {}}
    asyncValidate={getIdNumber && asyncValidate}
    asyncBlurFields={getIdNumber && ['idNumber']}
  />
}

export default ProfileForm