import React, { useState, useRef, useEffect } from 'react'
import { OTPInputContainer, OTPInputDigit, HiddenInput } from './OTPInput.style'
import { Row } from '../generic/grid'
import { Text } from '../generic'

function OTPInput({ value, onChange, digits = 4 }) {
  const [activeDigit, setActiveDigit] = useState(-1)
  const [inputBox, setInputBox] = useState("X")
  const input = useRef()

  useEffect(() => {
    if (inputBox !== "X") {
      if (inputBox.indexOf("X") === -1) {
        handleKeyDown({ key: "Backspace" })
      } else {
        handleKeyDown({ key: inputBox.replace("X","") })
      }
      setInputBox("X")
    }
  }, [inputBox])

  const handleKeyDown = e => {
    if (/[0-9]/.test(e.key)) {
      // console.log(activeDigit, e.key)
      let newValue = (value || "") + ""
      if (newValue.length - 1 < activeDigit) {
        newValue += e.key
      } else {
        newValue = newValue.substring(0, activeDigit) + e.key + newValue.substring(activeDigit + 1)
      }
      onChange(newValue.substring(0, digits))
      if (activeDigit >= digits - 1) {
        input.current.blur()
      } else {
        setActiveDigit(activeDigit + 1)
      }
    } else if (e.key === "Backspace") {
      let newValue = (value || "") + ""
      if (newValue.length <= activeDigit) {
        newValue = newValue.substring(0, activeDigit - 1) + newValue.substring(activeDigit + 1)
        setActiveDigit(Math.max(activeDigit - 1, 0))
      } else {
        newValue = newValue.substring(0, activeDigit) + newValue.substring(activeDigit + 1)
      }
      onChange(newValue.substring(0, digits))
    } else {
      // console.log(e.key)
    }
  }

  return <OTPInputContainer>
    <Row>{Array.from(Array(digits).keys(), (index) => <OTPInputDigit active={activeDigit === index} onClick={() => { input.current.focus(); setActiveDigit(Math.min(index, (value || "").toString().length)) }}><Text size={44} bold primary>{(value || "").toString()[index] || ""}</Text></OTPInputDigit>)}</Row>
    <HiddenInput type="text" value={inputBox} onChange={e => setInputBox(e.target.value)} ref={input} onBlur={() => setActiveDigit(-1)} />
  </OTPInputContainer>
}

export default OTPInput