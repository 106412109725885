import React from 'react'
import { WizardModal } from '../../shared'
import firebase from 'gatsby-plugin-firebase'
import { isRequired } from '../../../lib/validators'
import { UPDATE_POLICY } from '../CreditLifeQuickQuote/CreditLifeQuickQuote'

const buildSteps = (setOpen, refetchQueries, id) => ([{
    name: "init",
    noNext: true,
    nextAfterDelay: 10,
    nextSteps: ["getSignature"]
  },
  {
    name: "getSignature",
    beforeStep: async () => {
      firebase.analytics().logEvent("CREDIT_LIFE_GET_SIGNATURE");
    },
    title: "Credit Life",
    title2: "Add your signature to make it official",
    title3: <>
      Your signature will be added to the following documents to finalise your credit life insurance policy.
      <p />
      <ul style={{ textAlign: "left", fontSize: "12px", margin: 0 }}>
        <li>Application Form</li>
        <li>Power of Attorney to act on your behalf to cancel credit life insurance</li>
        <li>Form 23s to each of your Credit Providers</li>
        <li>Replacement Advice Record for you and your current insurers</li>
      </ul>
    </>,
    fieldNames: ["signature"],
    nextSteps: ["doSign"]
  },
  {
    beforeStep: async ({ apolloClient, chatState }) => {
      const { signature } = chatState
      const result = await apolloClient.mutate({ mutation: UPDATE_POLICY, variables: { signature, id }, refetchQueries })
      console.log(result)
    },
    name: "doSign",
    noNext: true,
    nextAfterDelay: 1000,
    nextSteps: ["done"]
  },
  {
    name: "done",
    beforeStep: () => setOpen(false)
  }
])

const fields = {
  signature: {
    type: "signature",
    validate: [isRequired]
  }
}

function CreditLifeSignature({ open, setOpen, id, refetchQueries }) {
  return <WizardModal
    name={`CreditLifeSignature-${id}`}
    open={open}
    setOpen={setOpen}
    steps={buildSteps(setOpen, refetchQueries, id)}
    initialValues={{ creditLifeId: id }}
    fields={fields}
  />
}

export default CreditLifeSignature
