import React, { useEffect } from 'react'
import { WizardModal } from '../../shared'
// import { printCurrency } from '../../../lib/utils'
// import {} from './MemberDetailsModal.style'
import gql from 'graphql-tag'
import { isRequired, forMemberTypes, isValidIdNumber, doesDOBMatchIdNumber, isValidAge, doesDobMatchAge } from '../../../lib/validators'
import  { idTypes, relationshipTypes } from '../../../constants'

const memberTypeText = {
  POLICY_HOLDER: "My Details",
  PARENT: "Parent / In Law",
  EXTENDED: "Extended Family Member",
  SPOUSE: "Spouse",
  CHILD: "Child",
  BENEFICIARY: "Beneficiary"
}

const buildSteps = (addMemberType, setOpen) => ([{
  name: "step1",
  title2: ({ memberType }) => addMemberType ? `Add ${memberTypeText[addMemberType]}` : `Edit ${memberTypeText[memberType] || "Dependant"}`,
  fieldNames: ({ memberType }) => ["PARENTS", "EXTENDED"].includes(memberType) ? ["firstNames", "surname", "gender", "relationship", "idType", "idNumber", "age", "DOB"] : ["firstNames", "surname", "gender", "idType", "idNumber", "age", "DOB"],
  nextSteps: ["submit"]
}, {
  name: "submit",
  submitBeforeStep: true,
  nextSteps: ["done"],
  nextAfterDelay: 10
}, {
  name: "done",
  beforeStep: () => setOpen(false)
}])

const GET_MEMBER = gql`
  query ($id: String!) {
    member(id: $id) {
      firstNames
      surname
      idType
      idNumber
      age
      DOB
      gender
      memberType
      relationship
    }
  }
`

const UPDATE_MEMBER = gql`
  mutation ($id: String!, $input: UpdateMemberInput!) {
    updateMember(id: $id, input: $input) {
      id
      firstNames
      surname
      idType
      idNumber
      age
      DOB
      gender
      memberType
      relationship
    }
  }
`

const ADD_MEMBER = gql`
  mutation ($input: CreateMemberInput!) {
    createMember(input: $input) {
      id
      firstNames
      surname
      idType
      idNumber
      age
      DOB
      gender
      memberType
      relationship
    }
  }
`

const fields = {
  firstNames: {
    label: "First Names",
    type: "text",
    validate: [isRequired]
  },
  surname: {
    label: "Surname",
    type: "text"
  },
  idType: {
    label: "ID Type",
    type: "select",
    options: idTypes,
    diabled: false
  },
  idNumber: {
    label: "ID Number",
    type: "text",
    validate: [isValidIdNumber],
    disabled: false
  },
  age: {
    label: "Age",
    validate: [isValidAge, isRequired]
  },
  DOB: {
    label: "Date of Birth",
    type: "date",
    validate: [doesDOBMatchIdNumber, doesDobMatchAge, isRequired]
  },
  gender: { 
    label: "Gender",
    type: "selector",
    validate: [forMemberTypes(["EXTENDED", "PARENT"], isRequired)], options: [{ value: "MALE", label: "Male" }, { value: "FEMALE", label: "Female" }] 
  },
  relationship: {
    label: "Relationship",
    type: "select",
    options: relationshipTypes.filter(
      (r) =>
        r.value.indexOf("MOTHER") === -1 &&
        r.value.indexOf("FATHER") === -1 &&
        r.value.indexOf("FRIEND") === -1 &&
        r.value.indexOf("FAMILY_FRIEND") === -1 &&
        r.value.indexOf("SPOUSE") === -1 &&
        r.value.indexOf("HUSBAND") === -1 &&
        r.value.indexOf("WIFE") === -1
    ),
    validate: [forMemberTypes(["EXTENDED", "PARENT"], isRequired)],
  }
}

function MemberDetailsModal({ id, open, setOpen, addMemberType, refetchQueries, funeralId }) {
  if (!id && addMemberType) {
    fields.idNumber.disabled = false
    fields.idType.disabled = false
  }

  return <WizardModal
    name={`member-${id || (addMemberType && addMemberType.toLowerCase()) || "child"}`}
    id={id}
    mode={addMemberType ? "add" : "edit"}
    query={GET_MEMBER}
    open={open}
    setOpen={setOpen}
    steps={buildSteps(addMemberType, setOpen)}
    updateMutation={UPDATE_MEMBER}
    addMutation={ADD_MEMBER}
    initialValuesFromData={data => {
      if (data.member && data.member.idNumber) {
        fields.idNumber.disabled = true
        fields.idType.disabled = true
      } else {
        fields.idNumber.disabled = false
        fields.idType.disabled = false
      }
      return data.member
    }}
    initialValues={{ memberType: addMemberType, funeralsIds: [funeralId] }}
    refetchQueries={refetchQueries}
    fields={fields}
  />
}

export default MemberDetailsModal
