import React, { useEffect } from 'react'
import { WizardModal } from '../../shared'
import { Text } from '../../generic'
import { printCurrency } from '../../../lib/utils'
import AddBankAccount from '../../profile/AddBankAccount'
import gql from 'graphql-tag'
import firebase from 'gatsby-plugin-firebase'
import { isRequired } from '../../../lib/validators'
import gotoFlowComponent from '../../generic/ChatFlow/gotoFlowComponent'

const WITHDRAW_FROM_BUCKET = gql`
  mutation WithdrawFromBucket($bucketId: String!, $savingsId: String, $bankAccountId: String, $amount: Float!) {
    withdrawSavings(bucketId: $bucketId, savingsId: $savingsId, bankAccountId: $bankAccountId, amount: $amount)
  }
`

const bucketName = {
  YEAR_END: 'December bucket',
  EMERGENCY: 'Emergency Fund',
  HOUSE: 'Housing Fund',
  CAR: 'Car Fund',
  EDUCATION: 'Education Fund',
  HOLIDAY: 'Holiday Fund',
  RETIREMENT: 'Retirement Fund',
  HEALTH: 'Medical Savings',
  FUNERAL: 'Funeral Savings',
  OTHER: 'Custom Savings',
}

const buildSteps = ({
  setOpen,
  transferToEmergencyFund,
  clientId,
  savingsId,
  refetchQueries,
  refetchBankAccounts,
  buckets,
}) => [
  // The ChatFlow wizard doesn't seem to currently handle the first step being skipped,
  // so we're adding an empty step to make sure the skipping of bucket selection works.
  {
    name: 'forceSkip',
    nextAfterDelay: 0,
    nextSteps: ['withdrawBucket'],
    noNext: true,
  },
  {
    name: 'withdrawBucket',
    title2: `You are about to withdraw from your savings`,
    title3: 'Choose a savings goal to withdraw funds from',
    fieldNames: ['bucketId'],
    nextSteps: ({ bucketId }) => {
      const bucket = buckets.find(({ id }) => id === bucketId)

      firebase.analytics().logEvent('SAVINGS_WITHDRAW_BUCKET_SELECTED', {
        clientId,
        savingsId,
        bucketType: bucket.bucketType,
      })

      return ['withdrawAmount']
    },
    skipStep: ({ bucketId }) => !!bucketId,
  },
  {
    name: 'withdrawAmount',
    title2: ({ bucketId }) => {
      const bucket = buckets.find(({ id }) => id === bucketId)

      return `Withdraw from ${bucketName[bucket.bucketType]}`
    },
    title3: 'You can enter your own amount or drag the slider to the amount you would like to withdraw.',
    fieldNames: ({ bucketId }) => [`amount_${bucketId}`],
    nextSteps: ({ bucketId }) => {
      const bucket = buckets.find(({ id }) => id === bucketId)

      firebase.analytics().logEvent('SAVINGS_WITHDRAW_AMOUNT_ENTERED', {
        bucketType: bucket.bucketType,
        transferToEmergencyFund,
        clientId,
        savingsId,
      })

      return transferToEmergencyFund ? ['areYouSure'] : ['areYouSure2']
    },
  },
  {
    name: 'areYouSure',
    title: (
      <>
        <Text span bold>
          Are you sure you want to withdraw your savings?
        </Text>{' '}
        You can add your savings to your Emergency fund to cover your for unplanned events.
      </>
    ),
    fieldNames: ['checkSure'],
    nextSteps: ({ checkSure }) => (checkSure === true ? ['bankAccount'] : ['transferToEmergencyFund']),
  },
  {
    name: 'areYouSure2',
    title: (
      <Text span bold>
        Are you sure you want to withdraw your savings?
      </Text>
    ),
    fieldNames: ['checkSure2'],
    nextSteps: ({ checkSure2 }) => (checkSure2 === true ? ['bankAccount'] : ['done']),
  },
  {
    name: 'bankAccount',
    title: 'Select your bank account',
    fieldNames: ['bankAccountId'],
    nextButtons: [
      {
        label: 'Add Bank Account',
        buttonProps: { inverted: true, outlined: false, disabled: false },
        nextSteps: ['addBankAccount'],
      },
      { label: 'Confirm', nextSteps: ['completeWithdrawal'] },
    ],
    nextButtonsInColumn: true,
    beforeStep: ({ chatState }) => {
      const { bucketId } = chatState
      const bucket = buckets.find(({ id }) => id === bucketId)

      console.log('CHAT STATE', chatState)

      firebase
        .analytics()
        .logEvent('SAVINGS_WITHDRAW_BANK_SELECT', { bucketType: bucket.bucketType, clientId, savingsId })
    },
  },
  {
    name: 'addBankAccount',
    component: gotoFlowComponent(AddBankAccount, ({ gotoNext }) => ({
      startWithCleanState: false,
      clientId,
      afterSubmit: () => {
        refetchBankAccounts().then(() => gotoNext())
      },
    })),
    noNext: true,
    beforeStep: ({ chatState }) => {
      const { bucketId } = chatState
      const bucket = buckets.find(({ id }) => id === bucketId)

      firebase
        .analytics()
        .logEvent('SAVINGS_WITHDRAW_ADD_BANK_ACCOUNT', { bucketType: bucket.bucketType, clientId, savingsId })
    },
    nextSteps: ['bankAccount'],
  },
  {
    name: 'completeWithdrawal',
    beforeStep: async ({ apolloClient, chatState }) => {
      const { bucketId, savingsId, bankAccountId } = chatState
      const bucket = buckets.find(({ id }) => id === bucketId)

      await apolloClient.mutate({
        mutation: WITHDRAW_FROM_BUCKET,
        variables: {
          bucketId,
          savingsId,
          bankAccountId,
          amount: chatState[`amount_${bucketId}`],
        },
        refetchQueries,
        awaitRefetchQueries: true,
      })
      firebase.analytics().logEvent('SAVINGS_WITHDRAW_COMPLETE', { bucketType: bucket.bucketType, clientId, savingsId })
    },
    text: (chatState) => {
      const { bucketId } = chatState
      const amount = chatState[`amount_${bucketId}`]

      return (
        <div style={{ textAlign: 'center', margin: '8px' }}>
          <Text size={16}>Total withdrawn</Text>
          <p />
          <Text size={24} primary bold>
            {printCurrency(amount)}
          </Text>
          <p />
          <Text size={18} bold>
            Your withdrawal is being processed. We will send you a notification once we have released your money.
          </Text>
          <p />
        </div>
      )
    },
    nextTitle: 'Take me home',
    nextSteps: ({ bucketId }) => {
      const bucket = buckets.find(({ id }) => id === bucketId)

      firebase.analytics().logEvent('SAVINGS_WITHDRAW_DONE', { bucketType: bucket.bucketType, clientId, savingsId })

      return ['done']
    },
  },
  {
    name: 'transferToEmergencyFund',
    beforeStep: async ({ chatState }) => {
      const { bucketId } = chatState
      const bucket = buckets.find(({ id }) => id === bucketId)

      await transferToEmergencyFund(chatState[`amount_${bucketId}`])

      firebase
        .analytics()
        .logEvent('SAVINGS_WITHDRAW_TRANFER_TO_EMERGENCY_FUND', { bucketType: bucket.bucketType, clientId, savingsId })
    },
    text: (chatState) => {
      const { bucketId } = chatState
      const amount = chatState[`amount_${bucketId}`]

      return (
        <div style={{ textAlign: 'center', margin: '8px' }}>
          <Text size={18} bold>
            My Emergency Fund
          </Text>
          <p />
          <Text size={24} primary bold>
            {printCurrency(amount)}
          </Text>
          <p />
          <Text size={18} bold>
            Your December savings has been added to your Emergency fund.
          </Text>
          <p />
          <Text size={18} bold>
            Keep up contributing towards your savings goals.
          </Text>
          <p />
        </div>
      )
    },
    nextTitle: 'Take me home',
    nextSteps: ({ bucketId }) => {
      const bucket = buckets.find(({ id }) => id === bucketId)

      firebase
        .analytics()
        .logEvent('SAVINGS_TRANSFER_TO_EMERGENCY_DONE', { bucketType: bucket.bucketType, clientId, savingsId })

      return ['done']
    },
  },
  {
    name: 'done',
    beforeStep: () => setOpen(false),
  },
]

const buildFields = ({ bankAccounts, buckets }) => ({
  bucketId: {
    label: 'Savings goal',
    required: true,
    type: 'select',
    options: buckets
      ? buckets
          .filter((bucket) => bucket.percentageFull || bucket.percentageFull === 0)
          .map((bucket) => ({
            label: bucketName[bucket.bucketType],
            caption: printCurrency(bucket.amount),
            value: bucket.id,
          }))
      : [],
    validate: [
      isRequired,
      (value) => {
        const bucket = buckets.find(({ id }) => id === value)

        return bucket.amount >= 50
          ? undefined
          : 'You can only withdraw from a savings goal with a balance greater than R 50.00'
      },
    ],
  },
  ...buckets
    .filter((bucket) => bucket.percentageFull || bucket.percentageFull === 0)
    .reduce(
      (out, bucket) => ({
        ...out,
        [`amount_${bucket.id}`]: {
          type: 'slider',
          endText: null,
          startText: 'R',
          min: 50,
          max: Math.floor(bucket.amount * 100) / 100,
          step: 10,
          validate: (value) => {
            if (bucket.amount < 50) {
              return 'You can only withdraw from a savings goal with a balance greater than R 50.00'
            }

            return value >= 50 && value <= bucket.amount
              ? undefined
              : `The withdrawal amount must be between R 50.00 and ${printCurrency(bucket.amount)}`
          },
        },
      }),
      {}
    ),
  checkSure: {
    type: 'radiogroup',
    options: [
      { value: true, label: 'Yes, I am sure' },
      { value: false, label: 'No, transfer savings to My Emergency Fund' },
    ],
    validate: (value) => (value === true || value === false ? undefined : 'Select one'),
  },
  checkSure2: {
    type: 'radiogroup',
    options: [
      { value: true, label: 'Yes, I am sure' },
      { value: false, label: 'No, I do not want to continue' },
    ],
    validate: (value) => (value === true || value === false ? undefined : 'Select one'),
  },
  bankAccountId: {
    type: 'radiogroup',
    options: bankAccounts.map((b) => ({
      value: b.id,
      label: (
        <>
          <Text bold>{b.bankName}</Text>
          <Text bold>Acc no. {b.accountNo}</Text>
        </>
      ),
    })),
    validate: (value) => (!value ? 'Select one' : undefined),
  },
})

let fields = {}

function WithdrawalModal({
  open,
  setOpen,
  bankAccounts,
  clientId,
  savingsId,
  bucketId,
  refetchQueries,
  transferToEmergencyFund,
  refetchBankAccounts,
  buckets,
}) {
  const bucket = bucketId && buckets.find((b) => b.id === bucketId)
  firebase.analytics().logEvent('SAVINGS_WITHDRAW_OPENED', {
    clientId,
    savingsId,
    openedFrom: bucket ? `${bucketName[bucket.bucketType]} bucket` : 'My Savings',
  })

  useEffect(() => {
    fields = buildFields({ bankAccounts, buckets })
  }, [bankAccounts, buckets])

  useEffect(() => {
    if (fields.bankAccountId) {
      fields.bankAccountId.options = bankAccounts.map((b) => ({
        value: b.id,
        label: (
          <>
            <Text bold>{b.bankName}</Text>
            <Text bold>Acc no. {b.accountNo}</Text>
          </>
        ),
      }))
    }
  }, [bankAccounts])

  return (
    <>
      <WizardModal
        name="withdrawal"
        open={open}
        setOpen={setOpen}
        steps={buildSteps({
          setOpen,
          transferToEmergencyFund,
          clientId,
          refetchQueries,
          refetchBankAccounts,
          buckets,
          savingsId,
        })}
        initialValues={{ savingsId, bucketId }}
        fields={fields}
      />
    </>
  )
}

export default WithdrawalModal
