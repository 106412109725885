import React, { useState } from 'react'
// import {} from './AddNewDocument.style'
import UploadDocument from '../../UploadDocument'
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo'
import { ActivityIndicator } from '../../../generic'
import { navigate } from 'gatsby'

const GET_CLIENT_ID = gql`{
  myClient {
    id
  }
}`

function AddNewDocument({ type, open, setOpen, subPage }) {
  const [isOpen, setIsOpen] = useState(open || true)
  const { data, loading, error } = useQuery(GET_CLIENT_ID)
  const handleSetOpen = (value) => {
    setIsOpen(value)
    if (setOpen) {
      setOpen(value)
    }
    if (value === false && !subPage) {
      navigate('/documents')
    } else if (value === false && subPage){
      navigate(`/documents/${subPage}`)
    }
  }

  if (loading) { return <ActivityIndicator /> }
  if (error) return "An error has occurred"

  return <UploadDocument open={isOpen} setOpen={handleSetOpen} type={type} clientId={data.myClient.id} />
}

export default AddNewDocument
