import React from 'react'
import { SavingsCardContainer, ProgressBarContainer, SavingsCardImageContainer } from './SavingsCard.style'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import Img from 'gatsby-image'
import { Text } from '../../generic'
import ProgressBar from '../ProgressBar'

const cardText = {
  HOUSE: "Housing",
  CAR: "Car",
  EDUCATION: "Education",
  HOLIDAY: "Holiday",
  EMERGENCY: "Emergency",
  YEAR_END: "December Fund",
  RETIREMENT: "Retirement",
  HEALTH: "Health",
  FUNERAL: "Funeral",
  OTHER: "Other"
}
function SavingsCard({ type, percentage, to, targetDate, title, onClick }) {
  const data = useStaticQuery(graphql`{
    blob: file(relativePath: { eq: "Blob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    EMERGENCY: file(relativePath: { eq: "EmergencyBlob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HOLIDAY: file(relativePath: { eq: "HolidayBlob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    EDUCATION: file(relativePath: { eq: "EducationBlob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    CAR: file(relativePath: { eq: "CarBlob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HOUSE: file(relativePath: { eq: "HousingBlob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HEALTH: file(relativePath: { eq: "HealthBlob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    RETIREMENT: file(relativePath: { eq: "RetirementBlob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    OTHER: file(relativePath: { eq: "OtherBlob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    YEAR_END: file(relativePath: { eq: "DecemberBlob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    FUNERAL: file(relativePath: { eq: "FuneralBlob.png" }) {
      childImageSharp {
        fluid(maxWidth: 184) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
  `)

  return <SavingsCardContainer to={to} onClick={onClick || (() => to && navigate(to))}>
    <SavingsCardImageContainer>
      <Img fluid={data[type] ? data[type].childImageSharp.fluid : data.blob.childImageSharp.fluid} alt={cardText[type || "OTHER"]} />
    </SavingsCardImageContainer>
    <Text medium style={{ marginTop: "11px",  marginBottom: percentage || percentage === 0 ? "0px" : "30px"  }}>{title || cardText[type || "OTHER"]}</Text>
    {(percentage || percentage === 0) && (
    <ProgressBarContainer>
      <ProgressBar percentage={percentage} targetDate={targetDate} />
    </ProgressBarContainer>)}
    {/*<Text bold primary>View</Text>*/}
  </SavingsCardContainer>
}

SavingsCard.cardText = cardText

export default SavingsCard