import React from 'react'
import { DocumentListItemContainer, DocumentListItemTitleContainer, DocumentListItemAlert, DocumentListItemArrow } from './DocumentListItem.style'
import { Text } from '../../generic'
import { rightArrow } from '../../../images/itemRow'

function DocumentListItem({ alert, title, onClick }) {
  return <DocumentListItemContainer onClick={onClick}>
    {alert && <DocumentListItemAlert />}
    <DocumentListItemTitleContainer>
      <Text size={14}>{title}</Text>
    </DocumentListItemTitleContainer>
    <DocumentListItemArrow src={rightArrow} alt=">" />
  </DocumentListItemContainer>
}

export default DocumentListItem
