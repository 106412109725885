import React, { useState } from 'react'
import { SummaryItemsRow } from '../../dashboard/MyDashboard/MyDashboard.style'
import { ActivityIndicator, Text, Button } from '../../generic'
import { useQuery, useMutation } from 'react-apollo'
import { printCurrency } from '../../../lib/utils'
import gql from 'graphql-tag'
import SummaryItemCard from '../../dashboard/SummaryItemCard'
import DetailedItemCard from '../../dashboard/DetailedItemCard'
import { BackActionHeader, ConfirmModal } from '../../shared'
import { navigate } from 'gatsby'
import moment from 'moment'

const MY_GROUP_PRODUCT = gql`query ($id:String!) {
  groupproduct(id:$id) {
    id
    groupProductNumber
    state
    balance
    processedUntil
    paymentDueDay
    payments {
      id
      amount
    }
    group {
      id
      name
    }
    groupContributions {
      id
      isPaid
      period
      contributions {
        amount
      }
    }
    funerals {
      id
      client {
        id
        firstNames
        surname
			}
      state
      currentPremiumTotal
      policyType
      livesCovered {
        memberType
      }
		}
  }
}`

const REMOVE_GROUP_PRODUCT_MEMBER = gql`
	mutation CancelFuneral($funeralId: String!, $cancelReason: String) {
		cancelFuneral(funeralId: $funeralId, cancelReason: $cancelReason) {
        id
        state
      }
    }
`

const policyDescription = (funeral) => {
  let description = ""
  if ((funeral.policyType || []).includes("COUPLE")) {
    description = "Family"
  } else {
    description = "Single Member"
  }
  if ((funeral.livesCovered || []).filter(l => l.memberType === "EXTENDED").length > 0) {
    description += " and " + (funeral.livesCovered.filter(l => l.memberType === "EXTENDED").length) + " adult dependants"
  }
  return description
}


function GroupProductContributions({ id }) {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [newMember, setNewMember] = useState(false)
  const [removeMember, setRemoveMember] = useState(false)
  const { loading, data, error } = useQuery(MY_GROUP_PRODUCT, { variables: { id } })
  const [removeGroupProductMutation] = useMutation(REMOVE_GROUP_PRODUCT_MEMBER, { refetchQueries: [{ query: MY_GROUP_PRODUCT, variables: { id } }] } )
  let dueDate = ""

  const removeGroupProductMember = (member) => {
    setRemoveMember({
      id: member.id,
      name: `${member.client.firstNames || ""} ${member.client.surname || ""}`
    })
    setConfirmOpen(true)
  }


  if (loading) {
    return <ActivityIndicator />
  }

  if (error) {
    return "Something has gone wrong. Please contact support"
  }
  const { groupContributions, processedUntil, funerals, balance } = data && data.groupproduct || {}
  const sortedGroupContributions = (groupContributions || []).sort((a, b) => b.period.localeCompare(a.period)) 
  const latestContribution = sortedGroupContributions.length > 0 && sortedGroupContributions['0'] || {}   
  const contributingMembers = (latestContribution.contributions || []).filter(contribution => contribution.amount > 0)
  return <>
    <BackActionHeader title="Home" onClick={() => navigate('/')} />
    <Text size={26} bold>Group Contribution</Text>

    <SummaryItemsRow>
      <SummaryItemCard title="Total Members" description="Contributing" value={contributingMembers.length} color="text" />
      <SummaryItemCard title="Total Payment" description={`Due: ${moment(processedUntil).format("MMM YYYY")}`} value={printCurrency((sortedGroupContributions.length > 0 && !sortedGroupContributions['0'].isPaid && sortedGroupContributions['0'].contributions || []).reduce((total, contribution) => total + contribution.amount || 0, 0))}   color="text" />
      <SummaryItemCard title="Total Paid" description="" value={printCurrency(balance)} color="text" />
    </SummaryItemsRow>
    <p />
    {/* <MokuNotePanel title="Save with Meerkat" description="Get a personalised quote for your funeral cover today. We can also consolidate any existing policies into one more affordable premium.">
      <div style={{ display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center" }}><Button onClick={() => setOpen(true)}>Get a Quick Quote</Button></div>
    </MokuNotePanel>
    <p />
    <MokuNotePanel title="Add Existing Cover" description="Add your existing cover and we’ll compare to our funeral cover to see if we can save you money each month. We’ll also be able to provide a summary of all your funeral polices in one place.">
      <div style={{ display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center" }}><Button onClick={() => setAddExisting(true)}>Add Existing Policy</Button></div>
    </MokuNotePanel>
    <p /> */}

    <Text size={16} bold>Contributors</Text>
    <p />
    {(funerals).map(funeral => <DetailedItemCard key={funeral.client.name} functionDescription={'Remove'} description={policyDescription(funeral)} title={`${funeral.client.firstNames || ""} ${funeral.client.surname || ""}`} functionColor={'alertRed'} value={printCurrency(funeral.currentPremiumTotal)}  onClick={() => removeGroupProductMember(funeral)} />)}
    <p />
    <p />
    <p />
    <center>
      <Button inverted onClick={() => setNewMember(true)}>Add New Member</Button>
    </center>
    <ConfirmModal 
        open={confirmOpen}
        title="Remove Member"
        description={`Are you sure you want to remove member ${removeMember.name}?`}
        confirmText="Yes"
        cancelText="No"
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => { removeGroupProductMutation({ variables: { funeralId: removeMember.id, cancelReason: "Member removed from scheme" } }).then(()=> setConfirmOpen(false)) }}
    />
    <div style={{ height: "75px" }} />
  </>
}

export default GroupProductContributions
