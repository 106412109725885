import React from 'react'
import { SavingsStatusCardContainer, SmilyImage } from './SavingsStatusCard.style'
import { Text } from '../../generic'
import { Col } from '../../generic/grid'
import { happyWhite, sadWhite, mehWhite } from '../../../images/smily'

const statusText = {
  "ON_TRACK": "On Track",
  "NO_SAVINGS": "No Savings yet",
  "BELOW_TARGET": "Progressing slowly"
}

const statusSmily = {
  "ON_TRACK": happyWhite,
  "NO_SAVINGS": sadWhite,
  "BELOW_TARGET": mehWhite
}

function SavingsStatusCard({ status }) {
  return <SavingsStatusCardContainer status={status}>
    <Col grow style={{ textAlign: "left" }}>
      <Text size={10} color="white" bold>Meerkat Savings Status</Text>
      <Text size={10} color="white">To date</Text>
    </Col>
    <Col size={0}>
      <Text size={16} color="white" bold>{statusText[status]}</Text>
    </Col>
    <Col size={0}>
      <SmilyImage src={statusSmily[status]} />
    </Col>
  </SavingsStatusCardContainer>
}

export default SavingsStatusCard
