import React from 'react'
import {
  DetailedItemCardContainer,
  TitleContainer,
  DescriptionContainer,
  AlertContainer,
  ValueContainer,
  ValueContainerRow,
  SummaryItemTitleContainer,
} from './DetailedItemCard.style'
import { SummaryItemDescription, SummaryItemTitle, SummaryItemValue } from '../../shared/Typography'

interface IDetailedItemCardProps {
  title: string
  description: string
  functionDescription: string
  functionColor: string
  value: number
  alert?: boolean
  color?: string
  backgroundColor?: string
  onClick?: () => void
}

function DetailedItemCard({
  title,
  description,
  value,
  functionDescription,
  functionColor,
  backgroundColor,
  onClick,
}: IDetailedItemCardProps) {
  return (
    // @ts-ignore
    <DetailedItemCardContainer backgroundColor={backgroundColor}>
      <SummaryItemTitleContainer>
        <TitleContainer>
          <SummaryItemTitle color="primary">{title}</SummaryItemTitle>
        </TitleContainer>
        <DescriptionContainer>
          <SummaryItemDescription>{description}</SummaryItemDescription>
        </DescriptionContainer>
      </SummaryItemTitleContainer>

      <ValueContainerRow>
        <AlertContainer>
          {/** @ts-ignore */}
          <SummaryItemValue color={functionColor || 'primary'} onClick={onClick}>
            {functionDescription}
          </SummaryItemValue>
        </AlertContainer>
        <ValueContainer>
          <SummaryItemValue>{value}</SummaryItemValue>
        </ValueContainer>
      </ValueContainerRow>
    </DetailedItemCardContainer>
  )
}

export default DetailedItemCard
