import React from 'react'
import { FuneralCoverItemRowContainer } from './FuneralCoverItemRow.style'
import { Col } from '../../generic/grid'
import { Text } from '../../generic'
import { printCurrency } from '../../../lib/utils'

function FuneralCoverItemRow({ title, value, color }) {
  return <FuneralCoverItemRowContainer color={color}>
    <Col alignHorizontal="flex-start"><Text size={12} bold>{title}</Text></Col>
    <Col alignHorizontal="flex-end"><Text size={12}>{printCurrency(value)}</Text></Col>
  </FuneralCoverItemRowContainer>
}

export default FuneralCoverItemRow
