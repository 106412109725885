import styled from '@emotion/styled'

const SavingsCardContainer = styled.div`
  background: ${props => props.theme.colors.grey};
  box-shadow: 0px 0px 8px rgba(64, 67, 87, 0.12);
  border-radius: 4px;
  padding: 14px;
  margin-bottom: 14px;
`

const TitleContainer = styled.div`
  margin-bottom: 4px;
`

export {
  SavingsCardContainer,
  TitleContainer
}