import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from '../generic'
import ContactForm from '../ContactForm'

function ContactModal({ open, setOpen, routing }) {
  return <Modal open={open}>
    <ContactForm routing={routing} open={open} setOpen={setOpen} />
  </Modal>
}

ContactModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  routing: PropTypes.string
}

export default ContactModal
