import React, { useState } from 'react'
import { WizardModal } from '../../../shared'
import { ActivityIndicator } from '../../../generic'
// import { printCurrency } from '../../../lib/utils'
// import {} from './GetVaccinationCertificate.style'
import gql from 'graphql-tag'
import { isRequired, isValidCellphoneNumber, isValidRsaIdNumber } from '../../../../lib/validators'
import { navigate } from 'gatsby'
import { useQuery } from '@apollo/react-hooks'
import UploadDocument from '../../UploadDocument'
import { gotoFlowComponent } from '../../../generic/ChatFlow'


const FETCH_VACCINATION_CERTIFICATE = gql`
  mutation ($input: VaccinationClientDetailsInput!) {
    fetchVaccinationCertificate(input: $input)
  }
`

const OTP_GET_DOCUMENT = gql`
  mutation ($fetchId: String, $OTP: String) {
    createVaccinationCertificateClientDocument(fetchId: $fetchId, OTP: $OTP) {
      id
    }
  }
`

const GET_CLIENT_INFO = gql`{
  myClient {
    id
    firstNames
    surname
    idNumber
    cellNumbers
  }
}`

// TODO: Duplicated function

const buildSteps = (setOpen, clientId) => ([/*{
  name: "step1",
  title: "Proof of Vaccination",
  title2: "Choose a method to provide proof of vaccination",
  nextButtons: [{ label: "Upload Vaccination Certificate", nextSteps: ["upload"] }, { label: "Get My Certificate for Me", nextSteps: ["getFromEVDS"] }],
  nextButtonsInColumn: true
},*/{ 
  name: "upload",
  component: gotoFlowComponent(UploadDocument, () => ({ type: "COVID19_VACCINATION_CERTIFICATE", clientId, afterSubmit: ({ data }) => { navigate(`/documents/vaccine/${data.createClientDocument.id}`) } })),
  nextSteps: ["upload"],
  noNext: true
}, {
  name: "scanQRCode",
  title: "Proof of Vaccination",
  title2: "Scan the QR Code on your vaccination certificate",
  fieldNames: ["qrCodeId"],
  nextOnValue: true,
  nextSteps: ["done"],
  noNext: true
}, {
  name: "getFromEVDS",
  title: "Proof of Vaccination",
  title2: "Confirm details used for EDVS",
  title3: "You will receive an SMS with an OTP if details are correct",
  fieldNames: ["idNumber", "firstNames", "surname", "cellNumber", "vaxCode"],
  nextSteps: ["EDVS_OTP"],
  nextTitle: "Send OTP"
}, {
  name: "EDVS_OTP",
  beforeStep: async ({ apolloClient, chatState, changeFieldValue }) => {
    const { idNumber, firstNames, surname, cellNumber, vaxCode } = chatState
    const result = await apolloClient.mutate({ mutation: FETCH_VACCINATION_CERTIFICATE, variables: { input: { idNumber, firstNames, surname, cellNumber, vaxCode } } })
    changeFieldValue('fetchId', result.data.fetchVaccinationCertificate)
  },
  title: "Proof of Vaccination",
  title2: "Enter OTP sent to cell number",
  fieldNames: ["OTP"],
  nextSteps: ["getCertificate"],
  nextTitle: "Get My Certificate"
}, {
  name: "getCertificate",
  beforeStep: async ({ apolloClient, chatState }) => {
    const { fetchId, OTP } = chatState
    const result = await apolloClient.mutate({ mutation: OTP_GET_DOCUMENT, variables: { fetchId, OTP } })
    const id = result.data && result.data.createVaccinationCertificateClientDocument && result.data.createVaccinationCertificateClientDocument.id
    if (id) {
      navigate(`/documents/vaccine/${id}`)
    }
  },
  nextSteps: ["done"]
}, {
  name: "done",
  beforeStep: () => setOpen(false)
}])

const fields = {
  idNumber: {
    label: "ID Number",
    validate: [isRequired, isValidRsaIdNumber]
  },
  firstNames: {
    label: "First Name",
    validate: [isRequired]
  },
  surname: {
    label: "Surname",
    validate: [isRequired]
  }, 
  cellNumber: {
    label: "Cell Number (used for EDVS)",
    validate: [isRequired, isValidCellphoneNumber]
  },
  vaxCode: {
    label: "Vaccination Proof Code (check SMS)",
    validate: [isRequired]
  },
  qrCodeId: {
    prompt: "Scan",
    type: "fileupload",
    fileTypes: "image/png, image/jpeg",
    capture: "user"
  },
  OTP: {
    label: "One Time Password",
    validate: [isRequired]
  }
}

function GetVaccinationCertificate({ open, setOpen }) {
  const [isOpen, setIsOpen] = useState(open || true)
  const { data, loading, error } = useQuery(GET_CLIENT_INFO)
  const handleSetOpen = (value) => {
    setIsOpen(value)
    if (setOpen) {
      setOpen(value)
    }
    if (value === false) {
      navigate('/documents')
    }
  }
  
  if (loading) { return <ActivityIndicator /> }
  if (error) return "An error has occurred"
  
  const clientInfo = { 
    idNumber: data.myClient.idNumber,
    firstNames: data.myClient.firstNames,
    surname: data.myClient.surname,
    cellNumber: data.myClient.cellNumbers[0]
  }
  const clientId = data.myClient.id

  return <WizardModal
    name="getVaccinationCertificate"
    open={isOpen}
    setOpen={handleSetOpen}
    steps={buildSteps(handleSetOpen, clientId)}
    initialValues={{ idType: "rsa_id", ...clientInfo }}
    fields={fields}
  />
}

export default GetVaccinationCertificate
