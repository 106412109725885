import React, { useEffect, useState } from 'react'
// import {} from './GetDocumentViaUrl.style'
import jsFileDownload from 'js-file-download'
import { ActivityIndicator, Text, Button } from '../../../generic'
import { BackActionHeader } from '../../../shared'
import { navigate } from 'gatsby'

async function downloadFile(url, auth, fileName) {
  if (!auth) {
    window.open(url, "_blank")

    return
  }

  const accessToken = localStorage.getItem('accessToken');
  const headers = { 'Authorization': accessToken ? `JWT ${accessToken}` : null }

  const response = await fetch(url, { headers })
  const data = await response.blob()

  jsFileDownload(data, fileName)
}

function GetDocumentViaUrl(props) {
  const [url, setUrl] = useState()
  const [loading, setLoading] = useState(true)

  const { title, auth, url: getUrl, fileName } = props
  useEffect(() => {
    setUrl(getUrl(props))
    setLoading(false)
  })

  return <>
    <BackActionHeader title="Document Vault" onClick={() => navigate('/documents')} />
    <Text bold size={26}>{title}</Text>
    <p />
    {loading 
      ? <ActivityIndicator />
      : <center>
          <Button onClick={() => downloadFile(url, auth, fileName)}>Download</Button>
          <p />
          {/*<Button inverted>Email</Button>*/}
        </center>}
  </>
}

export default GetDocumentViaUrl
