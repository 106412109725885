import React from 'react'
import EditBucketForm from '../EditBucketForm'
import { Modal } from '../../generic'

function EditBucketModal({ bucketId, open, setOpen, refetchQueries }) {
  return <Modal open={open}>
    <EditBucketForm id={bucketId} closeModal={() => setOpen(false)} refetchQueries={refetchQueries} />
  </Modal>
}

export default EditBucketModal

