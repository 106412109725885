import React, { useState, useEffect } from 'react'
import { MokuHoldingCard, WizardModal } from '../shared'
import QuickQuotePrice from '../funeral/QuickQuotePrice'
import { printCurrency } from '../../lib/utils'
import { MokuTitle } from '../funeral/FuneralQuickQuote/FuneralQuickQuote'
import { redCrossFaded, greenTickFaded } from '../../images'

const fields = {}
const buildSteps = ({ setOpen, productTitle, retryPaymentModal, amount, success, successMessage, failMessage }) => [
  {
    name: "ozowStatus",
    text: () => <>
      <MokuHoldingCard>
        <QuickQuotePrice title={productTitle} price={`${printCurrency(amount)}`} color="white" />
      </MokuHoldingCard>
      <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center',}}>
        <img src={success ? greenTickFaded : redCrossFaded} style={{ margin: 0, marginTop: "50px", display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
      </div>
      <p />
      <p />
      <MokuTitle title2={success ? successMessage : failMessage} />
    </>,
    nextButtons: () => {
      let buttons = []
      if (retryPaymentModal && success == false ) {
        buttons.push({ label: "Try Again", nextSteps: ["reTry"], buttonProps: { square: false } })
      }
      buttons.push({ label: "Done", nextSteps: ["done"], buttonProps: { square: false } })
      return buttons
    },
    nextButtonsInColumn: true,
  },
  {
    name: 'done',
    beforeStep: () => {
      setOpen(false)
    },
  },
  {
    name: 'reTry',
    beforeStep: () => {
      setOpen(false)
      retryPaymentModal && retryPaymentModal(true)
    },
  },
]

function OzowStatus({ open, setOpen, url, productTitle, retryPaymentModal, successMessage, failMessage }) {

  const [ozowPayment, setOzowPayment] = useState({});
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (url && url.includes('OZOW')) {
      const parsedUrl = new URL(url);
      const queryParams = new URLSearchParams(parsedUrl.search);
      const payment = {};
      queryParams.forEach((value, key) => {
        payment[key.toLowerCase()] = value;
      });

      setOzowPayment(payment);
      // console.log('ozowPayment', payment);
      if (payment.status.includes('Cancelled') || payment.status.includes('Error') || payment.status.includes('Abandoned')) {
        setSuccess(false)
        setOpen(true);
      } else if (payment.status && payment.status.includes('Complete')) {
        setSuccess(true)
        setOpen(true);
      }  
    }
  }, [url, setOpen]);

  return (
    <WizardModal
      name="Ozow"
      fields={fields}
      steps={buildSteps({ setOpen, productTitle: productTitle || ozowPayment.transactionreference, retryPaymentModal, amount: ozowPayment.amount, success, successMessage, failMessage })}
      open={open}
      setOpen={setOpen}
      ozowPayment={ozowPayment}
    />
  );
}

export default OzowStatus;
