import React from 'react'
import { Text, ActivityIndicator } from '../../generic'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import SavingsCard from '../SavingsCard'
import { AddBucketContainer, AddBucketImage, NewBucketDesktopContainer, NewBucketMobileContainer, MySavingsContainer } from './MySavings.style'
import { addBucket } from '../../../images'
import { Link, navigate } from 'gatsby'
import BankDetails from "../BankDetails"
import firebase from 'gatsby-plugin-firebase'

const MY_SAVINGS = gql`
  {
    myClient {
      id
      idNumber
      savings {
        id
        bucketInfo {
          id
          bucketType
          percentageFull
          targetDate
        }
      }
    }
  }
`



function MySavings() {
  const { data, loading, error } = useQuery(MY_SAVINGS)

  if (loading) {
    return <ActivityIndicator />
  }

  if (error) {
    return error.message
  }

  const { bucketInfo } = (data && data.myClient && data.myClient.savings) || { bucketInfo: [] }

  if (!data.myClient.savings || bucketInfo.length === 0) {
    firebase.analytics().logEvent("ADD_SAVINGS_START", {})

    return <MySavingsContainer>
      <Text xlarge heading>Welcome</Text>
      <p />
      <Text><p>Get started! Create your savings plan by choosing your first goal</p></Text>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      {["EMERGENCY", "FUNERAL", "EDUCATION", "YEAR_END", "HOLIDAY"].map((type, i) => (
        <SavingsCard key={i} to={`/savings/add/${type}`} type={type} />
      ))}
      <AddBucketContainer>
       <Link to={`/savings/add`}>
        <AddBucketImage><Text heading>+</Text></AddBucketImage>
        <Text medium>Add goal</Text>
        </Link>
      </AddBucketContainer>
      </div>  
    </MySavingsContainer>
  }

  return <MySavingsContainer>
    <Text heading>My goals</Text>
    <div style={{ marginTop: "28px", marginBottom: "116px", display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      {bucketInfo.filter(bucket => bucket.percentageFull || bucket.percentageFull === 0).map((bucket, i) => <SavingsCard key={i} to={`/savings/${bucket.id}`}type={bucket.bucketType} percentage={bucket.percentageFull} targetDate={bucket.targetDate} />)}
    </div>
    <NewBucketDesktopContainer>
    <Text heading>Create a new goal</Text>
    <div style={{ marginTop: "72px", display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      <AddBucketContainer onClick={() => navigate('/savings/add')}>
        <AddBucketImage><Text heading>+</Text></AddBucketImage>
        <Text medium>Add other goal</Text>
      </AddBucketContainer>
      {["EMERGENCY", "YEAR_END", "HOUSE", "HOLIDAY", "CAR", "EDUCATION", "HEALTH", "RETIREMENT", "FUNERAL", "OTHER"].map(type => (
        <SavingsCard key={type} to={`/savings/add/${type}`}type={type} />
      ))}
    </div>
    </NewBucketDesktopContainer>
    <NewBucketMobileContainer>
      <img src={addBucket} alt="Add" onClick={() => navigate("/savings/add")} />
    </NewBucketMobileContainer>
    <p />
    <Text heading>Save now towards your goals</Text>
    <p />
    <Text>To add to your savings, set up an EFT using the following details</Text><br />
    <BankDetails />
    <p />
  </MySavingsContainer>
}

// 63px betweeen

MySavings.MY_SAVINGS_QUERY = MY_SAVINGS

export default MySavings