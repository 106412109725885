import styled from '@emotion/styled'

const DocumentGridItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: max(min(47%, 160px), 16%);
  height: 60px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 16px;
  margin: 4px;
  box-shadow: 0px 0px 10px rgba(64, 67, 87, 0.12);
  border-radius: 4px;
`

export {
  DocumentGridItemContainer
}
