import React from 'react'
import { Text } from '../generic'
import { PrivateMenuItemContainer, PrivateMenuItemImage, AlertImage } from './PrivateMenuItem.style'

function PrivateMenuItem({ image, selectedImage, text, selected, alert, alerts, onClick }) {
  return <PrivateMenuItemContainer onClick={onClick}>
    <PrivateMenuItemImage src={selected ? selectedImage || image : image} selected={selected} />
    <Text color="dark" size={10} primary={selected}>{text}</Text>
    {alerts && alert && <AlertImage src={alert} />}
  </PrivateMenuItemContainer>
}

export default PrivateMenuItem