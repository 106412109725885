import React from 'react'
import { SelectableItem, Text } from '../../generic'
import { navigate } from 'gatsby'
import { SummaryItemsRow, TitleContainer } from './DebtRepaymentSummary.style'
import { printCurrency } from '../../../lib/utils'
import DebtRepaymentGraph from '../DebtRepaymentGraph/DebtRepaymentGraph'
import { svgIcons, debtColors } from '../shared'
import theme from '../../../theme.style'
import { BackActionHeader } from '../../shared'
import { IClientDebtData } from '../dataTransformer'
import { Link } from 'gatsby'
import { ComponentPadder } from '../../Layout'
import SummaryItemCard from '../../dashboard/SummaryItemCard'

interface IDebtRepaymentSummaryProps {
  clientDebtData: IClientDebtData
}

function DebtRepaymentSummary({ clientDebtData }: IDebtRepaymentSummaryProps) {
  //const theme = useTheme();
  const data = clientDebtData

  const debtBreakdown: { [key: string]: any } = {
    //TODO: use arary
    home: data.categoryData.home.totalDebt,
    education: 0, //TODO: ?
    personal: data.categoryData.personal.totalDebt,
    car: data.categoryData.car.totalDebt,
    credit: data.categoryData.credit.totalDebt,
  }

  const objectKeys = Object.keys(data.categoryData)

  const debtData = objectKeys.map((category, index) => ({
    x: index + 1,
    y: debtBreakdown[category],
    fill: theme.colors[debtColors[category]],
    _category: category,
  }))

  // TODO: add SA average data
  // const debtAverageData = objectKeys.map((category, index) => (
  //   {
  //     x: index + 1,
  //     y: debtBreakdownAverages[category],
  //     fill: theme.colors.grey4,// TODO: change this  [debtColors.gr]
  //   }
  // ));

  const debtSvgIconsArray = objectKeys.map((category) => svgIcons[category])

  const mainPage = (
    <>
      <ComponentPadder padding="0px 9px">
        {/* TODO: go back in history */}
        <BackActionHeader title="My Debt" onClick={() => navigate('/debt')} />
      </ComponentPadder>
      <TitleContainer>
        <Text bold size={26}>
          My Debt Repayments
        </Text>
      </TitleContainer>
      <SummaryItemsRow spacing={3}>
        <SummaryItemCard
          title="My Net Income"
          description="My Net-income"
          value={data.netIncome ? printCurrency(data.netIncome, true) : 'Unknown'}
        />
        <SummaryItemCard
          title="My Monthly Debt"
          description="Total debt repayments"
          color="alertRed"
          backgroundColor={theme.colors.alertRedBackground}
          value={data.monthlyDebt ? printCurrency(data.monthlyDebt, true) : 'R 0'}
          alert
        />
        <SummaryItemCard
          title="Income to Save"
          description="Money we would like you to save"
          value={data.incomeToSave ? printCurrency(data.incomeToSave, true) : 'Unknown'}
        />
      </SummaryItemsRow>
      <ComponentPadder padding="35px 0px 6px 0px">
        <Text bold size={16}>
          My Debt Profile
        </Text>
      </ComponentPadder>
      <ComponentPadder padding="0px 0px 30px 0px">
        <Text size={14}>
          Your debt repayments are
          <Text bold span size={14} color="primary">
            {' '}
            more than 40%{' '}
          </Text>
          of your expense budget. Get debt repayments under 40%.
        </Text>
      </ComponentPadder>
      <ComponentPadder padding="0px 0px 4px 0px">
        <Text bold size={13}>
          Your total debt repayments per month
        </Text>
      </ComponentPadder>
      <ComponentPadder padding="12px 8px" backgroundColor={theme.colors.grey} borderRadius={4}>
        <Text bold style={{ textAlign: 'right' }} size={18}>
          {data.monthlyDebt ? `${printCurrency(data.monthlyDebt)} pm` : 'Unknown'}
        </Text>
      </ComponentPadder>
      <Text size={10} style={{ textAlign: 'right', padding: '4px 0px 14px 0px' }}>
        Estimated*
      </Text>
      <ComponentPadder padding="19px 16px 23px 16px">
        <ComponentPadder padding="7px 0px 23px 0px">
          <Text size={13}>Your debt againgst the average South African based on your profile</Text>
        </ComponentPadder>
        <ComponentPadder padding="0px 0px 30px 0px">
          <DebtRepaymentGraph
            data={debtData}
            //averageData={debtAverageData}
            svgIcons={debtSvgIconsArray}
            barWidth={21}
            barSpacing={14}
            groupOffset={0} //{23}
            leftPadding={6} //prop not right
          />
        </ComponentPadder>
      </ComponentPadder>
      <ComponentPadder padding="0px 0px 12px 0px">
        <Text bold size={16}>
          Your Debt Profile
        </Text>
      </ComponentPadder>

      <ComponentPadder padding="0px 0px 16px 0px">
        {/* TODO: add router */}
        <Link to="home">
          <SelectableItem
            title="Property Loan"
            arrow
            leftIndicatorBarColor={debtColors.home}
            value={printCurrency(debtBreakdown.home)}
          />
        </Link>
        <Link to="education">
          <SelectableItem
            title="Education Loan"
            arrow
            leftIndicatorBarColor={debtColors.education}
            value={printCurrency(debtBreakdown.education)}
          />
        </Link>
        <Link to="personal">
          <SelectableItem
            title="Personal Loan"
            arrow
            leftIndicatorBarColor={debtColors.personal}
            value={printCurrency(debtBreakdown.personal)}
          />
        </Link>
        <Link to="car">
          <SelectableItem
            title="Car Loan"
            arrow
            leftIndicatorBarColor={debtColors.car}
            value={printCurrency(debtBreakdown.car)}
          />
        </Link>
        <Link to="credit">
          <SelectableItem
            title="Store and Credit Cards"
            arrow
            leftIndicatorBarColor={debtColors.credit}
            value={printCurrency(debtBreakdown.credit)}
          />
        </Link>
      </ComponentPadder>
      {/* <ComponentPadder padding="16px 0px 4px 0px">
      <Text bold size={16}>Pay-off your Debt Quicker</Text> 
    </ComponentPadder>
    <ComponentPadder padding="0px 0px 0px 0px">
      <SelectableItem 
        title="Start Debt Counselling" arrow 
        leftIndicatorBarColor={"alertRed"} 
        // TODO: add link/action // onClick={() => navigate('/funeral')}
      />
      { appConfig.coreFeatures.snowballActive &&
      <SelectableItem 
        title="Add Snowball" arrow 
        leftIndicatorBarColor={"alertRed"} 
        // TODO: add link/action // onClick={() => navigate('/funeral')}
      />
      }
      <SelectableItem 
        title="Get Credit Life Insurance" arrow 
        leftIndicatorBarColor={"alertRed"} 
        // TODO: add link/action // onClick={() => navigate('/funeral')}
      />
      <SelectableItem 
        title="Complete My Household Budget" arrow 
        leftIndicatorBarColor={"alertRed"} 
        // TODO: add link/action // onClick={() => navigate('/funeral')}
      />
    </ComponentPadder> */}
    </>
  )
  return mainPage
}

export default DebtRepaymentSummary
