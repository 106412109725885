import React from 'react'
import { LoanItemCardContainer } from './LoanItemCard.style'
import { Text, Label } from '../../generic'
import { Row, Col } from '../../generic/grid'
import { printCurrency } from '../../../lib/utils'

const loanStatusLabel = {
  PENDING: {
    title: "Pending",
    color: "alertAmber"
  },
  COVERED: {
    title: "Active",
    color: "primary",
    textColor: "primary"
  },
  CANCELLED: {
    title: "Cancelled",
    color: "alertRed"
  },
}

function LoanItemCard({ title, status, premiumAmount, onClick }) {
  return <LoanItemCardContainer onClick={onClick}>
    <Col>
      <Row ><Text size={14} bold>{title}</Text></Row>
      <Row ><Label {...loanStatusLabel[status || "COVERED"]} /></Row>
    </Col>
    <Col>
      <Row><Text size={10}>Next Premium</Text></Row>
      <Row><Text size={21} bold primary>{printCurrency(premiumAmount)}</Text></Row>
    </Col>
  </LoanItemCardContainer>
}

export default LoanItemCard
