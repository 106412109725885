import styled from '@emotion/styled'

const ProfileMenuContainer = styled.div`
`

const LogoutContainer = styled.div`
  padding-left: 4px;
  margin-top: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const LogoutImage = styled.img`
  margin: 0;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export {
  ProfileMenuContainer,
  LogoutContainer,
  LogoutImage
}