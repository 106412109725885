import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { Card } from '../../generic'

const SavingsCardContainer = styled(Card)`
  width: 45%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px;
  margin-right: 3.2%
  margin-bottom: 3.2%;
  padding-bottom: 21px;
`

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
`

const SavingsCardImageContainer = styled.div`
  margin-top: 7px;
  width: 70%;
  max-width: 184px;
`

export {
  SavingsCardContainer,
  ProgressBarContainer,
  SavingsCardImageContainer
}