import React from 'react'
import { MenuBarContainer, MenuBarHeaderContainer, MenuBarItemsContainer, MenuBarItemContainer, SelectedMenuItem, MeerkatLogo, LogoutContainer, LogoutImage } from './PrivateSideMenuBar.style'
import PrivateMenuItem from '../PrivateMenuItem'
import menuBarItems from '../PrivateMenuBar/menuBarItems'
import { logo, logoutBlack } from '../../images'
import { Text } from '../generic'
import { navigate } from 'gatsby'

function PrivateSideMenuBar({ selectedIndex, alerts }) {
  return <MenuBarContainer>
    <MenuBarHeaderContainer>
      <MeerkatLogo src={logo} alt="Meerkat" />
      <Text size={8}>Do more with your money</Text>
    </MenuBarHeaderContainer>
    <MenuBarItemsContainer>
      {menuBarItems.map((item, index) => <MenuBarItemContainer key={index}><PrivateMenuItem {...item} selected alerts={alerts} onClick={() => {
        if (selectedIndex !== index && item.path)  {
          navigate(item.path)
        }
      }} /></MenuBarItemContainer>)}
    </MenuBarItemsContainer>
    <SelectedMenuItem selectedIndex={selectedIndex} />
    <LogoutContainer onClick={() => { navigate('/logout') }}>
      <Text size={12}>Logout</Text>
      <LogoutImage src={logoutBlack} alt="Logout" />
    </LogoutContainer>
  </MenuBarContainer>
}

export default PrivateSideMenuBar
