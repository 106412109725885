import React from 'react'
import { BudgetFormContainer } from './BudgetForm.style'
import { Text, Button, ActivityIndicator } from '../../generic'
import { isNumber } from '../../../lib/validators'
import { GraphQLFormProvider, SubmitButton } from 'react-form-helper'
import { renderInput, renderLabel, renderDisplay, ColumnLayout } from '../../generic/form' 
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo'

const UPDATE_BUDGET = gql`
  mutation UpdateBudgetInfo($input: AddOrUpdateClientFinancialSummaryInput!, $income: Float) {
    addOrUpdateClientFinancialSummary(input: $input) {
      id
    }
    updateMyProfile(input: { income: $income }) {
      id
      income
    }
  }
`

const MY_BUDGET = gql`{
  myClient {
    id
    income
    estimatedIncome
    financialSummary {
      id
    }
    debtBreakdown {
      allDebt
      housingDebt
      vehicleDebt
      normalDebt
      compuScore
      affordability
      debtPaymentProjection {
        date
        payment
      }
      withDCPaymentProjection {
        payment
        payments {
          date
          payment
        }
        interestSaved
      }
      estimatedIncome
      hasDebtCounselling
      budget {
        description
        amount
      }
    }
  }
}`

const defaultFields = [
  "Rent / Home",
  "Education",
  "Water & Electricity",
  "Travel",
  "Mobile & Internet",
  "Other"
]

const getBudgetFields = (data) => {
  const fields = {
    netIncome: {
      label: "Your Net Income",
      validate: [isNumber],
      type: "currency",
      disabled: !!data.myClient.debtBreakdown.hasDebtCounselling
    }
  }

  let hasDebtCounselling
  const initialValues = {}

  if (data && data.myClient) {
    initialValues.clientId = data.myClient.id
    initialValues.summaryId = data.myClient.financialSummary && data.myClient.financialSummary.id

    initialValues.netIncome = data.myClient.income || data.myClient.estimatedIncome || 0

    hasDebtCounselling = data.myClient.debtBreakdown && data.myClient.debtBreakdown.hasDebtCounselling

    data.myClient.debtBreakdown && data.myClient.debtBreakdown.budget && data.myClient.debtBreakdown.budget.forEach(d => {
      fields[d.description || "Other"] = {
        label: d.description || "Other",
        type: "currency",
        validate: [isNumber],
        disabled: !!data.myClient.debtBreakdown.hasDebtCounselling
      }
      if (!initialValues[d.description || "Other"]) {
        initialValues[d.description || "Other"] = 0
      }
      initialValues[d.description || "Other"] += d.amount
    })
  }

  console.log(initialValues)

  defaultFields.forEach(field => {
    if (!Object.keys(fields).includes(field)) {
      fields[field] = {
        label: field,
        validate: [isNumber],
        type: "currency",
        disabled: !!data.myClient.debtBreakdown.hasDebtCounselling
      }
    }
  })

  return {
    fields,
    initialValues,
    hasDebtCounselling
  }
}

const buildUpdateBudgetInput = (input) => {
  console.log(input)

  const result = {
    input: {
      clientId: input.clientId,
      expenses: Object.keys(input).filter(fieldName => !['clientId', 'summaryId', 'netIncome'].includes(fieldName) && input[fieldName]).map(fieldName => ({
        description: fieldName,
        amount: input[fieldName]
      }))
    },
    income: input.netIncome
  }
  if (input.summaryId) {
    result.input.id = input.summaryId
  }

  return result
}

function BudgetForm({ setOpen }) {
  const { data, loading, error } = useQuery(MY_BUDGET)

  if (loading) {
    return <ActivityIndicator />
  }

  if (error) {
    console.log(error)
    return "An error has occurred. Please contact support"
  }

  const { fields, initialValues, hasDebtCounselling } = getBudgetFields(data)

  return <BudgetFormContainer>
           <GraphQLFormProvider
            addMutation={UPDATE_BUDGET}
            name="budget"
            mode={"add"}
            fields={fields}
            renderInput={renderInput}
            renderLabel={renderLabel}
            initialValues={initialValues}
            data={initialValues}
            mapInputVariables={buildUpdateBudgetInput}
            refetchQueries={[{ query: MY_BUDGET }]}
            afterSubmit={() => setOpen && setOpen(false)}
          >
            <Text>For a more accurate financial profile, enter your monthly income and expenses</Text>
            <p />
            <ColumnLayout fields={fields} />
            <p />
            {!hasDebtCounselling && <SubmitButton renderSubmitButton={({ submit, submitting, invalid }) => (
              <Button submitting={submitting} disabled={invalid} onClick={submit}>Submit</Button>
            )} />}
          </GraphQLFormProvider>
    </BudgetFormContainer>
}

export default BudgetForm
