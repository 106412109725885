import React, { useState } from 'react'
import { MyDashboardContainer, SummaryItemsRow, DashboardCard, TitleTextContainer, DashboardCardContentsContainer, OpenOptionContainer, debtColors, ToggleProductOptionsRow } from './MyDashboard.style'
import SummaryItemCard from '../SummaryItemCard'
import ToggleProductOption from '../ToggleProductOption'
import ViewMore from '../ViewMore'
import { Text, PieGraph, BarGraph, StackedBarGraph, Button, Slider, ActivityIndicator } from '../../generic'
import { Row } from '../../generic/grid'
import TipItem from '../TipItem'
import { overIndebted, setupBudget } from '../../../images/tips'
import { useTheme } from 'emotion-theming'
import { VictoryScatter } from 'victory'
import CustomPoint from '../CustomPoint'
import DebtGraph from '../DebtGraph'
import InformationRow from '../InformationRow'
import { printCurrency } from '../../../lib/utils'
import moment from 'moment'
import { useQuery, Query } from 'react-apollo'
import gql from 'graphql-tag'
import ContactModal from '../../ContactModal'
import { BudgetSlideUpModal } from '../../budget'
import { getAffordabilitySummary, getRiskProfile } from '../../../lib/financial'
import appConfig from '../../../appConfig'
import { GetIncomeMokuTip, GetInfoCard, TitleImage } from '../../shared'
import CreditWorthinessFactors from '../../shared/CreditWorthinessFactors'
import { InfoIconWithModal } from '../../shared/infoModal/InfoIcon'
import FinancialHealthGraphs from '../../shared/FinancialHealthGraphs'
import { navigate } from 'gatsby'
import improveCreditScoreImage from '../../../images/blogPosts/improveCreditScore.webp'
import consolidateDebtImage from '../../../images/blogPosts/consolidateDebt.webp'
import debtReviewImage from '../../../images/blogPosts/debtReview.webp'
import savingsImage from '../../../images/productInfo/savings.svg'
import funeralImage from '../../../images/productInfo/funeral.svg'
import { BudgetGraph } from '../../shared/FinancialHealthGraphs/FinancialHealthGraphs'
import { rollingMoney } from '../../../images'
import { BlogPostCards } from '../../sections'

const MY_DEBT_PROJECTION = gql`{
  myClient {
    id
    income
    debtBreakdown {
      finalPaymentDate
      allDebt
      housingDebt
      vehicleDebt
      normalDebt
      creditCardDebt
      compuScore
      affordability
      debtPaymentProjection {
        date
        payment
      }
      withDCPaymentProjection {
        payment
        payments {
          date
          payment
        }
        interestSaved
      }
      estimatedIncome
      hasDebtCounselling
      budget {
        description
        amount
      }
    }
  }
}`

const MY_SNOWBALL_PROJECTION = gql`
  query($amount: Float, $withDC: Boolean) {
    getSnowballProjection(amount: $amount, withDC: $withDC) {
      payment
      payments {
        date
        payment
      }
      interestSaved
    }
  }
`

const getMaxDate = da => {
  console.log(da, Math.max(...da.map(d => d.date)))
  if (da) {
    const date = Math.max(...da.map(d => d.date))

    if (date === -Infinity) {
      return ""
    } else {
      return moment(date).format("MMM YYYY")
    }
  } else {
    return ""
  }
}

const blogPosts = [
  {
    imageSrc: improveCreditScoreImage, 
    title: "7 Ways to Build and Improve Your Personal Credit Score", 
    description: "Your credit score is a big factor in getting a loan, but you may not realise just how much of an impact it can have on all aspects of your financial life.", 
    linkUrl: "https://www.meerkat.co.za/blog/news/7-ways-to-build-and-improve-your-personal-credit-score",
  },
  {
    imageSrc: consolidateDebtImage, 
    title: "What is the best way to consolidate debt?", 
    description: "Feeling overwhelmed by the many loan repayments you have. Do you want to consolidate into one single repayment? Lets explore your options.", 
    linkUrl: "https://www.meerkat.co.za/blog/news/best-way-to-consolidate-debt",
  },
  {
    imageSrc: debtReviewImage, 
    title: "The truth about debt review", 
    description: "Want to know the truth about debt review? In this article we delve into some truths you may not be aware of when it comes to being under debt review.", 
    linkUrl: "https://www.meerkat.co.za/blog/news/the-truth-about-debt-review",
  },
]

function MyDashboard() {
  const { data, loading, error } = useQuery(MY_DEBT_PROJECTION, { onCompleted: (data) => { if (data.myClient && data.myClient.debtBreakdown && data.myClient.debtBreakdown.hasDebtCounselling) {
    setWithDC(true);
    setMonthlyDebt(data.myClient.debtBreakdown.allDebt)
  } else {
    setMonthlyDebt(data.myClient.debtBreakdown.allDebt)
  } } })
  
  const useData = {
    ...((data && data.myClient && data.myClient.debtBreakdown) || {}),
    income: data && data.myClient.income,
    debtBreakdown: {
      home: (data && data.myClient && data.myClient.debtBreakdown.housingDebt)|| 0,
      education: 0, // ???
      personal: (data && data.myClient && data.myClient.debtBreakdown.normalDebt) || 0,
      car:  (data && data.myClient && data.myClient.debtBreakdown.vehicleDebt) || 0,
      credit: (data && data.myClient && data.myClient.debtBreakdown.creditCardDebt) || 0,
      compuScore: (data && data.myClient && data.myClient.debtBreakdown.compuScore) || 0,
    }
  }

  const maxY = useData && useData.budget ? Math.max(...useData.budget.map(d => d.amount)) : 0

  const [withDC, setWithDC] = useState(false)
  const [withSnowball, setWithSnowball] = useState(false)
  const [snowballAmount, setSnowballAmount] = useState(150)
  const [monthlyDebt, setMonthlyDebt] = useState(0)
  const [snowballProjection, setSnowballProjection] = useState()
  const [snowballLoading, setSnowballLoading] = useState(false)
  const [viewMore, setViewMore] = useState(false)
  const theme = useTheme()
  const [contactModalOpen, setContactModalOpen] = useState(false)
  const [aboutCLI, setAboutCLI] = useState(false)
  const [aboutFuneral, setAboutFuneral] = useState(false)
  const [budgetOpen, setBudgetOpen] = useState(false)

  if (error) {
    return "An error occurred, please contact support"
  }

  if (loading) {
    return <ActivityIndicator />
  }

  const income = (useData.income ? useData.income : useData.estimatedIncome)
  const budget = income - useData.affordability

  const riskSummmary = getRiskProfile(useData)
  const affordabilitySummary = getAffordabilitySummary(useData.affordability, income)
  const hasDebt = useData.allDebt && (useData.allDebt != 0)
  const hasOkCreditScore =  useData.debtBreakdown.compuScore > 620;

  return <MyDashboardContainer>
    <Text size={26} bold>My Debt</Text>
    <BudgetSlideUpModal open={budgetOpen} setOpen={setBudgetOpen} />
    <ContactModal open={contactModalOpen} setOpen={setContactModalOpen} routing="debt" /> 
    <SummaryItemsRow>
      <SummaryItemCard title="My Monthly Budget" description="My Expenses + My Monthly Debt" value={budget ? printCurrency(budget) : "Unknown"} onClick={() => setBudgetOpen(true)} />
      <SummaryItemCard title="My Monthly Debt" description="Total debt repayments" value={useData.allDebt ? printCurrency(useData.allDebt) : "R 0"} alert color="alertRed" />
      <SummaryItemCard title="My Debt Paid off by" description="Last month of debt repayments" value={!useData.allDebt ? "No Debt" : (useData.hasDebtCounselling && useData.finalPaymentDate ? moment(useData.finalPaymentDate).format("MMM YYYY") : getMaxDate(useData.debtPaymentProjection))} />
    </SummaryItemsRow>
    <p />
    <GetIncomeMokuTip income={useData.income} estimatedIncome={useData.estimatedIncome}/>
    <p />

    {/* No Debt section */}
    {
      !hasDebt && (hasOkCreditScore ?
      // Good credit score 
      <>
        <Text bold size={16}>Good Credit Score</Text>
        <p/>
        <Text size={13}>Great work! Your finances are looking good. Why not learn about how you can do more with your money</Text>
        <p/>
      </>
      :
      // Bad credit score
      <>
        <Text bold size={16}>Check out these ways to improve your credit score:</Text>
        <p/>
        <Text size={13}>
          <ol>
            <li>Pay your bills on time, every time</li>
            <li>Minimise new credit inquiries</li>
            <li>Pay more than the monthly minimum</li>
          </ol>
        </Text>
        <p/>
        <Text bold size={13}>Find out more:</Text>
        <p/>
        <BlogPostCards blogs={[blogPosts[0]]} />
      </>
      )
    }

    {/* Debt section */}
    {
      hasDebt && <>
      <Row><Text bold size={16}>My Debt Graph</Text><InfoIconWithModal
      title="What is My Debt Graph?"
      description={<>
          Your debt graph uses your income less your expenses to calculate your affordability.
          <br />
          <br />
          If your Affordability line is below the Monthly Debt line you are likely to be over-indebted. Turn on Debt Counselling to see how we can help.
          <br />
          <br />
        </>}
      buttonText="OK, got it"
    /></Row>
      <DebtGraph originalRepayments={useData.debtPaymentProjection} income={useData.income ? useData.income : useData.estimatedIncome} incomeEstimated={!useData.income} repayments={withSnowball && snowballProjection ? snowballProjection.payments : withDC ? useData.withDCPaymentProjection.payments : useData.debtPaymentProjection} />
      {/*<ViewMore visible={viewMore} setVisible={setViewMore}>
        <SummaryItemsRow>
          <DashboardCard>
            <Text medium>My Risk Profile</Text>
            <DashboardCardContentsContainer>
              <PieGraph min={-350} max={750} {...riskSummmary} />
            </DashboardCardContentsContainer>
            <center><Text small>View Credit Score Report</Text></center>
          </DashboardCard>
          <DashboardCard>
            <Text medium>Debt Capacity</Text>
            <DashboardCardContentsContainer>
              <PieGraph value={useData.affordability} min={Math.min(useData.affordability, -1000)} max={Math.max(useData.affordability, income * 0.4)} {...affordabilitySummary} />
            </DashboardCardContentsContainer>
            <center><Text small>Tips</Text></center>
          </DashboardCard>
        </SummaryItemsRow>
        <SummaryItemsRow>
          <DashboardCard onClick={() => setBudgetOpen(true)}>
            <Text medium>My Budget</Text>
            <DashboardCardContentsContainer>
            <StackedBarGraph data={useData.budget ? [
              useData.budget.map((d, index) => ({ x: index + 1, y: d.amount, fill: theme.colors.primary })),
            ] : []}>
              <VictoryScatter data={[{ x: ((useData.budget || []).length + 1) / 2, y: maxY * 1.1 }]} dataComponent={<CustomPoint label={`${printCurrency(budget)} pm`} backgroundColor="primary" />} />
            </StackedBarGraph>
            </DashboardCardContentsContainer>
            <center><Text small>Set-up Budget</Text></center>
          </DashboardCard>
          <DashboardCard>
            <Text medium>My Debt Repayments</Text>
            <DashboardCardContentsContainer>
              <BarGraph data={Object.keys(useData.debtBreakdown).map((category , index) => ({ x: index + 1, y: useData.debtBreakdown[category], fill: theme.colors[debtColors[category]] }))}>
                <VictoryScatter data={[{ x: 3, y: Math.max(...Object.keys(useData.debtBreakdown).map(category => useData.debtBreakdown[category])) * 1.05 }]} dataComponent={<CustomPoint label={printCurrency(useData.allDebt)} backgroundColor="alertRed" />} />
              </BarGraph>
            </DashboardCardContentsContainer>
            <center><Text small>View Debt Details</Text></center>
          </DashboardCard>
        </SummaryItemsRow>
      </ViewMore>*/}
      <p />
      <Text bold size={16}>Pay off your debt quicker</Text>
      <ToggleProductOptionsRow>
      <ToggleProductOption title="Debt Counselling" showSwitch expanded={withDC} setExpanded={!useData.hasDebtCounselling ? setWithDC : () => {}}>
        <>
          {!useData.affordability < 0 && !useData.hasDebtCounselling && <OpenOptionContainer>
            <Text><Text span color="alertRed">Alert!</Text> You are <Text span bold>OVER-INDEBTED</Text>. We might be able to reduce yout debt repayments by up to 50%.</Text>
          </OpenOptionContainer>}
          {useData.hasDebtCounselling && <OpenOptionContainer>
            <Text><Text span color="primary">Alert!</Text> You were <Text span bold>OVER-INDEBTED</Text>. We have been able to reduce your debt repayments.</Text>
          </OpenOptionContainer>}
          <OpenOptionContainer>
            <InformationRow title="Monthly Repayments" value ={printCurrency(useData.withDCPaymentProjection.payment)} />
            <InformationRow title="Interest Saved" value ={printCurrency(useData.withDCPaymentProjection.interestSaved)} />
            <InformationRow title="Maximum Debt Review Term" value ={`${useData.withDCPaymentProjection.payments.length} months`} />
            <InformationRow title="Paid Off By" value = {useData.hasDebtCounselling ? moment(useData.finalPaymentDate).format("MMM YYYY") : getMaxDate(useData.withDCPaymentProjection.payments)} valueColor="primary" />
            <p />
            {!useData.hasDebtCounselling && <center><Button onClick={() => setContactModalOpen(true)}>Ask about Debt Counselling</Button></center>}
          </OpenOptionContainer>
        </>
      </ToggleProductOption>
      {appConfig.coreFeatures.snowballActive &&  // feature currently set to false 
        <ToggleProductOption title="Snowball"  showSwitch expanded={withSnowball} setExpanded={setWithSnowball}>      
          <OpenOptionContainer>
              <Text>Settle your debt quicker with an additional monthly amount</Text>
            </OpenOptionContainer>
            <OpenOptionContainer>
              
              <Slider label="Recommended snowball amount" minValue={150} maxValue={10000} value={snowballAmount} step={1} onChange={setSnowballAmount} />
              <p />
              <Query query={MY_SNOWBALL_PROJECTION} variables={{ amount: snowballAmount, withDC }} fetchPolicy="network-only" onCompleted={(data) => setSnowballProjection(data.getSnowballProjection)}>
                {({ data, loading }) => (
                loading ? <center><ActivityIndicator /></center> : <>
                  <InformationRow title="Monthly Repayments" value ={printCurrency(data.getSnowballProjection.payment)} />
                  <InformationRow title="Interest Saved" value ={printCurrency(data.getSnowballProjection.interestSaved)} />
                  <InformationRow title="Paid Off By" value = {getMaxDate(data.getSnowballProjection.payments)} valueColor="primary" />
                  </>
                )}
              </Query>
              
              <p />
              {/*<center><Button>Ask about Snowball</Button></center>*/}
            </OpenOptionContainer>
        </ToggleProductOption>
      }
      </ToggleProductOptionsRow>
      <p/>
    </>}
    <p/>
    <CreditWorthinessFactors creditRecord={useData} income={income}/>
    {/* <RowSpacer height={8}/> */}
    <p/>
    { hasDebt && <FinancialHealthGraphs financialHealthData={useData} income={income}/>}
    { !hasDebt && <FinancialHealthGraphs financialHealthData={useData} income={income} title={"My Budget"} hideDebtRepayments/>} 
    {
      !hasDebt && <>
        <p/>
        <Text bold size={16}>Do More with your Money</Text>
        <p/>
        <Text size={13}>Here is how we at Meerkat will help you on your journey to financial wellbeing.</Text>
        <p/>
        <GetInfoCard 
          title="Savings"
          description="We all know the importance of saving. We are here to help you set up realistic goals."
          image={savingsImage}
          onClick={()=>{navigate("/savings")}}
        />
        <GetInfoCard
          title="Funeral Cover"
          description="Discover how to stay ahead of your expenses and financial needs by planning for your finances today."
          image={funeralImage} 
          onClick={()=>{navigate("/funeral")}}
        />
      </>
    }
    <>
      <p/>
      <TitleImage title="Keeping you on-track" description="Here are some tips form us to achieve your savings goals on time." image={rollingMoney} />
      <p />
      <BlogPostCards blogs={[blogPosts[1], blogPosts[2]]} />
    </>
    {/*<ToggleProductOption title="Credit Life Insurance" expanded={aboutCLI} setExpanded={setAboutCLI} />
    <ToggleProductOption title="Funeral Insurance" expanded={aboutFuneral} setExpanded={setAboutFuneral} />}
    {((useData.affordability < 0 && !useData.hasDebtCounselling) || (!useData.budget || useData.budget.length === 0 )) &&
    <TitleTextContainer>
      <Text bold>Our Tips</Text>
    </TitleTextContainer>
    }
    {useData.affordability < 0 && !useData.hasDebtCounselling && <TipItem title="You are over-indebted" actionText="Start Debt Counselling" icon={overIndebted} color="alertRed" actionTextColor="text" onClick={() => setContactModalOpen(true)} />}
    {(!useData.budget || useData.budget.length === 0) && <TipItem title="Set-up your Budget" actionText="Go to My Budget" icon={setupBudget} onClick={() => setBudgetOpen(true)}  />}*/}
  </MyDashboardContainer>
}

export {
  getMaxDate
}

export default MyDashboard
