import React, { useState } from 'react'
// import {} from './MyFuneralPolicy.style'
import { LoanInfoContainer, Separator } from '../../insurance/MyLoan/MyLoan.style'
import { InfoRow } from '../../insurance/MyLoan/MyLoan'
import { useQuery, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import { ActivityIndicator, Text, Button } from '../../generic'
import { printCurrency } from '../../../lib/utils'
import { BackActionHeader, ChangeBankAccount, MokuNotePanel } from '../../shared'
import { navigate } from 'gatsby'
import MemberDetailsCard from '../MemberDetailsCard'
import ToggleProductOption from '../../dashboard/ToggleProductOption'
import moment from 'moment'
import { RowSpacer } from '../../generic/Layouts'
import UploadDocument from '../../profile/UploadDocument'
import DocumentListItem from  '../../profile/DocumentListItem'
import { UpdateProfilePicture } from '../../profile'
import MemberDetailsModal from '../MemberDetailsModal'
import { funeral } from '../../../images/productInfo'
import AddNextOfKinModal from '../../insurance/AddNextOfKinModal'

// TODO: Change to use Product Type rather than Accounting Identifier
const FUNERAL_POLICY_INFO = gql`
  query ($id: String!) {
    funeral(id: $id) {
      id
      coverAmount
      livesCovered {
        id
        firstNames
        surname
        idNumber
        idType
        age
        memberType
        gender
        state
      }
      beneficiaries {
        payTo {
          id
          firstNames
          surname
          idNumber
          idType
          age
          memberType
          gender
        }
      }
      paymentDueDay
      paymentBankAccountId
      firstActivated
      policyType
      currentPremiumTotal
      expectFirstPaymentAt
      policyNumber
      state
      paymentMethod
      accountingIdentifier
      easyPayNumber
      pricingVersion
    }
  }
`

const GET_CLIENT_INFO = gql`
  {
    myClient {
      id
      documents {
        type
        id
      }
      photoId
    }
  }
`;

const UPDATE_PAYMENT_DETAILS = gql`
  mutation UpdatePaymentDetails($id: String!, $bankAccountId: ObjID, $payDay: Int) {
    updateFuneral(id: $id, input: {
      paymentDueDay: $payDay,
      paymentBankAccountId: $bankAccountId
    }) {
      id
      coverAmount
      livesCovered {
        id
        firstNames
        surname
        idNumber
        idType
        age
        memberType
        gender
        state
      }
      paymentDueDay
      paymentBankAccountId
      firstActivated
      policyType
      currentPremiumTotal
      expectFirstPaymentAt
      policyNumber
      state
      paymentMethod
      accountingIdentifier
      easyPayNumber
      pricingVersion
    }
  }
`

const memberTypeText = {
  POLICY_HOLDER: 'Me',
  SPOUSE: 'My Spouse',
  CHILD: 'My Children',
  PARENT: 'My Parents',
  EXTENDED: 'My Extended Family'
}

const getCoverType = (policyType) => {
  if (policyType === "SINGLE") {
    return "Myself"
  }
  if (policyType === "SINGLE_AND_CHILDREN") {
    return "Me and My Children"
  }
  if (policyType === "COUPLE") {
    return "Couple"
  }
  return "Family"
}

function MyFuneralPolicy({ id }) {
  const { data, loading, error }= useQuery(FUNERAL_POLICY_INFO, { variables: { id }}) 
  const { data:cData, loading:cLoading, error:cError } = useQuery(GET_CLIENT_INFO) ;
  const [documentModalOpen, setDocumentModalOpen] = useState(false);
  const [IdDocOpen, setIdDocOpen] = useState(false);
  const [selfieDocOpen, setSelfieDocOpen] = useState(false);
  const [open, setOpen] = useState(false)
  const [addMemberType, setAddMemberType] = useState("CHILD")
  const [nextOfKinOpen, setNextOfKinOpen] = useState(false)

  const [updatePaymentDetails] = useMutation(UPDATE_PAYMENT_DETAILS)
  const [memberTypeExpanded, setMemberTypeExpanded] = useState({
    POLICY_HOLDER: false,
    SPOUSE: false,
    CHILD: false,
    PARENT: false,
    EXTENDED: false
  })
  const [editPaymentDetails, setEditPaymentDetails] = useState(false)

  if (loading) { return <ActivityIndicator /> }

  if (error) {
    return error
  }

  const { coverAmount, livesCovered, paymentBankAccountId, paymentDueDay, firstActivated, policyType, currentPremiumTotal, expectFirstPaymentAt, policyNumber, state, paymentMethod, easyPayNumber, beneficiaries, accountingIdentifier, pricingVersion } = data.funeral

  const livesCoveredByType = {}

  livesCovered.filter(member => member.memberType !== "BENEFICIARY" && ["COVERED", "INITIAL", "POLICY_LAPSED", "IN_WAITING_PERIOD"].includes(member.state)).forEach(member => {
    if (!livesCoveredByType[member.memberType]) {
      livesCoveredByType[member.memberType] = []
    }
    livesCoveredByType[member.memberType].push(member)
  })

  const currentDocuments = cData?.myClient?.documents &&
    cData.myClient.documents.reduce((r, c) => {
        r[c.type] = c
        return r
      }, {}
  );
  const hasAllDocuments = !!(currentDocuments?.RSA_ID && cData?.myClient?.photoId);
  console.log("hasAllDocuments", cData, currentDocuments, hasAllDocuments);
  const funeralId = data?.funeral?.id;

  const policyStateDescription = {
    "INFO_MISSING": "Missing Information",
    "DORMANT": "Awaiting 1st Payment",
    "CANCELLED": "Cancelled",
    "LAPSED": "Lapsed",
    "ON_RISK_GRACE": "Missed Payment",
    "ACTIVE": "Active"
  }

  const paymentMethodText = {
    DEBI_CHECK: "Debicheck",
    DO: "Debit Order",
    EASY_PAY: "Easy Pay",
    MONEYMARKET: "Money Market"
  }

  return <>
    <AddNextOfKinModal title="Beneficiary" open={nextOfKinOpen} setOpen={setNextOfKinOpen} productId={id} refetchQueries={[[{ query: FUNERAL_POLICY_INFO, variables: { id } }]]}/>
    <MemberDetailsModal open={open} setOpen={setOpen} addMemberType={addMemberType} funeralId={id} refetchQueries={[{ query: FUNERAL_POLICY_INFO, variables: { id } }]} />
    <UploadDocument open={IdDocOpen} setOpen={setIdDocOpen} type={"RSA_ID"} clientId={cData?.myClient.id} source={"FUNERAL_POLICY_NOTIFICATION"} refetchQueries={[{query: GET_CLIENT_INFO}]}/>
    {/* <UploadDocument open={selfieDocOpen} setOpen={setSelfieDocOpen} type={"SELFIE"} clientId={cData?.myClient.id} source={"FUNERAL_POLICY_NOTIFICATION"} afterSubmit={()=>{cRefetch()}}/> */}
    <UpdateProfilePicture open={selfieDocOpen} setOpen={setSelfieDocOpen} refetchQueries={[{query: GET_CLIENT_INFO}]}/>
    <ChangeBankAccount open={editPaymentDetails} setOpen={setEditPaymentDetails} bankAccountId={paymentBankAccountId} payDay={paymentDueDay} onChangeBankAccount={(variables) => updatePaymentDetails({ variables: { id, ...variables } })} />
    <BackActionHeader title="My Funeral Policies" onClick={() => navigate('/funeral')} />
    <Text size="26" bold>Meerkat Policy</Text>
    <p />
    <LoanInfoContainer>
      <InfoRow title="Meerkat Policy" titleColor="primary" />
      <Separator />
      <InfoRow title="Policy Number" value={policyNumber} />
      <InfoRow title="Policy Status" value={policyStateDescription[state] || state} />
      <InfoRow title="Cover Amount" value={printCurrency(coverAmount)} />
      <InfoRow title="Start Date" value={firstActivated ? moment(firstActivated).format("YYYY-MM-DD") : expectFirstPaymentAt ? moment(expectFirstPaymentAt).format("YYYY-MM-DD") : '-'} />
      <InfoRow title="Cover Type" value={getCoverType(policyType)} />
      <InfoRow title="Monthly Contribution" value={printCurrency(currentPremiumTotal, 2)} />
      <InfoRow title="Payment Method" value={paymentMethodText[paymentMethod] || paymentMethod} />
      {easyPayNumber && <InfoRow title="Easy Pay Number" value={easyPayNumber} />}
    </LoanInfoContainer>
    <p/>
    { cData && !hasAllDocuments && <>
        <MokuNotePanel
          title={'Moku Tip: Provide missing documents'}
          description={"We are missing some documents for your Meerkat Funeral Policy. Complete your profile...."}
          footer={
            <>
              <RowSpacer height={8} />
              <center style={{ width: '100%' }}>
                { !currentDocuments?.RSA_ID && 
                <>
                <RowSpacer height={8} />
                <Button inverted onClick={() => setIdDocOpen(true)}>
                  Upload Id
                </Button>
                </>}
                { !cData?.myClient?.photoId &&
                <>
                <RowSpacer height={8} />
                <Button inverted onClick={() => setSelfieDocOpen(true)}>
                  Upload Selfie
                </Button>
                </>}
              </center>
              <RowSpacer height={8} />
            </>
          }
        />
        <p/>
      </>
    }
    <Text size="14" bold>Who&apos;s covered?</Text>
    <p />
    {Object.keys(livesCoveredByType).map((memberType, i) => <ToggleProductOption key={i} title={memberTypeText[memberType]} expanded={memberTypeExpanded[memberType]} setExpanded={value => setMemberTypeExpanded({ ...memberTypeExpanded, [memberType]: value })} noIndicator>
        {livesCoveredByType[memberType].map((member, i) => <MemberDetailsCard key={i} member={member} />)}
        {memberType === "CHILD" && !(pricingVersion || "").includes("INDFUN") && livesCoveredByType[memberType].length < (accountingIdentifier === "FREEDOM" ? 6 : 5) && <>
          <p />
          <center>
            <Text size={13}>You can add up to {accountingIdentifier === "FREEDOM" ? "6" : "5"} children with Meerkat Funeral.</Text>
            <p />
            <Button onClick={() => { setAddMemberType("CHILD"); setOpen(true) }}>Add Child</Button>
          </center>
        </>}
      </ToggleProductOption>
    )}
    {accountingIdentifier === "FREEDOM" && !(pricingVersion || "").includes("INDFUN") && !livesCoveredByType["SPOUSE"] && <>
      <p />
      <center>
        <Text size={13}>Your family funeral can cover your spouse. You may add them to this policy.</Text>
        <p />
        <Button onClick={() => { setAddMemberType("SPOUSE"); setOpen(true) }}>Add Spouse</Button>
      </center>
      <p />
    </>}
    <Text size={16} bold>Beneficiary</Text>
    {
      beneficiaries && beneficiaries[0] && beneficiaries[0].payTo
        ? <MemberDetailsCard member={beneficiaries[0].payTo} />
        : <>
            <p/>
            <MokuNotePanel
              title={'Moku Tip: Add a Beneficiary Today'}
              description={"As the policyholder, you will receive the payouts should anything happen to the people you've covered. You can nominate someone to receive the payout in the event of your death"}
              footer={
                <>
                  <RowSpacer height={8} />
                  <center style={{ width: '100%' }}>
                    <Button inverted onClick={() => { setNextOfKinOpen(true) }}>Add Beneficiary</Button>
                  </center>
                  <RowSpacer height={8} />
                </>
              }
            />
          </>
    }
    <p />
    <Text size={16} bold>Policy Documents</Text>
    <p/>
    {//funeralPolicies.length === 0 ? "None" : funeralPolicies.map(({ id, policyNumber }) => 
    (<>
      <DocumentListItem title={`Policy Schedule`} onClick={() => navigate(`/documents/funeral/${funeralId}/schedule`)} />
      <DocumentListItem title={`Policy Document`} onClick={() => navigate(`/documents/funeral/${funeralId}/policy`)} />
    </>
    )}
    <p />
    {/*<Text size="18" bold>Need more cover?</Text>
    <Text size="13" bold>Get a quick quote and save with Meerkat Funeral.</Text>*/}
    <center>
      <Button onClick={() => setEditPaymentDetails(true)}>Edit Payment Details</Button>
    </center>
    <div style={{ height: 70 }} />
  </>
}

export default MyFuneralPolicy
