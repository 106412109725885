import React from 'react'
import { ChatFlow, Text } from '../../generic'
import { isNumber, isInteger } from '../../../lib/validators'
import { AddSavingsBucketContainer, AddSavingsTitle } from './AddSavingsBucket.style'
import { navigate } from 'gatsby'
import gql from 'graphql-tag'
import moment from 'moment'
import BankDetails from '../BankDetails'
import MySavings from '../MySavings'
import firebase from 'gatsby-plugin-firebase'

const ADD_SAVINGS_BUCKET = gql`
  mutation AddSavingsBucket($input: SavingsBucketInfoInput) {
    addSavingsBucket(bucketInfo: $input) {
      id
      bucketInfo {
        bucketType
        percentageFull
      }
    }
  }
`

const monthDuration = (durationAmount, durationType) => {
  if (durationType === "Weeks") {
    return durationAmount / 4
  }
  if (durationType === "Years") {
    return durationAmount * 12
  }
  return durationAmount
}

// TODO: Use server side query
function SavingsCalculation({ chatState }) {
  console.log(chatState)

  const { targetAmount, durationAmount, durationType } = chatState

  const amount = targetAmount / monthDuration(durationAmount, durationType)

  return <Text large>R {Math.round(amount / 10, 0) * 10} per month</Text>
}

function SavingsDurationCalculation({ chatState }) {
  const { targetAmount, desiredSavings } = chatState

  const duration = targetAmount / desiredSavings
  
  return <Text large>{Math.ceil(duration)} months</Text>
}

const steps = [
  {
    name: "start",
    beforeStep: ({ chatState }) => firebase.analytics().logEvent("ADD_SAVINGS_GOAL_START" , { bucketType: chatState.bucketType }),
    nextSteps: ["whatSaveFor"],
    nextAfterDelay: 1
  },
  {
    name: "whatSaveFor",
    text: "What would you like to save for ?",
    fieldNames: ["bucketType"],
    nextOnValue: true,
    nextSteps: ["amount"],
    noNext: true,
    skipStep: ({ bucketType }) => !!bucketType
  },
  {
    name: "amount",
    text: "How much would you like to save in total?",
    fieldNames: ["targetAmount"],
    nextSteps: ["duration"]
  },
  {
    name: "duration",
    text: "How long would you like to save for?",
    fieldNames: ["durationAmount", "durationType"],
    cardContentStyle: { display: "flex", flexDirection: "row" },
    // This should set target date
    nextSteps: ["result"]
  },
  {
    name: "result",
    nextSteps: ["saveCommitment"],
    text: "You will need to save approximately",
    component: SavingsCalculation,
    noNext: true,
    nextAfterDelay: 100
  },
  {
    name: "saveCommitment",
    text: "What can you afford to save towards this goal?",
    fieldNames: ["desiredSavings"],
    nextSteps: ["reachGoal"],
  },
  {
    name: "reachGoal",
    text: "That is great! You will reach your goal in approximately",
    component: SavingsDurationCalculation,
    nextSteps: ["getStarted"],
    nextAfterDelay: 1000,
  },
  {
    beforeStep: ({ changeFieldValue, chatState }) => {
      const { targetAmount, desiredSavings } = chatState
      const durationAmount = Math.ceil(targetAmount / desiredSavings)
      changeFieldValue("targetDate", moment().add(durationAmount, "months").valueOf())
    },
    name: "getStarted",
    nextButtons: [{ label: "Add goal now", nextSteps: ["addGoal"] }, { label: "Not now", nextSteps: ["dontAdd"], inverted: true }]
  },
  {
    name: "addGoal",
    beforeStep: async ({ apolloClient, chatState, setChatState }) => {
      firebase.analytics().logEvent("ADD_SAVINGS_GOAL_DONE" , { bucketType: chatState.bucketType })
      const { data } = await apolloClient.mutate({ mutation: ADD_SAVINGS_BUCKET, variables: {
        input: {
          bucketType: chatState.bucketType,
          targetAmount: chatState.targetAmount,
          targetDate: chatState.targetDate,
          desiredSavings: chatState.desiredSavings 
        }
      }, awaitRefetchQueries: true, refetchQueries: [{ query: MySavings.MY_SAVINGS_QUERY }] });
    },
    text: "Your goal has been added. Set up an EFT to this account to start saving!",
    component: ({ chatState }) => <><p /><BankDetails /><p /></>, 
    nextButtons: [{ label: "Add another goal", nextSteps: ["addAnother"] }, { label: "Done", nextSteps: ["mySavings"] }],
  },
  {
    name: "addAnother",
    beforeStep: () => navigate('/savings/add')
  },
  { 
    name: "mySavings",
    beforeStep: () => navigate('/savings')
  },
  {
    name: "dontAdd",
    text: "Thanks for checking out your goal. We are here to help",
    nextAfterDelay: 100,
    handleNext: () => navigate('/savings')
  }
]

const fields = {
  bucketType: {
    type: "select",
    options: [
      { value: "EMERGENCY", label: "Emergency" },
      { value: "EDUCATION", label: "Education" },
      { value: "HOLIDAY", label: "Holiday" },
      { value: "YEAR_END", label: "December" },
      { value: "CAR", label: "Car" },
      { value: "MEDICAL", label: "Health" },
      { value: "FUNERAL", label: "Funeral" },
      { value: "RETIREMENT", label: "Retirement" },
      { value: "HOUSE", label: "Housing" },
      { value: "OTHER", label: "Other" }
    ]
  },
  targetAmount: {
    type: "currency",
    validate: [isNumber],
  },
  durationAmount: {
    type: "number",
    validate: [isInteger],
    strip: true
  },
  durationType: {
    type: "select",
    options: ["Weeks", "Months", "Years"],
    strip: true
  },
  desiredSavings: {
    type: "currency",
    validate: [isNumber],
  }
}

function AddSavingsBucket({ bucketType }) {
  return <AddSavingsBucketContainer>
    <AddSavingsTitle>
      <Text heading>New goal {bucketType ? `for ${bucketType}` : ""}</Text>
    </AddSavingsTitle>
    <ChatFlow
      name="addSavingsBucket"
      fields={fields}
      steps={steps}
      initialValues={{
        durationType: "Months",
        bucketType,
        desiredSavings: 500
      }}
    >
    </ChatFlow>
  </AddSavingsBucketContainer>
}

export default AddSavingsBucket
