import React from 'react'
import { WizardModal } from '../../shared'
import SavingsQuote from '../SavingsQuote'
import {
  car,
  carSelected,
  education,
  educationSelected,
  emergency,
  emergencySelected,
  funeral,
  funeralSelected,
  holiday,
  holidaySelected,
  house,
  houseSelected,
  other,
  otherSelected
} from '../../../images/savingsGoals'
import { isNumber, isRequired } from '../../../lib/validators'
import { savingsGoalMetaData, targetFromMetaData, targetDateFromMetaData, dateFromAmount } from '../../../lib/savings'
import gql from 'graphql-tag'
import firebase from 'gatsby-plugin-firebase'
import { amountFromDate } from '../SavingsSlider/SavingsSlider'
import moment from 'moment'

const ADD_SAVINGS_BUCKET = gql`
  mutation AddSavingsBucket($input: SavingsBucketInfoInput) {
    addSavingsBucket(bucketInfo: $input) {
      id
      bucketInfo {  
        bucketType
        percentageFull
      }
    }
  }
`

const EDIT_SAVINGS_BUCKET = gql`
  mutation EditSavingsBucket($id: String!, $input: SavingsBucketInput!){
    updateSavingsBucket(id: $id, input: $input){
      id
      bucketType
    }
  }
`

const toKeyValue = data => {
  let result = []
  Object.keys(data || {}).forEach(key => {
    if (data[key]) {
      result.push({
        key,
        value: data[key]
      })
    }
  })
  return result
}


//get initial values from GQL meta data
const fromKeyValue = (metaData, bucketType) => {
  const schema = savingsGoalMetaData[bucketType];
  const metaDataObject = metaData && metaData.reduce((result, {key, value})=> {result[key]=value; return result }, {});
  return (metaData && schema) ? Object.keys(schema).map((key) => {
    let asNumber;
    switch(schema[key].type){
      //TODO: add convertion for field types as needed
      //case "currency":
      case "radiogroup":
        // try to convert to number first (not enough schema meta data)
        asNumber = Number(metaDataObject[key]);
        if(!Number.isNaN(asNumber)){
          return {key, value: asNumber};
        }
        return {key, value: metaDataObject[key]};
      case "date":
        return {key, value: Number(metaDataObject[key])};
      default:
        return {key, value: metaDataObject[key]};
    }
  }) : [];
}

const buildSteps = (setOpen, refetchQueries, id) => ([{
  name: "bucketType",
  title: "Set up your Savings Goal",
  title2: "What would you like to save for?",
  fieldNames: ["bucketType"],
  nextSteps: ({ bucketType }) => {
    !id && firebase.analytics().logEvent("ADD_SAVINGS_GOAL_START" , { bucketType })
    return [bucketType]
  },
  handleNext: ({ chatState, gotoNext }) => {
    delete chatState[chatState.bucketType]
    gotoNext()
  },
  nextAfterDelay: id && 1,
}, {
  name: "EMERGENCY",
  beforeStep: async ({ changeFieldValue, chatState }) => {
    if (!(chatState.EMERGENCY && chatState.EMERGENCY.income)) {
      changeFieldValue("EMERGENCY.income", chatState.income)
    }
  },
  title: "Set up your Savings Goal",
  title2: "Set up Emergency Fund",
  fieldNames: ["EMERGENCY.income", "EMERGENCY.goalMonthsInFund", "existingAmount"],
  nextSteps: ["quote"],
  nextTitle: "View Plan"
}, {
  name: "HOLIDAY",
  title: "Set up your Savings Goal",
  title2: "Set up My Holiday goals",
  fieldNames: ["HOLIDAY.destination", "HOLIDAY.tripDate", "targetAmount", "existingAmount"],
  nextSteps: ["quote"],
}, {
  name: "OTHER",
  title: "Set up your Savings Goal",
  title2: "Set up Custom Goal",
  fieldNames: ["description", "targetDate", "targetAmount", "existingAmount"],
  nextSteps: ["quote"],
}, {
  name: "FUNERAL",
  title: "Set up your Savings Goal",
  title2: "Set up Funeral Savings Goal",
  fieldNames: ["FUNERAL.currentPremiums", "FUNERAL.coverTypes", "FUNERAL.sumAssured", "FUNERAL.stopPayingAge"],
  nextSteps: ["quote"],
}, {
  name: "CAR",
  title: "Set up your Savings Goal",
  title2: "Set up Car Savings Plan",
  fieldNames: ["description", "CAR.savingFor", "targetAmount", "targetDate", "existingAmount"],
  nextSteps: ["quote"],
}, {
  name: "quote",
  beforeStep: async ({ changeFieldValue, chatState }) => {
    const { existingAmount, desiredSavings } = chatState;
    const targetAmount = targetFromMetaData(chatState);
    let targetDate = targetDateFromMetaData(chatState);
    changeFieldValue('targetAmount', targetAmount);
  
    console.log(targetAmount, targetDate)

    if (desiredSavings && !targetDate) {
      targetDate = dateFromAmount(targetAmount, existingAmount, desiredSavings)
      changeFieldValue('targetDate', targetDate);
    } else if (targetAmount && targetDate) {
      changeFieldValue('targetDate', targetDate);
      changeFieldValue('desiredSavings', amountFromDate(targetAmount, existingAmount, targetDate));
    }
    !id && firebase.analytics().logEvent("ADD_SAVINGS_GOAL_QUOTE" , { bucketType: chatState.bucketType });
  },
  component: SavingsQuote,
  nextSteps: ["done"],
  nextTitle: "Activate"
},
{
  name: "done",
  beforeStep: async ({ apolloClient, chatState }) => {
    if (id){
      await apolloClient.mutate({ mutation: EDIT_SAVINGS_BUCKET, variables: {
        id,
        input: { 
          bucketType: chatState.bucketType,
          targetAmount: chatState.targetAmount,
          targetDate: chatState.targetDate,
          desiredSavings: chatState.desiredSavings,
          metaData: toKeyValue(chatState[chatState.bucketType] || {}),
          existingAmount: chatState.existingAmount || 0,
          description: chatState.description,

        } 
      }, refetchQueries, awaitRefetchQueries: true  })
      //TODO: fix events....
      //firebase.analytics().logEvent("ADD_SAVINGS_GOAL_DONE" , { bucketType: chatState.bucketType });
    }else{
      await apolloClient.mutate({ mutation: ADD_SAVINGS_BUCKET, variables: { input: { 
        bucketType: chatState.bucketType,
        targetAmount: chatState.targetAmount,
        targetDate: chatState.targetDate,
        desiredSavings: chatState.desiredSavings,
        metaData: toKeyValue(chatState[chatState.bucketType] || {}),
        existingAmount: chatState.existingAmount || 0,
        description: chatState.description,
      } }, refetchQueries, awaitRefetchQueries: true  })
      firebase.analytics().logEvent("ADD_SAVINGS_GOAL_DONE" , { bucketType: chatState.bucketType });
    }
    setOpen(false)
  } 
}])

function greaterThanZero(v){
  if(v > 0) { 
    return
  }
  return "Must be greater than zero"
}

function inTheFuture(date){
  if(-moment().startOf('day').diff(date) >= 0) { 
    return
  }
  return "Must be a future date"
}

const fields = {
  bucketType: {
    type: "imagegridselect",
    options: [{
      label: "Emergency Fund",
      value: "EMERGENCY",
      image: emergency,
      selectedImage: emergencySelected
    }, 
    /*{
      label: "Property",
      value: "HOUSE",
      image: house,
      selectedImage: houseSelected
    },*/
    {
      label: "Car",
      value: "CAR",
      image: car,
      selectedImage: carSelected
    },
    /*{
      label: "Education",
      value: "EDUCATION",
      image: education,
      selectedImage: educationSelected
    },*/
    {
      label: "Funeral",
      value: "FUNERAL",
      image: funeral,
      selectedImage: funeralSelected
    },
    {
      label: "Holiday",
      value: "HOLIDAY",
      image: holiday,
      selectedImage: holidaySelected
    },
    {
      label: "Custom Goal",
      value: "OTHER",
      image: other,
      selectedImage: otherSelected
    }],
    validate: [isRequired]
  },
  ...Object.keys(savingsGoalMetaData).reduce((result, bucketType) => {
    return {
      [bucketType]: {
        type: "keyvalue",
        config: savingsGoalMetaData[bucketType],
      },
      ...result
    }
  }, {}),
  existingAmount: {
    label: "Existing Savings",
    type: "currency",
    validate: [isNumber]
  },
  desiredSavings: {
    label: "Savings Contribution",
    type: 'currency',
    validate: [isNumber]
  },
  targetDate: {
    label: "Savings Goal Reached",
    type: 'date',
    validate: [isRequired, inTheFuture]
  },
  description: {
    label: "What are you saving up for?",
    type: 'text'
  },
  targetAmount: {
    label: "How much do you need?",
    type: "currency",
    validate: [isNumber, greaterThanZero]
  }
}

function SavingsGoalWizard({ open, setOpen, bucketType, refetchQueries, id, bucketData, income, birthDate }) {
  
  const useData = bucketData && {...bucketData};  
  if(useData && useData.bucketType && useData.metaData) {
    useData[useData.bucketType] = fromKeyValue(useData.metaData, useData.bucketType)
    useData.income = income
    useData.birthDate = birthDate
  }

  return <WizardModal
    name={id ? "editGoal" : "addGoal"}
    mode={"add"}
    id={id}
    open={open}
    setOpen={setOpen}
    steps={buildSteps(setOpen, refetchQueries, id)}
    initialValues={useData || { income, birthDate, bucketType, desiredSavings: 500 }}
    fields={fields}
  />
}

export default SavingsGoalWizard
