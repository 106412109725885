import styled from '@emotion/styled'

const AddSavingsBucketContainer = styled.div`
  margin-top: 7.8%;
  margin-left: 3%;
`

const AddSavingsTitle = styled.div`
  margin-bottom: 28px;
`

export {
  AddSavingsBucketContainer,
  AddSavingsTitle
}