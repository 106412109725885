import styled from '@emotion/styled'

const PrivateMenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 28px;
  width: 65px;
  position: relative;
`

const PrivateMenuItemImage = styled.img`
  height: 16px;
  width: 16px;
  margin-bottom: 1px;
`

const AlertImage = styled.img`
  position: absolute;
  top: 2px;
  right: 26.5px;
  z-index: 2;
`

export {
  PrivateMenuItemContainer,
  PrivateMenuItemImage,
  AlertImage
}