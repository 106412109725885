import React from 'react'
import MokuNotePanel from '../../shared/MokuNotePanel'
import WizardModal  from '../../shared/WizardModal'
// import { printCurrency } from '../../../lib/utils'
// import {} from './UpdateClearanceCertificateObligationModal.style'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'

/*const GET_CC_OBLIGATION_DETAILS = gql`query($id: String, $accessCode: String) {
  get(id)
}`*/

const ADD_OR_UPDATE_OBLIGATION = gql`
  mutation($id: String, $input: ObligationClearanceCertificateInput, $accessCode: String) {
    addOrUpdateObligationClearanceCertificate(clearanceCertificateId: $id, input: $input, accessCode: $accessCode) {
      id
      forObligations {
        id
        currentBalance
        description
        status
        statusAt
      }
      accessCode
    }
  }
`

const buildSteps = (setOpen) => ([{
  name: "step1",
  title: "Edit Paid Up Details",
  title2: "Update the Details Below",
  title3: <MokuNotePanel title="Don't have proof it is settled?" description="We can contact your creditors to get a settlement letter for you if you don't have one - select this under status and for a fee we will do this for you" />,
  fieldNames: ["description", "currentBalance", "status", "lastPaymentDate", "proofId"],
  nextTitle: "Save",
  nextSteps: ["save"]
}, {
  name: "save",
  submitBeforeStep: true,
  noNext: true,
  nextAfterDelay: 1000,
  nextSteps: ["done"]
}, {
  name: "done",
  beforeStep: () => setOpen(false)
}])

const fields = {
  description: {
    label: "Creditor Name",
    type: "text",
    disable: true
  },
  currentBalance: {
    label: "Indicated Balance",
    type: "text",
    disable: true
  },
  status: {
    label: "Status",
    type: "select",
    options: [{
      value: "ACTIVE",
      label: "Not Paid Up"
    }, /*{
      value: "PRESCRIBED",
      label: "Prescribed"
    }, {
      value: "EXCLUDED",
      label: "Not Part of Debt Review"
    },*/ {
      value: "PAID_UP_REQUEST_PROOF",
      label: "Paid Up - Meerkat to get proof"
    }, {
      value: "PAID_UP_CLIENT_PROVIDE_PROOF",
      label: "Paid Up - I will upload settlement letters"
    }]
  },
  lastPaymentDate: {
    label: "Last Payment Date",
    type: "date",
  },
  proofId: {
    label: "Proof of Status (can upload later)",
    type: "fileupload",
    prompt: "Upload Settlement Proof"
  },
}

function buildAddOrUpdateObligationInput(clearanceCertificateId, id, input, accessCode) {
  let result = {
    id: clearanceCertificateId,
    input: {
      id,
      description: input.description,
      currentBalance: input.currentBalance,
      status: input.status,
      lastPaymentDate: input.lastPaymentDate
    },
    accessCode
  }

  if (input.proofId) {
    result.input.proofDocumentIds = [input.proofId]
  }

  console.log("Sending", result)

  return result
}

function UpdateClearanceCertificateObligationModal({ open, setOpen, accessCode, setAccessCode, obligation, clearanceCertificateId, refetchQueries }) {
  console.log("AccessCode=", accessCode)

  // const [] = useQuery(GET)

  return <WizardModal
    name={`updateCCObligation-${(obligation && obligation.id) || "new"}`}
    mode="add"
    addMutation={ADD_OR_UPDATE_OBLIGATION}
    open={open}
    setOpen={setOpen}
    steps={buildSteps(setOpen)}
    mapInputVariables={input => buildAddOrUpdateObligationInput(clearanceCertificateId, obligation.id, input, accessCode)}
    initialValues={obligation ? { id: obligation.id, description: obligation.description, status: obligation.status || "ACTIVE", lastPaymentDate: obligation.lastPaymentDate, currentBalance: obligation.currentBalance } : {}}
    fields={fields}
    zIndex={1001}
    afterSubmit={({ data }) => {
      console.log(data)
      if (data && data.addOrUpdateObligationClearanceCertificate && data.addOrUpdateObligationClearanceCertificate.accessCode) {
         setAccessCode(data.addOrUpdateObligationClearanceCertificate.accessCode)
      }
    }}
    startWithCleanState={false}
  />
}

export default UpdateClearanceCertificateObligationModal
