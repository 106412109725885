import React, { ReactNode } from 'react'
import MokuPopup from '../../../images/svgs/mokuPopup.svg'
import theme from '../../../theme.style'
import { Text } from '../../generic'
import Button from '../../generic/Button'
import { ComponentPadder } from '../../Layout'

interface IErrorPageProps {
  onButtonClick?: () => void
  message: ReactNode
  showButton: boolean
}

function ErrorPage({ onButtonClick, message, showButton }: IErrorPageProps) {
  const onClick = onButtonClick
    ? onButtonClick
    : () => {
        return
      }
  const msg = message ? (
    message
  ) : (
    <>
      We&apos;re experiencing a setback. <br />
      Don&apos;t sweat, contact our support team and we will get you back on-track.
    </>
  )

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        minHeight: '500px',
        height: '100%',
        padding: '30px',
      }}
    >
      <MokuPopup />
      <Text style={{ maxWidth: '454px', fontWeight: 'bold' }} size={24} color="primary">
        {msg}
      </Text>
      <ComponentPadder padding="41px 0px 24px 0px">
        {showButton === undefined || showButton ? (
          <Button style={{ height: '44px' }} onClick={onClick}>
            <Text color={theme.colors.white} size={16}>
              Request call back
            </Text>
          </Button>
        ) : (
          <></>
        )}
      </ComponentPadder>
    </div>
  )
}

export default ErrorPage
