import styled from '@emotion/styled'
import { device } from '../../../theme.style'

const ToggleProductOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
  flex: 1;
  min-width: max(calc(50% - 158px), 300px);
  margin-right: 8px;

  ${device.tabletS`
    margin-right: 0;
  `}
  
`

const ToggleProductOptionTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 4px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  height: 36px;
  border-left: ${props => props.noIndicator ? "0px" : "8px"} solid ${props => props.theme.colors[props.color || "alertRed"]};
  background-color: ${props => props.theme.colors.grey};
`

// TODO: set to style
const AlertNotification = styled.div`
  background-color: ${props => props.alert ? "#EB5757" : props.theme.colors.grey};
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin-right: 5px;
`

const ExpanderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40px;
  height: 36px;
  align-items: center;
  justify-content: center;
`
const TitleTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  # background-color: ${props => props.expanded ? props.theme.colors.grey : "none"};
`



export {
  ToggleProductOptionContainer,
  ToggleProductOptionTitleContainer,
  AlertNotification,
  ExpanderContainer,
  TitleTextContainer,
  ContentContainer
}