import styled from '@emotion/styled'
import { device } from '../../../theme.style'

const AddBucketContainer = styled.div`
  width: 45%;
  max-width: 300px;
  background-color: #EFDCB7;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 11px;
  margin-left: 7px;
  margin-bottom: 8px;
  margin-right: 7px;
`

const AddBucketImage = styled.div`
  width: 98px;
  height: 98px;
  border-radius: 49px;
  border-width: 3px;
  border-color: #404357;
  margin-top: 64px;
  margin-bottom: 14px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;

  ${device.laptop`
    width: 50px;
    height: 50px;
  `}
`

const NewBucketDesktopContainer = styled.div`
  ${device.tablet`
    display: none;
  `}
`

const NewBucketMobileContainer = styled.div`
  display: none;

  ${device.tablet`
    display: block;
    position: fixed;
    width: 93.6%;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 50px;
  `}
`

const MySavingsContainer = styled.div`
  margin-top: 7.8%;
  padding-left: 3.2%;
  padding-right: 3.2%;
  margin-bottom: 7.8%;
`

export {
  AddBucketImage,
  AddBucketContainer,
  NewBucketDesktopContainer,
  NewBucketMobileContainer,
  MySavingsContainer
}