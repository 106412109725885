import styled from '@emotion/styled'
import { Label } from '../../generic'

const BankingDetailsContainer = styled.div`
  background: #f9f8f8;

  box-shadow: inset 0px 0px 10px rgba(64, 67, 87, 0.08);
  border-radius: 4px;
  margin: -1px 0px;

  & > div {
    border-top: 2px solid white;
  }
  & > div:first-child {
    border-top: none;
  }
`
const BankingDetailsRow = styled.div`
  margin: 2px 0px;
  padding: 12px 16px;
`

const ReferenceLabel = styled(Label)`
  margin: -5px 0px;
`

export { BankingDetailsContainer, BankingDetailsRow, ReferenceLabel }
