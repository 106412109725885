import styled from '@emotion/styled'

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.grey};
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(64, 67, 87, 0.12);
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 64px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    margin: 0;
  }
`

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`

export {
  MenuItemContainer,
  LeftContainer,
  RightContainer
}