import styled from '@emotion/styled'

const SavingsBucketContainer = styled.div`
  margin-bottom: 100px
`

const BlobContainer = styled.div`
  position: relative;
`

const BlobImage = styled.img`
  position: relative;
  margin: 0;
`

const BucketImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0;
`

const DatePickerContainer = styled.div`
  width: 100%;
  max-width: 360px;

  .MuiFormControl-root {
    min-width: 0 !important
  }
`

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const ContentCard = styled.div`
  background: #FFFFFF;
  box-shadow: inset 0px 0px 10px rgba(64, 67, 87, 0.08);
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 32px;
  padding: 12px;
`

const BucketGraphContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 4px;
`

const BucketGraphDescriptionContainer = styled.div`
  position: absolute;
  left: calc(50% - 38px);
  width: 76px;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

export {
  SavingsBucketContainer,
  BlobContainer,
  BlobImage,
  BucketImage,
  DatePickerContainer,
  ActionButtons,
  ContentCard,
  BucketGraphContainer,
  BucketGraphDescriptionContainer
}