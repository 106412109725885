import styled from '@emotion/styled'
import { device } from '../../theme.style'

const MenuBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 145px;
  padding: 0;
  margin: 0;
  height: 100vh;
  background: ${props => props.theme.colors.lightGreen};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);

  ${device.tabletS`
    display: none;
  `}
`

const MenuBarItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const MenuBarItemContainer = styled.div`
  z-index: 1;
  height: 92px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const SelectedMenuItem = styled.div`
  z-index: 0;
  position: absolute;
  top: ${props => 139 + ((props.selectedIndex || 0) * 92)}px;
  background: white;
  left: 0;
  width: 145px;
  height: 92px;

  :after {
    position: absolute;
    top: 15px;
    right: 0px;
    width: 5px;
    height: 62px;
    content: " ";
    background: ${props => props.theme.colors.primary};
  }
`

const MenuBarHeaderContainer = styled.div`
  margin-top: 44px;
  margin-bottom: 51px;
`

const MeerkatLogo = styled.img`
  margin: 0;
  width: 102px;
  margin-bottom: 6px;
`

const LogoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 33px;
  margin-bottom: 33px;
  width: 145px;
`

const LogoutImage = styled.img`
  margin: 0;
  margin-left: 9px;
`

export {
  MenuBarContainer,
  MenuBarItemsContainer,
  MenuBarItemContainer,
  SelectedMenuItem,
  MenuBarHeaderContainer,
  MeerkatLogo,
  LogoutContainer,
  LogoutImage
}
