import React, { useState } from "react"
import { BackActionHeader } from '../../shared'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { ActivityIndicator, Text } from '../../generic'
import { navigate } from 'gatsby'

const GET_GROUP = gql`
  query GetGroup($id: String!) {
    group(id: $id) {
      id
      name
      products {
        id
        groupProductNumber
      }
    }
  }
`

function GroupDetails({ id }) {
  const { data, loading, error } = useQuery(GET_GROUP, { variables: { id } })

  if (loading) {
    return <ActivityIndicator />
  }

  const { group } = data
  return <div>
  
    <BackActionHeader title="Group Sales" onClick={() => navigate('/group-sales')} />
    <Text bold size={26} style={{ padding: "2px" }}>
      {group.name}
    </Text>
    {group.products.map((groupProduct, index) => 
      <Text key={index} bold size={16} style={{ padding: "2px" }} onClick={() => navigate(`/group-funeral/${groupProduct.id}`)}>
        {groupProduct.groupProductNumber}
      </Text>
    )}
  </div>
}

export default GroupDetails