import React from 'react'
import './style.css'
import { VictoryBar, VictoryChart, VictoryAxis, Bar, VictoryGroup } from 'victory'

interface ICustomBarProps {
  svgIcons: SVGElement[]
  x: number
  y: number
  datum: any
  index: number
  xOffset?: number
}

class CustomBar extends React.Component<ICustomBarProps> {
  render() {
    const { x, y, index, svgIcons } = this.props
    const xOffset = this.props.xOffset ? this.props.xOffset : 0
    const SvgIcon = svgIcons[index] as SVGElement
    const [iconX, iconY] = [-21, -47 - 4.5] //TODO: generalize

    return (
      <>
        <Bar {...this.props} />
        <g transform={`translate(${x + xOffset} ${y}),scale(0.5 0.5)`}>
          {/** @ts-ignore */}
          <SvgIcon x={iconX} y={iconY} />
        </g>
      </>
    )
  }
}

function CustomLine(props: any) {
  const lineStyle = {
    vectorEffect: 'non-scaling-stroke' as const,
    shapeRendering: 'auto' as const,
  }

  const { x1, y1, x2, y2, strokeWidth, stroke } = props
  const lineProps = { x1, y1, x2, y2, stroke, strokeWidth }

  return (
    <>
      <defs>
        <marker
          id="triangle"
          viewBox="0 0 4 4"
          refX="1 "
          refY="2"
          markerUnits="strokeWidth"
          markerWidth="12"
          markerHeight="9.6"
          orient="auto-start-reverse"
        >
          {/* @ts-ignore */}
          <path d={'M 2 0 L 2 4 L 0 2 z'} fill={props.stroke} />
        </marker>
      </defs>
      <line {...lineProps} style={lineStyle} markerEnd="url(#triangle)" markerStart="url(#triangle)" />
    </>
  )
}

export interface IDebtRepaymentGraphProps {
  data: any[]
  averageData?: any[]
  svgIcons: SVGElement[]
  children?: React.ReactNode

  barWidth: number
  barSpacing: number
  groupOffset: number
  leftPadding?: number
}

function DebtRepaymentGraph(props: IDebtRepaymentGraphProps) {
  const { data, averageData, children, barWidth, barSpacing } = props

  //TODO: Add sets/setSize prop (generic group bar graph)
  const groupSize = averageData ? 2 : 1 //TODO: generalize

  const leftPadding = props.leftPadding === undefined ? 0 : props.leftPadding
  const groupOffset = props.groupOffset === undefined ? barWidth : props.groupOffset
  const svgIcons = props.svgIcons === undefined ? [] : props.svgIcons

  const getAdjustedBarWidthMultiplier = (
    barWidth: number,
    barSpace: number,
    groupOffset: number,
    groupSize: number
  ) => {
    const setSize = data.length
    const netWidth = (groupOffset * (groupSize - 1) + barWidth) * setSize + barSpace * (setSize - 1)
    return (chartWidth - leftPadding) / netWidth
  }

  const maxY = Math.max(...data.map((d) => d.y))
  const chartWidth = 160 //284

  const barMulti = getAdjustedBarWidthMultiplier(barWidth, barSpacing, groupOffset, groupSize)
  const barWidthAdj = barWidth * barMulti
  const groupOffsetAdj = groupOffset * barMulti
  const groupWidth = barWidthAdj + groupOffsetAdj * (groupSize - 1)

  const axisStyle = { stroke: '#404357', strokeWidth: 1 }

  return (
    <div className="chartDiv" style={{ maxWidth: '400px' }}>
      <VictoryChart
        style={{ parent: { overflow: 'visible', fill: 'none' } }}
        domainPadding={{
          x: [groupOffsetAdj + leftPadding + 0.5, groupOffsetAdj],
        }}
        padding={0} //{left:5, top:2, bottom:2}}
        height={100}
        width={chartWidth}
        minDomain={{
          y: -1 * ((maxY / 100) * 4),
        }}
        maxDomain={{
          y: maxY * 1.5,
        }}
      >
        <VictoryAxis
          dependentAxis
          tickFormat={() => ''}
          style={{ axis: axisStyle }}
          axisComponent={<CustomLine {...axisStyle} />}
        />
        <VictoryAxis tickFormat={() => ''} offsetY={0} style={{ axis: axisStyle }} />
        <VictoryGroup
          offset={groupOffsetAdj}
          domainPadding={{ x: [-groupOffsetAdj / groupSize, barWidthAdj - groupOffsetAdj / groupSize] }}
        >
          <VictoryBar
            alignment="start"
            animate={{
              duration: 2000,
            }}
            style={{
              data: {
                fill: ({ datum }) => datum.fill,
              },
            }}
            barWidth={barWidthAdj}
            data={data}
            cornerRadius={(barWidthAdj * 4) / 30}
            // @ts-ignore
            dataComponent={<CustomBar xOffset={groupWidth / 2} svgIcons={svgIcons} />}
          />
          <VictoryBar
            alignment="start"
            animate={{
              duration: 2000,
            }}
            style={{
              data: {
                fill: ({ datum }) => datum.fill,
              },
            }}
            barWidth={barWidthAdj}
            data={averageData}
            cornerRadius={(barWidthAdj * 4) / 30}
          />
        </VictoryGroup>
        {children}
      </VictoryChart>
    </div>
  )
}

export default DebtRepaymentGraph
