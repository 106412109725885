import React, { useState } from 'react'
import { Tabs, Text, Button } from '../../generic'
import { BackActionHeader } from '../../shared'
import ProfileForm from '../ProfileForm'
import { navigate } from 'gatsby'
import UpdateProfilePicture from '../UpdateProfilePicture'

function MyProfile() {
  const [open, setOpen] = useState(false)

  return <>
    <BackActionHeader title="My Profile" onClick={() => navigate('/profile')} />
    <Text heading><p>Profile Settings</p></Text>
    <UpdateProfilePicture open={open} setOpen={setOpen} />
    <p><Button inverted title="Update Photo" onClick={() => setOpen(true)}/></p>
    
    <Tabs>
      <ProfileForm tabTitle="Personal Information" />
      {/*<div tabTitle="Banking Information" />*/}
    </Tabs>
  </>
}

export default MyProfile
